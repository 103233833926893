import { Progress } from '@askable/ui/core/progress';
import _ from 'lodash';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { useState } from 'react';
import { Box } from 'ui';

import './styles/cardContainerStyles.scss';

function CardContainer(props: any) {
  const [step, setStep] = useState(0);
  const isLastStep = step === _.size(_.get(props, 'cardTexts', [])) - 1;
  const isFirstStep = step === 0;

  return (
    <Box
      id={props.cardId || '__cardContainer'}
      key={props.cardId || '__cardContainer'}
      className={`cardContainer ${props.additionalCard ? 'additionalCard' : ''} ${props.cardClass || ''}`}
      padding="24px"
      border="1px"
      borderColor="gray.200"
      borderRadius="8px"
      marginBottom="16px"
    >
      {props.sliderCard && (
        <>
          <p className="cardTitle">{_.get(props.cardTexts, `${[step]}.cardTitle`)}</p>
          <p className="cardText">{_.get(props.cardTexts, `${[step]}.cardText`)}</p>
          <div className="buttonsContainer">
            <div
              className={`backButton ${isFirstStep ? 'inactive' : ''}`}
              onClick={() => {
                if (!isFirstStep) setStep(step - 1);
              }}
            >
              <ArrowLeft color="#888" style={{ width: 20, height: 20 }} />
            </div>
            <div className="stepperButtonsComponent">
              {_.map(props.cardTexts, (stepCount: any, index: any) => {
                return <div key={`step-${index}`} className={`step-${index} ${index === step ? 'active' : ''}`} />;
              })}
            </div>
            <div
              className={`forwardButton ${isLastStep ? 'inactive' : ''}`}
              onClick={() => {
                if (!isLastStep) setStep(step + 1);
              }}
            >
              <ArrowRight className="arrow h-5 w-5" color="#888" />
            </div>
          </div>
        </>
      )}
      {!props.sliderCard && (
        <>
          {_.get(props, 'cardTitle') && <p className="cardTitle">{props.cardTitle}</p>}
          {_.get(props, 'cardText') && <p className="cardText">{props.cardText}</p>}
          {_.map(_.get(props, 'cardTexts'), (item: any, index: any) => {
            if (_.get(item, 'middleLink')) {
              return (
                <div
                  id={`__middleCardLinkContainer_${index}`}
                  key={`__middleCardLinkContainer_${index}`}
                  className="middleCardLinkContainer"
                  onClick={_.get(item, 'middleLinkAction')}
                >
                  <span className="middleCardLink">{_.get(item, 'middleLinkText', 'Learn more')}</span>
                  {!_.get(item, 'hideMiddleLinkArrow') && (
                    <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
                  )}
                </div>
              );
            }
            return (
              <div
                key={`cardText${index}`}
                className={`cardText ${_.get(item, 'additionalText') ? 'additionalText' : ''}`}
              >
                {item.cardText}
                {_.get(item, 'indicators') && (
                  <div className="cardIndicatorContainer">
                    {_.map(_.get(item, 'indicators'), (ind: any, indexInd: any) => {
                      return (
                        <div key={`cardIndicator${indexInd}`} className="cardIndicatorContent">
                          <div className="cardIndicatorInfo">
                            <p className="cardIndicatorLabel">{ind.label}</p>
                            <p className="cardIndicatorValue">{ind.displayValue}</p>
                          </div>

                          <Progress steps={ind.maxValue} currentStep={ind.value} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
          {_.get(props, 'onLinkClick') && (
            <div className="cardLinkContainer" onClick={props.onLinkClick}>
              <span className="cardLink">{_.get(props, 'linkText', 'Learn more')}</span>
              {!props.hideArrow && (
                <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
              )}
            </div>
          )}
          {_.get(props, 'onAdditionalLinkClick') && (
            <div className="cardLinkContainer additionalLink" onClick={props.onAdditionalLinkClick}>
              <span className="cardLink">{_.get(props, 'additionalLinkText', 'Learn more')}</span>
              {!props.hideAdditionalArrow && (
                <span className={`arrow ${navigator.platform === 'Win32' ? 'winOS' : ''}`}>&rarr;</span>
              )}
            </div>
          )}
        </>
      )}
    </Box>
  );
}

export default CardContainer;

import { DropdownMenuItem } from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { DuplicateUnmoderatedBooking } from 'containers/Studies/data/DuplicateUnmoderatedBooking.mutation';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE } from 'lib/constants';

import { CellActions } from '../components/CellActions';
import { DialogArchive } from '../components/DialogArchive';
import { DialogDuplicate } from '../components/DialogDuplicate';

import { ArchiveBookingMutation } from './data/ArchiveBooking.mutation';
import { DuplicateBookingMutation } from './data/DuplicateBooking.mutation';

import type { Booking, BookingConfig } from 'generated/graphql';

type Props = {
  status: Booking['status'];
  type: Booking['type'];
  name: Booking['name'];
  _id: Booking['_id'];
  demo: BookingConfig['demo'];
};
export function BookingsCellActions({ type, status, name, _id, demo }: Props) {
  const navigate = useNavigate();
  const [, duplicateBooking] = useMutation(DuplicateBookingMutation);
  const [, duplicateUnmoderatedBooking] = useMutation(DuplicateUnmoderatedBooking);
  const [, archiveBooking] = useMutation(ArchiveBookingMutation);

  const [isDuplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const onConfirm = async () => {
    const newBooking =
      type === BOOKING_TYPE.UNMODERATED
        ? await duplicateUnmoderatedBooking({ _id })
        : await duplicateBooking({ booking_id: _id });

    const newId = newBooking.data
      ? 'duplicateUnmoderatedBooking' in newBooking.data
        ? newBooking.data.duplicateUnmoderatedBooking._id
        : newBooking.data?.duplicateBooking?._id
      : undefined;

    if (newBooking.error || !newId) {
      toast.error(
        `Duplication failed. ${newBooking.error ? newBooking.error?.message : 'Duplicated booking id not found'}`,
      );
      return;
    }

    navigate(`/booking-setup/${newId}/project-setup/project-title`, { state: { bookingID: newId } });
  };

  const onArchive = async () => {
    const archivedBooking = await archiveBooking({
      booking_id: _id,
    });

    if (archivedBooking.error) {
      toast.error(`Duplication failed. ${archivedBooking.error?.message}`);
      return;
    }

    toast.success('Booking archived');
    setArchiveDialogOpen(false);
  };

  const canDuplicate = bookingUtils.canDuplicate({ config: { demo } });
  const canArchive = bookingUtils.canArchive({ status, config: { demo } });

  if (!canDuplicate && !canArchive) {
    return null;
  }
  return (
    <>
      <CellActions
        dropdownMenuContents={
          <>
            {canDuplicate && (
              <DropdownMenuItem onClick={() => setDuplicateDialogOpen(true)}>Duplicate</DropdownMenuItem>
            )}
            {canArchive && <DropdownMenuItem onClick={() => setArchiveDialogOpen(true)}>Archive</DropdownMenuItem>}
          </>
        }
      />
      <DialogDuplicate
        open={isDuplicateDialogOpen}
        onDuplicate={onConfirm}
        onCancel={() => setDuplicateDialogOpen(false)}
        onOpenChange={setDuplicateDialogOpen}
        resourceName={name || ''}
        resourceTypeName="study"
      />
      <DialogArchive
        open={isArchiveDialogOpen}
        onArchive={onArchive}
        onCancel={() => setArchiveDialogOpen(false)}
        onOpenChange={setArchiveDialogOpen}
        resourceName={name || ''}
        resourceTypeName="study"
      />
    </>
  );
}

import { AnchorButton, Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { ArrowLeft, ChevronDown, ChevronLeft, ChevronRight, Loader2, RefreshCcw, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { supportedSubmissionStatuses } from '../types/submissionStatus';

import type { SupportedSubmissionStatus } from '../types/submissionStatus';

/**
 * Header for the Booking Messages Container.
 * Paginates the messages and allows filtering by status.
 */

interface HeaderProps {
  activeStatuses: SupportedSubmissionStatus[];
  bookingId: string;
  currentPage: number;
  fetching: boolean;
  isStudy?: boolean;
  perPage: number;
  totalCount: number;
  totalPages: number;
  onFilterChange: (filter: SupportedSubmissionStatus | null) => void;
  onPageChange: (page: number) => void;
  onRefresh: () => void;
}

const getPaginationCount = (totalItems: number, currentPage: number, perPage: number) => {
  const firstItem = (currentPage - 1) * perPage + 1;
  const lastItem = Math.min(currentPage * perPage, totalItems);

  return `${firstItem}-${lastItem} of ${totalItems}`;
};

export const Header = ({
  activeStatuses,
  bookingId,
  currentPage = 1,
  fetching,
  isStudy,
  perPage,
  totalCount = 0,
  totalPages = 1,
  onFilterChange,
  onPageChange,
  onRefresh,
}: HeaderProps) => {
  const { t } = useTranslation();
  const backLink = isStudy ? `/studies/${bookingId}/recruit/participants` : `/bookings/${bookingId}/participants/all`;

  return (
    <header className="flex items-start justify-between gap-2 border-b-0.5 border-border p-3 md:items-center md:px-4">
      <div className="flex items-center gap-2">
        {bookingId ? (
          <AnchorButton variant="ghost" size="icon" title={t('sections.navigation.backToStudy')} asChild>
            <Link to={backLink} title={t('sections.navigation.backToStudy')}>
              <ArrowLeft className="h-4 w-4" />
            </Link>
          </AnchorButton>
        ) : null}

        <h2 className="pr-2 font-semibold text-foreground">{t('sections.messages.title')}</h2>
      </div>

      <div className="flex flex-wrap items-center justify-end gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              {activeStatuses.length > 0
                ? activeStatuses.map(f => t(`submission.status.${f}`)).join(', ')
                : t('sections.messages.filterByStatus')}
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            {supportedSubmissionStatuses.map(status => (
              <DropdownMenuCheckboxItem
                key={status}
                checked={activeStatuses.includes(status)}
                onCheckedChange={() => onFilterChange(status)}
              >
                {t(`submission.status.${status}`)}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        {activeStatuses.length > 0 ? (
          <Button onClick={() => onFilterChange(null)} aria-label={t('global.cancel')} variant="ghost" size="icon">
            <X className="h-4 w-4" />
          </Button>
        ) : null}

        <Button onClick={onRefresh} aria-label={t('global.refresh')} variant="ghost" size="icon" disabled={fetching}>
          {fetching ? (
            <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
          ) : (
            <RefreshCcw className="h-4 w-4" />
          )}
        </Button>

        {totalPages > 1 ? (
          <div className="flex items-center gap-2">
            <div className="whitespace-nowrap text-sm text-muted-foreground">
              {getPaginationCount(totalCount, currentPage, perPage)}
            </div>

            <Button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              title={t('global.previous')}
              size="icon"
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            <Button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage >= totalPages}
              title={t('global.next')}
              size="icon"
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>
        ) : null}
      </div>
    </header>
  );
};

import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { EsignatureTemplate } from 'containers/Studies/Recruit/data/EsignatureTemplate.query';
interface SummaryItemCustomTermsProps {
  studyId: string;
  templateId: string;
}

export const SummaryItemCustomTerms = ({ studyId, templateId }: SummaryItemCustomTermsProps) => {
  const { t } = useTranslation();
  const [templateQuery] = useQuery({
    query: EsignatureTemplate,
    pause: !studyId,
    variables: {
      id: studyId,
    },
  });

  const getTemplate = () => {
    const template = templateQuery.data?.getBookingESignatureTemplates?.find(item => item?._id === templateId);
    return [template?.name ?? ''];
  };

  return (
    <SummaryItem
      label={t('sections.studies.recruit.fields.customTermsTitle')}
      type="custom_terms_title"
      options={getTemplate()}
    />
  );
};

import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Check = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path fill={bgHsl} d="M46.2 53.5c-12.9 12.6-36 6.2-41.1-9.7-4.5-14.2 3.6-31 22-36l19.1 45.7Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M26.9 7c.3 0 .7.2.9.5l19.1 45.7c.1.3 0 .6-.1.8-6.7 6.6-16 8.1-24.3 6A25 25 0 0 1 4.4 44C-.2 29.4 8 12 26.9 7Zm-.3 1.7C9.1 13.7 1.6 30 5.8 43.5a23.5 23.5 0 0 0 17 15.1 24 24 0 0 0 22.5-5.3L26.6 8.7Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="M39.5 56c-11.7 3.4-24.8-2.2-28.8-18.2C2.1 3 52.3-5.8 59.7 27.4 62.7 41 50.2 53 39.5 56Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M29 6.1a26.5 26.5 0 0 1 31.6 21c1.6 7.3-.9 14-5.1 19.3A32.1 32.1 0 0 1 39.8 57a23.6 23.6 0 0 1-18-2 26 26 0 0 1-12-17 26 26 0 0 1 3-21.9c3.8-5.4 9.8-8.8 16.2-10ZM14.5 17.4a24 24 0 0 0-2.8 20.2 24 24 0 0 0 11 15.7c5 2.8 11 3.4 16.6 1.8 5-1.4 10.7-5 14.6-10 4-5 6.2-11 4.8-17.5A24.5 24.5 0 0 0 29.4 8.1c-6 1.1-11.5 4.3-14.9 9.3Z"
        clipRule="evenodd"
      />
      <path fill={bgHsl} d="M49.3 21.7 29.7 43.3 19.8 32l9.9 7 19.6-17.3Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M50.4 20.6c.6.6.6 1.5 0 2.1L30.9 44.3a1.5 1.5 0 0 1-2.3 0L18.7 33a1.5 1.5 0 0 1 2-2.2l8.9 6.3 18.8-16.5c.6-.6 1.5-.5 2 0ZM30.2 40.4a1 1 0 0 1-1 0l.5.6.5-.6Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { BookingRecruitGender, BookingRecruitLanguageInput, UpdateBookingConfigInput } from 'generated/graphql';

export function mapFormFieldsToBooking({
  dirtyValues,
  values,
}: {
  dirtyValues: Partial<RecruitFormFields>;
  values: RecruitFormFields;
}): UpdateBookingConfigInput | undefined {
  const { enable_advanced_filters, enable_custom_terms, ...actualValues } = dirtyValues;

  // NOTE: if there are no actual dirty values, we want to be able to bail e.g. in the update flow
  if (!Object.keys(actualValues).length) {
    return undefined;
  }

  // Extract the fields that don't directly map into the recruit schema
  const {
    _primary_contact_id,
    listing_title,
    listing_description,
    custom_terms_template_id,
    custom_terms_recipients,
    ...recruit
  } = actualValues;

  return {
    recruit: {
      ...recruit,
      gender: dirtyValues.gender
        ? !values.gender || !values.gender?.length || values.gender?.includes?.('any_gender')
          ? []
          : (values.gender as BookingRecruitGender[])
        : undefined,
      participants_excluded_months: recruit.participants_excluded_months
        ? recruit.participants_excluded_months === 'null'
          ? null
          : parseInt(recruit.participants_excluded_months)
        : undefined,
      industry_and_occupation: recruit.industry_and_occupation
        ? recruit.industry_and_occupation?.filter(item => !!item.name) || []
        : undefined,
      languages: recruit.languages
        ? (recruit.languages?.filter(item => !!item.locale && item.fluency.length) as BookingRecruitLanguageInput[]) ||
          []
        : undefined,

      // TODO: the location select should deal with stripping __typename and other unnecessary stuff from
      // the actual data before setting it as the field value in the form state
      locations: recruit.locations?.map(l => ({
        type: l.type,
        location: {
          city: l.location?.city ?? null,
          country: l.location?.country ?? null,
          state: l.location?.state ?? null,
          region: l.location?.region ?? null,
          formatted_address: l.location?.formatted_address ?? '',
          google_location: !l.location?.google_location
            ? null
            : {
                _place_id: l.location?.google_location._place_id,
                name: l.location?.google_location.name,
                viewport: l.location?.google_location.viewport,
                map_polygon_id: l.location?.google_location.map_polygon_id,
                geometry: {
                  latitude_ne: l.location?.google_location?.geometry?.latitude_ne,
                  longitude_ne: l.location?.google_location?.geometry?.longitude_ne,
                  latitude_sw: l.location?.google_location?.geometry?.latitude_sw,
                  longitude_sw: l.location?.google_location?.geometry?.longitude_sw,
                  width: l.location?.google_location?.geometry?.width,
                  height: l.location?.google_location?.geometry?.height,
                  radius_min: l.location?.google_location?.geometry?.radius_min,
                  radius_max: l.location?.google_location?.geometry?.radius_max,
                },
                address_components: l.location?.google_location.address_components?.map(c => ({
                  long_name: c?.long_name,
                  short_name: c?.short_name,
                  types: c?.types,
                })),
              },
          latitude: l.location?.latitude ?? null,
          longitude: l.location?.longitude ?? null,
          postal_code: l.location?.postal_code ?? null,
          street1: l.location?.street1 ?? null,
        },
      })),

      // TODO: this is a mess, the terms field needs refactoring to do all of this logic there!
      custom_terms:
        custom_terms_template_id === null
          ? null
          : custom_terms_template_id || custom_terms_recipients
            ? values.custom_terms_template_id
              ? {
                  _template_id: values.custom_terms_template_id,
                  recipients:
                    values.custom_terms_recipients
                      ?.split(/[\s,]/)
                      ?.map(str => str.trim())
                      .filter(Boolean) || [],
                }
              : null
            : undefined,
    },
    contact: {
      _primary_contact_id,
    },
    listing_information: {
      title: listing_title,
      description: listing_description,
    },
  };
}

import { Button } from '@askable/ui/core/button';
import { Card, CardHeader, CardTitle } from '@askable/ui/core/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@askable/ui/core/dialog';
import { Skeleton } from '@askable/ui/core/skeleton';
import { useFeatureFlags } from 'feature-flags';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation, useQuery } from 'urql';
import { useBoolean } from 'usehooks-ts';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { KindeSsoStrategy } from 'generated/graphql';

import { SettingsContainer } from '../../../../components/SettingsContainer';

import { ActiveConnections } from './components/ActiveConnections';
import { EnableEnterpriseSSO } from './components/EnableEnterpriseSSO';
import { LegacySSOConnection } from './components/LegacySSOConnection';
import { CreateSSOConnection } from './data/CreateSSOConnection.mutation';
import { EnterpriseSSODetails } from './data/EnterpriseSSODetails.query';

import type { TeamSettingsSsoConnection } from 'generated/graphql';

const AVAILABLE_CONNECTIONS: { id: KindeSsoStrategy; name: string }[] = [
  {
    id: KindeSsoStrategy.Oauth2AzureAd,
    name: 'Microsoft Azure AD (OAuth 2.0)',
  },
  {
    id: KindeSsoStrategy.WsfedAzureAd,
    name: 'Microsoft Entra ID (WS-Fed)',
  },
  {
    id: KindeSsoStrategy.SamlCustom,
    name: 'Custom SAML',
  },
];

export const EnterpriseSSO = () => {
  const { t } = useTranslation();
  const connectionTypeDialog = useBoolean();
  const { details } = useConnectedClient();
  const { ENTERPRISE_SSO } = useFeatureFlags(['ENTERPRISE_SSO']);
  const [{ data: teamSSOInfo, fetching }, refetch] = useQuery({
    query: EnterpriseSSODetails,
    pause: !ENTERPRISE_SSO,
    requestPolicy: 'network-only',
  });

  const [, createSSOConnection] = useMutation(CreateSSOConnection);

  const handleCreate = (strategy: KindeSsoStrategy) => async () => {
    await createSSOConnection({
      strategy,
      teamId: details?.ConnectedTeam?._id!,
    });

    refetch();

    connectionTypeDialog.setFalse();
  };

  const handleAsyncActionComplete = () => {
    refetch();
  };

  const connectionCount = teamSSOInfo?.viewer?.ConnectedTeam?.settings?.sso_connections?.length ?? 0;

  return (
    <SettingsContainer title={t('sections.settings.team.enterpriseSso.title')}>
      <p>{t('sections.settings.team.enterpriseSso.configureDescriptionLine1')}</p>
      {match([fetching, ENTERPRISE_SSO])
        .with([true, true], () => {
          return (
            <div className="rounded-md border p-4">
              <div className="flex gap-2">
                <Skeleton className="flex h-8 w-8 flex-shrink-0 rounded-full" />
                <div className="flex w-full flex-col gap-2">
                  <Skeleton className="h-6 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </div>
            </div>
          );
        })
        .with([false, true], () => {
          return (
            <div className="flex flex-col gap-2">
              {teamSSOInfo?.viewer?.ConnectedTeam?.settings?.sso?.company_domains ? (
                <LegacySSOConnection domains={teamSSOInfo?.viewer?.ConnectedTeam?.settings?.sso.company_domains} />
              ) : null}
              {connectionCount === 0 ? (
                <Dialog open={connectionTypeDialog.value} onOpenChange={connectionTypeDialog.toggle}>
                  <DialogTrigger>
                    <Button variant="primary" size="lg">
                      {t('sections.settings.team.enterpriseSso.addNewConnection')}
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="w-full max-w-2xl">
                    <DialogHeader>
                      <DialogTitle>{t('sections.settings.team.enterpriseSso.addNewConnection')}</DialogTitle>
                      <DialogDescription>
                        {t('sections.settings.team.enterpriseSso.addNewConnectionDescripton')}
                      </DialogDescription>
                    </DialogHeader>
                    <div className="grid grid-cols-2 gap-2">
                      {AVAILABLE_CONNECTIONS.map(a => {
                        return (
                          <Card className="hover:shadow" key={a.id} role="button" onClick={handleCreate(a.id)}>
                            <CardHeader>
                              <CardTitle>{a.name}</CardTitle>
                            </CardHeader>
                          </Card>
                        );
                      })}
                    </div>
                  </DialogContent>
                </Dialog>
              ) : (
                <ActiveConnections
                  handleDeleteComplete={handleAsyncActionComplete}
                  handleSaveComplete={handleAsyncActionComplete}
                  connections={
                    (teamSSOInfo?.viewer?.ConnectedTeam?.settings?.sso_connections as TeamSettingsSsoConnection[]) ?? []
                  }
                />
              )}
            </div>
          );
        })
        .otherwise(() => (
          <EnableEnterpriseSSO />
        ))}
    </SettingsContainer>
  );
};

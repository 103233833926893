import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/core/alert-dialog';
import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { cn } from '@askable/ui/lib/utils';
import { Copy, Ellipsis, Trash2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation, CombinedError } from 'urql';

import { CreateTaskBlockFigmaPrototype } from 'containers/Studies/BuildStudy/data/CreateTaskBlockFigmaPrototype.mutation';
import { CreateTaskBlockMultipleChoiceQuestion } from 'containers/Studies/BuildStudy/data/CreateTaskBlockMultipleChoiceQuestion.mutation';
import { CreateTaskBlockOpinionScale } from 'containers/Studies/BuildStudy/data/CreateTaskBlockOpinionScale.mutation';
import { DeleteTaskBlock } from 'containers/Studies/BuildStudy/data/DeleteTaskBlock.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { PreviewLink } from 'containers/Studies/components/PreviewLink';
import { TaskBlockType } from 'generated/graphql';

import { useActiveBlockId } from '../hooks/useActiveBlockId';

import type {
  TaskBlockFigmaPrototype,
  TaskBlockMultipleChoiceQuestion,
  TaskBlockOpinionScale,
} from 'generated/graphql';

export type BlockAction = 'duplicate' | 'delete';

interface BlockActionsProps {
  isVisible?: boolean;
  blockId: string;
}

const createTitleCopy = (title: string) => {
  // Check if title ends with (Copy N) and change it to (Copy N+1)
  // TODO: localise "Copy"
  const titleMatch = title.match(/\(Copy( \d+)?\)$/);
  if (!titleMatch) {
    return `${title} (Copy)`;
  }

  const number = titleMatch[1] ? parseInt(titleMatch[1]) + 1 : 2;
  return title.replace(/\(Copy( \d+)?\)$/, `(Copy ${number})`);
};

export const BlockActions = ({ isVisible = true, blockId }: BlockActionsProps) => {
  const { t } = useTranslation();
  const { study, setNewBlockId, isBuildDisabled } = useStudyContext();
  const { setActiveBlockId } = useActiveBlockId();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [, deleteTaskBlock] = useMutation(DeleteTaskBlock);
  const [, createTaskBlockFigmaPrototype] = useMutation(CreateTaskBlockFigmaPrototype);
  const [, createTaskBlockMultipleChoiceQuestion] = useMutation(CreateTaskBlockMultipleChoiceQuestion);
  const [, createTaskBlockOpinionScale] = useMutation(CreateTaskBlockOpinionScale);

  const block = useMemo(
    () =>
      match(blockId)
        .with('welcome', () => null)
        .with('thank_you', () => null)
        .otherwise(
          () =>
            study.config?.unmoderated?.task_blocks?.find(b => b?._id === blockId) as
              | TaskBlockFigmaPrototype
              | TaskBlockMultipleChoiceQuestion
              | TaskBlockOpinionScale,
        ),
    [study, blockId],
  );

  const handleDuplicateBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }
      // Calculate new position for copied block to be added underneath the original block
      const blockIndex = study.config?.unmoderated?.task_blocks?.findIndex?.(b => b?._id === blockId);
      const position =
        blockIndex !== undefined && blockIndex > -1
          ? blockIndex + 1
          : study.config?.unmoderated?.task_blocks?.length || 0;
      // TODO: add dedicated `duplicateStudyConfigTaskBlock` mutation that can be generic, so we don't
      // have to have a separate one for each type since we can just pass in an `_id`
      const newBlocks = await match(block.type)
        .with(TaskBlockType.FigmaPrototype, async () => {
          const { error, data } = await createTaskBlockFigmaPrototype({
            input: {
              _id: study._id,
              position,
              task_block: {
                title: createTitleCopy(block?.title || ''),
                instructions: block.instructions,
                is_recording_enabled: !!block.is_recording_enabled,
                figma_prototype:
                  'figma_prototype' in block
                    ? {
                        file_id: block.figma_prototype?.file_id ?? null,
                        start_screen_id: block.figma_prototype?.start_screen_id ?? null,
                        goal_screen_id: block.figma_prototype?.goal_screen_id ?? null,
                      }
                    : null,
              },
            },
          });
          if (!data?.createTaskBlockFigmaPrototype) {
            throw new Error('Something went wrong');
          }
          if (error) {
            throw new Error(error.message);
          }
          return data.createTaskBlockFigmaPrototype.config?.unmoderated?.task_blocks;
        })
        .with(TaskBlockType.MultipleChoiceQuestion, async () => {
          const { error, data } = await createTaskBlockMultipleChoiceQuestion({
            input: {
              _id: study._id,
              position,
              task_block: {
                title: createTitleCopy(block?.title || ''),
                instructions: block.instructions,
                is_recording_enabled: !!block.is_recording_enabled,
                multiple_choice_question: 'multiple_choice_question' in block ? block.multiple_choice_question : null,
              },
            },
          });
          if (!data?.createTaskBlockMultipleChoiceQuestion) {
            throw new Error('Something went wrong');
          }
          if (error) {
            throw new Error(error.message);
          }
          return data.createTaskBlockMultipleChoiceQuestion.config?.unmoderated?.task_blocks;
        })
        .with(TaskBlockType.OpinionScale, async () => {
          const { error, data } = await createTaskBlockOpinionScale({
            input: {
              _id: study._id,
              position,
              task_block: {
                title: createTitleCopy(block?.title || ''),
                instructions: block.instructions,
                is_recording_enabled: !!block.is_recording_enabled,
                opinion_scale: 'opinion_scale' in block ? block.opinion_scale : null,
              },
            },
          });
          if (!data?.createTaskBlockOpinionScale) {
            throw new Error('Something went wrong');
          }
          if (error) {
            throw new Error(error.message);
          }
          return data.createTaskBlockOpinionScale.config?.unmoderated?.task_blocks;
        })
        .otherwise(() => {
          // Unsupported block type
        });
      if (newBlocks) {
        const newBlockId = newBlocks[position]?._id;
        if (newBlockId) {
          setActiveBlockId(newBlockId);
          // hacky way to not validate the new block straight away
          setTimeout(() => setNewBlockId(newBlockId), 0);
        }
      }
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    }
  };

  const handleDeleteBlock = async () => {
    try {
      if (!block) {
        throw new Error('Missing block');
      }

      const currentIndex = study.config?.unmoderated?.task_blocks?.findIndex(b => b?._id === blockId);
      const previousBlockId =
        currentIndex !== undefined && currentIndex > 0
          ? study.config?.unmoderated?.task_blocks[currentIndex - 1]?._id
          : undefined;
      const { error } = await deleteTaskBlock({ input: { _id: study._id, _task_block_id: blockId } });

      if (error) {
        throw new Error(error.message);
      }

      setActiveBlockId(previousBlockId ?? 'welcome');
    } catch (err) {
      toast.error(err instanceof CombinedError ? err.message : t('sections.errorBoundary.default'));
    }
  };

  if (!block) {
    // TODO: error handling
    return null;
  }

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={t('sections.studies.options', { count: 2 })}
            size="icon"
            variant="ghost"
            className={cn(
              'transition-opacity group-hover:opacity-100 group-focus:opacity-100 aria-[expanded=true]:opacity-100 @[10rem]:flex',
              {
                'lg:opacity-0': !isVisible,
              },
            )}
          >
            <Ellipsis className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="z-[2101]" align="end">
          <DropdownMenuItem className="lg:!hidden" asChild disabled={isBuildDisabled}>
            <PreviewLink />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleDuplicateBlock} disabled={isBuildDisabled}>
            <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={isBuildDisabled}
            onClick={e => {
              e.stopPropagation();

              if (block.title === '') {
                handleDeleteBlock();
              } else {
                setIsOpenConfirm(true);
              }
            }}
          >
            <Trash2 className="h-4 w-4" /> {t('sections.studies.actions.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={isOpenConfirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.deleteBlockConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('sections.studies.actions.deleteBlockConfirmDescription')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirm(false)} className="w-full sm:w-fit">
              {t('global.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setIsOpenConfirm(false);
                handleDeleteBlock();
              }}
              className="w-full sm:w-fit"
            >
              {t('global.delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

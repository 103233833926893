import { Button } from '@askable/ui/core/button';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@askable/ui/core/dialog';
import { Skeleton } from '@askable/ui/core/skeleton';
import { Copy, ArrowLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { useConnectedClient } from 'context/ConnectedClientContext';

import { BookingsByTeamId } from '../../data/BookingsByTeamId.query';

import { ScreenerQuestionsCopyStudies } from './ScreenerQuestionsCopyStudies';
import { ScreenerQuestionsCopyStudy } from './ScreenerQuestionsCopyStudy';

import type { Booking, BookingQuestion } from '../../data/BookingsByTeamId.query';
import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

type ScreenerQuestion = NonNullable<RecruitFormFields['screener_questions']>[0];

interface ScreenerQuestionsCopyProps {
  isOpen?: boolean;
  onAddQuestions: (questions: ScreenerQuestion[]) => void;
  onClose: () => void;
}

export const ScreenerQuestionsCopy = ({ isOpen, onAddQuestions, onClose }: ScreenerQuestionsCopyProps) => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const [selectedStudy, setSelectedStudy] = useState<Booking | null>(null);

  const [{ data, fetching, error }] = useQuery({
    query: BookingsByTeamId,
    pause: !isOpen || !details?.ConnectedTeam?._id,
    requestPolicy: 'cache-first',
    variables: {
      id: details?.ConnectedTeam?._id,
      sortBy: 'created',
      order: -1,
      excludeBookingsWithNoQuestions: true,
      excludeAskablePlusBookings: false,
    },
  });

  const handleGoBack = () => {
    setSelectedStudy(null);
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          setSelectedStudy(null);
          onClose();
        }
      }}
    >
      <DialogContent
        className="flex h-full flex-col gap-0 border-0 p-0 text-foreground md:min-w-[50rem] [@media(min-height:800px)]:h-[800px]"
        onOpenAutoFocus={event => event.preventDefault()}
      >
        <header className="sticky top-0 z-0 flex gap-2 bg-background/90 px-3 py-4 backdrop-blur-sm">
          {selectedStudy ? (
            <>
              <Button variant="ghost" size="icon" className="-mt-1" onClick={handleGoBack}>
                <ArrowLeft className="h-6 w-6" />
              </Button>
              <div className="flex flex-col gap-1">
                <DialogTitle>{selectedStudy.name}</DialogTitle>
                <DialogDescription className="text-sm text-muted-foreground">
                  {t('sections.studies.recruit.screenerQuestionsCopy.addDescription')}
                </DialogDescription>
              </div>
            </>
          ) : (
            <>
              <div className="px-1">
                <Copy className="h-6 w-6" />
              </div>
              <div className="flex flex-col gap-1">
                <DialogTitle>{t('sections.studies.recruit.screenerQuestionsCopy.title')}</DialogTitle>
                <DialogDescription className="text-sm text-muted-foreground">
                  {t('sections.studies.recruit.screenerQuestionsCopy.listDescription')}
                </DialogDescription>
              </div>
            </>
          )}
        </header>

        {error ? <div className="px-4 text-center text-destructive">{error.message}</div> : null}

        {fetching && !data ? (
          <div className="flex flex-col gap-2 px-4 pb-4" aria-busy="true" aria-label={t('global.loading')}>
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-2/3" />
          </div>
        ) : null}

        {selectedStudy ? (
          <ScreenerQuestionsCopyStudy
            questions={selectedStudy.config?.question as BookingQuestion[]}
            onAddQuestions={onAddQuestions}
            onGoBack={handleGoBack}
          />
        ) : (
          <>
            {!fetching && data?.bookingsByTeam && data.bookingsByTeam.length > 0 ? (
              <ScreenerQuestionsCopyStudies
                studies={data.bookingsByTeam as Booking[]}
                onSelectStudy={setSelectedStudy}
              />
            ) : null}

            {!fetching && data?.bookingsByTeam && data.bookingsByTeam.length === 0 ? (
              <EmptyState title={t('sections.studies.recruit.screenerQuestionsCopy.noStudies')} />
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

import { toast } from '@askable/ui/core/sonner';
import { useFeatureFlags } from 'feature-flags';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ArrowRightIcon, Box, Button, Flex, Heading, Image, Link, Text } from 'ui';
import { useMutation } from 'urql';

import { CreateUnmoderatedBooking } from 'containers/Studies/data/CreateUnmoderatedBooking.mutation';
import { UpdateBookingConfig } from 'containers/Studies/data/UpdateBookingConfig.mutation';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { bookingUtils } from 'lib/booking';
import { teamUtils } from 'lib/teams';

import './styles/createBookingStyles.scss';

function RecruitmentType() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const viewer = useConnectedClient();

  const { UNMODERATED_STUDY } = useFeatureFlags(['UNMODERATED_STUDY']);

  const [, createUnmoderatedBooking] = useMutation(CreateUnmoderatedBooking);
  const [, updateBookingConfig] = useMutation(UpdateBookingConfig);

  // HACK: just temporary setup for unmod study creation
  const [loading, setLoading] = useState(false);
  const timezone = bookingUtils.getCurrentTimezone();
  const currency = teamUtils.getCurrency();

  return (
    <Flex direction="column" maxWidth="3xl" marginX="auto" paddingY={10}>
      <Heading size="lg" mb={10} textAlign="center" color="gray.800" fontWeight="semibold">
        Start a new research study
      </Heading>
      <Flex bgColor="blue.100" p={12} borderRadius="xl">
        <Box pr={10}>
          <Image src="/askable-plus-logo-complete-reverse.svg" mb={6} width="150px" />
          <Heading color="blue.800" fontWeight="semibold" size="md" mb={2}>
            Need us to run your research?
          </Heading>
          <Text maxW="xl" color="blue.800" pr={4}>
            Brief us in, and we’ll conduct unbiased research and deliver an actionable insights report so you can move
            forward with confidence.
          </Text>
        </Box>
        <Button colorScheme="blue" onClick={() => navigate('/askable-plus/create')} alignSelf="center">
          <RouterLink to="/askable-plus/create">Let’s go</RouterLink>
        </Button>
      </Flex>
      <div className="flex flex-col gap-4 px-12 py-6">
        <Link fontSize="xl">
          <RouterLink to="/booking-setup/create">
            I've got this, I just need the right participants <ArrowRightIcon ml={2} />
          </RouterLink>
        </Link>
        {UNMODERATED_STUDY && (
          <Button
            variant="link"
            alignSelf="flex-start"
            color="blue.500"
            fontSize="xl"
            isLoading={loading}
            onClick={async () => {
              try {
                setLoading(true);

                // Create new booking
                const createBookingResult = await createUnmoderatedBooking({
                  input: {
                    timezone,
                    currency: currency.code,
                    name: t('sections.studies.newStudy'),
                    config: {
                      unmoderated: {
                        welcome_block: {
                          title: t('sections.studies.build.blocks.welcome.defaultTitle'),
                          instructions: t('sections.studies.build.blocks.welcome.defaultDescription'),
                        },
                        thank_you_block: {
                          title: t('sections.studies.build.blocks.thank_you.defaultTitle'),
                          instructions: t('sections.studies.build.blocks.thank_you.defaultDescription'),
                        },
                      },
                    },
                  },
                });

                const bookingId = createBookingResult.data?.createUnmoderatedBooking._id;
                if (createBookingResult.error || !bookingId) {
                  toast.error(createBookingResult.error?.graphQLErrors[0].message ?? 'Error creating study');
                  return;
                }

                // Update recruit config to match the default we want
                // TODO: we should set the defaults on the BE when creating the booking, however some information
                // _does_ need to come from the FE, e.g. the listing description since it uses translations which live
                // on the FE atm :|
                const updateBookingResult = await updateBookingConfig({
                  _id: bookingId,
                  input: {
                    recruit: {
                      participants: 12,
                      locations: [
                        {
                          type: 'country',
                          location: {
                            city: null,
                            country: 'AU',
                            formatted_address: 'Australia',
                            google_location: null,
                            latitude: null,
                            longitude: null,
                            postal_code: null,
                            region: null,
                            state: null,
                            street1: null,
                          },
                        },
                      ],
                      age_range: [18, 99],
                      participant_source: 'panel',
                      participants_excluded_months: 3,
                      custom_terms: null,
                      screener_questions: [],
                      industry_and_occupation: [],
                      languages: [],
                      education_level: [],
                      marital_status: [],
                      occupational_status: [],
                      business_ownership: [],
                    },
                    contact: {
                      _primary_contact_id: viewer.details?._id ?? null,
                    },
                    listing_information: {
                      title: '',
                      description: t('sections.studies.recruit.fields.descriptionPlaceholder'),
                    },
                  },
                });
                if (updateBookingResult.error) {
                  toast.error(updateBookingResult.error?.graphQLErrors[0].message ?? 'Error creating study');
                  return;
                }

                navigate(`/studies/${bookingId}/build`);
              } catch (e: any) {
                toast.error(e.message ?? 'Error creating study');
              } finally {
                setLoading(false);
              }
            }}
          >
            Create Unmoderated Study <ArrowRightIcon ml={2} />
          </Button>
        )}
      </div>
    </Flex>
  );
}

export default RecruitmentType;

import { cn, secondsToTime } from '@askable/ui/lib/utils';
import { Link, useSearchParams } from 'react-router-dom';

export interface RecordingNavItemProps {
  duration?: number;
  image?: string;
  isActive?: boolean;
  resultId: string;
  title: string;
}

export const RecordingNavItem = ({ duration = 0, image, isActive, resultId, title }: RecordingNavItemProps) => {
  const [searchParams] = useSearchParams();

  const getUpdatedSearchParams = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('result_id', resultId);
    return newParams.toString();
  };

  return (
    <li>
      <Link
        to={{ search: getUpdatedSearchParams() }}
        className={cn(
          'flex w-full items-center gap-2 rounded-xl border border-border bg-background p-2 text-sm text-secondary-foreground',
          'hover:border-input focus:border-input active:border-primary active:ring-1 active:ring-primary',
          {
            'border-primary bg-background ring-1 ring-primary hover:border-primary focus:border-primary': isActive,
          },
        )}
      >
        <div className="flex h-8 w-9 flex-shrink-0 items-center justify-center overflow-hidden rounded-sm bg-primary text-primary-foreground">
          <svg fill="currentColor" className="absolute h-4 w-4" viewBox="0 0 24 24">
            <path d="M4.5 5.7C4.5 4.2 6 3.3 7.3 4l11.5 6.4c1.3.7 1.3 2.5 0 3.2L7.3 20c-1.3.7-2.8-.2-2.8-1.7V5.7Z" />
          </svg>
          {image ? <img src={image} alt="" className="h-full" /> : null}
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">{title}</div>
          {duration ? (
            <div className="text-xs text-muted-foreground">
              {secondsToTime(duration / 1000, 'labels', 'decisecond')}
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};

import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Card } from 'components/common';
import { askablePlusUtils } from 'lib/askablePlus';

import '../styles/meetingFormatStyles.scss';

function MeetingFormat(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(askablePlusBrief);
  const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(askablePlusBrief);
  let meeting_format = 2;
  if (hasDiscoveryInterviewType) {
    meeting_format = _.get(askablePlusBrief, 'askable_plus.research_type.discovery.booking_type');
  } else if (hasUsabilityTestingType) {
    meeting_format = _.get(askablePlusBrief, 'askable_plus.research_type.usability.booking_type');
  }

  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [meetingFormat, setMeetingFormat] = useState(meeting_format);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else if (totalResearchTypesSelected > 1 || (!hasDiscoveryInterviewType && !hasUsabilityTestingType)) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`);
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/meeting-format`,
        stepId: 'project_setup_meeting_format',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onClickCard = (value: any) => {
    setMeetingFormat(value);

    const researchBookingType = {};
    if (hasDiscoveryInterviewType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'discovery' does not exist on type '{}'.
      researchBookingType.discovery = {
        ...askablePlusBriefState.askable_plus.research_type.discovery,
        booking_type: value,
      };
    } else if (hasUsabilityTestingType) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'usability' does not exist on type '{}'.
      researchBookingType.usability = {
        ...askablePlusBriefState.askable_plus.research_type.usability,
        booking_type: value,
      };
    }

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: {
          ...askablePlusBriefState.askable_plus.research_type,
          ...researchBookingType,
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    let redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    if (hasDiscoveryInterviewType) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/session-format`;
    }
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Meeting format</h1>
      <p className="label">How would you like the researcher to meet with participants?</p>
      <div className="researchTypeGroup">
        <div className="researchTypeTasks">
          <Card
            id="__remoteCard"
            title="Remote"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Askable Sessions, Zoom, Google Meet, Microsoft Teams, etc' }]}
            image="/askablePlus/illustrations/remoteFormat.svg"
            selected={meetingFormat === 2}
            onClickCard={() => onClickCard(2)}
          />
          <Card
            id="__inPersonCard"
            title="In-person"
            className="researchTypeCard"
            vertical
            content={[{ label: '1 on 1 interviews, Focus groups' }, { label: 'In home visits' }]}
            image="/askablePlus/illustrations/inPersonFormat.svg"
            selected={meetingFormat === 1}
            onClickCard={() => onClickCard(1)}
          />
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(MeetingFormat);

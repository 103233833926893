import { Button } from '@askable/ui/core/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Calendar } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';

import { MiniCalendar } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';

import './styles/closingDateStyles.scss';

function ClosingDate(props: any) {
  const booking = _.get(props, 'booking');
  const timezone = _.get(booking, 'config.timezone');
  const [bookingState, setBookingState] = useState(booking);
  const [openMiniCalendar, setOpenMiniCalendar] = useState(false);
  const [session, setSession] = useState(_.get(booking, 'session[0]'));
  const [sessionTimes, setSessionTimes] = useState(_.get(booking, 'session[0]'));

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/closing-date`,
      stepId: 'additional_info_closing_date',
    });
  }, []);

  useEffect(() => {
    if (!_.get(props, 'booking.session[0]')) {
      let predefinedCompletedBy;
      if (bookingUtils.isOnlineTask(props.booking)) {
        predefinedCompletedBy = momentTimezone(momentTimezone().add(5, 'days')).endOf('day');
      } else if (bookingUtils.isLongitudinal(props.booking)) {
        const daysPeriod = bookingUtils.getLongitudinalStudyPeriod(bookingState) || 5;
        predefinedCompletedBy = momentTimezone(momentTimezone().add(daysPeriod, 'days')).endOf('day');
      }
      if (!predefinedCompletedBy) return;
      const defaultSession = {
        start: predefinedCompletedBy.valueOf(),
        end: predefinedCompletedBy.valueOf(),
      };
      setSession(defaultSession);
      setBookingState({
        ..._.get(props, 'booking'),
        session: [defaultSession],
      });
    } else {
      setSession(_.get(props, 'booking.session[0]'));
      setBookingState(_.get(props, 'booking'));
    }
  }, [props.booking]);

  useEffect(() => {
    if (bookingUtils.isRemote(booking)) {
      props.history.push({
        pathname: `/booking-setup/${booking._id}/additional-info/your-timezone`,
      });
    } else if (bookingUtils.isInPerson(booking)) {
      props.history.push({
        pathname: `/booking-setup/${booking._id}/additional-info/session-location`,
      });
    }
    // handleSessionTimes();
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [props.booking, bookingState]);

  const rightContent = () => {
    const cardTexts = [
      {
        cardText:
          'Yes, you can change the closing date at any time. Your study will automatically close if the quota is filled as well.',
      },
    ];
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__closingDateCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Can I change the closing date later?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            window.open(
              'https://help.askable.com/en/articles/4849426-can-i-change-the-completion-date-for-my-unmoderated-booking',
              '_blank',
            );
          }}
        />
      </>
    );
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/additional-info/description`;
    props.history.push({
      pathname: redirectTo,
      bookingSession: { session, sessionTimes },
      bookingState,
    });
  };

  const onChangeDate = (day: any) => {
    const selectedDay = momentTimezone(day).tz(timezone).endOf('day');
    const newSessionTime = {
      start: selectedDay.valueOf(),
      end: selectedDay.valueOf(),
    };
    const sessionObj = {
      ...session,
      start: selectedDay.valueOf(),
      end: selectedDay.valueOf(),
    };
    setSession(sessionObj);
    setSessionTimes(newSessionTime);
    setOpenMiniCalendar(false);

    const bookingStateObj = {
      ...booking,
      session: [sessionObj],
    };
    setBookingState(bookingStateObj);
    props.updateBookingState(bookingStateObj);

    if (_.get(booking, 'session[0].start') !== _.get(newSessionTime, 'start')) {
      props.history.replace({
        bookingState: bookingStateObj,
        bookingSession: { session: sessionObj, sessionTimes: newSessionTime },
      });
    } else {
      props.history.replace({ bookingSession: null });
    }
  };
  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Closing date
      </h1>
      <h1 className="label">
        Please let us know by which date you're aiming to have this study completed. This date is publicly visible to
        the participants.
      </h1>
      <Popover open={openMiniCalendar} onOpenChange={setOpenMiniCalendar}>
        <PopoverTrigger>
          <div
            id="closingDateContainer"
            className="flex w-64 items-center justify-between rounded-sm border p-2"
            onClick={() => setOpenMiniCalendar(true)}
          >
            <p id="__closingDate" className="closingDate">
              {momentTimezone(_.get(session, 'start')).tz(timezone).format('dddd, Do MMMM')}
            </p>
            <Calendar color="#666" className="h-4 w-4" />
          </div>
        </PopoverTrigger>
        <PopoverContent align="start">
          <MiniCalendar
            timezone={timezone}
            month={momentTimezone(_.get(session, 'start')).tz(_.get(bookingState, 'config.timezone')).month()}
            year={momentTimezone(_.get(session, 'start')).tz(_.get(bookingState, 'config.timezone')).year()}
            selectedDay={_.get(session, 'start')}
            onClickDay={onChangeDate}
            disablePastDates
          />
        </PopoverContent>
      </Popover>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ClosingDate);

import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingSubmissionByUser = graphql(`
  query BookingSubmissionByUser($_booking_id: ID!, $_user_id: ID!) {
    bookingSubmissionByUser(_booking_id: $_booking_id, _user_id: $_user_id) {
      _id
      status
      status_updated
      applicant {
        firstname
        lastname
        age
        city
        country
        gender
      }
      data {
        _question_id
        _answer_id
        value
        option {
          label
          screen_in
        }
      }
    }
  }
`);

export type BookingSubmissionByUserProps = NonNullable<
  ResultOf<typeof BookingSubmissionByUser>['bookingSubmissionByUser']
>;

export type ScreenerQuestionAnswerPartial = NonNullable<
  NonNullable<NonNullable<ResultOf<typeof BookingSubmissionByUser>['bookingSubmissionByUser']>['data']>[number]
>;

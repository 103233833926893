import { Button } from '@askable/ui/core/button';
import { Plus, Trash } from 'lucide-react';
import { debounce } from 'radash';
import { Fragment, memo, useCallback, useEffect } from 'react';
import { Flex, HStack, Input, SimpleGrid, Text, Tooltip, ReactSelect } from 'ui';

import { SegmentMethod } from 'generated/graphql';

import { bookingParticipantSelectors, useBookingParticipantState } from '../state/booking-participant-state';
import { mapSegmentFiltersToUIFilters } from '../utils/booking-participant-utils';

import type { BookingParticipantStateAndActions } from '../state/booking-participant-state';
import type { Filter, FilterOption, FilterType, SingleChangeValue } from '../utils/booking-participant-utils';
import type { BookingContainerBookingQuery, SegmentFilterMethod } from 'generated/graphql';
import type { FC } from 'react';
import type { MultiValue } from 'react-select';

export const FILTER_CONDITIONS: readonly FilterOption<SegmentMethod>[] = [
  { value: SegmentMethod.And, label: 'and' },
  { value: SegmentMethod.Or, label: 'or' },
];

type Props = {
  booking?: BookingContainerBookingQuery['bookingByID'];
};

type FilterItemProps = {
  updateFilter: (segmentId: string, filter: Filter<string>) => void;
  availableFilters: FilterType;
  onRemove: (segmentId: string, filterId: string) => void;
  segmentId: string;
  filter: Filter;
};

const FilterItem: FC<FilterItemProps> = memo(({ onRemove, updateFilter, availableFilters, segmentId, filter }) => {
  const currentFilter = availableFilters.filterConfigs[filter.field?.value!];

  const handleFieldChange = (payload: SingleChangeValue) => {
    updateFilter(segmentId, {
      ...filter,
      field: payload,
      value: null,
      constraint: availableFilters.filterConfigs[payload?.value!].config.constraintOptions[0],
    });
  };

  const handleConstraintChange = (payload: SingleChangeValue<SegmentFilterMethod>) => {
    updateFilter(segmentId, {
      ...filter,
      constraint: payload,
    });
  };

  const handleSelectValueChange = (payload: MultiValue<{ label: string; value: string }>) => {
    updateFilter(segmentId, {
      ...filter,
      value: payload,
    });
  };

  const debouncedUpdateFilter = debounce({ delay: 300 }, (v: string) => {
    updateFilter(segmentId, {
      ...filter,
      value: [
        {
          label: v,
          value: v,
        },
      ],
    });
  });

  const handleFilterInputChange = useCallback(
    (value: string) => {
      debouncedUpdateFilter(value);
    },
    [filter, segmentId],
  );

  const handleRemoveClick = () => {
    onRemove(segmentId, filter.id!);
  };

  return (
    <>
      {}
      <label hidden htmlFor={`filter-field-${filter.id}`}>
        Filter field {filter.id}
      </label>
      <ReactSelect
        aria-label={filter.field?.label}
        inputId={`filter-field-${filter.id}`}
        autoFocus
        placeholder="Select..."
        value={filter.field}
        onChange={handleFieldChange}
        options={availableFilters.options ?? []}
      />

      {}
      <label hidden htmlFor={`filter-constraint-${filter.id}`}>
        Filter constraint {filter.id}
      </label>
      <ReactSelect
        id={`filter-constraint-${filter.id}`}
        placeholder="Select..."
        value={filter.constraint}
        options={currentFilter?.config?.constraintOptions ?? []}
        onChange={handleConstraintChange}
      />

      {}
      <label hidden htmlFor={`filter-value-${filter.id}`}>
        Filter value {filter.id}
      </label>
      {currentFilter?.fieldType === 'text' || currentFilter?.fieldType === 'number' ? (
        <Input
          id={`filter-value-${filter.id}`}
          placeholder="Enter a value..."
          w="full"
          defaultValue={filter?.value?.[0]?.value ?? ''}
          onChange={e => handleFilterInputChange(e.target.value)}
        />
      ) : (
        <ReactSelect
          inputId={`filter-value-${filter.id}`}
          value={filter.value}
          placeholder="Select..."
          isMulti
          options={currentFilter?.options ?? []}
          onChange={handleSelectValueChange}
        />
      )}
      <Tooltip label="Delete filter">
        <Button variant="ghost" onClick={handleRemoveClick} aria-label={`Remove filter ${filter.id}`}>
          <Trash className="h-4 w-4" />
        </Button>
      </Tooltip>
    </>
  );
});

FilterItem.displayName = 'FilterItem';
const bookingParticipantStateSelector = (
  state: BookingParticipantStateAndActions,
): Partial<BookingParticipantStateAndActions> => {
  return {
    filterConfigs: state.filterConfigs,
    segmentId: state.segmentId,
    clearFilters: state.clearFilters,
    removeFilter: state.removeFilter,
    updateFilter: state.updateFilter,
    updateCondition: state.updateCondition,
    addFilter: state.addFilter,
    updateSegment: state.updateSegment,
    forceUpdateSegment: state.forceUpdateSegment,
    clearPersistedActions: state.clearPersistedActions,
    segments: state.segments,
  };
};

export const BookingParticipantFilters: FC<Props> = memo(() => {
  const {
    clearFilters,
    removeFilter,
    updateFilter,
    updateCondition,
    addFilter,
    forceUpdateSegment,
    clearPersistedActions,
    segments,
    segmentId,
  } = useBookingParticipantState(bookingParticipantStateSelector);

  const availableFilters = useBookingParticipantState(b => b.filterConfigs);
  const currentFilters = useBookingParticipantState(bookingParticipantSelectors.currentSegmentFilters);

  // Initialize filters for new segment
  useEffect(() => {
    if (segmentId && segments) {
      const currentSegment = segments.find(s => s._id === segmentId);

      if (currentSegment && !currentFilters) {
        useBookingParticipantState.setState(state => {
          const initialFilters = currentSegment.filters
            ? mapSegmentFiltersToUIFilters(currentSegment.filters, state.filterConfigs)
            : [];

          const initialCondition =
            FILTER_CONDITIONS.find(c => c.value === currentSegment.method) ?? FILTER_CONDITIONS[0];

          return {
            ...state,
            segmentFilters: {
              ...state.segmentFilters,
              [segmentId]: {
                filters: initialFilters,
                condition: initialCondition,
              },
            },
          };
        });
      }
    }
  }, [segmentId, segments, currentFilters]);

  useEffect(() => {
    if (segmentId === 'all') {
      forceUpdateSegment?.();
    }
  }, [segmentId]);

  const handleConditionChange = useCallback(
    (value: SingleChangeValue<SegmentMethod>) => {
      if (segmentId) {
        updateCondition?.(segmentId, value);
      }
    },
    [updateCondition, segmentId],
  );

  const handleClearFilters = () => {
    if (segmentId) {
      clearFilters?.(segmentId);
      clearPersistedActions?.('filters');
    }
  };

  if (!segmentId || !currentFilters) {
    return null;
  }

  return (
    <Flex alignItems="flex-start" w="full" direction="column" as="form" data-testid="filter-form">
      <SimpleGrid
        w="full"
        gridTemplateColumns="6rem minmax(11rem, 20rem) 9rem minmax(11rem, 20rem) 40px"
        gridColumnGap="2"
        gridRowGap="5"
        alignItems="center"
        display={currentFilters.filters.length >= 1 ? 'grid' : 'none'}
        mb="6"
      >
        {currentFilters.filters.map((filter, index) => {
          return (
            <Fragment key={filter.id}>
              {index === 0 ? (
                <Text>Where</Text>
              ) : (
                <ReactSelect
                  isDisabled={index > 1}
                  value={currentFilters.condition}
                  onChange={handleConditionChange}
                  options={FILTER_CONDITIONS}
                />
              )}
              <FilterItem
                updateFilter={updateFilter!}
                availableFilters={availableFilters!}
                filter={filter}
                segmentId={segmentId}
                onRemove={removeFilter!}
              />
            </Fragment>
          );
        })}
      </SimpleGrid>
      <HStack justifyContent="space-between" w="full">
        <Button onClick={() => addFilter?.(segmentId)}>
          <Plus className="h-4 w-4" /> Add filter
        </Button>
        {currentFilters.filters.length >= 1 ? (
          <Button variant="ghost" type="button" role="banner" onClick={handleClearFilters}>
            Clear all filters
          </Button>
        ) : null}
      </HStack>
    </Flex>
  );
});

BookingParticipantFilters.displayName = 'BookingParticipantFilters';

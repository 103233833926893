import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import markAllNotificationsAsreadByTeamMutation from 'data/mutations/notifications/markAllNotificationsAsReadByTeam';
import markNotificationAsReadMutation from 'data/mutations/notifications/markNotificationAsRead';
import fetchNotificationsByTeam from 'data/queries/notifications/fetchNotificationsByTeam';
import notificationsByTeamSubscription from 'data/subscriptions/notifications/notificationsByTeam';

import NotificationItem from './NotificationItem';

import type { Notifications as NotificationsType } from 'generated/graphql';

type NotificationsProps = {
  team_id: string;
  onClose?: () => void;
};

const Notifications = ({ team_id }: NotificationsProps) => {
  const client = useApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const { t } = useTranslation();

  const { loading, error, data, fetchMore, subscribeToMore } = useQuery(fetchNotificationsByTeam, {
    variables: {
      _team_id: team_id,
      offset: 0,
      limit,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  const [markAllNotificationsAsReadByTeam] = useMutation(markAllNotificationsAsreadByTeamMutation, {
    client,
    onCompleted: () => {
      setIsLoading(false);
      toast.success(t('components.notifications.allRead'));
    },
    onError: () => {
      setIsLoading(false);
      toast.error(t('components.notifications.allReadError'));
    },
  });

  const onMarkAllNotifications = async () => {
    setIsLoading(true);
    // Mark all the notification from that team as read
    markAllNotificationsAsReadByTeam({
      variables: {
        _team_id: team_id,
      },
    });
  };

  const onLoadMore = () => {
    setIsLoading(true);
    const currentLength = notificationsByTeam?.length || 0;
    fetchMore({
      variables: {
        _team_id: team_id,
        offset: currentLength,
        limit: 20,
      },
    }).then(fetchMoreResult => {
      const resultLength = fetchMoreResult?.data?.notificationsByTeam?.length || 0;
      setIsLoading(false);
      setLimit(currentLength + resultLength);
    });
  };

  const [markNotificationAsRead] = useMutation(markNotificationAsReadMutation, { client });

  const onClickNotification = (id: string | number) => {
    // onClose();
    markNotificationAsRead({
      variables: { _id: id },
    });
  };

  useEffect(() => {
    subscribeToMore({
      document: notificationsByTeamSubscription,
      variables: { _team_id: team_id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newNotificationItem = subscriptionData.data.notificationsByTeam;
        return {
          ...prev,
          notificationsByTeam: [newNotificationItem, ...(prev.notificationsByTeam ?? [])],
        };
      },
    });
  }, []);

  const notificationsByTeam = data?.notificationsByTeam;
  const hasMoreRecords = notificationsByTeam?.length && limit > 0 && Number.isInteger(limit / 20);

  return (
    <div>
      {error ? <div className="text-destructive">{error.message}</div> : null}
      {loading || isLoading ? (
        <div className="max-w-sm px-3 py-10 text-center text-sm text-muted-foreground">{t('global.loading')}...</div>
      ) : (
        <ul className="max-h-[500px] overflow-auto">
          <li className="flex items-center justify-between border-b px-2 py-1 text-xs">
            <strong>{t('components.notifications.title')}</strong>
            <Button
              variant="link"
              className="text-xs text-muted-foreground"
              onClick={onMarkAllNotifications}
              data-testid="notifications_mark_unread"
            >
              {t('components.notifications.markAsRead')}
            </Button>
          </li>

          {notificationsByTeam?.map(item => {
            if (!item) {
              return null;
            }
            return (
              <li
                value={`item_${item._id}`}
                key={`item_${item._id}`}
                className="rounded-none border-b-0.5 border-border p-0 hover:bg-slate-100"
              >
                <NotificationItem item={item as NotificationsType} onClick={onClickNotification} />
              </li>
            );
          })}
          <div className="flex justify-center p-2">
            {hasMoreRecords ? (
              <Button variant="ghost" className="w-full" onClick={onLoadMore} data-testid="notifications_load_more">
                {t('components.notifications.loadMore')}
              </Button>
            ) : null}
          </div>
          {notificationsByTeam?.length === 0 && (
            <div className="pb-4 text-center text-sm text-muted-foreground" data-testid="notifications_empty">
              {t('components.notifications.empty')}
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default Notifications;

import { Card, CardContent, CardHeader, CardTitle } from '@askable/ui/core/card';
import { Skeleton } from '@askable/ui/core/skeleton';
import { format, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { utils } from 'lib/utils';

import { RemainingCreditsQuery } from '../data/RemainingCredits.query';

type RemainingCreditsProps = {
  teamId: string;
};

export const RemainingCredits = ({ teamId }: RemainingCreditsProps) => {
  const { t } = useTranslation();
  const [{ data, fetching }] = useQuery({
    query: RemainingCreditsQuery,
    variables: {
      _team_id: teamId,
    },
  });

  const expiryDate = data?.viewer?.ConnectedTeam?.settings?.billing?.subscription?.end;
  const credits = data?.viewer?.ConnectedTeam?.settings?.billing?.subscription?.credit?.remaining;

  return (
    <Card className="w-fit gap-0">
      <CardHeader className="text-xl font-bold" data-testid="remaining-credits">
        <CardTitle className="flex items-center gap-1">
          {fetching ? <Skeleton className="h-6 w-20" /> : utils.numberWithCommas(credits ?? 0)}
          <div>{t('global.credits').toLocaleLowerCase()}</div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        {expiryDate ? (
          <time
            dateTime={formatISO(expiryDate)}
            title={formatISO(expiryDate)}
            className="text-sm text-muted-foreground"
          >
            {t('global.creditExpires', { date: format(expiryDate, 'dd MMM yyyy') })}
          </time>
        ) : null}
      </CardContent>
    </Card>
  );
};

import { Button } from '@askable/ui/core/button';
import { FormControl, FormItem, FormLabel } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/core/select';
import { Textarea } from '@askable/ui/core/textarea';
import { cn } from '@askable/ui/lib/utils';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Copy, GripVertical, Plus, Trash2 } from 'lucide-react';
import { memo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { getObjectId } from 'containers/Studies/utils/utils';
import { BookingRecruitQuestionType } from 'generated/graphql';

import { ScreenerQuestionItemMultipleChoice } from './ScreenerQuestionItemMultipleChoice';

import type { ScreenerQuestionOption } from 'containers/Studies/Recruit/components/SectionScreenerQuestions';
import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { CSSProperties } from 'react';
import type { FieldError } from 'react-hook-form';

interface ScreenerQuestionItemProps {
  index: number;
  isDisabled?: boolean;
  isFocused?: boolean;
  isMovable?: boolean;
  onCopy: (id: string) => void;
  onRemove: (id: string) => void;
}

const ScreenerQuestionItemComponent = ({
  index,
  isDisabled,
  isMovable,
  isFocused = false,
  onCopy,
  onRemove,
}: ScreenerQuestionItemProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: `screener_questions.${index}`,
    control,
  });

  const titleError = (error as FieldError & { title?: { message: string } })?.title;
  const optionsError = (error as FieldError & { options?: { message: string }[] })?.options;
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: isMovable && !isDisabled ? field.value._id : '',
  });
  const [descriptionState, setDescriptionState] = useState({
    show: !!field.value.description,
    shouldFocus: false,
  });

  const dragStyle: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const addOption = () => {
    setValue(
      `screener_questions.${index}`,
      {
        ...field.value,
        type: BookingRecruitQuestionType.MultipleChoice,
        options: [...(field.value.options || []), { _id: getObjectId(), label: '', is_qualified: true }],
      },
      {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      },
    );
  };

  const handleShowDescription = () => {
    setDescriptionState({
      show: true,
      shouldFocus: true,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      `screener_questions.${index}`,
      { ...field.value, title: e.target.value },
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      },
    );
  };

  const handleOptionChange = (optionIndex: number, label: string, isQualified: boolean) => {
    const updatedList = (field.value.options ?? []).map((item: ScreenerQuestionOption, i: number) => {
      if (i === optionIndex) {
        return {
          ...item,
          label,
          is_qualified: isQualified,
        };
      }
      return item;
    });

    field.onChange({ ...field.value, options: updatedList });
  };

  const handleOptionRemove = (optionIndex: number) => {
    const updatedList = (field.value.options ?? []).filter((_, i: number) => i !== optionIndex);
    field.onChange({ ...field.value, options: updatedList });
  };

  const handleOptionSortChange = (updatedList: ScreenerQuestionOption[]) => {
    setValue(`screener_questions.${index}`, { ...field.value, options: updatedList }, { shouldValidate: false });
  };

  const handleMultipleChoiceChange = (value: boolean) => {
    setValue(
      `screener_questions.${index}`,
      { ...field.value, is_multiple_selection: value },
      { shouldValidate: false },
    );
  };

  const handleTypeChange = (type: BookingRecruitQuestionType) => {
    if (type === BookingRecruitQuestionType.ShortAnswer) {
      field.onChange({ ...field.value, type, options: [] });
    } else {
      setValue(
        `screener_questions.${index}`,
        {
          ...field.value,
          type,
          options: [
            { _id: getObjectId(), label: '', is_qualified: true },
            { _id: getObjectId(), label: '', is_qualified: true },
          ],
        },
        {
          shouldValidate: false,
          shouldDirty: false,
          shouldTouch: false,
        },
      );
    }
  };

  return (
    <li
      ref={setNodeRef}
      style={dragStyle}
      className={cn(
        `flex flex-col gap-4 overflow-hidden rounded-lg border border-border bg-background opacity-100 transition-opacity
        starting:opacity-0`,
        { 'z-20 shadow-lg': isMovable && isDragging },
      )}
      data-testid={field.value._id}
    >
      <div
        className={cn('flex items-center justify-between gap-2 px-4 pt-2 aria-[disabled=true]:!cursor-not-allowed', {
          '!cursor-grab touch-none active:!cursor-grabbing': isMovable && !isDisabled,
        })}
        {...attributes}
        {...listeners}
      >
        <div>{isMovable ? <GripVertical className="h-4 w-4" /> : null}</div>

        <div className="flex items-center gap-2">
          <Button
            disabled={isDisabled}
            size="icon"
            title={t('global.copy')}
            variant="ghost"
            onClick={() => onCopy(field.value._id)}
          >
            <Copy className="h-4 w-4" />
          </Button>

          <Button
            disabled={isDisabled}
            size="icon"
            title={t('global.remove')}
            variant="ghost"
            onClick={() => onRemove(field.value._id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <FormItem className="flex flex-col gap-4 p-4 pt-0">
        <FormControl>
          <div className="flex flex-col gap-2 md:flex-row">
            <div className="flex flex-1 flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_title`}>
                {t('sections.studies.recruit.fields.questionTitle')}
              </FormLabel>

              <Input
                autoFocus={isFocused}
                disabled={isDisabled}
                name={`input_screener_question_${field.value._id}_title`}
                value={field.value?.title}
                onChange={handleInputChange}
              />
            </div>

            <div className="flex w-[180px] flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_type`}>&nbsp;</FormLabel>

              <Select
                disabled={isDisabled}
                value={field.value?.type ?? BookingRecruitQuestionType.ShortAnswer}
                onValueChange={handleTypeChange}
              >
                <SelectTrigger id={`input_screener_question_${field.value._id}_type`}>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent className="z-20">
                  <SelectItem value="short_answer">{t('sections.studies.recruit.options.short_answer')}</SelectItem>
                  <SelectItem value="multiple_choice">
                    {t('sections.studies.recruit.options.multiple_choice')}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </FormControl>

        {titleError ? <FormErrorMessage message={titleError.message} prefix="sections.studies.formValidation" /> : null}

        {descriptionState.show ? (
          <FormControl>
            <div className="flex flex-col gap-1">
              <FormLabel htmlFor={`input_screener_question_${field.value._id}_description`}>
                {t('sections.studies.recruit.fields.descriptionTitle')} ({t('formFields.optional').toLocaleLowerCase()})
              </FormLabel>

              <Textarea
                autoFocus={descriptionState.shouldFocus}
                disabled={isDisabled}
                maxRows={3}
                name={`input_screener_question_${field.value._id}_description`}
                rows={1}
                value={field.value?.description ?? ''}
                onChange={e => {
                  if (descriptionState.shouldFocus) {
                    setDescriptionState(prev => ({ ...prev, shouldFocus: false }));
                  }
                  field.onChange({ ...field.value, description: e.target.value });
                }}
              />
            </div>
          </FormControl>
        ) : (
          <Button variant="outline" onClick={handleShowDescription} disabled={isDisabled}>
            <Plus className="h-4 w-4" />
            {t('sections.studies.recruit.fields.descriptionCta')}
          </Button>
        )}

        {field.value?.type === 'multiple_choice' ? (
          <ScreenerQuestionItemMultipleChoice
            id={field.value._id}
            isDisabled={isDisabled}
            options={field.value?.options ?? []}
            isMultipleSelection={field.value?.is_multiple_selection}
            onAddOption={addOption}
            onChangeMultipleSelection={handleMultipleChoiceChange}
            onChangeValue={handleOptionChange}
            onRemove={handleOptionRemove}
            onSort={handleOptionSortChange}
          />
        ) : null}

        {optionsError ? (
          <FormErrorMessage
            message={optionsError.find(error => error?.message)?.message}
            prefix="sections.studies.formValidation"
          />
        ) : null}

        {error ? <FormErrorMessage message={error?.message} prefix="sections.studies.formValidation" /> : null}
      </FormItem>
    </li>
  );
};

ScreenerQuestionItemComponent.displayName = 'ScreenerQuestionItem';

export const ScreenerQuestionItem = memo(ScreenerQuestionItemComponent);

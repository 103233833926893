import { TooltipProvider } from '@askable/ui/core/tooltip';
import { ThemeProvider } from 'next-themes';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

import { KindePropertyWrapper } from 'containers/Auth/KindePropertyWrapper';
import { RequireAuth } from 'containers/Auth/RequireAuth';

import { BuildStudy } from './BuildStudy/BuildStudy';
import { Recruit } from './Recruit/Recruit';
import { MessagesContent as RecruitMessagesContent } from './Recruit/containers/MessagesContent';
import { ParticipantsContent as RecruitParticipantsContent } from './Recruit/containers/ParticipantsContent';
import { SettingsContent as RecruitSettingsContent } from './Recruit/containers/SettingsContent';
import { Report } from './Report/Report';
import { Results } from './Results/Results';
import { ParticipantContent as ResultsParticipantContent } from './Results/containers/ParticipantContent';
import { TaskContent as ResultsTaskContent } from './Results/containers/TaskContent';
import { StudiesContainer } from './StudiesContainer';

import type { RouteObject } from 'react-router-dom';

export const studiesRoutes: RouteObject[] = [
  {
    path: 'studies/:studyId',
    element: (
      <RequireAuth>
        <KindePropertyWrapper>
          <ThemeProvider attribute="class">
            <HelmetProvider>
              <TooltipProvider>
                <StudiesContainer />
              </TooltipProvider>
            </HelmetProvider>
          </ThemeProvider>
        </KindePropertyWrapper>
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="build" replace />,
      },
      {
        path: 'build',
        element: <BuildStudy />,
      },
      {
        path: 'recruit',
        element: <Recruit />,
        children: [
          {
            index: true,
            element: <Navigate to="settings" replace />,
          },
          {
            path: 'participants/:segmentId?',
            element: <RecruitParticipantsContent />,
          },
          {
            path: 'messages',
            element: <RecruitMessagesContent />,
          },
          {
            path: 'settings',
            element: <RecruitSettingsContent />,
          },
        ],
      },
      {
        path: 'results',
        element: <Results />,
        children: [
          {
            index: true,
            element: <ResultsTaskContent />,
          },
          {
            path: 'task/:taskId',
            element: <ResultsTaskContent />,
          },
          {
            path: 'participant/:participantId',
            element: <ResultsParticipantContent />,
          },
        ],
      },
      {
        path: 'report',
        element: <Report />,
      },
    ],
  },
];

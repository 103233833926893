import { AnchorButton } from '@askable/ui/core/button';
import { cn } from '@askable/ui/lib/utils';
import { TriangleAlert } from 'lucide-react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface NavItem {
  label: string;
  to: string;
  key: string;
}

interface Props {
  isInvalid: boolean;
  item: NavItem;
  section: string;
}

const INVALID_STATE_DELAY_MS = 400;

export const NavLink = memo(function NavLinkMemo({ isInvalid, item, section }: Props) {
  const { t } = useTranslation();
  const [showInvalidState, setShowInvalidState] = useState(isInvalid);

  useEffect(() => {
    if (isInvalid) {
      const timer = setTimeout(() => {
        setShowInvalidState(true);
      }, INVALID_STATE_DELAY_MS);
      return () => clearTimeout(timer);
    } else {
      setShowInvalidState(false);
    }
  }, [isInvalid]);

  return (
    <AnchorButton
      key={item.to}
      asChild
      variant="ghost"
      className={cn(
        `relative flex-1 gap-0 rounded-lg px-4 text-muted-foreground transition-colors hover:text-foreground
        focus:text-foreground lg:flex-none`,
        {
          'bg-secondary text-foreground': section === item.to || section === item.to.split('/')[0],
          'bg-destructive/10 text-foreground hover:bg-destructive/10 focus:bg-destructive/10': showInvalidState,
        },
      )}
    >
      <Link to={item.to} title={isInvalid ? t('formValidation.invalid') : undefined} replace>
        {item.label}
        <TriangleAlert
          className={cn('h-3 w-0 scale-0 text-destructive transition-all', {
            'ml-1 w-3 scale-100': showInvalidState,
          })}
        />
      </Link>
    </AnchorButton>
  );
});

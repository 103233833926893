import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@askable/ui/core/table';
import { format, formatDistanceToNow, formatISO } from 'date-fns';
import { Ellipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { TableLoading } from 'components/Loading/TableLoading';
import resendInvitationMutation from 'data/mutations/teamInvitations/resendInvitation';
import revokeInvitationMutation from 'data/mutations/teamInvitations/revokeInvitation';

import type {
  ResendInvitationMutation,
  ResendInvitationMutationVariables,
  RevokeInvitationMutation,
  RevokeInvitationMutationVariables,
  TeamInvitations,
} from 'generated/graphql';

type PendingInvitationsProps = {
  invitations: TeamInvitations[];
  fetching: boolean;
};

export const PendingInvitations = ({ invitations, fetching }: PendingInvitationsProps) => {
  const { t } = useTranslation();
  const [, resendInvitation] = useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    resendInvitationMutation,
  );
  const [, revokeInvitation] = useMutation<RevokeInvitationMutation, RevokeInvitationMutationVariables>(
    revokeInvitationMutation,
  );

  // Resend the invitation and give a feedback back to the user
  const onResendInvitation = async (invitationId: string) => {
    try {
      await resendInvitation({ _id: invitationId });
      toast.success(t('sections.settings.team.invitationResent'));
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  // Revoke an invitation
  const onRevokeInvitation = async (invitationId: string) => {
    try {
      await revokeInvitation({ _id: invitationId });
      toast.success(t('sections.settings.team.invitationRevoked'));
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <section className="flex flex-col gap-2">
      <header className="flex flex-col">
        <h3 className="text-md font-semibold">{t('sections.settings.team.pendingInvitations')}</h3>
      </header>
      {fetching ? (
        <TableLoading className="py-6" />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('sections.settings.team.name')}</TableHead>
              <TableHead>{t('sections.settings.team.invitedBy')}</TableHead>
              <TableHead>{t('sections.settings.team.sent')}</TableHead>
              <TableHead>&nbsp;</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invitations.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center text-muted-foreground">
                  {t('sections.settings.billing.emptyTable')}
                </TableCell>
              </TableRow>
            ) : (
              invitations?.map(invitation => (
                <TableRow key={invitation._id!} applyDefaultHoverStyle>
                  <TableCell className="font-medium">{invitation.email}</TableCell>
                  <TableCell>{`${invitation?.InviterUser?.meta?.identity?.firstname} ${invitation?.InviterUser?.meta?.identity?.lastname ?? ''}`}</TableCell>
                  <TableCell>
                    {invitation?.last_sent ? (
                      <time
                        className="flex flex-col"
                        dateTime={formatISO(invitation?.last_sent)}
                        title={formatISO(invitation?.last_sent)}
                      >
                        {formatDistanceToNow(invitation?.last_sent, { addSuffix: true })}
                        <div className="text-xs text-muted-foreground">
                          {format(invitation?.last_sent, 'd MMM yyyy')}
                        </div>
                      </time>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="ml-auto">
                          <Ellipsis className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={() => onResendInvitation(invitation._id)}>
                          {t('sections.settings.team.resend')}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => onRevokeInvitation(invitation._id)}
                          className="text-destructive"
                        >
                          {t('sections.settings.team.cancelInvite')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      )}
    </section>
  );
};

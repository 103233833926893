export const chunkImageIds = (ids: string[], size: number = 50) => {
  return ids.reduce((acc: string[][], id, i) => {
    const chunkIndex = Math.floor(i / size);
    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [];
    }
    acc[chunkIndex].push(id);
    return acc;
  }, []);
};

import { Button } from '@askable/ui/core/button';
import { Skeleton } from '@askable/ui/core/skeleton';
import { Plus } from 'lucide-react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { getObjectId } from 'containers/Studies/utils/utils';

import { IndustryList } from '../data/IndustryList.query';

import { IndustryAndOccupationItem } from './IndustryAndOccupationItem';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export type IndustryAndOccupationType = NonNullable<RecruitFormFields['industry_and_occupation']>[number];

const FIELD_NAME = 'industry_and_occupation';

export const IndustryAndOccupation = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: FIELD_NAME });
  const formValues = useWatch({ control, name: FIELD_NAME });

  const [industryListQuery] = useQuery({ query: IndustryList });

  const industries = industryListQuery.data?.industryList ?? [];
  const hasIndustries = industries.length > 0;
  const selectedIndustries = formValues?.map(item => item.name) ?? [];
  const isAddDisabled = isDisabled || selectedIndustries.length >= industries.length;

  /**
   * TODO: THIS SHOULD GET SOME REFACTOR; WE SHOULD KEEP A LOCAL STATE FOR `CREATING` STATE
   * INSTEAD OF STORING A FAKE/BROKEN ITEM IN THE FORM STATE; THIS ALSO SHOULD BE A SINGLE
   * INPUT RENDERING ALL INDIVIDUAL ITEMS, INSTEAD OF HAVING EACH ROW BE A SEPARATE INPUT :|
   */
  const addListItem = () => {
    if (!hasIndustries) {
      return;
    }

    const newItem: IndustryAndOccupationType = {
      _id: getObjectId(),
      name: '',
      subcategories: [],
    };

    field.onChange([...(formValues ?? []), newItem]);
  };

  const handleRemove = (id: string) => {
    const updatedList = formValues?.filter((item: IndustryAndOccupationType) => item._id !== id);
    field.onChange(updatedList);
  };

  if (industryListQuery.error) {
    return (
      <div className="text-sm text-destructive">{t('sections.studies.recruit.fields.industryAndOccupationError')}</div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {industryListQuery.fetching ? <Skeleton className="h-8 w-2/3" /> : null}

      {!industryListQuery.fetching && industries?.length
        ? field.value?.map((item: IndustryAndOccupationType, index: number) => (
            <IndustryAndOccupationItem
              index={index}
              isDisabled={isDisabled}
              items={industries}
              key={item._id}
              selectedIndustries={selectedIndustries}
              onRemove={handleRemove}
            />
          ))
        : null}

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}

      <Button variant="outline" onClick={addListItem} disabled={isAddDisabled}>
        <Plus className="h-4 w-4" /> {t('sections.studies.recruit.fields.industryAndOccupationCta')}
      </Button>
    </div>
  );
};

import { Alert, AlertDescription, AlertTitle } from '@askable/ui/core/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/core/alert-dialog';
import { Button } from '@askable/ui/core/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@askable/ui/core/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { cn } from '@askable/ui/lib/utils';
import { CircleCheck, CircleDashed, Ellipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useMutation } from 'urql';
import { useBoolean } from 'usehooks-ts';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { KindeSsoStrategy } from 'generated/graphql';

import { DeleteSSOConnection } from '../data/DeleteSSOConnection.mutation';

import { CustomSamlForm } from './CustomSaml';
import { MicrosoftSSO } from './MicrosoftSSO';

import type { TeamSettingsSsoConnection } from 'generated/graphql';

interface Props {
  handleDeleteComplete: () => void;
  handleSaveComplete: () => void;
  connection?: Omit<TeamSettingsSsoConnection, 'strategy'> & {
    strategy: KindeSsoStrategy | 'legacy';
  };
}

const SSO_TITLE_MAP: Record<KindeSsoStrategy | 'legacy', string> = {
  oauth2_azure_ad: 'Microsoft Azure AD (OAuth 2.0)',
  wsfed_azure_ad: 'Microsoft Entra ID (WS-Fed)',
  saml_custom: 'Custom SAML',
  legacy: 'Legacy SSO connection',
};

export const ActiveConnection = ({ connection, handleSaveComplete, handleDeleteComplete }: Props) => {
  const { t } = useTranslation();
  const configureDialog = useBoolean();
  const deleteDialog = useBoolean();
  const { details } = useConnectedClient();
  const [{ fetching }, deleteSSOConnection] = useMutation(DeleteSSOConnection);

  const domainsLength = connection?.options?.home_realm_domains.length ?? 0;

  const name = SSO_TITLE_MAP[connection?.strategy!];

  const onSaveComplete = () => {
    handleSaveComplete();
    toast.success(t('sections.settings.team.enterpriseSso.updateSuccessful'));

    configureDialog.setFalse();
  };

  const onDelete = async () => {
    const result = await deleteSSOConnection({
      connectionId: connection?.connection_id!,
    });

    if (result.error) {
      toast.error(result.error.message);
      return;
    }

    toast.success(t('sections.settings.team.enterpriseSso.deleteSuccessful'));

    handleDeleteComplete();

    deleteDialog.setFalse();
  };

  const isLegacy = connection?.strategy === 'legacy';

  return (
    <Alert className={cn('flex items-center justify-between', { 'opacity-80': isLegacy })}>
      <div className="flex gap-2">
        {connection?.enabled ? (
          <CircleCheck className={cn('h-4 w-4 text-success', { 'text-warning': isLegacy })} />
        ) : (
          <CircleDashed className="h-4 w-4" />
        )}
        <div className="flex flex-col gap-1">
          <AlertTitle>{name}</AlertTitle>
          {domainsLength ? (
            <AlertDescription>{connection?.options?.home_realm_domains.join(', ')}</AlertDescription>
          ) : (
            <p>{t('sections.settings.team.enterpriseSso.domains.noDomains')}</p>
          )}
        </div>
      </div>
      {!isLegacy ? (
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost">
                <Ellipsis className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={configureDialog.setTrue}>{t('global.configure')}</DropdownMenuItem>
                <DropdownMenuItem onClick={deleteDialog.setTrue}>{t('global.delete')}</DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>

          <Dialog open={configureDialog.value} onOpenChange={configureDialog.toggle}>
            <DialogContent className="w-full max-w-xl">
              <DialogHeader>
                <DialogTitle>{name}</DialogTitle>
              </DialogHeader>
              {match(connection?.strategy)
                .with(KindeSsoStrategy.SamlCustom, () => {
                  return (
                    <CustomSamlForm
                      handleSaveComplete={onSaveComplete}
                      connectionId={connection?.connection_id!}
                      teamId={details?.ConnectedTeam?._id!}
                      defaultValues={{
                        entityId: connection?.options?.saml_custom?.saml_entity_id ?? '',
                        emailKey: connection?.options?.saml_custom?.saml_email_key_attr ?? '',
                        idpMetadataUrl: connection?.options?.saml_custom?.saml_idp_metadata_url ?? '',
                        homeRealmDomains: connection?.options?.home_realm_domains.join(', ') ?? '',
                        isEnabled: connection?.enabled ?? false,
                      }}
                    />
                  );
                })
                .otherwise(() => {
                  return (
                    <MicrosoftSSO
                      handleSaveComplete={onSaveComplete}
                      teamId={details?.ConnectedTeam?._id!}
                      connectionId={connection?.connection_id!}
                      strategy={connection?.strategy as KindeSsoStrategy}
                      defaultValues={{
                        isEnabled: connection?.enabled ?? false,
                        clientId: connection?.options?.azure_ad?.client_id ?? '',
                        azureAdDomain: connection?.options?.azure_ad?.entra_id_domain ?? '',
                        clientSecret: connection?.options?.azure_ad?.client_secret ?? '',
                        homeRealmDomains: connection?.options?.home_realm_domains.join(', ') ?? '',
                        useCommonEndpoint: connection?.options?.azure_ad?.is_use_common_endpoint ?? false,
                      }}
                    />
                  );
                })}
            </DialogContent>
          </Dialog>

          <AlertDialog open={deleteDialog.value} onOpenChange={deleteDialog.toggle}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t('sections.settings.team.enterpriseSso.deleteConfirmation.title')}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t('sections.settings.team.enterpriseSso.deleteConfirmation.description')}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel disabled={fetching}>Cancel</AlertDialogCancel>
                <AlertDialogAction disabled={fetching} onClick={onDelete}>
                  {t('global.delete')}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      ) : null}
    </Alert>
  );
};

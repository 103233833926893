/* eslint-disable react/display-name */
import { t } from 'i18next';
import { forwardRef } from 'react';

import { Badge } from '../core/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '../core/tooltip';
import { cn } from '../lib/utils';

import type { BadgeProps } from '../core/badge';
import type { TooltipContentProps } from '@radix-ui/react-tooltip';

type Props = BadgeProps & {
  align?: TooltipContentProps['align'];
  title?: string;
};

export const BetaBadge = forwardRef<HTMLDivElement, Props>(
  ({ align = 'center', className, title = t('betaBadge.beta', { defaultValue: 'Beta' }), ...props }, ref) => {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Badge {...props} className={cn(className)} ref={ref}>
            {title}
          </Badge>
        </TooltipTrigger>
        <TooltipContent side="bottom" align={align}>
          <p className="max-w-[288px]">
            {t('betaBade.description', { defaultValue: 'This feature is available for early adopters and may change' })}
          </p>
        </TooltipContent>
      </Tooltip>
    );
  },
);

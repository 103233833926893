import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Participant = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={fgHsl}
        d="M25.5 11.5s-14 1.9-14.3 1.8L14.7 59l39-2.7-4.5-46.5-14.7.5a74 74 0 0 1-.8-4.3c-.6-3.1-3-3.8-4.8-3.8-1.7 0-3.6 2.5-3.8 4.5 0 1.6.2 4 .4 5Z"
      />
      <path
        fill={bgHsl}
        d="m29.5 22.3 1.2.1c.6 0 1.3-.3 2-.7.7-.5 1.1-1 1.2-1.7.2-.8-.2-1.6-1-2-.5-.4-1-.5-1.6-.6h-.4c-1.5-.4-3 1-3.3 2.2-.1.5-.3 2 1.9 2.7Zm1.5 9.1a7.3 7.3 0 0 0 1.4-.1c2.6-.5 5.9-2.8 5-4.7-1.9-3.2-5.5-3.4-7.9-3-.9.1-2.3 1-3 1.7a4 4 0 0 0-1.3 2.5c.2 2.6 3.4 3.5 5.7 3.6Zm4.8 3.8c-2.8.6-7 1-10.7 1.3l-5.6.5c-.8 0-1 .6-.9 1.1 0 .5.5.9 1.3.8h.4l5-.5c3.8-.3 8.3-.8 11.4-1.4.9-.2 1.2-.7 1-1.2-.2-.5-.3-.9-1.1-.7l-.8.1Zm-1.2 9.7c-2.5.5-6.4.9-9.8 1.2l-4.6.4c-.8.1-1.4.6-1.3 1.2.1.5.8.8 1.5.8L25 48c3.5-.3 7.5-.7 10.3-1.3 1-.2 1.2-.7 1-1.2-.2-.5-.3-.8-1.7-.6ZM30.8 41a1 1 0 0 0-1.2-.7c-1.7.6-4.3.8-6.7 1l-3.2.4a1 1 0 0 0 .2 2l.1-.1 3.1-.3c2.4-.3 5.2-.5 7-1 .6-.2.9-.8.7-1.3ZM20.3 52.8H22a53.4 53.4 0 0 0 3.5-.4c.5 0 1-.5.9-1 0-.6-.6-1-1-.9l-1.4.1-3.7.3a1 1 0 1 0-.1 2Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M41.2 8.5c-.3-1.6-1.3-2.4-3-2.2l-2.6.1A6.6 6.6 0 0 0 33 2.2a6.6 6.6 0 0 0-5-.7c-3.6.7-4.4 3.8-4.3 5.9l-3.6.3c-.3 0-.7.2-1 .5-.3.3-.6.9-.4 1.9v.6l-6.2 1c-1 .2-1.8 1-2 2l-1 .6a2 2 0 0 0-1.7 1 3 3 0 0 0-.5 2l.5 10.2v.8c.7 12.8 1.5 29 3 33.9.2.4.6.7 1 .7h.2a351.3 351.3 0 0 0 22.6-1.2c5.3-.4 10.2-.7 17-1.6.4 0 .9-.7.9-1.2V58h.4c1.4 0 2.5-1.2 2.3-2.6l-.4-4.6c.6-.3 1.2-.7 1.7-1.2a8.5 8.5 0 0 0-.6-12.5 7.5 7.5 0 0 0-2.9-1.5l-2.5-25.5A2.4 2.4 0 0 0 48 8l-6.7.5ZM51 35.4l-2.4-25c0-.3-.2-.5-.5-.5l-6.6.5v1c0 .3 0 .7-.3 1-.4.5-.8.5-1.2.5h-.2l-6.8.6h-.3l-1.8.1a756.1 756.1 0 0 1-10.3.8c-.2 0-.6 0-1-.2s-.5-.5-.6-1.6l-6.2 1c-.2 0-.4.2-.4.5l3 43.6c0 .2.3.4.5.4a786.7 786.7 0 0 1 37-2c.3 0 .5-.2.4-.5a30 30 0 0 0-.4-4 11.5 11.5 0 0 1-4.2.3 8 8 0 0 1-5.6-3 9.4 9.4 0 0 1-1.3-8c.6-1.8 2.1-4.1 6-5.1l2-.4H51ZM29.5 7c-1 0-1.7-.6-1.8-1.5 0-.8.6-1.6 1.6-1.6 1-.1 1.8.5 1.8 1.4 0 .8-.6 1.6-1.6 1.7Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M53 40.2c-.1.1-2.7 2.2-4.3 4.2L47.2 43a1.1 1.1 0 1 0-1.6 1.5L48 47c.2.2.5.3.8.3h.1c.4 0 .7-.2.9-.5 1.2-2 4.6-4.8 4.6-4.8.5-.4.6-1.1.2-1.6-.4-.5-1.1-.6-1.6-.2Z"
      />
    </svg>
  );
};

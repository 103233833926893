import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Card } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';

import './styles/sessionFormatStyles.scss';

function SessionFormat(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingData, setBookingData] = useState(null);
  const [bookingState, setBookingState] = useState(booking);
  const [sessionFormat, setSessionFormat] = useState(_.get(booking, 'config.session.type'));
  const [oldBookingData, setOldBookingData] = useState(null);
  const [showHeadsUpMessage, setShowHeadsUpMessage] = useState(true);

  useEffect(() => {
    props.updateLastStep({
      step: 'Project Setup',
      subStep: `/booking-setup/${props.booking._id}/project-setup/session-format`,
      stepId: 'project_setup_session_format',
    });
  }, []);

  useEffect(() => {
    setBookingState(props.booking);
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
  }, [bookingState]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText:
          'Yes they do, participants agree to that when they sign up to Askable, and also again for every opporunity they apply to.',
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>
            We highly recommend using the individual interview format. This is because if you have any issues with a
            specific person, it&apos;s easier to find a replacement participant.
          </span>
        ),
      },
      {
        cardText: 'Focus groups that rely on minimum group sizes can be quite stressful!',
        additionalText: true,
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__sessionFormatCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Do participants agree to be recorded for research purposes?"
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'http://help.askable.com/en/articles/4849134-what-do-participants-have-to-agree-to-before-being-recorded',
              '_blank',
            );
          }}
        />
        <p className="cardContainerTitle additionalTitle">Tips</p>
        <CardContainer cardId="__sessionFormatCard2" cardClass="slideInAnimationDelay160" cardTexts={cardTexts2} />
      </>
    );
  };

  const onClickCard = (type: any) => {
    // Check if it's using focus group with Askable Live
    if (!oldBookingData) setOldBookingData(props.booking);
    let remoteConfig = _.get(props, 'booking.config.remote');
    if (oldBookingData) remoteConfig = _.get(oldBookingData, 'config.remote');
    if (type === 2 && bookingUtils.isAskableLive(_.get(props, 'booking'))) {
      remoteConfig = {
        ..._.get(props, 'booking.config.remote'),
        tool: 'other',
        askable_live: false,
      };
    }
    setSessionFormat(type);

    let reviewSubmission = bookingState.config.options.review_submission;
    if (
      type === 2 &&
      (bookingUtils.isRemote(_.get(props, 'booking')) || bookingUtils.isInPerson(_.get(props, 'booking')))
    ) {
      reviewSubmission = true;
    }

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        session: {
          ...bookingState.config.session,
          type,
          slot_min: type === 1 ? 1 : _.get(bookingState, 'total_participants'),
          slot_max: type === 1 ? 1 : _.get(bookingState, 'total_participants'),
        },
        remote: remoteConfig,
        options: {
          ...bookingState.config.options,
          review_submission: reviewSubmission,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (type !== _.get(booking, 'config.session.type')) {
      const bookingObj = {
        config: {
          session: {
            type,
            slot_min: type === 1 ? 1 : _.get(bookingState, 'total_participants'),
            slot_max: type === 1 ? 1 : _.get(bookingState, 'total_participants'),
          },
          remote: remoteConfig,
          options: {
            review_submission: reviewSubmission,
          },
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { session: { type: any... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.updateBookingState(bookingStateObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.updateBookingState(bookingStateObj);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/project-setup/session-duration`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Session format
      </h1>
      <p className="label">What format will you be doing doing your research in?</p>
      <div className="sessionFormatTypes">
        <Card
          title="Individual Interviews"
          className="sessionFormatCard"
          content={[
            {
              label:
                'One participant in an classic interview style usability test. You can have multiple facilitators, observers and note takers.',
            },
          ]}
          selected={sessionFormat === 1}
          onClickCard={() => onClickCard(1)}
        />
        <Card
          title="Focus Group"
          className="sessionFormatCard"
          content={[
            {
              label:
                'Have up to 50 participants all together in one group session. You can also schedule multiple focus groups.',
            },
          ]}
          selected={sessionFormat === 2}
          onClickCard={() => onClickCard(2)}
        />
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
      {sessionFormat === 2 && bookingUtils.isAskableLive(oldBookingData) && showHeadsUpMessage && (
        <div className="additionalInfoContainer flipInAnimation">
          <img className="headsUpIcon" src="/icons/warningIcon.svg" alt="Type illustration" />
          <div className="additionalInfoContent">
            <p className="additionalInfoTitle">Heads up!</p>
            <p className="additionalInfoText">
              Focus group sessions are not available for the <b>Askable Sessions</b> tool. Choosing focus group the
              remote testing toll will be changed to <b>another tool</b>.
            </p>
            <p className="additionalInfoText">
              If you want to change the tool to be used, just access the{' '}
              <a
                onClick={() =>
                  props.history.push({
                    pathname: `/booking-setup/${booking._id}/project-setup/tool`,
                  })
                }
              >
                Tools
              </a>{' '}
              option in the menu
            </p>
          </div>
          <X
            className="closeIcon"
            onClick={() => {
              setShowHeadsUpMessage(false);
            }}
            style={{ width: 20, height: 20 }}
          />
        </div>
      )}
    </div>
  );
}

export default deprecatedWithRouter(SessionFormat);

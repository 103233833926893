/* eslint-disable max-lines */
import { Button } from '@askable/ui/core/button';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { RadioGroup, RadioGroupItem } from '@askable/ui/core/radio-group';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Circle, CircleCheck } from 'lucide-react';
import { useEffect, useState } from 'react';

import { GoogleMaps } from 'components/common';
import LocationAutocomplete from 'components/common/LocationAutocomplete/view';
import CardContainer from 'components/createBooking/components/cardContainer';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import { bookingUtils } from 'lib/booking';
import { location } from 'lib/location';

import { stepperMenuUtils } from '../stepperMenu/stepperMenuUtils';

import type { Booking, Location } from 'generated/graphql';

import './styles/sessionLocationStyles.scss';

type Props = {
  booking: Booking;
  bookingSteps: any;
  context: any;
  history: any;
  updateLastStep: any;
  renderRightContent: any;
  renderRightAppPreview: any;
  validateBooking: any;
  options: any;
  team: any;
};
function SessionLocation(props: Props) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [bookingSteps, setBookingSteps] = useState(_.get(props, 'bookingSteps'));
  const [bookingData, setBookingData] = useState<Partial<Booking> | null>(null);
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapMarker, setMapMarker] = useState<{
    name: string | null;
    marker: google.maps.Marker;
  } | null>(null);
  const [addressError, setAddressError] = useState<boolean>(false);

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/session-location`,
      stepId: 'additional_info_session_location',
    });
    createGoogleMap();
  }, []);

  useEffect(() => {
    if (bookingUtils.isRemote(_.get(props, 'booking'))) {
      props.history.push({
        pathname: `/booking-setup/${_.get(props, 'booking._id')}/additional-info/your-timezone`,
      });
    } else if (
      bookingUtils.isOnlineTask(_.get(props, 'booking')) ||
      bookingUtils.isLongitudinal(_.get(props, 'booking'))
    ) {
      props.history.push({
        pathname: `/booking-setup/${_.get(props, 'booking._id')}/additional-info/closing-date`,
      });
    }
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    setBookingSteps(_.get(props, 'bookingSteps'));
  }, [props.bookingSteps]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    const isError = stepperMenuUtils.validateSessionLocation(bookingState);
    setAddressError(isError);
  }, [bookingState]);

  const createGoogleMap = async () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'google' does not exist on type 'Window &... Remove this comment to see the full error message
    const map = await new window.google.maps.Map(document.getElementById('addressMapPreviewContainer'), {
      ...props.options,
      controlSize: 24,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: 'cooperative',
    });
    setGoogleMap(map);
    loadSavedMarker(map);
  };

  const markerDragged = () => {
    if (_.get(mapMarker, 'marker')) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const newLat = mapMarker.marker.getPosition().lat();
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const newLng = mapMarker.marker.getPosition().lng();
      onUpdateMarkerPosition(newLat, newLng);
    }
  };

  const loadSavedMarker = async (map: google.maps.Map) => {
    if (_.get(props.booking, 'config.location.latitude') && _.get(props.booking, 'config.location.longitude')) {
      const marker = await handleMarker({
        savedMarker: null,
        map,
        item: {
          name: _.get(props.booking, 'config.location.name'),
          latitude: _.get(props.booking, 'config.location.latitude'),
          longitude: _.get(props.booking, 'config.location.longitude'),
        },
      });
      setMapMarker(marker);
    }
  };

  const handleMarker = async ({
    savedMarker,
    map,
    item,
  }: {
    savedMarker: { marker: google.maps.Marker | null } | null;
    map: google.maps.Map | null;
    item: { name: string | null; longitude: number | null; latitude: number | null };
  }) => {
    const markerIcon = { url: '/booking/icons/mapMarkerIcon.svg' };
    if (savedMarker?.marker) {
      savedMarker?.marker?.setMap(null);
    }

    const marker = new window.google.maps.Marker({
      title: item.name,
      position: { lat: item?.latitude ?? 0, lng: item?.longitude ?? 0 },
      animation: window.google.maps.Animation.DROP,
      icon: markerIcon,
      // draggable: true,
      map,
    });
    marker.addListener('dragend', markerDragged);

    const newMarker = {
      name: item.name,
      marker,
    };

    return newMarker;
  };

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText:
          'For in person testing, participants are required to confirm that they are following the local rules and restrictions.',
      },
    ];
    const cardTexts2 = [
      {
        cardText: (
          <span>
            Yes you can, just select the in-context research option on this page. You&apos;ll be asked to choose from
            some additional in context options such as where you would like to do the research and how far you&apos;re
            willing to travel.
          </span>
        ),
      },
    ];

    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__sessionLocationCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="Covid 19"
          cardTexts={cardTexts1}
          onLinkClick={() => {
            window.open(
              'https://help.askable.com/en/articles/4849231-am-i-allowed-to-do-face-to-face-testing',
              '_blank',
            );
          }}
        />
        <CardContainer
          additionalCard
          cardId="__sessionLocationCard2"
          cardClass="slideInAnimationDelay160"
          cardTitle="Can I do in-context research?"
          cardTexts={cardTexts2}
          onLinkClick={() => {
            window.open(
              'https://help.askable.com/en/articles/4849419-can-i-do-in-context-research-with-askable',
              '_blank',
            );
          }}
        />
      </>
    );
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/additional-info/description`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const saveBookingData = (objectToUpdate: Partial<Booking>, bookingStateObj: Partial<Booking>) => {
    setBookingData(objectToUpdate);

    if (
      (_.get(booking, 'steps.additional_info_session_location') === 'error' &&
        _.get(bookingStateObj, 'config.location.name')) ||
      (_.get(booking, 'steps.additional_info_session_location') === 'seen' &&
        !_.get(bookingStateObj, 'config.location.name'))
    ) {
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }
    props.history.replace({ booking: objectToUpdate, bookingState: bookingStateObj });
  };

  const onLocationSelected = (value: Location) => {
    // Get the new timezone from the coordinates that came from the google api
    location.getTimezoneFromCoordinates(value.latitude, value.longitude).then(async data => {
      const locationObj = {
        name: value.formatted_address,
        street1: value.street1,
        city: value.city,
        postal_code: value.postal_code,
        state: value.state,
        country: value.country,
        latitude: value.latitude,
        longitude: value.longitude,
        region: value.region,
        google_location: {
          viewport: _.get(value, 'google_location.viewport') || {},
        },
      };

      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          timezone: data.timeZoneId,
          location: {
            ...bookingState?.config?.location,
            ...locationObj,
          },
        },
      };
      setBookingState(bookingStateObj);

      const bookingObj = {
        config: {
          ...bookingData?.config,
          timezone: data.timeZoneId,
          location: locationObj,
        },
      };
      saveBookingData(bookingObj, bookingStateObj);

      const markerToSave = await handleMarker({
        savedMarker: mapMarker,
        map: googleMap,
        item: {
          name: value.formatted_address ?? null,
          latitude: value.latitude ?? null,
          longitude: value.longitude ?? null,
        },
      });
      setMapMarker(markerToSave);
    });
  };

  const onChangeAddressLevel = (value: string) => {
    const locationObj = {
      level: value,
    };
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        location: {
          ...bookingState.config?.location,
          ...locationObj,
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        ...bookingData?.config,
        location: {
          ...bookingData?.config?.location,
          ...locationObj,
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);
  };

  const onUpdateMarkerPosition = (latitude: number, longitude: number) => {
    if (latitude && longitude) {
      const locationObj = {
        latitude,
        longitude,
      };
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          location: {
            ...bookingState.config?.location,
            ...locationObj,
          },
        },
      };
      setBookingState(bookingStateObj);

      const bookingObj = {
        config: {
          ...bookingData?.config,
          location: {
            ...bookingData?.config?.location,
            ...locationObj,
          },
        },
      };
      saveBookingData(bookingObj, bookingStateObj);
    }
  };

  const handleInContextResearch = (isDoingInContextResearch: boolean) => {
    let in_context = {};
    if (isDoingInContextResearch) {
      in_context = {
        location_type: _.get(bookingState, 'config.in_context.location_type') || 1,
        travel_time_limit: _.get(bookingState, 'config.in_context.travel_time_limit') || 30,
      };
    }

    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        in_context: {
          ...in_context,
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        ...bookingData?.config,
        in_context: {
          ...in_context,
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);
  };

  const handleInContextOption = (key: string, value: number) => {
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        in_context: {
          ...bookingState.config?.in_context,
          [key]: value,
        },
      },
    };
    setBookingState(bookingStateObj);

    const bookingObj = {
      config: {
        ...bookingData?.config,
        in_context: {
          ...bookingData?.config?.in_context,

          [key]: value,
        },
      },
    };
    saveBookingData(bookingObj, bookingStateObj);
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Where will the sessions be held?
      </h1>
      <div
        className={`sessionLocationOptionContainer streetAddress ${!bookingUtils.isInContextResearch(bookingState) ? 'active' : ''}`}
      >
        <Button
          variant={!bookingUtils.isInContextResearch(bookingState) ? 'default' : 'ghost'}
          onClick={() => handleInContextResearch(false)}
          className="gap-2"
        >
          {!bookingUtils.isInContextResearch(bookingState) ? (
            <CircleCheck className="h-5 w-5" />
          ) : (
            <Circle className="h-5 w-5" />
          )}{' '}
          At my office or space
        </Button>

        <div className={`sessionLocationOption ${!bookingUtils.isInContextResearch(bookingState) ? 'active' : ''}`}>
          <div className="addressLocationContainer">
            <p className="addressLabel">Street address</p>
            <LocationAutocomplete
              placeholder="123 Main Street"
              initialSearchValue={_.get(bookingState, 'config.location.name')}
              onNewPlace={onLocationSelected}
              type="address"
              countryCodes={[_.get(bookingState, 'config.location.country')]}
              styleWidth={500}
              canChangeCountry
            />
            {addressError && (
              <p className="text-sm font-bold text-destructive">
                Please enter a valid address. Address must have a street number.
              </p>
            )}
          </div>
          <div className="addressLocationContainer">
            <p className="addressLabel">Office, floor (Optional)</p>
            <Input
              placeholder="Level 2, Suite A"
              className="inputField inputWithBorder"
              value={_.get(bookingState, 'config.location.level', '') || ''}
              onChange={e => onChangeAddressLevel(e.target.value)}
            />
          </div>
          <div className="addressMapPreview" id="addressMapPreviewContainer">
            {googleMap && (
              <GoogleMaps
                id="addressMap"
                googleMap={googleMap}
                style={{
                  width: 500,
                  height: 250,
                  borderRadius: '8px',
                  display:
                    bookingState.config?.location?.latitude && bookingState.config?.location?.longitude
                      ? undefined
                      : 'none',
                }}
                lat={bookingState.config?.location?.latitude}
                lng={bookingState.config?.location?.longitude}
                locationViewport={[_.get(bookingState, 'config.location.google_location.viewport')]}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`sessionLocationOptionContainer ${bookingUtils.isInContextResearch(bookingState) ? 'active' : ''}`}
      >
        <Button
          variant={bookingUtils.isInContextResearch(bookingState) ? 'default' : 'ghost'}
          onClick={() => handleInContextResearch(true)}
          className="gap-2"
        >
          {bookingUtils.isInContextResearch(bookingState) ? (
            <CircleCheck className="h-5 w-5" />
          ) : (
            <Circle className="h-5 w-5" />
          )}
          I'm doing in-context research
        </Button>
        <div className={`sessionLocationOption ${bookingUtils.isInContextResearch(bookingState) ? 'active' : ''}`}>
          <div className="inContextContainer mt-2">
            <p className="inContextTitle">Where would you like to do it?</p>
            <RadioGroup
              defaultValue={_.get(bookingState, 'config.in_context.location_type')}
              onValueChange={(value: string) => handleInContextOption('location_type', parseInt(value, 10))}
            >
              {bookingUtils.bookingInContextResearchLocationTypes().map(option => (
                <div className="flex items-center space-x-2" key={option.value}>
                  <RadioGroupItem
                    value={String(option.value)}
                    id={`inContextResearchLocationTypesGroup_${option.value}`}
                  />
                  <Label htmlFor={`inContextResearchLocationTypesGroup_${option.value}`}>{option.label}</Label>
                </div>
              ))}
            </RadioGroup>

            <p className="inContextTitle">How far are you willing to travel?</p>
            <RadioGroup
              defaultValue={_.get(bookingState, 'config.in_context.travel_time_limit')}
              onValueChange={(value: string) => handleInContextOption('travel_time_limit', parseInt(value, 10))}
            >
              {bookingUtils.bookingInContextResearchTravelTimeTypes().map(option => (
                <div className="flex items-center space-x-2" key={option.value}>
                  <RadioGroupItem
                    value={String(option.value)}
                    id={`inContextResearchTravelTimeLimitTypesGroup_${option.value}`}
                  />
                  <Label htmlFor={`inContextResearchTravelTimeLimitTypesGroup_${option.value}`}>{option.label}</Label>
                </div>
              ))}
            </RadioGroup>

            <p className="inContextTitle">Where will you be travelling from?</p>
            <p className="inContextLabel">Street address</p>
            <LocationAutocomplete
              placeholder="123 Main Street"
              initialSearchValue={_.get(bookingState, 'config.location.name')}
              onNewPlace={onLocationSelected}
              countryCodes={[_.get(bookingState, 'config.location.country')]}
              styleWidth={500}
              canChangeCountry
            />
          </div>
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(SessionLocation);

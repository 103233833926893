import { cn } from '@askable/ui/lib/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import simpleheat from 'simpleheat';

import type { ScreenImage, BookingTaskEventDetailsClick } from 'generated/graphql';

export type HeatmapView = 'heatmap' | 'clickmap';

type HeatData = [number, number, number];

interface HeatmapProps {
  activeClicks: number[];
  clicks?: BookingTaskEventDetailsClick[];
  image: ScreenImage;
  isDownloading?: boolean;
  view: HeatmapView;
}

const CONFIG_HEATMAP = {
  radius: 25,
  blur: 15,
  minOpacity: 0.6,
  gradient: {
    0.4: 'blue',
    0.6: 'cyan',
    0.7: 'lime',
    0.8: 'yellow',
    1.0: 'red',
  },
} as const;

const clickMapRadius = 8;

export const Heatmap = ({ activeClicks, clicks = [], image, isDownloading, view }: HeatmapProps) => {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: image.width,
    height: image.height,
  });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [scale, setScale] = useState<number>(1);

  const filteredClicks = useMemo(() => {
    if (clicks.length === 0) {
      return [];
    }

    if (activeClicks.length === 0) {
      return clicks;
    }

    return clicks.filter((_, index) => activeClicks.includes(index));
  }, [clicks, activeClicks]);

  const downloadHeatmap = useCallback(() => {
    if (!canvasRef.current || !imageRef.current) {
      return;
    }

    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = dimensions.width;
    tempCanvas.height = dimensions.height;
    const ctx = tempCanvas.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.drawImage(imageRef.current, 0, 0, dimensions.width, dimensions.height);
    ctx.drawImage(canvasRef.current, 0, 0, dimensions.width, dimensions.height);

    const link = document.createElement('a');
    link.download = `${view}_${image.name.replace(/ /g, '-').toLowerCase()}.png`;
    link.href = tempCanvas.toDataURL('image/png');
    link.click();
  }, [canvasRef, imageRef, dimensions, view, image.name]);

  useEffect(() => {
    if (!containerRef.current || !isImageLoaded) return;

    const updateDimensions = () => {
      const container = containerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth;
      const containerHeight = containerWidth * (image.height / (image.width || 1));

      setDimensions({ width: containerWidth, height: containerHeight });
      setScale(containerWidth / image.width);
    };

    updateDimensions();

    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(updateDimensions);
    if (container) resizeObserver.observe(container);

    return () => {
      if (container) resizeObserver.unobserve(container);
    };
  }, [image, isImageLoaded]);

  // Draw the visualization based on view type
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !dimensions.width || !dimensions.height) return;

    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (view === 'clickmap') {
      const seenPositions = new Set<string>();

      // Draw clickmap points
      filteredClicks.forEach(click => {
        const key = `${Math.round(click.position_x)},${Math.round(click.position_y)}`;
        let x = click.position_x * scale;
        const y = click.position_y * scale;

        // If this click is overlapping another click offset it by 3px to the right so it's visible
        if (seenPositions.has(key)) {
          x += 3;
        } else {
          seenPositions.add(key);
        }

        ctx.beginPath();
        ctx.arc(x, y, clickMapRadius, 0, Math.PI * 2);
        ctx.fillStyle = 'white';
        ctx.fill();

        ctx.beginPath();
        ctx.arc(x, y, clickMapRadius - 2, 0, Math.PI * 2);
        ctx.fillStyle = click.handled ? '#2b9b7a' : '#dd3c4c';
        ctx.fill();
      });
    } else {
      const heatMap = simpleheat(canvas);
      heatMap.radius(CONFIG_HEATMAP.radius, CONFIG_HEATMAP.blur);
      heatMap.gradient(CONFIG_HEATMAP.gradient);
      heatMap.max(10);

      const scaledData: HeatData[] = filteredClicks.map(click => [
        click.position_x * scale,
        click.position_y * scale,
        1,
      ]);

      heatMap.data(scaledData);
      heatMap.draw(CONFIG_HEATMAP.minOpacity);
    }
  }, [filteredClicks, dimensions, scale, view]);

  // Reset image loaded state when image changes
  useEffect(() => {
    setIsImageLoaded(false);
  }, [image.src]);

  // Trigger download when the download prop changes
  useEffect(() => {
    if (isDownloading && imageRef.current) {
      downloadHeatmap();
    }
  }, [isDownloading, downloadHeatmap]);

  return (
    <div
      ref={containerRef}
      className={cn('relative m-auto min-h-40 w-full min-w-80 flex-1 opacity-0 transition-opacity', {
        'opacity-100': isImageLoaded,
      })}
      style={{
        height: dimensions.height,
        maxHeight: image.height,
        maxWidth: image.width,
      }}
    >
      <img
        ref={imageRef}
        src={image.src}
        alt={image.name}
        className="absolute top-0 h-full w-full rounded-sm object-contain"
        crossOrigin="anonymous"
        onLoad={() => setIsImageLoaded(true)}
      />

      {view === 'clickmap' ? <div className="absolute top-0 h-full w-full bg-darkest/40"></div> : null}

      <canvas
        ref={canvasRef}
        title={`${image.name} - ${filteredClicks.length} ${t('sections.studies.results.clicks', { count: filteredClicks.length })}`}
        className="absolute top-0 h-full w-full object-contain"
      />
    </div>
  );
};

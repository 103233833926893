import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { format, formatISO, formatDistanceToNowStrict, differenceInSeconds } from 'date-fns';
import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

const formatDate = (date: number, justNowLabel?: string) => {
  const now = new Date();
  const secondsDifference = differenceInSeconds(now, date);

  if (secondsDifference < 60) {
    return justNowLabel ?? 'Just now';
  }

  return formatDistanceToNowStrict(date, { addSuffix: true });
};

interface DateTimeProps {
  children: ReactNode;
  date: number;
}

export const DateTime = ({ date, children }: DateTimeProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <time dateTime={formatISO(date)}>{children}</time>
      </TooltipTrigger>
      <TooltipContent>
        {formatDate(date, t('sections.studies.justNow'))}
        <br />
        {format(date, 'h:mmaaa, d MMM yyyy')}
      </TooltipContent>
    </Tooltip>
  );
};

import { toast } from '@askable/ui/core/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'components/common';
import { utils } from 'lib/utils';

// Mutations

import { CreditRequestChangeStatus } from './data/CreditRequestChangeStatus.mutation';

function CreditRequestAction(props: any) {
  const [{ fetching: loading }, creditRequestChangeStatus] = useMutation(CreditRequestChangeStatus);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const executeAction = async () => {
      const extraArguments = utils.parseQueryParams(searchParams);
      if (!extraArguments?.get('credit_request_id') || !extraArguments?.get('status')) {
        props.history.replace({ pathname: '/' });
        return;
      }

      try {
        const creditRequestId: any = extraArguments.get('credit_request_id');
        const status: string | any = extraArguments.get('status');
        const { error } = await creditRequestChangeStatus({
          _id: creditRequestId,
          status: parseInt(status, 10),
        });
        if (error) {
          throw error;
        }
        toast.success(`Request successfully ${parseInt(status, 10) === 1 ? 'approved' : 'refused'}`);
        props.history.replace({ pathname: '/' });
      } catch (e: any) {
        if (e?.graphQLErrors[0]?.message) {
          toast.error(e.graphQLErrors[0].message);
        }
        props.history.replace({ pathname: '/' });
      }
    };
    executeAction();
  }, []);

  if (loading) return <LoadingOverlay />;

  return null;
}

export default deprecatedWithRouter(CreditRequestAction);

import { Avatar, AvatarImage, AvatarFallback } from '@askable/ui/core/avatar';
import { TableCell, TableRow } from '@askable/ui/core/table';
import { secondsToTime, cn } from '@askable/ui/lib/utils';
import { format, formatISO } from 'date-fns';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { Stat } from 'containers/Studies/Results/components/Stat';
import { BookingTaskResultStatus } from 'generated/graphql';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';

interface FigmaPrototypeResponseTableRowProps {
  result: BookingTaskResultPartial;
}

export const FigmaPrototypeResponseTableRow = forwardRef<HTMLTableRowElement, FigmaPrototypeResponseTableRowProps>(
  ({ result }, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const name = result.user?.displayName ?? result.user?._id;
    const initials =
      result.user?.displayName
        ?.split(' ')
        .map(n => n[0])
        .join('') ?? 'U';

    const duration =
      result.task_started && result.task_ended ? Math.max(result.task_ended - result.task_started, 0) / 1000 : null;
    const isClickable = ['completed', 'skipped'].includes(result.status);
    const clicks = 'path' in result.details ? result.details?.path?.reduce((all, item) => all + item.clicks, 0) : 0;
    const misclicks =
      'path' in result.details ? result.details?.path?.reduce((all, item) => all + item.misclicks, 0) : 0;
    const pathsLength = 'path' in result.details ? result.details?.path?.length : 0;

    return (
      <TableRow
        ref={ref}
        onClick={
          isClickable
            ? () => {
                navigate({ search: `type=individual&result_id=${result._id}` });
              }
            : undefined
        }
        key={result._id}
        className={cn('h-auto', { 'cursor-pointer hover:bg-secondary': isClickable })}
        id={`result-${result._id}`}
      >
        <TableCell>
          <div className="flex min-w-40 max-w-48 flex-row items-center gap-2" title={name}>
            <Avatar size="sm">
              <AvatarImage src={result.user?.picture || undefined} alt={result.user?.displayName || undefined} />
              <AvatarFallback variant="primary">{initials}</AvatarFallback>
            </Avatar>
            <span className="truncate">{name}</span>
          </div>
        </TableCell>
        <TableCell>
          {match(result.status)
            .with(BookingTaskResultStatus.Completed, () => (
              <Stat type="reachedGoal" value={t('sections.studies.results.reachedGoal')} />
            ))
            .with(BookingTaskResultStatus.Skipped, () => (
              <Stat type="reachedGoal" value={t('sections.studies.results.reachedGoalFailed')} />
            ))
            .otherwise(() => null)}
        </TableCell>
        <TableCell>
          {result.task_ended && duration ? secondsToTime(duration, 'labels', 'decisecond') : <>-</>}
        </TableCell>
        <TableCell>{clicks > 0 ? `${Math.floor((misclicks / clicks) * 100)}%` : 'n/a'}</TableCell>
        <TableCell>{pathsLength}</TableCell>
        <TableCell>
          {result.task_ended ? (
            <time dateTime={formatISO(result.task_ended)} className="whitespace-nowrap">
              {format(result.task_ended, 'd MMM yyyy, h:mmaaa')}
            </time>
          ) : (
            <>-</>
          )}
        </TableCell>
      </TableRow>
    );
  },
);

FigmaPrototypeResponseTableRow.displayName = 'FigmaPrototypeResponseTableRow';

import { useState, useRef } from 'react';
import { useMutation } from 'urql';

import { chunkImageIds } from 'containers/Studies/utils/figma-files';

import { LoadFigmaImages } from '../data/LoadFigmaImages.mutation';

// this needs to be cancellable so we avoid rate limits
export const useLoadImages = () => {
  const [loading, setLoading] = useState(false);
  const [{ fetching }, loadFigmaImages] = useMutation(LoadFigmaImages);
  const abortControllerRef = useRef<AbortController | null>(null);

  const cancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setLoading(false);
    }
  };

  const loadImages = async ({ fileId, nodeIds, size = 50 }: { fileId: string; nodeIds: string[]; size?: number }) => {
    // Cancel any existing operation
    cancel();

    const chunkedNodeIds = chunkImageIds(nodeIds, size);
    abortControllerRef.current = new AbortController();

    setLoading(true);
    try {
      for (const chunk of chunkedNodeIds) {
        // Check if we've been cancelled
        if (!abortControllerRef.current?.signal || abortControllerRef.current?.signal.aborted) {
          break;
        }

        const { error } = await loadFigmaImages(
          {
            fileId,
            nodeIds: chunk,
          },
          { context: { fetchOptions: { signal: abortControllerRef.current.signal } } },
        );

        if (error) {
          break;
        }
      }
    } finally {
      if (abortControllerRef.current?.signal.aborted) {
        abortControllerRef.current = null;
      }
      setLoading(false);
    }
  };

  return { loading: fetching || loading, loadImages, cancel };
};

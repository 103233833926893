import { cn, isMobileByWidth } from '@askable/ui/lib/utils';
import { Link, useSearchParams } from 'react-router-dom';

import { Stat } from 'containers/Studies/Results/components/Stat';

import type { FigmaPrototypeResultType } from 'containers/Studies/Results/containers/FigmaPrototypeResultDialog';
import type { ScreenImage } from 'generated/graphql';

/**
 * Display a single screen image with optional details
 * Aspect ratios:
 * - Desktop: 64:45 eg. 128x90px
 * - Mobile (when isMobile: true): 9:16 eg. 180x320px
 */

export interface ScreenItemProps {
  id: string;
  image?: ScreenImage | null;
  index?: number;
  isActive?: boolean;
  isLinked?: boolean;
  pathId?: string;
  resultType?: FigmaPrototypeResultType;
  showDetails?: boolean;
  stats?: {
    average_duration: number;
    total_responses: number;
  };
}

const EMPTY_IMAGE = { width: 480, height: 640, src: '', name: 'default' };

export const ScreenItem = ({
  id,
  image = EMPTY_IMAGE,
  index = 0,
  isActive,
  isLinked = true,
  pathId,
  resultType,
  showDetails,
  stats,
}: ScreenItemProps) => {
  const [searchParams] = useSearchParams();
  const isMobile = image ? isMobileByWidth(image.width) : false;
  const rowSpan = showDetails ? 2 : 1;

  const getUpdatedSearchParams = () => {
    const newParams = new URLSearchParams(searchParams);

    if (resultType) {
      newParams.set('type', resultType);
    }

    if (pathId) {
      newParams.set('path_id', pathId);
      newParams.set('path_index', index.toString());
    }

    newParams.set('node_id', id);

    return newParams.toString();
  };

  const content = (
    <>
      <div
        className={cn('checkered min-h-8 items-start overflow-hidden rounded-xl border-0.5 border-border', {
          'border-primary ring-1 ring-primary hover:border-primary focus:border-primary active:border-primary':
            isActive,
          'aspect-[64/45]': !image,
        })}
      >
        {image && image.src ? (
          <img
            alt={image.name}
            className={cn('image-smooth w-full object-cover object-top', {
              'aspect-[64/45]': !isMobile,
              'aspect-[9/16]': isMobile,
            })}
            height={image.height ?? 200}
            src={image.src}
            width={image.width}
            loading="lazy"
          />
        ) : null}
      </div>

      {showDetails ? (
        <div className="flex flex-col gap-1">
          {image ? <h3 className="text-sm font-medium leading-tight">{image.name ?? ''}</h3> : null}
          <div className="flex gap-3 text-sm text-muted-foreground">
            {stats?.total_responses ? <Stat type="responses" value={stats.total_responses} /> : null}
            {stats?.average_duration ? <Stat type="averageDuration" value={stats.average_duration} /> : null}
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <li
      id={`screen-${id}`}
      className={cn(
        'grid translate-y-0 snap-center grid-rows-[subgrid] items-center gap-2 opacity-100',
        'transition-all starting:translate-y-2 starting:opacity-0',
        `row-span-${rowSpan}`,
      )}
      style={{ transitionDelay: `${30 * index}ms` }}
    >
      {isLinked ? (
        <Link to={{ search: getUpdatedSearchParams() }} className="flex flex-col gap-2">
          {content}
        </Link>
      ) : (
        content
      )}
    </li>
  );
};

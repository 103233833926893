import { Button } from '@askable/ui/core/button';
import { Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  bookingParticipantSelectors,
  useBookingParticipantState,
} from 'containers/Booking/containers/BookingParticipantsContainer/state/booking-participant-state';

export const InfoButton = () => {
  const { t } = useTranslation();
  const setSidePanel = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
  const panelState = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);

  const handleClick = () => {
    if (panelState?.panel === 'booking-details') {
      setSidePanel(null);
      return;
    }

    setSidePanel({
      panel: 'booking-details',
      type: 'single',
    });
  };

  return (
    <Button variant="ghost" size="icon" onClick={handleClick}>
      <Info className="h-4 w-4" />
      <span className="sr-only">{t('sections.studies.recruit.sections.settings')}</span>
    </Button>
  );
};

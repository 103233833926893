import { Alert, AlertDescription, AlertTitle } from '@askable/ui/core/alert';
import { Button } from '@askable/ui/core/button';
import { Trans, useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

export const EnableEnterpriseSSO = () => {
  const { t } = useTranslation();

  const intercom = useIntercom();

  return (
    <Alert variant="default" className="my-3 max-w-sm">
      <AlertTitle>{t('sections.settings.team.enterpriseSso.enableSsoTitle')}</AlertTitle>
      <AlertDescription className="flex items-center gap-1">
        <Trans i18nKey="sections.settings.team.enterpriseSso.enableSsoDescription">
          <Button variant="link" className="h-7 p-0" onClick={() => intercom.showNewMessages()}>
            {t('sections.settings.team.enterpriseSso.chatWithUs')}
          </Button>{' '}
          {t('sections.settings.team.enterpriseSso.andWellHelpYou')}
        </Trans>
      </AlertDescription>
    </Alert>
  );
};

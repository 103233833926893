import { cn } from '../lib/utils';

import type { SVGAttributes } from 'react';

export const AskableIcon = ({ className, ...props }: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" strokeWidth={1.5} className={cn('h-16 w-16 text-muted', className)} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0128 0.750053C19.3645 0.762337 21.4795 2.87665 21.4674 10.203C21.4624 13.1559 21.1135 15.2597 20.2191 16.7197C18.2721 20.8222 13.9424 22.7821 11.9753 23.2501L11.9813 19.6243C4.62899 19.612 2.51467 17.498 2.52691 10.1713C2.53912 2.845 4.66052 0.737773 12.0128 0.750053ZM11.9747 8.58271C10.9272 8.58227 10.0786 7.73621 10.079 6.69277C10.0795 5.64964 10.9288 4.80397 11.9762 4.8044C13.0234 4.80482 13.8724 5.6512 13.8719 6.69433C13.8715 7.73776 13.0219 8.58313 11.9747 8.58271ZM11.9742 9.88148C13.5451 9.88213 16.083 10.2075 16.0824 11.7723C16.0818 13.3374 14.3858 15.2351 11.972 15.2341C9.55778 15.233 7.86376 13.3342 7.8644 11.7689C7.86505 10.2041 10.403 9.88085 11.9742 9.88148Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const AskableIconLoading = () => {
  return (
    <AskableIcon
      className="h-16 w-16 animate-[shimmer_1.5s_infinite] bg-repeat"
      style={{
        WebkitMask: 'linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%',
      }}
    />
  );
};

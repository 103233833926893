import { createLucideIcon } from 'lucide-react';

export const CircleCheckSolid = createLucideIcon('CircleCheckSolid', [
  [
    'path',
    {
      d: 'M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm3 8a1 1 0 0 1 .7 1.7l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.4l1.3 1.3 3.3-3.3c.2-.2.4-.3.7-.3Z',
      fill: 'currentColor',
      stroke: 'none',
    },
  ],
]);

import { FormControl, FormField, FormItem } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { RadioGroup } from '@askable/ui/core/radio-group';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/core/select';
import { Slider } from '@askable/ui/core/slider';
import { cn } from '@askable/ui/lib/utils';
import { Smile, Hash, Star } from '@askable/ui/unmod/icons';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { ScaleType } from '@askable/ui/unmod/opinion-scale';
import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';
import type { ComponentType } from 'react';

const SCALE_MAX = 10;
const SCALE_MIN = 5;

const SCALE_TYPE_OPTIONS: { value: ScaleType; icon: ComponentType<{ size: number }> }[] = [
  { value: 'numerical', icon: Hash },
  { value: 'stars', icon: Star },
  { value: 'emotions', icon: Smile },
] as const;

export const OpinionScaleField = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<BlockFormFields>();
  const scaleType = useWatch({ control, name: 'opinion_scale.scale_type' });
  const isZeroStart = useWatch({ control, name: 'opinion_scale.is_zero_start' });

  // Reset is_zero_start to false when scale type changes away from numerical
  useEffect(() => {
    if (scaleType !== 'numerical' && isZeroStart) {
      setValue('opinion_scale.is_zero_start', false);
    }
  }, [scaleType, isZeroStart, setValue]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Label htmlFor="input_label_low">
          {t('sections.studies.build.formFields.opinionScale.labelsTitle')} (
          {t('formFields.optional').toLocaleLowerCase()})
        </Label>

        <div className="grid grid-cols-3 gap-2">
          <FormField
            control={control}
            name="opinion_scale.label_low"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    id="input_label_low"
                    placeholder={t('sections.studies.build.formFields.opinionScale.labelLowPlaceholder')}
                    {...field}
                    disabled={isDisabled}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="opinion_scale.label_mid"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    id="input_label_mid"
                    placeholder={t('sections.studies.build.formFields.opinionScale.labelMidPlaceholder')}
                    {...field}
                    disabled={isDisabled}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="opinion_scale.label_high"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    id="input_label_high"
                    placeholder={t('sections.studies.build.formFields.opinionScale.labelHighPlaceholder')}
                    {...field}
                    disabled={isDisabled}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>

      <FormField
        control={control}
        name="opinion_scale.scale_type"
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-col gap-2">
              <Label htmlFor="input_scale_type">
                {t('sections.studies.build.formFields.opinionScale.scaleTypeTitle')}
              </Label>
              <FormControl>
                <RadioGroup
                  {...field}
                  className="grid grid-cols-3 gap-2"
                  disabled={isDisabled}
                  id="input_scale_type"
                  onValueChange={field.onChange}
                >
                  {SCALE_TYPE_OPTIONS.map(option => {
                    const Icon = option.icon;
                    return (
                      <Label
                        key={option.value}
                        htmlFor={`input_scale_type_${option.value}`}
                        className={cn(
                          `flex w-full flex-1 cursor-pointer flex-col items-center justify-between gap-1 whitespace-nowrap rounded-lg border
                          border-border p-4 hover:border-input focus:border-input active:border-input`,
                          {
                            'border-primary ring-0.5 ring-primary hover:border-primary focus:border-primary active:border-primary':
                              field.value === option.value,
                            'cursor-not-allowed opacity-50 hover:border-border focus:border-border active:border-border':
                              isDisabled,
                          },
                        )}
                      >
                        <input
                          type="radio"
                          id={`input_scale_type_${option.value}`}
                          name={'input_scale_type'}
                          disabled={isDisabled}
                          className="sr-only"
                          value={option.value}
                        />
                        <Icon size={32} />
                        {t(`sections.studies.build.formFields.opinionScale.${option.value}`)}
                      </Label>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          </FormItem>
        )}
      />

      {scaleType === 'numerical' ? (
        <FormField
          control={control}
          name="opinion_scale.is_zero_start"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col gap-2">
                <Label htmlFor="input_is_zero_start">
                  {t('sections.studies.build.formFields.opinionScale.isStartZeroTitle')}
                </Label>
                <FormControl>
                  <Select
                    value={field.value ? 'true' : 'false'}
                    onValueChange={value => field.onChange(value === 'true')}
                    disabled={isDisabled}
                  >
                    <SelectTrigger id="input_is_zero_start" className="w-full">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="z-20">
                      <SelectItem value="true">0</SelectItem>
                      <SelectItem value="false">1</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
              </div>
            </FormItem>
          )}
        />
      ) : null}

      <FormField
        control={control}
        name="opinion_scale.scale_max"
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-col gap-3">
              <Label htmlFor="input_scale_max">{t('sections.studies.build.formFields.opinionScale.scaleTitle')}</Label>
              <FormControl>
                <Slider
                  id="input_scale_max"
                  value={[field.value ?? SCALE_MIN]}
                  max={SCALE_MAX}
                  min={SCALE_MIN}
                  onValueChange={value => field.onChange(value[0])}
                  disabled={isDisabled}
                />
              </FormControl>

              <ol className="flex justify-between text-sm">
                {Array.from({ length: SCALE_MAX - SCALE_MIN + 1 }).map((_, i) => {
                  const value = i + SCALE_MIN;
                  const isSelected = field.value === value;

                  return (
                    <li key={i}>
                      <button
                        type="button"
                        className={cn('cursor-pointer pl-[5px]', {
                          'font-semibold text-foreground': isSelected,
                          'cursor-not-allowed opacity-50': isDisabled,
                        })}
                        disabled={isDisabled}
                        onClick={() => field.onChange(value)}
                      >
                        {value}
                      </button>
                    </li>
                  );
                })}
              </ol>
            </div>
          </FormItem>
        )}
      />
    </>
  );
};

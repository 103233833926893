import { graphql } from 'src/graphql';

export const CreateUnmoderatedBooking = graphql(`
  mutation createUnmoderatedBooking($input: CreateUnmoderatedBookingInput!) {
    createUnmoderatedBooking(input: $input) {
      _id
      name
      type
      status
      config {
        unmoderated {
          welcome_block {
            type
            title
            instructions
          }
          thank_you_block {
            type
            title
            instructions
          }
          task_blocks {
            _id
            type
            title
            instructions
            is_recording_enabled

            ... on TaskBlockFigmaPrototype {
              figma_prototype {
                file_id
                goal_screen_id
                start_screen_id
              }
            }

            ... on TaskBlockMultipleChoiceQuestion {
              multiple_choice_question {
                options {
                  _id
                  value
                }
                has_other_option
                is_multiple_select
                is_randomised_order
              }
            }

            ... on TaskBlockOpinionScale {
              opinion_scale {
                is_zero_start
                label_low
                label_mid
                label_high
                scale_max
                scale_type
              }
            }
          }
        }
      }
    }
  }
`);

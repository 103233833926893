import { Skeleton } from '@askable/ui/core/skeleton';
import { GitCompare } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { ScreenItem } from 'containers/Studies/Results/components/ScreenItem';
import { Section } from 'containers/Studies/Results/components/Section';
import { FigmaPrototypeResultScreens } from 'containers/Studies/Results/data/FigmaPrototypeResultScreens.query';

interface ScreenAnalysisProps {
  taskId: string;
}

/**
 * An aggregate of all the screens viewed in a prototype task
 */
export const ScreenAnalysis = ({ taskId }: ScreenAnalysisProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { studyId } = params;

  const [{ data, fetching, error }] = useQuery({
    query: FigmaPrototypeResultScreens,
    variables: {
      _booking_id: studyId ?? '',
      _task_id: taskId ?? '',
      has_results: false,
    },
    pause: !studyId || !taskId,
  });

  return (
    <Section
      description={t('sections.studies.results.screenAnalysisDescription')}
      id="screen-analysis"
      title={t('sections.studies.results.screenAnalysisTitle')}
    >
      {!fetching && data?.figmaPrototypeResultScreens ? (
        <>
          {data?.figmaPrototypeResultScreens.length > 0 ? (
            <ol className="grid grid-cols-[repeat(auto-fit,12rem)] gap-3">
              {data?.figmaPrototypeResultScreens.map((screen, index) => (
                <ScreenItem
                  key={screen.screen.id}
                  {...screen.screen}
                  resultType="screen"
                  index={index}
                  showDetails
                  stats={{
                    average_duration: screen.stats?.average_duration ?? 0,
                    total_responses: screen.stats?.total_responses ?? 0,
                  }}
                />
              ))}
            </ol>
          ) : (
            <div className="flex h-full min-h-44 flex-col items-center justify-center gap-2 text-sm text-muted-foreground">
              <GitCompare className="h-6 w-6" />
              {t('sections.studies.results.noScreens')}
            </div>
          )}
        </>
      ) : (
        <Skeleton className="h-[20rem]" />
      )}

      {!fetching && error && <div className="text-destructive">{error.message}</div>}
    </Section>
  );
};

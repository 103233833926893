import { graphql } from 'src/graphql';

export const MultipleChoiceQuestionResult = graphql(`
  query MultipleChoiceQuestionResult($_booking_id: ID!, $_task_id: ID!) {
    bookingTaskResultMultipleChoiceQuestion(_booking_id: $_booking_id, _task_id: $_task_id) {
      answer_counts {
        _id
        count
        is_current
        value
      }
      other_answers {
        _user_id
        answer
        user {
          displayName
        }
      }
      total_responses
    }
  }
`);

/* Notes: 
- Component renders the iframe with the Figma prototype url
- Tracking of events (setting goal screens in builder, saving participant results, etc) will be
  handled by the parent component, depending on the use case
*/

import { useEffect, useMemo, useState } from 'react';

import { figmaMessageHandler } from './figma';

import type { HTMLProps } from 'react';

type ClickEventType = {
  nodeId: string;
  targetNodeId: string;
  position: { x: number; y: number };
  handled: boolean;
};

type ScreenChangeEventType = {
  nodeId: string;
};

export type FigmaEmbedParams = {
  mode?: 'dev';
  footer?: 'false' | 'true';
  'viewport-controls'?: 'false' | 'true';
  'disable-default-keyboard-nav'?: 'false' | 'true';
  'show-proto-sidebar'?: 'false' | 'true';
  'hotspot-hints'?: 'false' | 'true';
  'device-frame'?: 'false' | 'true';
  scaling?: 'scale-down' | 'contain' | 'min-zoom' | 'scale-down-width' | 'fit-width' | 'free';
  'content-scaling'?: 'fixed' | 'responsive';
  'hide-ui'?: 'false' | 'true';
  'bg-color'?: string;
};

const DEFAULT_EMBED_PARAMS: FigmaEmbedParams = {
  footer: 'false',
  'viewport-controls': 'false',
  'disable-default-keyboard-nav': 'false',
  'show-proto-sidebar': 'false',
  'hotspot-hints': 'false',
  'hide-ui': 'true',
};

type Props = HTMLProps<HTMLIFrameElement> & {
  clientId: string;
  /* Changing the file id will cause the iframe to reload */
  fileId: string;
  startingPointNodeId?: string;
  /* Changing the node id will cause the iframe to reload */
  loadingPlaceholder?: React.ReactNode;
  embedParams?: FigmaEmbedParams;
  /* Used for sending messages to the iframe (eg. navigating to a different frame without a reload) */
  iframeRef?: React.RefObject<HTMLIFrameElement>;
  onScreenChange?: (event: ScreenChangeEventType) => void;
  onClick?: (event: ClickEventType) => void;
  onLoad?: () => void;
};

export const FigmaPrototypeFrame = ({
  fileId,
  startingPointNodeId,
  loadingPlaceholder,
  embedParams,
  iframeRef,
  onScreenChange,
  onClick,
  onLoad,
  clientId,
  ...props
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      figmaMessageHandler(event, {
        mousePressOrRelease:
          onClick &&
          (message => {
            onClick({
              nodeId: message.data.presentedNodeId,
              targetNodeId: message.data.targetNodeId,
              position: message.data.nearestScrollingFrameMousePosition,
              handled: !!message.data.handled,
            });
          }),
        presentedNodeChanged:
          onScreenChange &&
          (message => {
            onScreenChange({
              nodeId: message.data.presentedNodeId,
            });
          }),
        initialLoad: () => {
          new Promise(resolve => {
            setTimeout(resolve, 2500);
          }).then(() => {
            setIsLoaded(true);
            onLoad?.();
          });
        },
      });
    };

    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [onClick, onLoad, onScreenChange]);

  const embedUrlParams = useMemo(() => {
    const params: { [k: string]: string } = {
      'client-id': clientId,
      'embed-host': 'askable',
      ...DEFAULT_EMBED_PARAMS,
      ...embedParams,
    };

    if (startingPointNodeId) {
      params['starting-point-node-id'] = startingPointNodeId;
      params['node-id'] = startingPointNodeId;
    }

    if (!params['bg-color']) {
      params['bg-color'] = document.documentElement.classList.contains('dark') ? '2e3138' : 'F5F5F5';
    }

    return new URLSearchParams(params).toString();
  }, [clientId, embedParams, startingPointNodeId]);

  const embedUrl = useMemo(() => {
    if (!fileId) {
      return null;
    }

    return `https://embed.figma.com/proto/${fileId}?${embedUrlParams}`;
  }, [fileId, embedUrlParams]);

  useEffect(() => {
    setIsLoaded(false);
  }, [embedUrl]);

  if (!embedUrl) {
    return null;
  }

  return (
    <>
      <iframe
        {...props}
        src={embedUrl.toString()}
        className="h-full w-full flex-1 border-0"
        title="Interactive prototype"
        ref={iframeRef}
      />
      {!isLoaded && loadingPlaceholder}
    </>
  );
};

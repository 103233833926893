import { Camera, Mic, ScreenShare } from 'lucide-react';

interface PermissionsProps {
  key: 'camera' | 'microphone' | 'screen';
  description: string;
}

interface PermissionsListProps {
  description?: string;
  permissions: PermissionsProps[];
}

const iconByKey = {
  camera: Camera,
  microphone: Mic,
  screen: ScreenShare,
};

export const PermissionsList = ({ permissions, description }: PermissionsListProps) => {
  return (
    <div className="flex flex-col gap-2 text-sm">
      {description && <p>{description}</p>}
      {permissions.map(({ key, description: permissionDescription }) => {
        const Icon = iconByKey[key];
        return (
          <div key={key} className="flex items-center gap-2">
            <Icon className="h-4 w-4" /> {permissionDescription}
          </div>
        );
      })}
    </div>
  );
};

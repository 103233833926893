import { Button } from '@askable/ui/core/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { toast } from '@askable/ui/core/sonner';
import { Switch } from '@askable/ui/core/switch';
import { Textarea } from '@askable/ui/core/textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { z } from 'zod';

import { UpdateSSOConnection } from '../data/UpdateSSOConnection.mutation';

const customSamlForm = z.object({
  entityId: z.string(),
  idpMetadataUrl: z.string().url(),
  emailKey: z.string(),
  isEnabled: z.boolean().default(false),
  homeRealmDomains: z.string(),
});

interface Props {
  teamId: string;
  connectionId: string;
  handleSaveComplete: () => void;
  defaultValues?: z.infer<typeof customSamlForm>;
}

export const CustomSamlForm = ({ defaultValues, teamId, connectionId, handleSaveComplete }: Props) => {
  const { t } = useTranslation();

  const [{ fetching }, updateSSOConnection] = useMutation(UpdateSSOConnection);

  const form = useForm<z.infer<typeof customSamlForm>>({
    resolver: zodResolver(customSamlForm),
    defaultValues,
  });

  const onSubmit = async (values: z.infer<typeof customSamlForm>) => {
    const { error } = await updateSSOConnection({
      teamId,
      connectionId,
      isEnabled: values.isEnabled,
      options: {
        home_realm_domains: values.homeRealmDomains.split(',').map(a => a.trim()),
        saml_custom: {
          saml_email_key_attr: values.emailKey,
          saml_entity_id: values.entityId,
          saml_idp_metadata_url: values.idpMetadataUrl,
        },
      },
    });

    if (error) {
      toast.error(error.message);
      return;
    }

    handleSaveComplete();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="entityId"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.saml.entityIDTitle')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>{t('sections.settings.team.enterpriseSso.saml.entityIDDescription')}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="idpMetadataUrl"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.saml.idpMetadataUrlTitle')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>
                {t('sections.settings.team.enterpriseSso.saml.idpMetadataUrlDescription')}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="emailKey"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.saml.emailKeyTitle')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormDescription>{t('sections.settings.team.enterpriseSso.saml.emailKeyDescription')}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="homeRealmDomains"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <FormLabel>{t('sections.settings.team.enterpriseSso.domains.title')}</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormDescription>{t('sections.settings.team.enterpriseSso.domains.description')}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormItem className="space-y-2">
          <FormLabel>{t('sections.settings.team.enterpriseSso.saml.assertionUrlTitle')}</FormLabel>
          <FormControl>
            <Input readOnly disabled value={`${import.meta.env.VITE_KINDE_DOMAIN}/login/saml/callback`} />
          </FormControl>
          <FormDescription>{t('sections.settings.team.enterpriseSso.saml.assertionUrlDescription')}</FormDescription>
          <FormMessage />
        </FormItem>

        <FormField
          control={form.control}
          name="isEnabled"
          render={({ field: { value, ...fields } }) => {
            return (
              <FormItem className="space-y-2">
                <div className="flex items-center justify-between">
                  <FormLabel>{t('global.enabled')}</FormLabel>
                  <FormControl>
                    <Switch {...fields} checked={value} onCheckedChange={fields.onChange} />
                  </FormControl>
                </div>
                <FormDescription>{t('sections.settings.team.enterpriseSso.enabledDescription')}</FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <Button
          isLoading={fetching}
          className="ml-auto"
          disabled={!form.formState.isDirty}
          variant="primary"
          size="xl"
          type="submit"
        >
          {t('global.save')}
        </Button>
      </form>
    </Form>
  );
};

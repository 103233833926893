import { Card, CardContent, CardDescription, CardHeader } from '@askable/ui/core/card';
import { cn } from '@askable/ui/lib/utils';
import { Loader2 } from 'lucide-react';

type Props = {
  teamId: string;
  orgCode: string;
  teamName: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const TeamCard = ({ teamName, disabled, isLoading, onClick }: Props) => {
  return (
    <Card
      className={cn('flex h-[8.5rem] cursor-pointer flex-col justify-between font-medium hover:shadow-md', {
        'opacity-50': disabled,
      })}
      onClick={onClick}
    >
      <CardHeader>
        <div className="flex h-12 w-12 items-center justify-center rounded-sm bg-muted uppercase text-muted-foreground">
          {isLoading ? <Loader2 className="animate-spin" /> : teamName[0]}
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription className="line-clamp-2">{teamName}</CardDescription>
      </CardContent>
    </Card>
  );
};

import { ListFilter } from 'lucide-react';

import { cn } from '../lib/utils';

import { Badge } from './/badge';
import { Button } from './/button';
import { Checkbox } from './/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './/command';
import { Popover, PopoverContent, PopoverTrigger } from './/popover';
import { Separator } from './/separator';

import type { ComponentType } from 'react';

type Props = {
  popoverContentClassName?: string;
  facets?: {
    get: (value?: string | number) => number | undefined;
    size: number;
  };
  filterValue: (string | number)[];
  setFilterValue?: (args: (string | number)[] | undefined) => void;
  title?: string;
  options: {
    label: string;
    value: string | number;
    icon?: ComponentType<{ className?: string }>;
  }[];
};

export const FacedFilter = ({
  facets,
  setFilterValue,
  filterValue,
  title,
  options,
  popoverContentClassName,
}: Props) => {
  const selectedValues = new Set(filterValue);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" disabled={facets?.size === 0}>
          <ListFilter size={16} className="mr-2" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1">
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter(option => selectedValues.has(option.value))
                    .map(option => (
                      <Badge variant="secondary" key={option.value} className="rounded-sm">
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('w-[200px] p-0', popoverContentClassName)} align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map(option => {
                if (!facets?.get(option.value)) {
                  return null;
                }
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      setFilterValue?.(filterValues.length ? filterValues : undefined);
                    }}
                  >
                    <Checkbox checked={isSelected} className="mr-2" />
                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span className="text-foreground">{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex items-center justify-center text-xs text-muted-foreground">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem onSelect={() => setFilterValue?.(undefined)} className="justify-center text-center">
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingTaskResults = graphql(`
  query BookingTaskResults(
    $first: Int
    $filter: BookingTaskResultsFilterInput
    $after: String
    $before: String
    $orderBy: BookingTaskResultsOrderBy
  ) {
    bookingTaskResults(first: $first, filter: $filter, after: $after, before: $before, order_by: $orderBy) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        _id
        _user_id
        _task_id
        status
        task_ended
        task_started
        task_loaded
        user {
          _id
          displayName
          picture
        }
        details {
          type

          ... on BookingTaskResultDetailsFigmaPrototype {
            _path_id
            path {
              _id
              node_id
              started
              ended
              clicks
              misclicks
              image {
                height
                name
                src
                width
              }
            }
          }

          ... on BookingTaskResultDetailsMultipleChoiceQuestion {
            answers {
              _id
              value
            }
            other_answer
          }

          ... on BookingTaskResultDetailsOpinionScale {
            answer
          }
        }
      }
    }
  }
`);

export type BookingTaskResultPartial = NonNullable<
  NonNullable<ResultOf<typeof BookingTaskResults>['bookingTaskResults']['nodes']>[number]
>;

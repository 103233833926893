import { DropdownMenuItem } from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { canProjectBeArchived, canProjectBeDuplicated } from 'utils/projects-utils';

import { CellActions } from '../components/CellActions';
import { DialogArchive } from '../components/DialogArchive';
import { DialogDuplicate } from '../components/DialogDuplicate';

import { ArchiveProjectMutation } from './data/ArchiveProject.mutation';
import { DuplicateProjectMutation } from './data/DuplicateProject.mutation';

import type { Project } from 'generated/graphql';

type Props = {
  status: Project['status'];
  name: Project['name'];
  id: Project['_id'];
};

export function ProjectsCellActions({ status, name, id }: Props) {
  const { details } = useConnectedClient();
  const navigate = useNavigate();
  const [, duplicateProject] = useMutation(DuplicateProjectMutation);
  const [, archiveProject] = useMutation(ArchiveProjectMutation);

  const [isDuplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const onConfirm = async () => {
    const newProject = await duplicateProject({
      project_id: id,
    });

    if (newProject.error || !newProject.data?.duplicateProject?._id) {
      toast.error(
        `Duplication failed. ${newProject.error ? newProject.error?.message : 'Duplicated study ID not found'}`,
      );
      return;
    }

    navigate(`/askable-plus/${newProject?.data?.duplicateProject?._id}/project-setup/project-title`, {});
  };

  const onArchive = async () => {
    const archivedProject = await archiveProject({
      project_id: id,
    });

    if (archivedProject.error) {
      toast.error(`Archive failed. ${archivedProject.error?.message}`);
      return;
    }

    toast.success('Study archived');
    setArchiveDialogOpen(false);
  };

  const canDuplicate = canProjectBeDuplicated(details);
  const canArchive = canProjectBeArchived({ status });

  if (!canDuplicate && !canArchive) {
    return null;
  }
  return (
    <>
      <CellActions
        dropdownMenuContents={
          <>
            {canDuplicate && (
              <DropdownMenuItem onClick={() => setDuplicateDialogOpen(true)}>Duplicate</DropdownMenuItem>
            )}
            {canArchive && <DropdownMenuItem onClick={() => setArchiveDialogOpen(true)}>Archive</DropdownMenuItem>}
          </>
        }
      />
      <DialogDuplicate
        open={isDuplicateDialogOpen}
        onDuplicate={onConfirm}
        onCancel={() => setDuplicateDialogOpen(false)}
        onOpenChange={setDuplicateDialogOpen}
        resourceName={name || ''}
        resourceTypeName="project"
      />
      <DialogArchive
        open={isArchiveDialogOpen}
        onArchive={onArchive}
        onCancel={() => setArchiveDialogOpen(false)}
        onOpenChange={setArchiveDialogOpen}
        resourceName={name || ''}
        resourceTypeName="project"
      />
    </>
  );
}

import { toast } from '@askable/ui/core/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect } from 'react';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'components/common';
import { utils } from 'lib/utils';

// Mutations

import { RequestToJoinTeamChangeStatusMutation } from './data/RequestToJoinTeamChangeStatus.mutation';

function RequestJoinAction(props: any) {
  const [{ fetching: loading }, requestToJoinTeamChangeStatus] = useMutation(RequestToJoinTeamChangeStatusMutation);

  useEffect(() => {
    const executeAction = async () => {
      const extraArguments = utils.parseQueryParams(_.get(props, 'location.search'));
      if (extraArguments && extraArguments.get('team_request_id') && extraArguments.get('request_status')) {
        try {
          const teamRequestId: string | any = extraArguments.get('team_request_id');
          const requestStatus: string | any = extraArguments.get('request_status');
          const { error } = await requestToJoinTeamChangeStatus({
            _id: teamRequestId,
            status: parseInt(requestStatus, 10),
          });
          if (error) {
            throw error;
          }
          toast.success(`Request successfully ${parseInt(requestStatus, 10) === 1 ? 'approved' : 'refused'}`);
          props.history.replace({ pathname: '/settings/team' });
        } catch (e: any) {
          if (_.get(e, 'graphQLErrors[0].message')) {
            toast.error(e.graphQLErrors[0].message);
          }
          props.history.replace({ pathname: '/' });
        }
      }
    };
    executeAction();
  }, []);

  if (loading) return <LoadingOverlay />;
  return null;
}

export default deprecatedWithRouter(RequestJoinAction);

/* eslint-disable max-lines */
import { Button } from '@askable/ui/core/button';
import { FormControl, FormField, FormItem, FormLabel } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { Skeleton } from '@askable/ui/core/skeleton';
import { toast } from '@askable/ui/core/sonner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { Figma, FigmaLogo } from '@askable/ui/unmod/icons';
import { ErrorMessage } from '@hookform/error-message';
import { format } from 'date-fns';
import { RefreshCcw, Trash2 } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FigmaFileDetails } from 'containers/Studies/BuildStudy/data/FigmaFileDetails.query';
import { SaveFigmaFile } from 'containers/Studies/BuildStudy/data/SaveFigmaFile.mutation';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InfoAlert } from 'containers/Studies/components/InfoAlert';
import { InfoPopover } from 'containers/Studies/components/InfoPopover';
import { parseFigmaEmbedUrl } from 'utils/string-utils';

import { useLoadImages } from '../../hooks/useLoadImages';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

interface TextareaFieldProps {
  isDisabled?: boolean;
}

class FigmaConnectionError extends Error {}
type SaveFileResponse = { fileId: string; nodeIds: string[]; startingPointNodeIds: string[] };

export const FigmaFileField = ({ isDisabled }: TextareaFieldProps) => {
  const { t } = useTranslation();
  const { figmaAuth } = useStudyContext();
  const { control, formState, setValue, watch, getValues } = useFormContext<BlockFormFields>();

  const [fileUrl, setFileUrl] = useState<string>('');
  const [isImporting, setIsImporting] = useState(false);

  const fileId = watch('figma_prototype.file_id');
  const [figmaFileQuery] = useQuery({
    query: FigmaFileDetails,
    variables: { fileId: fileId! },
    pause: !fileId,
  });
  const figmaFile = figmaFileQuery.data?.figmaFile ?? null;

  const [saveFigmaFile, saveFigmaFileMutation] = useMutation(SaveFigmaFile);
  const isFileLoading = !!figmaFileQuery.fetching || !!saveFigmaFile.fetching;
  const { loadImages, loading: loadingImages, cancel } = useLoadImages();

  const saveFile = useCallback(
    async ({
      fileId,
      startingPointNodeId,
    }: {
      fileId: string;
      startingPointNodeId?: string;
    }): Promise<SaveFileResponse> => {
      const { error, data } = await saveFigmaFileMutation({ fileId, loadImages: false });
      if (error?.graphQLErrors?.length) {
        if (error.graphQLErrors?.[0].extensions.code === 403) {
          throw new FigmaConnectionError(error?.graphQLErrors?.[0]?.message);
        }
        throw new Error(error?.graphQLErrors?.[0]?.message);
      }

      if (!data?.saveFigmaFile?.file_id) {
        throw new Error('No file ID');
      }

      // check the starting point node ID requested actually still exists in the frames
      const existingStartingPointNodeId = startingPointNodeId
        ? data.saveFigmaFile.frames?.find(frame => frame.node_id === startingPointNodeId)?.node_id
        : undefined;

      const startingPointNodeIds = (data.saveFigmaFile.canvases ?? [])
        .flatMap(canvas => canvas.flow_starting_point_node_ids ?? [])
        .filter<string>((nodeId): nodeId is string => !!nodeId);

      if (existingStartingPointNodeId && !startingPointNodeIds.includes(existingStartingPointNodeId)) {
        startingPointNodeIds.unshift(existingStartingPointNodeId);
      }

      if (startingPointNodeIds.length) {
        await loadImages({
          fileId: data.saveFigmaFile.file_id,
          nodeIds: startingPointNodeIds,
        });
      }

      setValue('figma_prototype.file_id', fileId);
      setValue(
        'figma_prototype.start_screen_id',
        // Check to see if the starting point node ID exists in the new frames
        existingStartingPointNodeId ?? '',
      );
      setValue('figma_prototype.goal_screen_id', '');
      setFileUrl('');

      return {
        fileId: data.saveFigmaFile.file_id,
        nodeIds: data.saveFigmaFile?.frames?.map(frame => frame.node_id) ?? [],
        startingPointNodeIds,
      };
    },
    [saveFigmaFileMutation, setValue, loadImages],
  );

  const handleImport = async () => {
    try {
      setIsImporting(true);

      // TODO: ideally `figmaAuth.authenticate` can deal with this, so we don't have to handle
      // it every time with use it
      const figmaConnected = await figmaAuth.checkStatus();
      if (figmaConnected) {
        try {
          const details = parseFigmaEmbedUrl(fileUrl);
          if (!details.isValid) {
            throw new Error(details.error);
          }

          const { fileId, startingPointNodeId } = details;

          const res = await saveFile({ fileId, startingPointNodeId });
          setIsImporting(false);
          return res;
        } catch (e) {
          /*
           ** Catching the case where a user's refresh token is invalid when they try
           ** to upload a figma file. This happened even when the viewerFigmaAuthStatus query
           ** previously indicated a valid refresh token. So it seems that the query is not 100% reliable
           ** TODO ASK-9261: Once we fix the viewerFigmaAuthStatus query reliability we can probably remove this logic
           */
          if (e instanceof FigmaConnectionError) {
            /*
             ** By now the API has deleted the user's figma integration
             ** so they'll need to re-authenticate when they click the import button again
             */
            await figmaAuth.checkStatus();
          } else {
            throw e;
          }
        }
      }

      return await new Promise<SaveFileResponse>((resolve, reject) => {
        figmaAuth.authenticate({
          onTimeout: () => {
            toast.error(t('sections.studies.formValidation.figmaPrototypeFileId.timeout'));
          },
          onSuccess: async () => {
            try {
              const details = parseFigmaEmbedUrl(fileUrl);
              if (!details.isValid) {
                return reject(details.error);
              }

              const { fileId, startingPointNodeId } = details;

              const res = await saveFile({ fileId, startingPointNodeId });
              resolve(res);
            } catch (err) {
              reject(err);
            }
          },
        });
      });
    } catch (e: unknown) {
      if (e instanceof FigmaConnectionError) {
        toast.error(t('sections.oauthFigma.saveFileErrorMessage'));
      } else {
        toast.error(e instanceof Error ? e?.message : 'Figma file import failed');
      }
    } finally {
      setIsImporting(false);
    }
  };

  return (
    <FormField
      control={control}
      name="figma_prototype.file_id"
      render={({ field }) => (
        <FormItem>
          <FormLabel hasErrorStyle={false} className="flex w-fit items-center gap-1" htmlFor="prototype-import">
            {t('sections.studies.build.formFields.figmaPrototypeFileId.label')}
            <InfoPopover
              title={t('sections.studies.build.formFields.figmaPrototypeFileId.infoTitle')}
              description={t('sections.studies.build.formFields.figmaPrototypeFileId.infoDescription')}
              moreLink="https://help.askable.com/en/articles/10568042"
            />
          </FormLabel>
          <FormControl>
            <>
              <Input type="hidden" name={field.name} />

              {field.value ? (
                <>
                  <div
                    className={cn(
                      'flex items-center gap-3 rounded-xl border-0.5 border-border bg-background-subtle p-3',
                      { 'opacity-50': !field.value },
                    )}
                  >
                    <div className="aspect-square rounded-sm bg-darkest p-2">
                      <FigmaLogo size={20} />
                    </div>
                    <div className="flex w-0 grow basis-0 flex-col justify-between self-stretch">
                      <div className="truncate text-sm font-medium leading-tight">
                        {isFileLoading || !figmaFile ? (
                          <Skeleton className="w-full rounded">&nbsp;</Skeleton>
                        ) : (
                          figmaFile.name
                        )}
                      </div>
                      <div className="text-xs leading-tight text-muted-foreground">
                        {isFileLoading || !figmaFile ? (
                          <Skeleton className="w-2/3 rounded">&nbsp;</Skeleton>
                        ) : figmaFile?.updated ? (
                          <>
                            {t('global.updated')} {format(figmaFile.updated, 'h:mmaaa, d MMM yyyy')}
                          </>
                        ) : null}
                      </div>
                    </div>
                    {!isDisabled ? (
                      <div className="flex gap-1">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              aria-label={t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRefresh')}
                              onClick={async () => {
                                const currentStartingPointNodeId = getValues('figma_prototype.start_screen_id');
                                const res = await saveFile({ fileId, startingPointNodeId: currentStartingPointNodeId });
                                loadImages({
                                  fileId: res.fileId,
                                  // starting point node ID's would already have been loaded
                                  nodeIds: res.nodeIds.filter(nodeIds => !res.startingPointNodeIds.includes(nodeIds)),
                                });
                              }}
                              data-testid="refresh-prototype"
                              size="icon"
                              variant="ghost"
                              disabled={isFileLoading || loadingImages || isDisabled}
                            >
                              <RefreshCcw className="h-4 w-4 text-muted-foreground" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRefresh')}
                          </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              aria-label={t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRemove')}
                              onClick={async () => {
                                cancel();
                                setValue(field.name, '');
                                setValue('figma_prototype', {
                                  file_id: '',
                                  start_screen_id: '',
                                  goal_screen_id: '',
                                });
                              }}
                              size="icon"
                              variant="ghost"
                              disabled={isDisabled}
                            >
                              <Trash2 className="h-4 w-4 text-muted-foreground" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('sections.studies.build.formFields.figmaPrototypeFileId.tooltipRemove')}
                          </TooltipContent>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>

                  {figmaFile?.link_is_public === false ? (
                    // TODO(ASK-8724): handle this case
                    <div className="text-sm text-destructive">
                      {t('sections.studies.formValidation.figmaPrototypeFileId.notPublic')}
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="flex gap-1">
                  <Input
                    id="prototype-import"
                    data-testid="prototype-url-input"
                    placeholder="www.figma.com/link"
                    value={fileUrl}
                    disabled={isDisabled || isImporting}
                    onChange={e => setFileUrl(e.target.value)}
                  />
                  <Button
                    variant="secondary"
                    className="h-auto min-h-0"
                    data-testid="submit-prototype"
                    isLoading={isImporting}
                    onClick={async () => {
                      const res = await handleImport();
                      if (res) {
                        loadImages({
                          fileId: res.fileId,
                          // starting point node ID's would already have been loaded
                          nodeIds: res.nodeIds.filter(nodeId => !res.startingPointNodeIds.includes(nodeId)),
                        });
                      }
                    }}
                    disabled={!fileUrl || isDisabled}
                  >
                    {t('sections.studies.import')}
                  </Button>
                </div>
              )}
            </>
          </FormControl>

          <ErrorMessage
            errors={formState.errors}
            name={field.name}
            render={({ message }) => <FormErrorMessage message={message} prefix="sections.studies.formValidation" />}
          />

          {!isDisabled && !field.value ? (
            <InfoAlert
              title={t('sections.studies.build.formFields.figmaPrototypeFileId.calloutTitle')}
              description={t('sections.studies.build.formFields.figmaPrototypeFileId.calloutDescription')}
              Icon={<Figma size={32} />}
              id="figma_info"
              moreLink="https://help.askable.com/en/articles/10568042-prototype-test-block-setup#h_f985ae8cad"
            />
          ) : null}
        </FormItem>
      )}
    />
  );
};

import { secondsToTime } from '../../lib/utils';
import { Tooltip, TooltipTrigger, TooltipContent } from '../tooltip';

interface Chapter {
  label: string;
  timestamp: number;
}

interface ChapterControlsProps {
  chapters: Chapter[];
  duration: number;
  isDisabled: boolean;
  labels: { progress: string };
  loadingProgress: number;
  progress: number;
  playbackRate: number;
  onSeek: (time: number) => void;
}

interface ChapterSegmentProps {
  chapter: Chapter;
  currentLoadingTime: number;
  currentTime: number;
  duration: number;
  index: number;
  nextChapter: Chapter;
}

const GAP_SIZE = 2;

const getProgress = (currentTime: number, start: number, end: number) => {
  return Math.max(0, Math.min(100, ((currentTime - start) / (end - start)) * 100));
};

const ChapterSegment = ({
  chapter,
  currentLoadingTime,
  currentTime,
  duration,
  index,
  nextChapter,
}: ChapterSegmentProps) => {
  const startPercent = (chapter.timestamp / duration) * 100;
  const endPercent = (nextChapter.timestamp / duration) * 100;
  const segmentPosition = {
    left: `calc(${startPercent}% + ${index * GAP_SIZE}px)`,
    width: `calc(${endPercent - startPercent}% - ${GAP_SIZE}px)`,
  };

  return (
    <div
      key={chapter.timestamp}
      style={segmentPosition}
      className="absolute top-1/2 h-1 -translate-y-1/2 transition-[height] duration-100 group-focus-within:h-1.5 group-hover:h-1.5"
    >
      {/* Filled progress */}
      <div
        className="absolute inset-0 rounded-sm bg-lightest"
        style={{ width: `${getProgress(currentTime, chapter.timestamp, nextChapter.timestamp)}%` }}
      />
      {/* Loading progress */}
      <div
        className="absolute inset-0 rounded-sm bg-lightest/30"
        style={{
          width: `${getProgress(currentLoadingTime, chapter.timestamp, nextChapter.timestamp)}%`,
        }}
      />
      {/* Background */}
      <div className="absolute inset-0 rounded-sm bg-lightest/20" />
    </div>
  );
};

export const ControlProgress = ({
  chapters,
  duration,
  isDisabled,
  labels,
  loadingProgress,
  playbackRate,
  progress,
  onSeek,
}: ChapterControlsProps) => {
  // Sort chapters by timestamp and add start/end markers
  const sortedChapters = [
    { label: 'Start', timestamp: 0 },
    ...chapters.sort((a, b) => a.timestamp - b.timestamp),
    { label: 'End', timestamp: duration },
  ];

  const currentTime = progress * duration * playbackRate;
  const currentLoadingTime = loadingProgress * duration * playbackRate;
  const currentProgress = progress * playbackRate;

  return (
    <div className="group relative w-full py-2 md:py-4">
      <label htmlFor="player-progress" className="sr-only">
        {labels.progress}
      </label>
      <input
        id="player-progress"
        className="absolute inset-0 top-1/2 z-20 h-full -translate-y-1/2 cursor-pointer opacity-0 "
        disabled={isDisabled}
        max={1}
        min={0}
        step={0.002}
        type="range"
        value={currentProgress}
        onChange={e => onSeek((Number(e.target.value) * duration) / playbackRate)}
      />

      <div
        className="absolute top-1/2 z-20 h-3 w-3 origin-center -translate-x-1 -translate-y-1/2 cursor-pointer rounded-full bg-lightest
          transition-transform group-focus-within:scale-125 group-hover:scale-125"
        style={{ left: `${currentProgress * 100}%` }}
      />

      {sortedChapters.map((chapter, index) => {
        if (index === sortedChapters.length - 1) {
          return null;
        }

        return (
          <ChapterSegment
            key={chapter.timestamp}
            chapter={chapter}
            currentLoadingTime={currentLoadingTime}
            currentTime={currentTime}
            duration={duration}
            index={index}
            nextChapter={sortedChapters[index + 1]}
          />
        );
      })}

      {sortedChapters.map((chapter, index) => {
        if (index === sortedChapters.length - 1 || chapter.label === 'Start') {
          return null;
        }

        return (
          <Tooltip key={chapter.timestamp}>
            <TooltipTrigger
              className="absolute top-1/2 z-30 h-full w-3 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
              style={{ left: `${(chapter.timestamp / duration) * 100}%` }}
              onClick={() => onSeek(chapter.timestamp)}
            ></TooltipTrigger>

            <TooltipContent className="bg-darkest text-lightest md:-mb-2">
              {chapter.label} ({secondsToTime(chapter.timestamp)})
            </TooltipContent>
          </Tooltip>
        );
      })}
    </div>
  );
};

import { useTranslation } from 'react-i18next';

import { DEVICE_OPTIONS } from 'containers/Studies/data/schemas/recruitSchema';

import { RadioGroupField } from './Fields/RadioGroupField';
import { SliderField } from './Fields/SliderField';

export const SectionParticipants = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();

  const deviceOptions = DEVICE_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.device.${option}`),
    value: option,
  }));

  return (
    <>
      <SliderField
        name="participants"
        label={t('sections.studies.recruit.fields.participantsTitle')}
        min={1}
        max={50}
        infoPopover={{
          title: t('sections.studies.recruit.fields.participantsCalloutTitle'),
          description: t('sections.studies.recruit.fields.participantsCalloutDescription'),
          moreLink: 'https://help.askable.com/en/articles/10616764-recruiting-for-unmoderated#h_b0658011e5',
        }}
        isDisabled={isDisabled}
      />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <RadioGroupField
        name="device"
        label={t('sections.studies.recruit.fields.deviceTitle')}
        options={deviceOptions}
        infoPopover={{
          title: t('sections.studies.recruit.fields.deviceCalloutTitle'),
          description: t('sections.studies.recruit.fields.deviceCalloutDescription'),
          moreLink: 'https://help.askable.com/en/articles/10616764-recruiting-for-unmoderated#h_b4ce391110',
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

interface Props {
  instructions: string;
  title: string;
}

export const TaskCardDraggableContent = ({ instructions, title }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <h3 className="text-lg font-semibold">{title}</h3>
        <div className="whitespace-pre-wrap text-sm">{instructions.trim()}</div>
      </div>
    </div>
  );
};

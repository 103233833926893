import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { cn } from '@askable/ui/lib/utils';
import { datadogRum } from '@datadog/browser-rum';
import { CircleHelp } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { SummaryInfoMessage } from 'containers/Studies/Recruit/components/Summary/SummaryInfoMessage';
import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { SummaryItemIndustries } from 'containers/Studies/Recruit/components/Summary/SummaryItemIndustries';
import { SummaryParticipantsDevice } from 'containers/Studies/Recruit/components/Summary/SummaryParticipantsDevice';
import { SummaryValidationMessage } from 'containers/Studies/Recruit/components/Summary/SummaryValidationMessage';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { SubmitBookingForReview } from 'containers/Studies/data/SubmitBookingForReview.mutation';
import { AGE_LIMIT } from 'containers/Studies/data/schemas/recruitSchema';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export const RecruitSummary = () => {
  const { t } = useTranslation();
  const { study, isValid, hasSubmitted, setHasSubmitted } = useStudyContext();
  const { getValues, setValue } = useFormContext<RecruitFormFields>();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const [, submitBookingForReview] = useMutation(SubmitBookingForReview);

  const showFields = useMemo(() => {
    return (
      study.recruit?.age_range?.[0] !== AGE_LIMIT.min ||
      study.recruit?.age_range?.[1] !== AGE_LIMIT.max ||
      !!study.recruit?.locations?.length ||
      !!study.recruit?.gender?.length ||
      !!study.recruit?.industry_and_occupation?.length ||
      !!study.recruit?.languages?.length ||
      !!study.recruit?.education_level?.length ||
      !!study.recruit?.marital_status?.length ||
      !!study.recruit?.occupational_status?.length ||
      !!study.recruit?.business_ownership?.length ||
      !!study.recruit?.screener_questions?.length
    );
  }, [study.recruit]);

  // Remove questions from the form state that haven't been saved with the study
  const cleanupScreenerQuestions = useCallback(() => {
    const validQuestionIds = new Set(study.recruit?.screener_questions?.map(q => q._id) ?? []);
    const currentQuestions = getValues('screener_questions') ?? [];
    const validQuestions = currentQuestions.filter(q => validQuestionIds.has(q._id));

    if (currentQuestions.length !== validQuestions.length) {
      setValue('screener_questions', validQuestions);
    }
  }, [study.recruit, getValues, setValue]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      datadogRum.addAction('recruit.submit', { bookingId: study._id, isValid });

      // 1. set local `hasSubmitted: true`
      setHasSubmitted(true);

      // 2. check global validation state, if any errors -> bail
      if (!isValid.build || !isValid.recruit) {
        // TODO: should we show an error toast here?
        return;
      }

      // 3. update booking status (this will)
      const { error } = await submitBookingForReview({ _id: study._id });
      if (error) {
        throw new Error(error.graphQLErrors?.[0]?.message || t('sections.errorBoundary.default'));
      }

      cleanupScreenerQuestions();
      toast.success(t('sections.studies.recruit.submitted'));
      navigate(`/studies/${study._id}/recruit/participants`);
    } catch (err: unknown) {
      toast.error(err instanceof Error ? err.message : t('sections.errorBoundary.default'));
      datadogRum.addError(err, { bookingId: study._id });
    } finally {
      setIsSubmitting(false);
    }
  }, [study._id, isValid, setHasSubmitted, submitBookingForReview, cleanupScreenerQuestions, t, navigate]);

  return (
    <aside className="fixed bottom-0 w-full min-w-[20rem] md:static md:bottom-auto lg:pr-4">
      <div
        className="sticky top-0 z-10 flex max-h-[96vh] flex-col gap-5 rounded-t-xl bg-background p-4 md:max-h-[calc(100vh_-_5rem)]
          lg:rounded-xl lg:p-6"
      >
        <div
          className={cn('hidden flex-col gap-4 overflow-hidden transition-all starting:h-0 md:flex', {
            flex: showSummary,
          })}
        >
          <SummaryParticipantsDevice participants={study.recruit?.participants ?? 0} device={study.recruit?.device!} />

          {showFields ? (
            <>
              <div className="font-semibold">{t('sections.studies.recruit.audience')}</div>

              <ul className="flex flex-col gap-3 overflow-auto text-balance text-sm">
                {study.recruit?.locations?.length ? (
                  <SummaryItem
                    label={t('sections.studies.recruit.fields.locationsTitle')}
                    type="locations"
                    options={study.recruit?.locations.map(location => location.location?.formatted_address!)}
                    variant="stacked"
                  />
                ) : null}

                {study.recruit?.age_range &&
                (study.recruit?.age_range[0] !== AGE_LIMIT.min || study.recruit?.age_range?.[1] !== AGE_LIMIT.max) ? (
                  <SummaryItem
                    label={t('sections.studies.recruit.fields.ageRangeTitle')}
                    type="age_range"
                    options={study.recruit?.age_range}
                    variant="dash-between"
                  />
                ) : null}

                <SummaryItem
                  label={t('sections.studies.recruit.fields.genderTitle')}
                  type="gender"
                  options={
                    study.recruit?.gender && study.recruit.gender.length > 0
                      ? study.recruit?.gender.map(val => t(`sections.studies.recruit.options.gender.${val}`))
                      : study.recruit?.gender !== null
                        ? [t('sections.studies.recruit.options.gender.any_gender')]
                        : undefined
                  }
                />

                <SummaryItemIndustries options={study.recruit?.industry_and_occupation ?? []} />

                <SummaryItem
                  label={t('sections.studies.recruit.fields.languagesTitle')}
                  type="languages"
                  options={
                    study.recruit?.languages
                      ? study.recruit?.languages.map(val => t(`global.locale.${val.locale}`))
                      : null
                  }
                />

                <SummaryItem
                  label={t('sections.studies.recruit.fields.educationLevelTitle')}
                  type="education_level"
                  options={
                    study.recruit?.education_level?.length
                      ? study.recruit?.education_level.map(val =>
                          t(`sections.studies.recruit.options.education_level.${val}`),
                        )
                      : null
                  }
                />

                <SummaryItem
                  label={t('sections.studies.recruit.fields.maritalStatusTitle')}
                  type="marital_status"
                  options={
                    study.recruit?.marital_status?.length
                      ? study.recruit?.marital_status.map(val =>
                          t(`sections.studies.recruit.options.marital_status.${val}`),
                        )
                      : null
                  }
                />

                <SummaryItem
                  label={t('sections.studies.recruit.fields.occupationalStatusTitle')}
                  type="occupational_status"
                  options={
                    study.recruit?.occupational_status?.length
                      ? study.recruit?.occupational_status.map(val =>
                          t(`sections.studies.recruit.options.occupational_status.${val}`),
                        )
                      : null
                  }
                />

                <SummaryItem
                  label={t('sections.studies.recruit.fields.businessOwnershipTitle')}
                  type="business_ownership"
                  options={
                    study.recruit?.business_ownership?.length
                      ? study.recruit?.business_ownership.map(val =>
                          t(`sections.studies.recruit.options.business_ownership.${val}`),
                        )
                      : null
                  }
                />

                {study.recruit?.screener_questions?.length ? (
                  <li className="flex gap-2">
                    <CircleHelp className="h-4 w-4 text-muted-foreground" />
                    <div className="flex flex-col">
                      <div className="text-xs text-muted-foreground">
                        {t('sections.studies.recruit.fields.screenerQuestionsTitle')}
                      </div>
                      {study.recruit?.screener_questions.length}{' '}
                      {t('sections.studies.recruit.screenerQuestions', {
                        count: study.recruit?.screener_questions.length,
                      }).toLocaleLowerCase()}
                    </div>
                  </li>
                ) : null}
              </ul>
            </>
          ) : null}
        </div>

        {hasSubmitted ? <SummaryValidationMessage /> : null}

        <div className="flex gap-1">
          <Button className="md:!hidden" size="lg" variant="outline" onClick={() => setShowSummary(prev => !prev)}>
            {t('sections.studies.recruit.summary')}
          </Button>
          <Button
            className="flex-1"
            isLoading={isSubmitting}
            size="lg"
            type="submit"
            variant="primary"
            onClick={handleSubmit}
          >
            {t('sections.studies.recruit.submitStudy')}
          </Button>
        </div>

        <SummaryInfoMessage />
      </div>
      {showSummary ? (
        <div
          aria-hidden="true"
          className="fixed inset-0 bottom-0 top-0 h-screen w-screen bg-darkest/80 opacity-100 transition-opacity starting:opacity-0 md:hidden"
          onClick={() => setShowSummary(false)}
        />
      ) : null}
    </aside>
  );
};

import { graphql } from 'src/graphql';

export const BookingTaskResultClicks = graphql(`
  query BookingTaskResultClicks($filter: BookingTaskResultClicksFilterInput!) {
    bookingTaskResultClicks(filter: $filter) {
      type
      position_x
      position_y
      handled
      target_location_id
    }
  }
`);

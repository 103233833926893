import { Button } from '@askable/ui/core/button';
import { Command, CommandGroup, CommandItem, CommandList } from '@askable/ui/core/command';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { Spinner } from '@askable/ui/core/spinner';
import { X } from 'lucide-react';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedSearch } from 'shared-utils/hooks';
import { useClient } from 'urql';
import { useOnClickOutside } from 'usehooks-ts';

import { CountrySelect } from 'components/common';
import getLocationFromGooglePlaceId from 'data/queries/location/getLocationFromGooglePlaceId';
import countryCodeData from 'lib/data/phoneCountryCodes.json';
import { utils } from 'lib/utils';

import { LocationAutocompleteQuery } from '../LocationAutocompleteQuery/view';

import { useUserLatLong } from './useUserLatLong';

import type { Location } from 'generated/graphql';

type AutocompleteType = 'geocode' | 'address' | 'establishment' | '(regions)' | '(cities)';

interface LocationResult {
  sessionToken: string | null;
  descriptionMatches: Array<{
    match: boolean | null;
    text: string | null;
  } | null> | null;
  placeId: string | null;
  description: string | null;
}

interface AutocompleteResults {
  data?: {
    locationAutocomplete: Array<LocationResult | null> | null;
  };
  fetching: boolean;
  error?: Error;
}

type LocationAutocompleteProps = {
  canChangeCountry?: boolean;
  clearValue?: boolean;
  countryCodes?: string[] | null;
  id?: string;
  initialSearchValue?: string;
  isDisabled?: boolean;
  isMultiSelect?: boolean;
  label?: string;
  placeholder: string;
  selectedOptions?: string[];
  styleWidth?: number;
  type?: AutocompleteType;
  onNewPlace: (place: Location) => void;
  onRemoveOption?: (name: string) => void;
  setIsLoading?: (isLoading: boolean) => void;
};

export default function LocationAutocomplete({
  id = '',
  canChangeCountry,
  clearValue,
  countryCodes: initialCountryCodes,
  initialSearchValue,
  isDisabled,
  isMultiSelect,
  label,
  placeholder,
  selectedOptions,
  styleWidth,
  type,
  onNewPlace,
  onRemoveOption,
  setIsLoading,
}: LocationAutocompleteProps) {
  const { t } = useTranslation();
  const client = useClient();
  const inputRef = useRef<HTMLInputElement>(null);
  const { latitude, longitude } = useUserLatLong();

  // State
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [openCountrySelect, setOpenCountrySelect] = useState(false);
  const [countryCodes, setCountryCodes] = useState<string[]>((initialCountryCodes || []).filter(Boolean));
  const [debouncedSearch, setDebouncedSearch] = useState(initialSearchValue);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);

  // Custom hooks
  const { searchTerm: search, handleChange: setSearch } = useDebouncedSearch({
    defaultSearchTerm: initialSearchValue,
    debounceFunction: setDebouncedSearch,
  });

  useOnClickOutside(inputRef, () => setPopoverOpen(false));

  const countryData = useMemo(() => countryCodeData.find(c => c.region === countryCodes?.[0]), [countryCodes]);

  // Handlers
  const handleInputChange = (value: string) => {
    setSearch(value);
    setPopoverOpen(!!(value && value.length > 1));
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleInputFocus = (value: string) => {
    setPopoverOpen(!!(value && value.length > 1));
  };

  const handleLocationSelect = async (placeId: string, description: string) => {
    setIsLoading?.(true);
    setIsLoadingLocation(true);

    if (!isMultiSelect) {
      setPopoverOpen(false);
      setSearch(description);
    }

    try {
      const result = await client.query(getLocationFromGooglePlaceId, { placeId }).toPromise();
      const location = utils.removeTypenames(result.data?.getLocationFromGooglePlaceId) as Location;

      if (!location) {
        console.warn('No data returned from getLocationFromGooglePlaceId query');
        return;
      }

      if (clearValue) {
        setSearch('');
      }

      setPopoverOpen(false);
      onNewPlace(location);
      setTimeout(() => inputRef.current?.focus(), 0);
    } finally {
      setIsLoading?.(false);
      setIsLoadingLocation(false);
    }
  };

  const renderAutocompleteResults = (result: AutocompleteResults) => {
    const { data, fetching } = result;
    return (
      <Command>
        <CommandList>
          {!data?.locationAutocomplete && search.length > 1 ? (
            <div className="flex h-[2.25rem] items-center justify-center p-2 pb-0">
              <Spinner className="h-5 w-5" />
            </div>
          ) : null}
          {!fetching && data?.locationAutocomplete?.length === 0 ? (
            <CommandItem className="flex h-[2.25rem] items-center justify-center !bg-transparent p-0 pt-2 text-sm text-muted-foreground">
              {t('formValidation.noLocations')}
              <Button
                className="text-muted-foreground"
                variant="link"
                onClick={() => {
                  setSearch('');
                  setTimeout(() => inputRef.current?.focus(), 0);
                }}
              >
                {t('global.clear')}
              </Button>
            </CommandItem>
          ) : null}
          <CommandGroup className={fetching ? 'opacity-50' : undefined}>
            {data?.locationAutocomplete?.map((location: LocationResult | null) => {
              if (!location?.placeId) {
                return null;
              }

              return (
                <CommandItem
                  key={location.placeId}
                  className="cursor-pointer whitespace-pre p-2 text-sm"
                  value={location.description!}
                  onSelect={() => handleLocationSelect(location.placeId!, location.description!)}
                >
                  {location.descriptionMatches?.map((matchObj, index) => {
                    if (!matchObj) {
                      return null;
                    }

                    const { text, match } = matchObj;
                    return match ? <strong key={index}>{text}</strong> : <span key={index}>{text}</span>;
                  })}
                </CommandItem>
              );
            })}
          </CommandGroup>
        </CommandList>
      </Command>
    );
  };

  const renderSelectedOptions = () =>
    selectedOptions?.map(option => (
      <Button
        key={option}
        disabled={isDisabled}
        variant="ghost"
        className="flex max-w-xs items-center gap-1 rounded-sm bg-info-foreground pr-3 leading-none hover:bg-info/10 focus:bg-info/10
          active:bg-info/20"
        onKeyDown={e => e.key === 'Enter' && onRemoveOption?.(option)}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => onRemoveOption?.(option)}
      >
        <div className="truncate">{option}</div>
        <X className="h-3 w-3" />
      </Button>
    ));

  const renderInput = () => {
    const inputProps = {
      disabled: isDisabled,
      id: `input_${id}_location_autocomplete`,
      placeholder,
      ref: inputRef,
      value: search || '',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value),
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => handleInputFocus(e.target.value),
    };

    if (isMultiSelect) {
      return (
        <div
          className="group flex min-h-[2.1rem] flex-wrap items-center gap-1 rounded-md border border-input bg-background p-1 text-sm
            ring-primary focus-within:ring-1 aria-[disabled=true]:opacity-50"
          aria-disabled={isDisabled}
        >
          {renderSelectedOptions()}
          <Input variant="borderless" className="h-6 w-fit" {...inputProps} />
          {isLoadingLocation ? <Spinner className="h-3 w-3" /> : null}
        </div>
      );
    }

    return <Input {...inputProps} />;
  };

  return (
    <div>
      <div className="flex flex-col gap-1">
        {!isMultiSelect ? (
          <Label htmlFor={`input_${id}_location_autocomplete`}>{label ?? t('formFields.location')}</Label>
        ) : null}
        {renderInput()}

        <Popover open={popoverOpen}>
          <PopoverTrigger className="mt-1 w-full" />
          <PopoverContent
            className="min-h-10 p-0"
            sideOffset={-3}
            style={{ width: styleWidth || 'var(--radix-popover-trigger-width)' }}
          >
            <LocationAutocompleteQuery
              input={debouncedSearch}
              biasLocation={true}
              location={{
                countries: canChangeCountry ? undefined : countryCodes,
                latitude,
                longitude,
              }}
              types={type}
            >
              {renderAutocompleteResults}
            </LocationAutocompleteQuery>
          </PopoverContent>
        </Popover>
      </div>

      {openCountrySelect ? (
        <CountrySelect
          bookingCountry={{ name: countryData?.name ?? '', region: countryData?.region ?? '' }}
          onChange={value => setCountryCodes([value.region])}
          openCountrySelect={openCountrySelect}
          closeCountrySelect={() => {
            setOpenCountrySelect(false);
            setPopoverOpen(true);
          }}
        />
      ) : null}
    </div>
  );
}

import { Button } from '@askable/ui/core/button';
import { cn } from '@askable/ui/lib/utils';
import { MapPin } from 'lucide-react';

import type { Location } from 'generated/graphql';

type Props = {
  locations: Location[];
  currentLocation: Location | null;
  onSelectLocation: (location: Location) => void;
  onDeleteLocation: (location: Location) => void;
};

const ParticipantLocationsList = ({ locations, currentLocation, onSelectLocation, onDeleteLocation }: Props) => {
  return (
    <div className="rounded border bg-muted p-4">
      <ul className="flex flex-col gap-2">
        {locations.map(data => {
          return (
            <li
              key={data?.formatted_address}
              className={cn(
                'pointer flex items-center justify-between rounded border bg-white px-2 text-sm hover:shadow-sm',
                {
                  'border-primary': currentLocation?.formatted_address === data?.formatted_address,
                },
              )}
            >
              <div
                className="flex h-full w-full cursor-pointer items-center gap-2 py-3"
                onClick={() => onSelectLocation(data)}
              >
                <MapPin className="mx-2 h-4 w-4" />
                <p>{data?.formatted_address}</p>
              </div>
              <Button
                variant="ghost"
                size="sm"
                aria-label={`Delete ${data?.formatted_address}`}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onDeleteLocation(data);
                }}
              >
                Delete
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ParticipantLocationsList;

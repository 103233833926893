import { Button } from '@askable/ui/core/button';
import { FormLabel } from '@askable/ui/core/form';
import { Label } from '@askable/ui/core/label';
import { Switch } from '@askable/ui/core/switch';
import { Plus } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { SortableList } from 'containers/Studies/components/SortableList';

import { ScreenerQuestionItemOption } from './ScreenerQuestionItemOption';

import type { ScreenerQuestionOption } from 'containers/Studies/Recruit/components/SectionScreenerQuestions';

interface ScreenerQuestionItemMultipleChoice {
  id: string;
  isDisabled?: boolean;
  isMultipleSelection?: boolean;
  options: ScreenerQuestionOption[];
  onAddOption: () => void;
  onChangeMultipleSelection: (value: boolean) => void;
  onChangeValue: (index: number, label: string, isQualified: boolean) => void;
  onRemove: (index: number) => void;
  onSort: (options: ScreenerQuestionOption[]) => void;
}

export const ScreenerQuestionItemMultipleChoice = ({
  id,
  isDisabled,
  options,
  isMultipleSelection,
  onAddOption,
  onChangeMultipleSelection,
  onChangeValue,
  onRemove,
  onSort,
}: ScreenerQuestionItemMultipleChoice) => {
  const { t } = useTranslation();

  return (
    <div className="-mx-2 -mb-[1] flex flex-col gap-2 overflow-auto pb-1">
      <FormLabel
        htmlFor={`input_screener_question_${id}_options`}
        className="flex w-full items-center justify-between gap-2 px-2"
      >
        {t('sections.studies.recruit.fields.optionsTitle')}
        <div className="flex items-center gap-2 ">
          <Label htmlFor={`input_screener_question_${id}_is_multiple_selection`} className="font-normal">
            {t('sections.studies.recruit.fields.is_multiple_selectionTitle')}
          </Label>
          <Switch
            checked={!!isMultipleSelection}
            disabled={isDisabled}
            id={`input_screener_question_${id}_is_multiple_selection`}
            size="small"
            onCheckedChange={onChangeMultipleSelection}
          />
        </div>
      </FormLabel>

      <div className="px-1">
        {options.length ? (
          <SortableList
            onChange={onSort}
            items={options}
            renderItem={(option, index) => (
              <ScreenerQuestionItemOption
                id={option._id}
                index={index}
                isQualified={option.is_qualified}
                isDisabled={isDisabled}
                isMovable={(options ?? []).length > 1}
                key={index}
                value={option.label}
                onChangeValue={onChangeValue}
                onRemove={onRemove}
              />
            )}
          />
        ) : null}
      </div>

      <div className="px-2">
        <Button variant="outline" onClick={onAddOption} disabled={isDisabled}>
          <Plus className="h-4 w-4" />
          {t('sections.studies.recruit.fields.optionsCta')}
        </Button>
      </div>
    </div>
  );
};

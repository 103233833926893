import { Button } from '@askable/ui/core/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { BatchMessageAdd } from './BatchMessageAdd';

import type { User } from '../data/BookingSubmissionsWithMessages.query';

interface PanelBatchMessagesProps {
  bookingId?: string;
  onClose: () => void;
  users: User[];
}

const panelWidth = window.innerWidth < 400 ? '96%' : '25rem';
const showNamesCount = 3;

const ParticipantsList = ({ participants }: { participants: string[] }) => {
  const { t } = useTranslation();

  return (
    <>
      {participants.slice(0, showNamesCount).join(', ')}
      {participants.length > showNamesCount ? (
        <>
          ,{' '}
          <Tooltip>
            <TooltipTrigger className="underline">
              {t('global.plusMore', { count: participants.length - showNamesCount })}
            </TooltipTrigger>
            <TooltipContent className="w-[14rem]" side="bottom">
              {participants.slice(showNamesCount).join(', ')}
            </TooltipContent>
          </Tooltip>
        </>
      ) : null}
    </>
  );
};

export const PanelBatchMessages = ({ bookingId, users, onClose }: PanelBatchMessagesProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="opacity-1 absolute right-0 top-0 h-full overflow-hidden transition-all duration-150 ease-in-out starting:opacity-50"
      style={{ width: users.length > 0 ? panelWidth : 0 }}
    >
      <div
        className="fixed flex h-full flex-col overflow-auto border-l border-border bg-background text-foreground shadow-md dark:bg-accent"
        style={{ width: panelWidth, height: 'var(--mainContainerHeight)' }}
      >
        {users.length > 0 ? (
          <>
            <div
              className="sticky top-0 z-10 flex w-full items-center justify-between border-b border-border bg-background/90 p-2 backdrop-blur-sm
                sm:p-3"
            >
              <div className="flex w-full flex-col">
                <div className="flex w-full items-center justify-between gap-2">
                  <h3 className="font-semibold">{t('sections.messages.messagePeople', { count: users.length })}</h3>

                  <Button variant="ghost" size="icon" onClick={onClose}>
                    <X className="h-4 w-4" />
                  </Button>
                </div>

                <div className="text-sm text-muted-foreground">
                  <ParticipantsList participants={users.map(u => u?.displayName!)} />
                </div>
              </div>
            </div>

            <BatchMessageAdd userIds={users.map(u => u?._id!)} bookingId={bookingId ?? ''} onClose={onClose} />
          </>
        ) : null}
      </div>
    </div>
  );
};

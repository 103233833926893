import { Button } from '@askable/ui/core/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@askable/ui/core/dropdown-menu';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@askable/ui/core/tooltip';
import { MoreHorizontal } from 'lucide-react';

import type { ReactNode } from 'react';

type Props = {
  dropdownMenuContents: ReactNode;
};
export const CellActions = ({ dropdownMenuContents }: Props) => {
  return (
    <DropdownMenu>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent>More actions</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent align="end" className="w-[160px]">
        {dropdownMenuContents}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

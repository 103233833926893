import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { ParticipantDetails } from 'containers/Studies/Results/components/Participant/ParticipantDetails';
import { BOOKING_STATUS } from 'lib/constants';

import { useStudyContext } from '../StudiesContainer';
import { getPageTitle } from '../utils/getPageTitle';

import { ResultsHeader } from './components/ResultsHeader';
import { FigmaPrototypeResultDialog } from './containers/FigmaPrototypeResultDialog';
import { ResultsNavigation } from './containers/ResultsNavigation';

import type { BookingTaskResultDetailsType } from 'generated/graphql';

// @todo replace these types from API
export interface ParticipantResponse {
  id: string;
  type: BookingTaskResultDetailsType;
  question: string;
  answer: {
    id: string;
    value: string;
  };
}

export interface Recording {
  url: string;
  duration: number;
  screens: any[];
}

export interface ParticipantProps {
  completed: number;
  duration: number;
  id: string;
  misclickRate: number;
  name: string;
  outcome: 'success' | 'incomplete';
  screensViewed: number;
  status: string;
  thumbnail: string;
  recording: Recording | null;
  responses: ParticipantResponse[];
  details: {
    age: number;
    location: string;
    gender: string;
  };
  screenerQuestions: {
    answer: string;
    eligible: boolean;
    id: string;
    question: string;
  }[];
  studyDetails: {
    completionDate: number;
    duration: number;
    deviceType: string;
    os: string;
  };
}

export interface ResultsProps {
  id: string;
  name: string;
  status: string;
  participants: ParticipantProps[];
  tasks: any[];
  completed: number;
  created: number;
}

export const Results = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const { participantId, taskId } = params;

  const isLive = study.status !== BOOKING_STATUS.DRAFT && study.status !== BOOKING_STATUS.IN_REVIEW;

  useEffect(() => {
    if (!isLive) {
      location.hash = '#main';
    }
  }, [isLive]);

  // If we do have blocks but no taskId in the url, redirect to the first block
  if (!participantId && !taskId && !!study?.config?.unmoderated?.task_blocks?.length) {
    const taskId = study.config?.unmoderated?.task_blocks?.[0]?._id;
    return <Navigate to={`/studies/${study._id}/results/task/${taskId}`} replace />;
  }

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle({
            section: t('sections.studies.tabs.results'),
            study: study.name || t('sections.studies.untitledStudy'),
          })}
        </title>
      </Helmet>

      <ResultsHeader hasSidebar={isLive} />
      {isLive ? (
        <>
          <ResultsNavigation active={participantId ? 'participants' : 'blocks'} />
          <main className="main">
            <Outlet />
          </main>
          <FigmaPrototypeResultDialog />
          <ParticipantDetails />
        </>
      ) : (
        <main className="main">
          <div className="flex h-full flex-1 flex-row gap-4 overflow-auto rounded-xl bg-background">
            <EmptyState title={t('sections.studies.results.notLive')} />
          </div>
        </main>
      )}
    </>
  );
};

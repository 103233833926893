import { Avatar, AvatarFallback } from '@askable/ui/core/avatar';
import { AnchorButton, Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@askable/ui/core/dropdown-menu';
import { differenceInYears } from 'date-fns';
import { Ellipsis, Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Stat } from 'containers/Studies/Results/components/Stat';

interface ParticipantHeaderProps {
  name: string;
  age?: number;
  city?: string;
  country?: string;
  gender?: string;
  duration: number;
}

export const ParticipantHeader = ({ name, age, city, country, gender, duration }: ParticipantHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const initials =
    name
      .split(' ')
      .map(n => n[0])
      .join('') ?? 'U';

  const demographics = [
    age && differenceInYears(new Date(), new Date(age)),
    gender && gender.charAt(0).toUpperCase() + gender.slice(1),
    city,
    country,
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <header className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-3">
        <Avatar size="xl">
          <AvatarFallback variant="primary">{initials}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <h2 className="text-xl font-semibold">{name}</h2>
          <div className="flex flex-wrap items-center gap-x-4 text-sm text-muted-foreground">
            {demographics ? demographics : null}
            <Stat type="duration" value={duration} />
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <AnchorButton
          variant="outline"
          size="icon"
          href={location.hash === '#details' ? '#main' : '#details'}
          title={t('sections.studies.results.showMoreInfo')}
        >
          <Info className="h-4 w-4" />
        </AnchorButton>

        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="hidden"
              title={t('sections.studies.options', { count: 2 })}
            >
              <Ellipsis className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>...</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

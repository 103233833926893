/* eslint-disable max-lines */
import { useMutation, useQuery } from '@apollo/client';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { RadioGroup, RadioGroupItem } from '@askable/ui/core/radio-group';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { CreditCard, X } from 'lucide-react';
import { isNumber } from 'radash';
import { useState, useEffect, useRef, Fragment } from 'react';
import Spinner from 'react-spinkit';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button as ChakraButton,
  HStack,
  Select,
  Input as ChakraInput,
  useDisclosure,
  VStack,
  FormLabel,
  Box,
  FormHelperText,
  FormControl,
} from 'ui';

import { NewCreditCard, BoxMessage, Slider, LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import validateCouponMutation from 'data/mutations/coupon/validateCoupon';
import deleteTeamCreditCardMutation from 'data/mutations/teams/deleteTeamCreditCard';
import purchaseCreditsMutation from 'data/mutations/teams/purchaseCredits';
import { FetchCreditPacksQuery } from 'data/queries/credits/fetchCreditPacks';
import fetchTeamPaymentDetails from 'data/queries/teams/fetchTeamPaymentDetails';
import { useFetchCountryListQuery, useFetchTeamPaymentDetailsQuery, useUpdateTeamMutation } from 'generated/graphql';
import { analytics } from 'lib/analytics';
import { CREDITS_TYPE } from 'lib/constants';
import { creditUtils } from 'lib/credits';
import { localStorage } from 'lib/storage';
import { teamUtils } from 'lib/teams';
import { utils } from 'lib/utils';

import { UpdateBillingCountryComponent } from './UpdatingBillingCountryComponent';
import ModalPaymentStatus from './modalPaymentStatus';

import type { CountryData, Credits, Location, Teams, Coupon } from 'generated/graphql';

import './buyCreditsStyles.scss';

const MAX_STEP = 700;
const DEFAULT_STEP = 100;

type PaymentMethodType = {
  label: string;
  value: number;
};

const paymentMethods: Record<string, PaymentMethodType> = {
  STRIPE: {
    label: 'Pay with Credit Card',
    value: 1,
  },
  INVOICE: {
    label: 'Pay with Invoice',
    value: 2,
  },
};

// Common component to create a custom tab that doesn't rely on Material Design
function BuyCredits(props: any) {
  const [team, setTeam] = useState({
    settings: {
      billing: {
        registration: '',
        company_name: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_email: '',
        location: {
          street1: '',
          level: '',
          city: '',
          postal_code: '',
          country: localStorage.get('countryByIp'),
          state: '',
        },
      },
    },
  });
  const [numberOfCredits, setNumberOfCredits] = useState(50);
  const [coupon, setCoupon] = useState<Partial<Coupon>>({});
  const [couponCode, setCouponCode] = useState({});
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(paymentMethods.STRIPE.value);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<PaymentMethodType[]>([paymentMethods.INVOICE]);
  const [purchasing, setPurchasing] = useState(false);
  const [errorCouponCode, setErrorCouponCode] = useState('');
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [reference, setReference] = useState('');
  const [creditCardCompletedStatus, setCreditCardCompletedStatus] = useState(false);
  const [openModalCreditCard, setOpenModalCreditCard] = useState(false);
  const [openModalRemoveCard, setOpenModalRemoveCard] = useState(false);
  const [loadingTotalAmountContainer, setLoadingTotalAmountContainer] = useState(false);
  const [paymentModal, setPaymentModal] = useState<{ open: false; step?: number } | { open: true; step: number }>({
    open: false,
    step: 1,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [savings, setSavings] = useState(12);
  const [valueStep, setValueStep] = useState(DEFAULT_STEP);
  const [stepsArray, setStepsArray] = useState([]);
  const [creditsByParams, setCreditsByParams] = useState(false);
  const [loadingRemoveCreditCard, setLoadingRemoveCreditCard] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { details: clientDetails } = useConnectedClient();
  const team_id = props.team_id || clientDetails?.team?.id;
  const isDisplayingHeaderAds = localStorage.get('showingHeaderAds') === 'true';

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const stripeEnabled = !!(stripe && elements);
    if (stripeEnabled) {
      setPaymentMethodOptions([paymentMethods.STRIPE, paymentMethods.INVOICE]);
      return;
    }
    setPaymentMethodOptions([paymentMethods.INVOICE]);
    if (paymentMethodSelected === paymentMethods.STRIPE.value) {
      setPaymentMethodSelected(paymentMethods.INVOICE.value);
    }
  }, [stripe, elements, paymentMethodSelected]);

  let currency = teamUtils.getCurrency();

  const maxCreditPackSize = 500000;

  const teamByIdResults = useFetchTeamPaymentDetailsQuery({
    variables: {
      _id: team_id,
    },
    skip: !props.team_id,
    client: props.client,
  });
  const teamByIdData = teamByIdResults.data?.teamById;

  const [validateCoupon] = useMutation(validateCouponMutation, { client: props.client });
  const [purchaseCredits] = useMutation(purchaseCreditsMutation, { client: props.client });
  const [deleteTeamCreditCard] = useMutation(deleteTeamCreditCardMutation, {
    client: props.client,
  });
  const [updateTeam] = useUpdateTeamMutation({
    client: props.client,
  });

  const creditPacksResult = useQuery(FetchCreditPacksQuery, { client: props.client });

  const creditPacks = creditPacksResult?.data?.creditPacks;

  const resultCountryList = useFetchCountryListQuery({
    client: props.client,
  });
  const countryList: CountryData[] | null | undefined = utils.removeTypenames(
    resultCountryList.data?.countryList,
  ) as any;
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (teamByIdData && teamByIdData.settings?.billing) {
      const teamToUpdate: Teams = utils.removeTypenames(teamByIdData);
      // If no location is set againsts the team yet, it should not update it as it can cause problems setting the location to null in a later process
      if (teamToUpdate.settings?.billing?.location === null) {
        updateTeam({
          variables: {
            _id: team_id,
            team: {
              settings: {
                billing: {
                  location: {},
                },
              },
            },
          },
        });
      }
      const teamBillingSettingsSanitised: any = _.omit(teamToUpdate.settings?.billing, ['stripe', 'xero_id']);
      const teamBillingLocation: Location = teamBillingSettingsSanitised.location;
      const teamToSet = {
        settings: {
          ...teamToUpdate.settings,
          billing: {
            ...teamBillingSettingsSanitised,
            ...(teamBillingLocation?.country
              ? { location: teamBillingLocation }
              : { location: team.settings.billing.location }),
          },
        },
      };
      setTeam(teamToSet);

      if (teamBillingLocation?.country) onUpdateCurrency(teamBillingLocation.country);
    }
  }, [teamByIdData, resultCountryList.loading]);

  useEffect(() => {
    let coupon_code = '';
    const extraArguments = utils.parseQueryParams(_.get(props, 'location.search'));

    const totalCreditsToBuy = (() => {
      // Checks whether or not there's an URL to rule the number of credits
      // If it doesnt, then it should populate with the defaults coming from props
      if (extraArguments && extraArguments.get('credits')) {
        setCreditsByParams(true);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        return parseInt(extraArguments.get('credits'), 10);
      }
      const sessionStorageCredits = sessionStorage.getItem('creditsToBuy');
      if (sessionStorageCredits) {
        return parseInt(sessionStorageCredits, 10);
      }

      if (props.creditsToBuy > 0) {
        return props.creditsToBuy;
      }
    })();

    // Apply a coupon that is coming from the URL
    if (extraArguments && extraArguments.get('coupon')) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      coupon_code = extraArguments.get('coupon');
    }

    // Find the index of the value step and set it to update the slider
    setNumberOfCredits(parseInt(totalCreditsToBuy, 10));
    setCouponCode(coupon_code);

    if (coupon_code !== '') {
      onApplyDiscount({ coupon_code });
    }

    if (creditPacks) {
      const steps = creditUtils.generateArrayOfStepsForSlider(creditPacks);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ index: number; value: number; ... Remove this comment to see the full error message
      setStepsArray(steps);
      const closesValueInArray = creditUtils.findClosest(creditPacks, steps, totalCreditsToBuy, 'value');
      setValueStep(_.get(closesValueInArray, 'index') || 100);
      const savingsData = creditUtils.getSavings(creditPacks, totalCreditsToBuy);
      setSavings(savingsData);
    }
  }, [creditPacks]);

  useEffect(() => {
    if (couponCode !== '' && numberOfCredits > 20000) {
      onApplyDiscount({ coupon_code: '' });
      setCouponCode('');
    }
  }, [numberOfCredits]);

  const onUpdateTeam = () => {
    updateTeam({
      variables: {
        _id: team_id,
        team,
      },
    });
  };

  const onUpdateCurrency = (country_code: string) => {
    const countryData = countryList?.find(item => item.country_code === country_code);
    if (countryData) {
      currency = teamUtils.getCurrency();
    } else {
      currency = teamUtils.getDefaultCurrency();
    }
  };

  const onApplyDiscount = ({ coupon_code, total_price }: any) => {
    if (!coupon_code) return;
    setLoadingTotalAmountContainer(true);
    setErrorCouponCode('');

    validateCoupon({
      variables: {
        coupon: coupon_code,
        _team_id: props.team_id,
        total_price,
      },
    })
      .then(result => {
        setCoupon({
          code: _.get(result, 'data.validateCoupon.code') ?? undefined,
          discount_value: _.get(result, 'data.validateCoupon.discount_value') ?? undefined,
          discount_type: _.get(result, 'data.validateCoupon.discount_type') ?? undefined,
          campaign_image: _.get(result, 'data.validateCoupon.campaign_image') ?? undefined,
        });
        setLoadingTotalAmountContainer(false);
        setCouponCode('');
      })
      .catch(e => {
        setErrorCouponCode(_.get(e, 'graphQLErrors[0].message'));
        setLoadingTotalAmountContainer(false);
      });
  };

  const onRegisterPayment = async () => {
    setPurchasing(true);
    setPaymentModal({
      open: true,
      step: 1,
    });
    const creditPackSelected = getSelectedCreditPack();
    const extraArguments: { [k: string]: string | undefined } = {};
    let stripe_token = null;

    // Update team details
    await updateTeam({
      variables: {
        _id: props.team_id,
        team,
      },
    });

    if (paymentMethodSelected === 1) {
      stripe_token = await getStripeToken();
      extraArguments.stripe_token = stripe_token?.token?.id;
    }

    extraArguments.purchase_order = purchaseOrder;
    extraArguments.reference = reference;

    // Check if the user is buying credits coming from a booking context or not
    if (_.get(props.location, 'state._booking_id')) {
      extraArguments._booking_id = _.get(props, 'location.state._booking_id') ?? undefined;
    }
    purchaseCredits({
      variables: {
        purchase_credits: {
          credit_pack: creditPackSelected?._id,
          _team_id: props.team_id,
          payment_method: paymentMethodSelected === 1 ? 'card' : 'invoice',
          currency_code: currency.code,
          currency_symbol: currency.symbol,
          coupon_code: coupon?.code ?? '',
          quantity: numberOfCredits,
          ...extraArguments,
        },
      },
    })
      .then(async ({ data }) => {
        // Track Event Analytics
        analytics.track('client_bought_credits', {
          page: window.location.href,
          action: 'Client bought credits',
          credit_pack: creditPackSelected?._id,
          payment_method: paymentMethodSelected === 1 ? 'card' : 'invoice',
          currency_code: currency.code,
          currency_symbol: currency.symbol,
          coupon_code: coupon?.code ?? '',
          quantity: numberOfCredits,
          isBulk: creditPackSelected?.type === 1,
        });
        // Clear session storage
        sessionStorage.removeItem('creditsToBuy');

        setPaymentModal({
          open: true,
          step: 2,
        });

        if (props.onSuccess && data?.purchaseCredits) {
          await props.onSuccess(data.purchaseCredits);
        } else {
          setPaymentModal({ open: false });
        }
      })
      .catch(e => {
        setErrorMessage(_.get(e, 'graphQLErrors[0]'));
        setPaymentModal({
          open: false,
        });
      })
      .finally(() => {
        setPurchasing(false);
      });
  };

  const getSelectedCreditPack = () => {
    const creditPackSelected = _.filter(creditPacks as Credits[], item => (item?.quantity || 0) <= numberOfCredits);
    if (creditPackSelected?.length === 0) {
      return undefined;
    }
    return creditPackSelected[creditPackSelected.length - 1];
  };

  const getStripeToken = async () => {
    const stripePayment = teamByIdData?.settings?.billing?.stripe?.payment;
    if (stripePayment) return null;
    const cardElement = elements?.getElement(CardElement);
    if (!cardElement) return null;
    const token = await stripe?.createToken(cardElement);
    return token;
  };

  const isBillingAddressValid = () => {
    const { location, company_name } = team.settings.billing;
    if (
      location?.street1 &&
      location?.city &&
      location?.state &&
      location?.postal_code &&
      location?.country &&
      company_name
    ) {
      return true;
    }
    return false;
  };

  const validateFields = () => {
    const billingAddressIsValid = isBillingAddressValid();
    switch (paymentMethodSelected) {
      case 1: // Validate Credit card fields
        // Client has credit card attached
        if (!!teamByIdData?.settings?.billing?.stripe?.payment && billingAddressIsValid) {
          return true;
        }

        // Client does not have a credit card attached
        if (creditCardCompletedStatus && billingAddressIsValid) {
          return true;
        }

        return false;
      case 2: // Validate invoice fields
        if (_.size(team.settings.billing.registration) > 0 && billingAddressIsValid) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const debouncedUpdateCredits = (credits: any) => {
    const savingsData = creditUtils.getSavings(creditPacks, credits);
    setNumberOfCredits(parseInt(credits, 10));
    sessionStorage.setItem('creditsToBuy', credits);
    setSavings(savingsData);
  };

  const renderDiscountPrice = () => {
    if (!(coupon?.discount_type && coupon?.discount_value)) {
      return '';
    }
    // Discount type - Flat Amount
    if (coupon.discount_type === 1) {
      return `${currency.symbol}${utils.formatMoney(coupon.discount_value, 2)}`;
    }
    // Discount type - Percentage
    if (coupon.discount_type === 2) {
      return `${coupon.discount_value}%`;
    }
  };

  const renderTotalAmountContainer = () => {
    const creditPackSelected = getSelectedCreditPack();
    const qtdCredits = numberOfCredits;
    let priceCreditPack = (creditPackSelected?.price ?? []).find(item => item?.currency_code === currency.code);
    // If no credit pack has been found for the currency code, it should default to USD
    if (!priceCreditPack) {
      currency = teamUtils.getDefaultCurrency();
      priceCreditPack = (creditPackSelected?.price ?? []).find(item => item?.currency_code === currency.code);
    }
    const totalAmount =
      isNumber(qtdCredits) && isNumber(priceCreditPack?.value) ? qtdCredits * (priceCreditPack?.value ?? 0) : undefined;

    // Checks if the discount is a percentage of the total amount
    let totalDiscount = 0;
    const discountType = coupon.discount_type || 1;
    if (discountType === 1) {
      totalDiscount = coupon.discount_value || 0;
    } else if (isNumber(totalAmount) && isNumber(coupon?.discount_value)) {
      totalDiscount = (totalAmount * coupon.discount_value) / 100;
    }
    // Subtotal amount is equal to numbers of credits * price per credit
    const subtotal = totalAmount;

    const preTaxTotal = isNumber(subtotal) ? Math.max(subtotal - totalDiscount, 0) : undefined;

    // Calculate taxes
    const taxByCountry = teamUtils.getTax();
    const totalTaxes =
      isNumber(preTaxTotal) && isNumber(taxByCountry?.tax_rate)
        ? (preTaxTotal * (taxByCountry?.tax_rate ?? 0)) / 100
        : undefined;

    // Total Price is equal subtotal + taxes
    const totalPrice = isNumber(preTaxTotal) && isNumber(totalTaxes) ? preTaxTotal + totalTaxes : undefined;

    const taxDisplay = (() => {
      if (taxByCountry?.tax_label) {
        if (taxByCountry?.tax_rate) {
          return 'show_amount';
        }
        return 'show_label';
      }
      return 'hide';
    })();

    return isNumber(totalPrice) && priceCreditPack ? (
      <div className="totalAmountContainer">
        <div className="totalContainer">
          <p>Total</p>
          <h2 id="__totalToPay" className="total">
            {currency.symbol}
            {utils.formatMoney(totalPrice, 2)} {priceCreditPack.currency_code}
            {taxDisplay === 'show_label' ? (
              <em className="font-normal not-italic">&nbsp;+&nbsp;{taxByCountry?.tax_label}</em>
            ) : null}
          </h2>
        </div>
        <div className="breakdownSubtitleContainer">
          <div className="child">
            <span>Credits</span>
            <span id="__totalCredits">{utils.numberWithCommas(qtdCredits)}</span>
          </div>
          <div className="child pbottom15">
            <span>Cost per credit</span>
            <span>
              {currency.symbol}
              {utils.formatMoney(priceCreditPack?.value ?? undefined, 2)} {priceCreditPack.currency_code}
            </span>
          </div>

          <div className="child subtotalContainer" />
          {taxDisplay === 'show_amount' && (
            <div className="child">
              <span>Subtotal</span>
              <span>
                {currency.symbol}
                {utils.formatMoney(subtotal, 2)} {priceCreditPack.currency_code}
              </span>
            </div>
          )}
          {coupon?.code && (
            <div className="child">
              <span>
                Discount <span className="discountCode">( {coupon.code} )</span>
              </span>
              <span className="discountCode"> - {renderDiscountPrice()}</span>
            </div>
          )}
          {taxDisplay === 'show_amount' && (
            <div className="child">
              <span>{taxByCountry?.tax_label}</span>
              <span>
                {currency.symbol}
                {utils.formatMoney(totalTaxes, 2)} {priceCreditPack.currency_code}
              </span>
            </div>
          )}
        </div>
        <div className="couponCodeContainer">
          <h4>Coupon</h4>
          <div className="couponCodeContentContainer">
            <Input
              placeholder="Coupon code"
              value={(couponCode as string) ?? ''}
              onChange={(e: any) => setCouponCode(e.target.value)}
            />
            {loadingTotalAmountContainer ? (
              <Spinner fadeIn="none" name="ball-clip-rotate" color="#FF5266" className="loadingSpinner mleft15" />
            ) : (
              <a
                onClick={() => onApplyDiscount({ coupon_code: couponCode, totalPrice })}
                className={`${couponCode ? 'enabled' : 'disabled'} mleft15`}
              >
                Apply
              </a>
            )}
          </div>
          {errorCouponCode ? <div className="text-xs text-destructive">{errorCouponCode}</div> : null}
        </div>
      </div>
    ) : null;
  };

  const renderCreditCardFields = () => {
    if (teamByIdData?.settings?.billing?.stripe?.customer_id) {
      const { payment } = teamByIdData.settings.billing.stripe;
      if (payment) {
        return (
          <div className="creditCardComponent">
            <div className="creditCard">
              <CreditCard className="h-5 w-5" />
              <p>**** **** **** {payment.last4}</p>
            </div>
            <a onClick={() => setOpenModalCreditCard(true)}>Edit card</a>
            <a className="removeCard" onClick={() => setOpenModalRemoveCard(true)}>
              Remove card
            </a>
          </div>
        );
      }
    }
    return (
      <VStack spacing={3}>
        <Box p="10px" pl="16px" h="40px" border="1px" borderColor="gray.500" borderRadius={8} w="full">
          <CardElement
            id="__my_card"
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
                  color: 'rgb(68, 68, 68)',
                  '::placeholder': {
                    color: '#718096',
                  },
                },
              },
            }}
            onChange={(value: any) => setCreditCardCompletedStatus(value.complete)}
          />
        </Box>
      </VStack>
    );
  };

  const renderInvoiceFields = () => {
    const taxesForCountry = teamUtils.getTax();
    return (
      <VStack spacing={3}>
        <ChakraInput
          id="__invoiceNumber"
          placeholder={`${taxesForCountry?.tax_number_label} ${paymentMethodSelected === 1 ? '' : '*'}`}
          type="text"
          value={team.settings.billing.registration}
          onChange={value => {
            // Transform what's coming from the input to get rid of blank spaces
            // This is specially useful for ABN fields because people usually copy and past from places where blank spaces are added to the ABN
            const transformedValue = value.target.value.replace(/\s/g, '');
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  registration: transformedValue,
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          id="__purchaseOrderNumber"
          placeholder="Purchase order (optional)"
          type="text"
          value={purchaseOrder}
          onChange={value => setPurchaseOrder(value.target.value)}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          id="__referenceEmail"
          placeholder="Reference (optional)"
          type="email"
          value={reference}
          onChange={value => setReference(value.target.value)}
        />
      </VStack>
    );
  };

  const renderStates = (selectedCountryState: (string | null)[]) => {
    if (!selectedCountryState.length) return null;
    return selectedCountryState?.map(item => {
      if (!item) return null;
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderBillingInfo = () => {
    const { location, company_name } = team.settings.billing;
    const selectedCountry = countryList?.find(item => item?.country_code === location?.country);
    const selectedCountryStates = selectedCountry?.states;

    return (
      <VStack spacing={3}>
        <ChakraInput
          id="__companyName"
          placeholder="Company Name *"
          type="text"
          value={company_name}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  company_name: value.target.value,
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          id="__streetAddress"
          placeholder="Street Address *"
          type="text"
          value={location?.street1 || ''}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  location: {
                    ...team.settings.billing.location,
                    street1: value.target.value,
                  },
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          id="__streetLevel"
          placeholder="Level / Floor / Office etc"
          type="text"
          value={location?.level || ''}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  location: {
                    ...team.settings.billing.location,
                    level: value.target.value,
                  },
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <HStack spacing={3}>
          <ChakraInput
            id="__city"
            placeholder="City *"
            type="text"
            value={location?.city || ''}
            onChange={value => {
              setTeam({
                settings: {
                  billing: {
                    ...team.settings.billing,
                    location: {
                      ...team.settings.billing.location,
                      city: value.target.value,
                    },
                  },
                },
              });
            }}
            onBlur={onUpdateTeam}
          />
          <ChakraInput
            id="__postalCode"
            placeholder="Postal code *"
            type="text"
            value={location?.postal_code || ''}
            onChange={value => {
              setTeam({
                settings: {
                  billing: {
                    ...team.settings.billing,
                    location: {
                      ...team.settings.billing.location,
                      postal_code: value.target.value,
                    },
                  },
                },
              });
            }}
            onBlur={onUpdateTeam}
          />
        </HStack>
        <HStack spacing={3} alignItems="flex-start">
          <FormControl>
            <Select
              id="country"
              value={location?.country || localStorage.get('countryByIp') || ''}
              onChange={async event => {
                onOpen();
                props.onUpdateStripeKey();
                const teamBillingWithCountry = {
                  settings: {
                    ...team.settings,
                    billing: {
                      ...team.settings.billing,
                      location: {
                        ...team.settings.billing.location,
                        country: event.target.value,
                      },
                    },
                  },
                };
                await updateTeam({
                  variables: {
                    _id: team_id,
                    team: teamBillingWithCountry,
                  },
                });
                if (countryList) {
                  onUpdateCurrency(event.target.value);
                }
                setTeam(teamBillingWithCountry);
                onClose();
                window.location.reload();
              }}
              placeholder="Select country"
              borderColor="gray.500"
              disabled={_.size(teamByIdData?.settings?.billing?.xero_id) > 0}
            >
              {countryList?.map(item => {
                if (!item) return null;
                return (
                  <option key={item._id} value={item.country_code!}>
                    {item.name}
                  </option>
                );
              })}
            </Select>
            {_.size(teamByIdData?.settings?.billing?.xero_id) > 0 && (
              <FormHelperText>Contact us to update</FormHelperText>
            )}
          </FormControl>
          {selectedCountryStates?.length ? (
            <Select
              value={location?.state}
              onBlur={onUpdateTeam}
              onChange={event => {
                setTeam({
                  settings: {
                    billing: {
                      ...team.settings.billing,
                      location: {
                        ...team.settings.billing.location,
                        state: event.target.value,
                      },
                    },
                  },
                });
              }}
              placeholder="State/Province *"
              borderColor="gray.500"
            >
              {renderStates(selectedCountryStates)}
            </Select>
          ) : (
            <ChakraInput
              name="state"
              placeholder="State/Province"
              type="text"
              value={location?.state || ''}
              onChange={event => {
                setTeam({
                  settings: {
                    billing: {
                      ...team.settings.billing,
                      location: {
                        ...team.settings.billing.location,
                        state: event.target.value,
                      },
                    },
                  },
                });
              }}
              onBlur={onUpdateTeam}
            />
          )}
        </HStack>
      </VStack>
    );
  };

  const renderBillingContact = () => {
    const { contact_first_name, contact_last_name, contact_email } = team.settings.billing;
    return (
      <VStack spacing={3}>
        <ChakraInput
          placeholder="Billing Contact First Name"
          type="text"
          value={contact_first_name || ''}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  contact_first_name: value.target.value,
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          placeholder="Billing Contact Last Name"
          type="text"
          value={contact_last_name || ''}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  contact_last_name: value.target.value,
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
        <ChakraInput
          placeholder="Billing Email"
          type="email"
          value={contact_email || ''}
          onChange={value => {
            setTeam({
              settings: {
                billing: {
                  ...team.settings.billing,
                  contact_email: value.target.value,
                },
              },
            });
          }}
          onBlur={onUpdateTeam}
        />
      </VStack>
    );
  };

  const renderTeamInfoContainer = () => {
    return (
      <Box mb={40}>
        {errorMessage && (
          <BoxMessage type="error mbottom20" error={errorMessage}>
            <span className="errorMessage">{_.get(errorMessage, 'message')}</span>
          </BoxMessage>
        )}
        {paymentMethodOptions.length > 1 && (
          <div className="teamBlock">
            <FormLabel>Payment method</FormLabel>
            <RadioGroup
              defaultValue={paymentMethodSelected.toString(10)}
              onValueChange={value => {
                setPaymentMethodSelected(parseInt(value, 10));
              }}
            >
              {paymentMethodOptions.map(option => (
                <div className="flex items-center space-x-2" key={option.value}>
                  <RadioGroupItem value={option.value.toString(10)} id={`paymentMethodGroup_${option.value}`} />
                  <Label htmlFor={`paymentMethodGroup_${option.value}`}>{option.label}</Label>
                </div>
              ))}
            </RadioGroup>
          </div>
        )}
        {paymentMethodSelected === 1 && (
          <div className="teamBlock creditCardContainer">
            <FormLabel>Credit Card</FormLabel>
            {renderCreditCardFields()}
          </div>
        )}
        <div className="teamBlock invoiceDetailsContainer">
          <FormLabel>{`${paymentMethodSelected === 1 ? 'Payment Details' : 'Invoice Details'}`}</FormLabel>
          {renderInvoiceFields()}
        </div>
        <div className="teamBlock billingInfoContainer">
          <FormLabel>Billing Info</FormLabel>
          {renderBillingInfo()}
        </div>
        <div className="teamBlock billingContactContainer">
          <FormLabel>Billing Contact</FormLabel>
          {renderBillingContact()}
        </div>
        <VStack mt={4} spacing={4} alignItems="flex-start">
          <ChakraButton
            colorScheme="brand"
            isLoading={purchasing}
            w="full"
            onClick={() => {
              onRegisterPayment();
            }}
            disabled={!validateFields() || purchasing}
          >
            Pay now
          </ChakraButton>
          {paymentMethodSelected === 1 && (
            <img alt="Powered By Stripe" src="/powered_by_stripe.svg" className="logo_stripe" />
          )}
        </VStack>
      </Box>
    );
  };

  const renderCreditPackStep = (value: any, className: any, savingsToRender: any, index: any) => {
    const isSavingActive = savingsToRender === savings;
    return (
      <Fragment key={index}>
        <div className={`step t${className}`} />
        <p className={`stepValue t${className}`}>{value}</p>
        <div className={`savingContainer t${className} s${className} ${isSavingActive ? 'active' : ''}`}>
          <p>Save {savingsToRender}%</p>
        </div>
      </Fragment>
    );
  };

  const onCloseModal = () => {
    sessionStorage.removeItem('creditsToBuy');
    if (creditsByParams) {
      props.onClose({ shouldGoBack: false, redirect: '/' });
    } else {
      props.onClose();
    }
  };

  const onConfirmCreditCardDeletion = async () => {
    setLoadingRemoveCreditCard(true);
    try {
      await deleteTeamCreditCard({
        variables: {
          _id: props.team_id,
        },
        refetchQueries: [
          {
            query: fetchTeamPaymentDetails,
            variables: {
              _id: props.team_id,
            },
          },
        ],
      });
      setOpenModalRemoveCard(false);
    } catch (e) {
      setErrorMessage(_.get(e, 'graphQLErrors[0]'));
    } finally {
      setLoadingRemoveCreditCard(false);
    }
  };

  const bulkCreditPacks = (creditPacks ?? []).filter(creditPack => creditPack?.type === CREDITS_TYPE.CREDIT_PACK);
  const bulkCreditPacksOrdered = utils.sortArray(bulkCreditPacks, 'quantity');
  const adHocCreditPack = (creditPacks ?? []).find(creditPack => creditPack?.type === CREDITS_TYPE.ADHOC);
  const adHocCreditPackPrice =
    (adHocCreditPack?.price ?? []).find(price => price?.currency_code === currency.code) ?? undefined;
  const adHocPrice = adHocCreditPackPrice?.value || undefined;

  if (creditPacksResult.loading || resultCountryList.loading || !bulkCreditPacks || !adHocPrice) {
    return <LoadingOverlay style={{ opacity: 0.8 }} />;
  }

  if (teamByIdResults.loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;
  if (teamByIdResults.error) return `Error ${teamByIdResults.error.message}`;
  let discountMark = 0;
  return (
    <>
      <div className={`buyCreditsComponent ${isDisplayingHeaderAds ? 'headerAds' : ''}`}>
        <div className="titleComponent">
          <h1>Buy prepaid credits</h1>
          <a
            href="https://help.askable.com/en/articles/4254373-ways-of-buying-credits"
            target="blank"
            className="mleft15"
          >
            (What&apos;s a credit?)
          </a>
        </div>
        <X className="closeIcon" onClick={onCloseModal} style={{ width: 32, height: 32 }} />
        <div className="creditsContainer">
          <div className="manualInputContainer">
            <div className="inputContainer">
              <ChakraInput
                fontSize={18}
                name="numberOfCredits"
                type="number"
                w="120px"
                value={numberOfCredits}
                max={maxCreditPackSize}
                onChange={value => {
                  const newNumberOfCredits = parseInt(value.target.value);
                  if (newNumberOfCredits <= maxCreditPackSize) {
                    setNumberOfCredits(newNumberOfCredits);

                    // Check whether the savings are different to what we had. If so, it should set to the new one
                    const closesValueInArray = creditUtils.findClosest(
                      creditPacks,
                      stepsArray,
                      newNumberOfCredits,
                      'value',
                    );
                    if (_.get(closesValueInArray, 'savings') !== savings) {
                      setSavings(_.get(closesValueInArray, 'savings'));
                    }
                    // Find the index of the value step and set it to update the slider
                    if (_.has(closesValueInArray, 'index')) setValueStep(closesValueInArray.index);
                  }
                }}
              />
              <h2>Credits</h2>
            </div>
            <div className="descriptionsContainer">
              <p>- Incentives included</p>
              <p>- Valid for 1 year</p>
            </div>
          </div>
          <div className="sliderComponent">
            <Slider
              min={0}
              max={MAX_STEP}
              startValue={valueStep}
              // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
              onUpdateValue={([index]) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'never'.
                debouncedUpdateCredits(stepsArray[index].value);
              }}
              railCustomStyles={{ backgroundColor: '#D4D4D4' }}
            />
            <div className="ticks">
              {_.map(bulkCreditPacksOrdered, (bulkCredits: any, index: any) => {
                discountMark += 1;
                const bulkCreditPackPrice =
                  _.find(
                    _.get(bulkCredits, 'price'),
                    (price: any) => price.currency_code === (currency.code || localStorage.get('currency.code')),
                  ) || _.find(_.get(bulkCredits, 'price'), (price: any) => price.currency_code === 'USD');
                return adHocPrice
                  ? renderCreditPackStep(
                      utils.formatMoney(bulkCredits.quantity),
                      discountMark,
                      Math.round(((adHocPrice - bulkCreditPackPrice.value) / adHocPrice) * 100),
                      index,
                    )
                  : null;
              })}
            </div>
          </div>
        </div>
        <div className="paymentContainer">
          <div className="leftContainer">{renderTeamInfoContainer()}</div>
          <div className="rightContainer">
            <div className={`campaignImageContainer ${coupon?.campaign_image ? 'withImage' : ''}`}>
              <img
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'campaign_image' does not exist on type '... Remove this comment to see the full error message
                src={coupon.campaign_image}
                className="campaignImage"
                alt="Promotional"
              />
            </div>
            {renderTotalAmountContainer()}
            <a href="https://www.askable.com/legal?tab=terms" target="blank">
              See credits terms & conditions
            </a>
          </div>
        </div>
      </div>
      <NewCreditCard
        client={props.client}
        onClose={() => setOpenModalCreditCard(false)}
        open={openModalCreditCard}
        team_id={_.get(props, 'team_id')}
      />
      <ModalPaymentStatus
        client={props.client}
        open={paymentModal.open}
        step={paymentModal.step}
        payment_method={paymentMethodSelected}
      />
      <AlertDialog
        isOpen={openModalRemoveCard}
        leastDestructiveRef={cancelButtonRef}
        onClose={() => setOpenModalRemoveCard(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Are you sure?
            </AlertDialogHeader>
            <AlertDialogBody>
              This credit card will be removed for you and your whole team. This can’t be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <ChakraButton
                colorScheme="gray"
                variant="outline"
                ref={cancelButtonRef}
                onClick={() => setOpenModalRemoveCard(false)}
                disabled={loadingRemoveCreditCard}
              >
                Cancel
              </ChakraButton>
              <ChakraButton isLoading={loadingRemoveCreditCard} onClick={onConfirmCreditCardDeletion} ml={3}>
                Remove
              </ChakraButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <UpdateBillingCountryComponent isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
}

export default deprecatedWithRouter(BuyCredits);

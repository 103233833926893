import { useFeatureFlags } from 'feature-flags';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { PathAnalysis } from 'containers/Studies/Results/components/Task/FigmaPrototype/PathAnalysis';
import { ScreenAnalysis } from 'containers/Studies/Results/components/Task/FigmaPrototype/ScreenAnalysis';
import { Stats } from 'containers/Studies/Results/components/Task/FigmaPrototype/Stats';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { FigmaPrototypeResultStats } from 'containers/Studies/Results/data/FigmaPrototypeResultStats.query';
import { BookingTaskResultDetailsType } from 'generated/graphql';

interface FigmaPrototypeResultsProps {
  taskId: string;
  title: string;
}

export const FigmaPrototypeResults = ({ title, taskId }: FigmaPrototypeResultsProps) => {
  const { UNMODERATED_STUDY_DEV } = useFeatureFlags(['UNMODERATED_STUDY_DEV']);
  const params = useParams();
  const { studyId } = params;

  const [{ data, fetching }] = useQuery({
    query: FigmaPrototypeResultStats,
    variables: {
      _booking_id: studyId ?? '',
      _task_id: taskId,
    },
    pause: !studyId || !taskId,
  });

  return (
    <>
      <TaskHeader
        title={title}
        type={BookingTaskResultDetailsType.FigmaPrototype}
        responses={data?.figmaPrototypeResultStats.total_responses ?? 0}
      />

      {UNMODERATED_STUDY_DEV ? (
        <>
          <Stats statsData={data?.figmaPrototypeResultStats ?? null} isLoading={fetching} />
          <PathAnalysis taskId={taskId} />
          <ScreenAnalysis taskId={taskId} />
        </>
      ) : null}
    </>
  );
};

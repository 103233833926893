import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { ThumbsDown, ThumbsUp } from 'lucide-react';
import { useMutation } from 'urql';

import { RateParticipantDocument } from 'generated/graphql';
import { handleRequest } from 'network/handleRequest';

import type {
  SubmissionStatus,
  Submission,
  RateParticipantMutation,
  RateParticipantMutationVariables,
} from 'generated/graphql';
import type { MouseEventHandler } from 'react';

const enum ThumbsType {
  UP = 1,
  DOWN = 0,
}

const ThumbsIcon = ({ thumbsType, className }: { thumbsType: ThumbsType; className?: string }) => {
  const icon =
    thumbsType === ThumbsType.UP
      ? { Icon: ThumbsUp, dataTestId: 'thumbs-up-icon' }
      : { Icon: ThumbsDown, dataTestId: 'thumbs-down-icon' };
  return <icon.Icon data-testid={icon.dataTestId} className={cn('h-4 w-4', className)} />;
};

const getThumbsIconButtonLabel = (thumbsType: ThumbsType) =>
  `Rate as ${thumbsType === ThumbsType.UP ? 'good' : 'bad'} participant`;

const ThumbsIconButton = ({
  onClick,
  thumbsType,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  thumbsType: ThumbsType;
}) => {
  return (
    <Button
      aria-label={getThumbsIconButtonLabel(thumbsType)}
      onClick={onClick}
      size="icon_rounded_full"
      variant="outline"
    >
      <ThumbsIcon thumbsType={thumbsType} className="h-4 w-4" />
    </Button>
  );
};

type Props = {
  submissionId: string;
  status: SubmissionStatus;
  rating: Submission['rating'];
  firstName: string;
};

const RateParticipantPopoverContent = ({ submissionId }: { submissionId: string; firstName: string }) => {
  const [, rateParticipant] = useMutation<RateParticipantMutation, RateParticipantMutationVariables>(
    RateParticipantDocument,
  );

  const onClick =
    (thumbsType: ThumbsType): MouseEventHandler<HTMLButtonElement> =>
    async e => {
      e.stopPropagation();
      const [, error] = await handleRequest(() => {
        return rateParticipant({
          input: {
            _submission_id: submissionId,
            overall: thumbsType,
          },
        });
      });

      if (error) {
        toast.error('Failed to rate participant. Please contact support.');
      }
    };

  return (
    <div className="flex items-center space-x-3">
      {[ThumbsType.DOWN, ThumbsType.UP].map(thumbValue => (
        <Tooltip key={thumbValue}>
          <TooltipTrigger asChild>
            <ThumbsIconButton key={thumbValue} thumbsType={thumbValue} onClick={onClick(thumbValue)} />
          </TooltipTrigger>
          <TooltipContent>{getThumbsIconButtonLabel(thumbValue)}</TooltipContent>
        </Tooltip>
      ))}
    </div>
  );
};

export const BookingParticipantRating = ({ rating, submissionId, firstName }: Props) => {
  const overall = rating?.overall;

  if (typeof overall !== 'number') {
    return <RateParticipantPopoverContent firstName={firstName} submissionId={submissionId} />;
  }

  const selectedThumbsType = overall === ThumbsType.UP ? ThumbsType.UP : ThumbsType.DOWN;

  return (
    <div className="flex space-x-3">
      <div className="flex h-8 w-8 content-center items-center justify-center border-none bg-transparent hover:bg-transparent">
        <ThumbsIcon thumbsType={selectedThumbsType} className="h-4 w-4" />
      </div>
    </div>
  );
};

/* eslint-disable react/display-name */
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../lib/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-sm border px-1 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 py-[2px] px-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground',
        destructive: 'border-transparent bg-destructive text-destructive-foreground',
        outline: 'text-foreground',
        secondary: 'border-transparent bg-secondary text-secondary-foreground',
        warning: 'border-transparent bg-warning text-warning-foreground',
      },
      size: {
        default: '',
        small: 'text-xs py-0.5 px-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

const Badge = React.forwardRef(({ className, variant, ...props }: BadgeProps, ref: React.LegacyRef<HTMLDivElement>) => {
  return <div ref={ref} className={cn(badgeVariants({ variant }), className)} {...props} />;
});

export { Badge, badgeVariants };

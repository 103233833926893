import { createLucideIcon } from 'lucide-react';

import { cn } from '../lib/utils';

import type { LucideProps } from 'lucide-react';

const InReviewStatusComp = createLucideIcon('InReviewStatus', [
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '10',
      stroke: 'currentColor',
      strokeWidth: '2px',
    },
  ],
  [
    'path',
    {
      fill: 'currentColor',
      stroke: 'currentColor',
      d: 'M12 7.25c0-.69.566-1.263 1.241-1.12a6.001 6.001 0 0 1 0 11.74c-.675.143-1.241-.43-1.241-1.12v-9.5Z',
    },
  ],
]);

export const InReviewStatus = ({ className, ...props }: LucideProps) => {
  return <InReviewStatusComp className={cn('text-warning', className)} {...props} />;
};

import { graphql } from 'src/graphql';

export const FigmaPrototypeResultPaths = graphql(`
  query FigmaPrototypeResultPaths($_booking_id: ID!, $_task_id: ID!, $has_results: Boolean = true) {
    figmaPrototypeResultPaths(_booking_id: $_booking_id, _task_id: $_task_id) {
      _id
      result_status
      stats {
        average_duration
        completed_count
        misclick_rate
        skipped_count
        total_responses
      }
      screens {
        id
        image {
          height
          name
          src
          width
        }
        started
        ended
      }
      results @include(if: $has_results) {
        _booking_id
        _id
        _task_id
        _user_id
        created
        details {
          _path_id
          path {
            _id
            node_id
            started
            ended
            clicks
            misclicks
            image {
              height
              name
              src
              width
            }
          }
        }
        status
        task_ended
        task_loaded
        task_started
        updated
        user {
          _id
          displayName
          picture
        }
        video {
          _id
          code
          rooms {
            _id
            startedAt
            composition {
              duration
              status
            }
          }
        }
      }
    }
  }
`);

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { LoadingState } from 'containers/Studies/Results/components/LoadingState';
import { RecordingNavItem } from 'containers/Studies/Results/components/Recording/RecordingNavItem';
import { RecordingPlayer } from 'containers/Studies/Results/components/Recording/RecordingPlayer';
import { BookingTaskResultStatus } from 'generated/graphql';

import type { Chapter } from '@askable/ui/core/video-player';
import type { AskableVideo, BookingTaskResultFigmaPrototype, FigmaPrototypeScreen } from 'generated/graphql';

interface RecordingContentProps {
  results?: BookingTaskResultFigmaPrototype[];
  screens: FigmaPrototypeScreen[];
  showRelatedResults?: boolean;
  title: string;
}

// Get the duration of the latest video
const getVideoDuration = (video: AskableVideo | null) => {
  return video?.rooms?.at(-1)?.composition?.duration ?? 0;
};

export const RecordingContent = ({ results, screens, showRelatedResults = true, title }: RecordingContentProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const nodeId = searchParams.get('node_id');
  const resultId = searchParams.get('result_id');

  const activeResult = results?.find(r => r._id === resultId) ?? results?.at(0);

  const room = activeResult?.video?.rooms?.at(-1);
  const showVideo =
    room &&
    activeResult?.status &&
    [BookingTaskResultStatus.Completed, BookingTaskResultStatus.Skipped].includes(activeResult?.status);

  // Mark where the user changed screens in the video
  const chapters: Chapter[] = useMemo(() => {
    if (!activeResult?.task_started || screens.length === 0) {
      return [];
    }

    return screens.map((screen, index: number) => ({
      label: screen.image?.name ?? `${t('sections.studies.results.screen.count', { count: index + 1 })}`,
      node_id: screen.id,
      timestamp: Math.max((screen.started ?? 0) - (activeResult.task_started ?? 0), 0) / 1000,
    }));
  }, [screens, activeResult?.task_started, t]);

  // If nodeId is in chapters then set the current position of the video to that chapter
  const seekTo = useMemo(() => {
    return chapters.find(chapter => chapter.node_id === nodeId)?.timestamp ?? 0;
  }, [chapters, nodeId]);

  return (
    <div className="flex flex-col md:flex-row">
      <div className="order-1 aspect-video max-h-[75vh] w-full p-4 md:order-3">
        {match([showVideo, room?.composition?.status, room?._id, activeResult?.video?.code])
          .with([false, P._, P._, P._], () => <EmptyState title={t('sections.studies.results.video.notCompleted')} />)
          .with([true, P.when(status => ['processing', 'enqueued'].includes(status || '')), P._, P._], () => (
            <LoadingState title={t('sections.studies.results.video.processing')} />
          ))
          .with([true, P.when(status => status === 'completed'), P.string, P.string], () => (
            <RecordingPlayer
              id={room?._id ?? ''}
              code={activeResult?.video?.code ?? ''}
              title={title}
              chapters={chapters}
              seekTo={seekTo}
            />
          ))
          .otherwise(() => (
            <EmptyState title={t('sections.studies.results.video.notFound')} />
          ))}
      </div>

      {/* Related results sidebar for PATH and SCREEN */}
      {showRelatedResults && results && results.length > 0 ? (
        <nav
          className="sticky top-0 order-2 max-h-[calc(100vh-12rem)] min-w-56 overflow-auto border-r-0.5 border-border p-4 pt-0 md:order-1
            md:pt-4"
        >
          <ol className="flex flex-col gap-2 p-[1px]">
            {results?.map(item => (
              <RecordingNavItem
                key={item._id}
                duration={getVideoDuration(item.video ?? null)}
                isActive={item._id === activeResult?._id}
                resultId={item._id}
                title={item.user?.displayName ?? item.user?._id ?? ''}
              />
            ))}
          </ol>
        </nav>
      ) : null}
    </div>
  );
};

import { globalVariables } from 'lib/globalVariables';

import type { Maybe } from 'generated/graphql';

const envVariables = globalVariables.getEnvironmentVariables();

export const getParticipantAskableSessionAuthLink = (roomCode: string) => {
  return `${envVariables.participantsAppURI}/askable-sessions/${roomCode}`;
};

export const getParticipantOpportunityLink = (bookingId: string) => {
  return `${envVariables.participantsAppURI}/booking/${bookingId}`;
};

export const getSessionUrl = (code: string) => {
  return `${envVariables.sessionsAppURI}/${code}`;
};

type GetUnmodUrlArgs = {
  studyId: string;
  mode?: 'demo' | 'preview';
};

export const getUnmodUrl = ({ studyId, mode }: GetUnmodUrlArgs) => {
  return mode
    ? `${envVariables.sessionsAppURI}/unmod/${studyId}?mode=${mode}`
    : `${envVariables.sessionsAppURI}/unmod/${studyId}`;
};

type Args = {
  roomCode: string;
  playback?: boolean;
  roomId?: string;
  teamId?: Maybe<string>;
};

export const getClientAskableSessionAuthLink = ({ roomCode, playback, roomId, teamId }: Args) => {
  let url = `${window.location.origin}/askable-sessions/${!playback ? `rooms/${roomCode}` : roomCode}`;
  if (playback || roomId) {
    url += '/playback';
    if (roomId) {
      url += `/${roomId}`;
    }
  }
  const params = new URLSearchParams();
  if (teamId) {
    params.set('teamId', teamId);
  }
  if (params.toString()) {
    url += `?${params.toString()}`;
  }
  return url;
};

import { Button } from '@askable/ui/core/button';
import { FormControl, FormField, FormItem } from '@askable/ui/core/form';
import { Label } from '@askable/ui/core/label';
import { Switch } from '@askable/ui/core/switch';
import { cn } from '@askable/ui/lib/utils';
import { Radio, Checkbox } from '@askable/ui/unmod/icons';
import { Plus } from 'lucide-react';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { SortableList } from 'containers/Studies/components/SortableList';
import { getObjectId } from 'containers/Studies/utils/utils';

import { MultipleChoiceQuestionOption } from './MultipleChoiceQuestionOption';

import type { BlockFormFields } from 'containers/Studies/BuildStudy/containers/BlockForm';

interface MultipleChoiceQuestionOptionProps {
  _id: string;
  value: string;
}

export const MultipleChoiceQuestionField = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { control, setValue, clearErrors } = useFormContext<BlockFormFields>();
  const {
    field,
    fieldState: { error },
  } = useController({ name: 'multiple_choice_question.options' });

  const addOption = () => {
    const newOption: MultipleChoiceQuestionOptionProps = {
      _id: getObjectId(),
      value: '',
    };

    setValue('multiple_choice_question.options', field.value ? [...field.value, newOption] : [newOption], {
      shouldValidate: false,
    });
  };

  const handleRemove = (index: number) => {
    if (field.value.length === 1) {
      return;
    }
    const updatedList = field.value.filter((_: MultipleChoiceQuestionOptionProps, i: number) => i !== index);
    field.onChange(updatedList);
  };

  const handleOptionChange = (optionIndex: number, value: string) => {
    const updatedList = field.value.map((option: MultipleChoiceQuestionOptionProps, i: number) => {
      if (i === optionIndex) {
        return { ...option, value };
      }
      return option;
    });

    field.onChange(updatedList);
  };

  const handleSortChange = (updatedList: MultipleChoiceQuestionOptionProps[]) => {
    field.onChange(updatedList);
    clearErrors('multiple_choice_question');
  };

  return (
    <>
      <FormField
        control={control}
        name="multiple_choice_question.is_multiple_select"
        render={({ field }) => (
          <FormItem>
            <div className="flex flex-col gap-2">
              <Label>{t('sections.studies.build.formFields.multipleChoiceQuestion.isMultipleSelectTitle')}</Label>
              <FormControl>
                <div className="grid grid-cols-2 gap-2">
                  {[
                    {
                      value: false,
                      label: t('sections.studies.build.formFields.multipleChoiceQuestion.single'),
                      icon: <Radio size={24} />,
                    },
                    {
                      value: true,
                      label: t('sections.studies.build.formFields.multipleChoiceQuestion.multiple'),
                      icon: <Checkbox size={24} />,
                    },
                  ].map(option => (
                    <button
                      type="button"
                      disabled={isDisabled}
                      key={String(option.value)}
                      className={cn(
                        `flex w-full flex-1 cursor-pointer flex-col items-center justify-between gap-1 whitespace-nowrap rounded-lg border
                        border-border p-4 hover:border-input focus:border-input`,
                        {
                          'cursor-not-allowed opacity-50 hover:border-border': isDisabled,
                          'border-primary ring-0.5 ring-primary hover:border-primary disabled:border-primary':
                            field.value === option.value,
                        },
                      )}
                      onClick={() => {
                        if (!isDisabled) {
                          field.onChange(option.value);
                        }
                      }}
                    >
                      {option.icon}
                      {option.label}
                    </button>
                  ))}
                </div>
              </FormControl>
            </div>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="multiple_choice_question.options"
        render={({ field }) => (
          <FormItem>
            <div className="relative flex flex-col gap-2">
              <Label htmlFor="input_has_other_option">{t('sections.studies.answers', { count: 2 })}</Label>
              <FormControl>
                <SortableList
                  onChange={handleSortChange}
                  containerClass="gap-1"
                  items={field.value}
                  renderItem={(option, i) => (
                    <MultipleChoiceQuestionOption
                      id={option._id}
                      index={i}
                      isDisabled={isDisabled}
                      isMovable={field.value.length > 1}
                      key={option._id}
                      value={option.value}
                      onChangeValue={handleOptionChange}
                      onRemove={handleRemove}
                    />
                  )}
                />
              </FormControl>

              <Button variant="secondary" onClick={addOption} disabled={isDisabled}>
                <Plus className="h-4 w-4" />
                {t('sections.studies.build.formFields.multipleChoiceQuestion.addAnswer')}
              </Button>
            </div>

            {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="multiple_choice_question.has_other_option"
        render={({ field }) => (
          <FormItem>
            <div className="relative flex items-center justify-between gap-2">
              <Label htmlFor="input_has_other_option">
                {t('sections.studies.build.formFields.multipleChoiceQuestion.hasOtherOptionTitle')}
              </Label>
              <FormControl>
                <Switch
                  aria-describedby="has_other_option_description"
                  id="input_has_other_option"
                  checked={field.value ?? false}
                  disabled={isDisabled}
                  size="small"
                  onCheckedChange={checked => field.onChange(checked)}
                />
              </FormControl>
            </div>

            <div className="-mt-[4px] text-pretty text-xs text-muted-foreground" id="has_other_option_description">
              {t('sections.studies.build.formFields.multipleChoiceQuestion.hasOtherOptionDescription')}
            </div>
          </FormItem>
        )}
      />

      <FormField
        control={control}
        name="multiple_choice_question.is_randomised_order"
        render={({ field }) => (
          <FormItem>
            <div className="relative flex items-center justify-between gap-2">
              <Label htmlFor="input_is_randomised_order">
                {t('sections.studies.build.formFields.multipleChoiceQuestion.isRandomisedOrderTitle')}
              </Label>
              <FormControl>
                <Switch
                  aria-describedby="is_randomised_order_description"
                  id="input_is_randomised_order"
                  checked={field.value ?? false}
                  disabled={isDisabled}
                  size="small"
                  onCheckedChange={checked => field.onChange(checked)}
                />
              </FormControl>
            </div>

            <div className="-mt-[4px] text-pretty text-xs text-muted-foreground" id="is_randomised_order_description">
              {t('sections.studies.build.formFields.multipleChoiceQuestion.isRandomisedOrderDescription')}
            </div>
          </FormItem>
        )}
      />
    </>
  );
};

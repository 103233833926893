import { Skeleton } from '@askable/ui/core/skeleton';
import { cn } from '@askable/ui/lib/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { Section } from 'containers/Studies/Results/components/Section';
import { Tabs } from 'containers/Studies/Results/components/Tabs';
import { Path } from 'containers/Studies/Results/components/Task/FigmaPrototype/Path';
import { FigmaPrototypeResultPaths } from 'containers/Studies/Results/data/FigmaPrototypeResultPaths.query';
import { BookingTaskResultStatus } from 'generated/graphql';

import type {
  BookingTaskResultStatus as BookingTaskResultStatusType,
  FigmaPrototypePathResult,
} from 'generated/graphql';

interface PathAnalysisProps {
  taskId: string;
}

/**
 * A list of paths for a prototype task
 */
export const PathAnalysis = ({ taskId }: PathAnalysisProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { studyId } = params;

  // Fetch both completed paths and skipped paths separately
  const [{ data, fetching, error }] = useQuery({
    query: FigmaPrototypeResultPaths,
    variables: {
      _booking_id: studyId ?? '',
      _task_id: taskId,
      has_results: false,
    },
    pause: !studyId || !taskId,
  });

  // Create 2 lists based on the result_status
  const completed =
    data?.figmaPrototypeResultPaths.filter(p => p.result_status === BookingTaskResultStatus.Completed) || [];
  const skipped =
    data?.figmaPrototypeResultPaths.filter(p => p.result_status === BookingTaskResultStatus.Skipped) || [];

  const [activeTab, setActiveTab] = useState(
    completed.length === 0 && skipped.length > 0 ? BookingTaskResultStatus.Skipped : BookingTaskResultStatus.Completed,
  );

  const tabs = [];

  if (completed.length > 0) {
    tabs.push({
      label: `${t('sections.studies.results.reachedGoal')} (${completed.length})`,
      value: 'completed',
    });
  }

  if (skipped.length > 0) {
    tabs.push({
      label: `${t('sections.studies.results.reachedGoalFailed')} (${skipped.length})`,
      value: 'skipped',
    });
  }

  return (
    <Section title={t('sections.studies.results.taskAnalysisTitle')} id="task-analysis">
      {!fetching && data?.figmaPrototypeResultPaths ? (
        <>
          <Tabs
            value={activeTab}
            variant="underline"
            tabs={tabs}
            onValueChange={value => setActiveTab(value as BookingTaskResultStatusType)}
          />

          {completed.length > 0 ? (
            <ol className={cn('flex flex-col gap-6', { hidden: activeTab !== 'completed' })}>
              {completed.map((path, index) => (
                <li key={path._id}>
                  <Path id={path._id} index={index} path={path as FigmaPrototypePathResult} />
                </li>
              ))}
            </ol>
          ) : null}

          {skipped.length > 0 ? (
            <ol className={cn('flex flex-col gap-6', { hidden: activeTab !== 'skipped' })}>
              {skipped.map((path, index) => (
                <li key={path._id}>
                  <Path id={path._id} index={index} path={path as FigmaPrototypePathResult} />
                </li>
              ))}
            </ol>
          ) : null}
        </>
      ) : (
        <Skeleton className="h-[20rem]" />
      )}

      {!fetching && error && <div className="text-destructive">{error.message}</div>}
    </Section>
  );
};

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@askable/ui/core/tooltip';
import { useRef } from 'react';
import { useElementOverflow } from 'shared-utils/hooks';

export const CellCreatedBy = ({ displayName }: { displayName: string }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflowing = useElementOverflow(ref);

  const comp = (
    <div ref={ref} className="max-w-[136px] overflow-hidden text-ellipsis whitespace-nowrap text-muted-foreground">
      {displayName}
    </div>
  );

  if (!isOverflowing) {
    return comp;
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="cursor-text">{comp}</TooltipTrigger>
        <TooltipContent>
          <p>{displayName}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import { cn } from '../lib/utils';

type Props = {
  className?: string;
};

export const Logo = ({ className }: Props) => {
  return (
    <svg viewBox="0 0 365 88" className={cn('text-brand', className)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3941 0.00020373C64.4941 0.0471502 72.578 8.12849 72.5313 36.1314C72.5124 47.418 71.179 55.4595 67.7599 61.0398C60.318 76.7205 43.7693 84.2113 36.2505 86L36.2737 72.1418C8.17134 72.0948 0.0899412 64.0145 0.136676 36.0104C0.183382 8.00753 8.29184 -0.04674 36.3941 0.00020373ZM36.2464 29.9327C32.2427 29.9311 28.999 26.6972 29.0006 22.709C29.0023 18.7219 32.2486 15.4896 36.2523 15.4912C40.2549 15.4928 43.4997 18.7279 43.4979 22.7149C43.4965 26.7032 40.2489 29.9344 36.2464 29.9327ZM36.2443 34.897C42.2488 34.8994 51.9494 36.1429 51.9467 42.1241C51.9445 48.1063 45.4622 55.3597 36.2359 55.3557C27.0084 55.3518 20.5334 48.0938 20.5359 42.1111C20.5383 36.1301 30.2388 34.8945 36.2443 34.897Z"
        fill="currentColor"
      />
      <path
        d="M84.5973 51.0848C84.5973 37.648 94.2442 30.7266 103.1 30.7266C105.187 30.636 107.27 30.9826 109.215 31.7442C111.16 32.5058 112.924 33.6654 114.394 35.1486L115.485 31.6666H126.436V70.4817H116.019L114.394 66.6793C112.905 68.3046 111.068 69.5733 109.02 70.3907C106.972 71.2082 104.765 71.5533 102.565 71.4003C93.0036 71.4003 84.5973 63.4963 84.5973 51.0848ZM114.843 51.0848C114.843 44.6762 110.8 40.8951 105.667 40.8951C100.533 40.8951 96.4902 44.8471 96.4902 51.0848C96.4902 57.3226 100.469 61.2532 105.667 61.2532C110.864 61.2532 114.843 57.344 114.843 51.0848Z"
        fill="currentColor"
      />
      <path
        d="M132.661 61.1396L141.538 57.1021C142.071 58.6008 143.048 59.902 144.34 60.833C145.631 61.764 147.175 62.2807 148.768 62.3145C151.719 62.3145 153.046 60.8405 153.046 59.217C153.046 57.5935 151.719 56.2476 148.383 54.9445L143.548 53.2142C141.031 52.5406 138.815 51.0364 137.261 48.9459C135.708 46.8555 134.908 44.3018 134.992 41.7C134.992 35.2913 140.746 30.6771 148.361 30.6771C154.971 30.6771 159.056 33.2405 161.345 37.8975L153.046 42.5331C152.792 41.585 152.239 40.7442 151.468 40.1359C150.697 39.5276 149.75 39.1846 148.768 39.1579C147.056 39.1579 145.901 40.0978 145.901 41.5077C145.901 42.9176 146.671 43.6439 148.468 44.2848L153.751 46.2288C159.826 48.4931 163.783 52.1461 163.783 59.0461C163.783 65.9461 157.794 71.3934 148.318 71.3934C139.891 71.3934 134.693 67.2919 132.661 61.1396Z"
        fill="currentColor"
      />
      <path
        d="M169.218 14.9374H181.047V44.46L191.999 31.6427H206.309L189.903 49.4161L207.335 70.4792H192.362L181.004 55.9529V70.4792H169.218V14.9374Z"
        fill="currentColor"
      />
      ,
      <path
        d="M205.923 51.0839C205.923 37.6471 215.57 30.7258 224.447 30.7258C226.53 30.6381 228.609 30.9862 230.551 31.7477C232.492 32.5092 234.252 33.6673 235.719 35.1477L236.81 31.6657H247.762V70.4808H237.345L235.719 66.6784C234.231 68.3037 232.394 69.5724 230.346 70.3898C228.297 71.2073 226.091 71.5524 223.891 71.3994C214.329 71.3994 205.923 63.4954 205.923 51.0839ZM236.19 51.0839C236.19 44.6753 232.147 40.8942 227.014 40.8942C221.88 40.8942 217.837 44.8462 217.837 51.0839C217.837 57.3217 221.794 61.2524 227.014 61.2524C232.233 61.2524 236.19 57.3431 236.19 51.0839Z"
        fill="currentColor"
      />
      <path
        d="M267.14 66.0326L265.813 70.3051H255.931V14.7633H267.76V35.5701C269.279 33.9232 271.135 32.6209 273.201 31.7512C275.268 30.8815 277.497 30.4646 279.738 30.5286C288.765 30.5286 297.941 36.9373 297.941 50.8868C297.941 63.9391 288.679 71.2237 279.118 71.2237C276.851 71.3765 274.581 70.9851 272.497 70.0819C270.413 69.1788 268.576 67.7903 267.14 66.0326V66.0326ZM286.027 51.0791C286.027 44.9268 282.07 40.9747 276.787 40.9747C271.503 40.9747 267.525 44.8413 267.525 51.0791C267.525 57.3168 271.567 61.162 276.701 61.162C277.992 61.1632 279.27 60.8972 280.453 60.3807C281.636 59.8642 282.7 59.1085 283.576 58.1611C284.452 57.2138 285.122 56.0954 285.544 54.8765C285.966 53.6576 286.13 52.3646 286.027 51.0791V51.0791Z"
        fill="currentColor"
      />
      <path d="M302.606 14.9374H314.435V70.4792H302.606V14.9374Z" fill="currentColor" />,
      <path
        d="M342.115 71.3935C330.286 71.3935 322.115 63.2545 322.115 51.0567C322.115 38.8589 330.222 30.6985 342.029 30.6985C355.719 30.6985 361.944 40.1833 361.944 49.7322C361.949 51.1696 361.792 52.6029 361.473 54.0047H333.944C334.161 56.1131 335.191 58.0546 336.816 59.4181C338.44 60.7817 340.533 61.4604 342.65 61.3106C344.29 61.4042 345.923 61.0302 347.358 60.2321C348.794 59.4341 349.972 58.2448 350.757 56.8031L360.532 61.6096C357.131 67.826 350.971 71.3935 342.115 71.3935ZM334.179 46.1647H349.837C349.587 44.3132 348.645 42.6243 347.202 41.436C345.758 40.2478 343.918 39.6477 342.051 39.7561C340.176 39.6555 338.33 40.2564 336.875 41.4413C335.42 42.6262 334.458 44.3103 334.179 46.1647V46.1647Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const IconLogo = ({ className }: Props) => {
  return (
    <svg viewBox="0 0 30 36" className={cn('text-brand', className)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M15.025 0C26.669.02 30.019 3.403 30 15.125c-.008 4.724-.56 8.09-1.977 10.426-3.084 6.564-9.942 9.7-13.058 10.449l.01-5.801C3.33 30.179-.02 26.797 0 15.074.02 3.352 3.38-.02 15.025 0zm-.06 12.532a3.013 3.013 0 01-3.003-3.024 3.013 3.013 0 013.005-3.021 3.014 3.014 0 013.002 3.024 3.013 3.013 0 01-3.005 3.021zm-.001 2.078c2.488.001 6.508.522 6.507 3.026-.001 2.504-2.688 5.54-6.51 5.538-3.825-.001-6.508-3.04-6.507-5.544.001-2.503 4.021-3.02 6.51-3.02z"
      />
    </svg>
  );
};

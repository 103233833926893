import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Hash = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={bgHsl}
        d="M29.6 4c-2.4-.6-4.8.9-5.4 3.3l-2.8 11-9.5-1.2a4.5 4.5 0 0 0-5 3.8c-.3 1.4.7 2.9 2 4.7 1.4 1.9 3.2 4 4.7 4.2l4.8.7-.7 2.8-7.9-1a4.5 4.5 0 0 0-5 3.8c-.2 1.3 1.2 3.3 2.4 5 1.4 2 2.8 3.7 4.3 4l3.1.3-1.6 6.8c-.4 1.5.4 3 1.8 5 1.3 1.7 3 4 4.3 4.3 2.5.6 5-.9 5.5-3.3l2.8-11 2.5.3-1.7 6.8c-.4 1.4.5 2.8 1.8 4.7 1.3 1.9 3 4.2 4.4 4.6 2.4.6 4.8-1 5.4-3.3l2.8-11 9.5 1.2c2.5.3 4.7-1.4 5-3.9.3-1.3-.3-2.6-1.6-4.4-1.4-2-3.6-4.3-5.1-4.5l-4.8-.6.7-2.9 7.9 1c2.4.4 4.7-1.3 5-3.8.2-1.2-.7-2.7-2-4.4-1.4-2-3.2-4.3-4.7-4.5l-3.1-.4 1.6-6.8c.4-1.3-1-3.3-2.4-5.3-1.3-2-2.3-3.6-3.7-4-2.5-.6-5 1-5.5 3.3l-2.8 11-2.5-.2 1.7-6.8c.4-1.4-.8-3.1-2-5-1.3-2-2.7-4-4.2-4.3Z"
      />
      <path
        fill={fgHsl}
        d="M8.7 21a2.5 2.5 0 0 1 2.9-2.1l40.2 5.4a2.5 2.5 0 1 1-.7 5L11 23.9A2.5 2.5 0 0 1 8.7 21Zm-2 15.1a2.5 2.5 0 0 1 2.9-2.2l40.2 5.4a2.5 2.5 0 1 1-.7 5L8.9 39a2.5 2.5 0 0 1-2.2-2.8Z"
      />
      <path
        fill={fgHsl}
        d="M29 5.9c1.3.3 2.2 1.7 1.8 3L19.7 53.5a2.5 2.5 0 1 1-5-1.2L26 7.7A2.5 2.5 0 0 1 29 6Zm15 2c1.4.3 2.2 1.7 1.9 3L34.8 55.6a2.5 2.5 0 1 1-5-1.2L41 9.7A2.5 2.5 0 0 1 44 8Z"
      />
    </svg>
  );
};

// import { Skeleton } from '@askable/ui/core/skeleton';

/**
 * Updated to be just the outline of each section
 * @todo: restore skeleton loaders when product asks for them back
 */

interface SkeletonLoaderProps {
  section?: 'build' | 'recruit' | 'report' | 'results' | string;
}

const Header = () => (
  <header
    className="studies-header left-0 grid cursor-progress grid-cols-2 items-center gap-2 border-b border-border bg-background px-2 py-3
      lg:grid-cols-3"
  >
    <div className="order-1 h-9 w-2/5" />
    <div className="order-4 col-start-1 col-end-3 w-full justify-self-center lg:order-2 lg:col-start-2 lg:col-end-3 lg:w-fit" />
    <div className="order-3 h-9 w-1/5 justify-self-end" />
  </header>
);

const BuildSkeleton = () => (
  <>
    <nav className="build-nav relative flex flex-col items-center gap-2 overflow-auto bg-background p-4">
      <div>
        <div className="h-14 w-full rounded-xl" />
      </div>
    </nav>
    <main className="build-editor lg:p-4">
      <div className="flex min-h-full flex-col gap-4 bg-background p-4 lg:rounded-xl lg:p-6">
        <div className="flex items-center gap-3">
          <div className="h-9 w-10" />
          <div className="h-7 w-full" />
        </div>
      </div>
    </main>
    <aside className="build-preview flex flex-col gap-3 overflow-auto p-4">
      <div className="min-w-xs flex h-full flex-col gap-10 rounded-xl p-6">
        <div className="h-20 w-20" />
        <div className="h-8 w-3/5" />
      </div>
    </aside>
  </>
);

// @todo: customise these layouts when we have the designs
const RecruitSkeleton = () => (
  <>
    <nav className="page-nav bg-background">
      <div>
        <div className="h-14 w-full rounded-xl" />
      </div>
    </nav>

    <main className="main h-full p-4">
      <div className="flex min-h-full flex-col gap-4 bg-background p-4 lg:rounded-xl lg:p-6">
        <div className="h-9 w-10" />
      </div>
    </main>
  </>
);

const ResultsSkeleton = () => (
  <>
    <nav className="page-nav bg-background">
      <div>
        <div className="h-14 w-full rounded-xl" />
      </div>
    </nav>

    <main className="main h-full p-4">
      <div className="flex min-h-full flex-col gap-4 bg-background p-4 lg:rounded-xl lg:p-6">
        <div className="h-9 w-10" />
      </div>
    </main>
  </>
);

const ReportSkeleton = () => (
  <>
    <nav className="page-nav bg-background">
      <div>
        <div className="h-14 w-full rounded-xl" />
      </div>
    </nav>

    <main className="main h-full p-4">
      <div className="flex min-h-full flex-col gap-4 bg-background p-4 lg:rounded-xl lg:p-6">
        <div className="h-9 w-10" />
      </div>
    </main>
  </>
);

export const SkeletonLoader = ({ section }: SkeletonLoaderProps) => {
  const renderSection = () => {
    switch (section) {
      case 'build':
        return <BuildSkeleton />;
      case 'recruit':
        return <RecruitSkeleton />;
      case 'report':
        return <ReportSkeleton />;
      case 'results':
        return <ResultsSkeleton />;
      default:
        return <ResultsSkeleton />;
    }
  };

  return (
    <div className="studies-container h-full min-h-0 w-full flex-auto bg-secondary text-foreground">
      <Header />
      {renderSection()}
    </div>
  );
};

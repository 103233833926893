/* eslint-disable max-lines */
import { BetaBadge } from '@askable/ui/components/beta-badge';
import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import { useFeatureFlags } from 'feature-flags';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { Card } from 'components/common';
import { ResearcherMethods } from 'generated/graphql';
import { askablePlusUtils } from 'lib/askablePlus';
import { BOOKING_TYPE, DEFAULT_QUOTA } from 'lib/constants';

import type { ProjectAskablePlusResearchConfig } from 'generated/graphql';

import './styles/researchTypeStyles.scss';

function ResearchType(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  console.log('askablePlusBrief', askablePlusBrief);
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [researchType, setResearchType] = useState(_.get(askablePlusBrief, 'askable_plus.research_type'));
  const { CONTINUOUS_DISCOVERY } = useFeatureFlags(['CONTINUOUS_DISCOVERY'], {
    application: 'clients',
  });

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/research-type`,
        stepId: 'project_setup_research_type',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const handleResearchTypeData = (key?: ResearcherMethods): ProjectAskablePlusResearchConfig => {
    if (!key) {
      return {
        booking_type: null,
        quota: null,
        _booking_id: null,
        session: {},
      };
    }

    const quota = props?.project?.askable_plus?.research_type?.[key]?.quota ?? DEFAULT_QUOTA.REMOTE;

    switch (key) {
      case ResearcherMethods.Discovery:
      case ResearcherMethods.Usability:
        return {
          quota,
          booking_type: BOOKING_TYPE.REMOTE,
          session: {
            slot_min: 1,
            slot_max: 1,
            duration: 45,
            type: 1,
            time_limit: 45,
          },
        };

      case ResearcherMethods.CompetitiveAnalysis:
        return { quota };

      case ResearcherMethods.Survey:
        return {
          quota: DEFAULT_QUOTA.SURVEY,
          booking_type: BOOKING_TYPE.ONLINE,
          session: {
            slot_min: DEFAULT_QUOTA.SURVEY,
            slot_max: DEFAULT_QUOTA.SURVEY,
            duration: 10,
            type: 1,
            time_limit: 120,
          },
        };

      case ResearcherMethods.Longitudinal:
        return {
          booking_type: BOOKING_TYPE.LONGITUDINAL_STUDY,
          quota: DEFAULT_QUOTA.LONGITUDINAL_STUDY,
          longitudinal_study: {
            participant_workload: {
              time: 20,
              measure: 1,
              frequency: 1,
            },
            period: {
              time: 5,
              frequency: 1,
            },
          },
        };
      case ResearcherMethods.ContinuousAiModerated:
        return {
          quota: 20,
          continuous: true,
          booking_type: BOOKING_TYPE.ONLINE,
          session: {
            slot_min: 1,
            slot_max: 1,
            duration: 20,
            type: 1,
            time_limit: 20,
          },
        };
      case ResearcherMethods.ContinuousResearcherModerated:
        return {
          quota: 5,
          continuous: true,
          booking_type: BOOKING_TYPE.REMOTE,
          session: {
            slot_min: 1,
            slot_max: 1,
            duration: 45,
            type: 1,
            time_limit: 45,
          },
        };

      default:
        return {
          quota: null,
          booking_type: null,
          _booking_id: null,
          session: {},
        };
    }
  };

  const onChangeResearchType = (type: ResearcherMethods) => (selected: boolean) => {
    let newResearchType = {};
    const isContinuousDiscoverType = type === ResearcherMethods.ContinuousAiModerated;
    if (isContinuousDiscoverType) {
      newResearchType = {
        ...Object.keys(researchType).reduce((reseachTypes: any, key) => {
          reseachTypes[key] = handleResearchTypeData(undefined);
          return reseachTypes;
        }, {}),
        [ResearcherMethods.ContinuousAiModerated]: handleResearchTypeData(ResearcherMethods.ContinuousAiModerated),
        [ResearcherMethods.ContinuousResearcherModerated]: handleResearchTypeData(
          ResearcherMethods.ContinuousResearcherModerated,
        ),
      };
    } else {
      newResearchType = {
        ...researchType,
        [type]: handleResearchTypeData(!selected ? type : undefined),
        [ResearcherMethods.ContinuousAiModerated]: handleResearchTypeData(undefined),
        [ResearcherMethods.ContinuousResearcherModerated]: handleResearchTypeData(undefined),
      };
    }

    setResearchType(newResearchType);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        research_type: newResearchType,
      },
    };

    if (
      !(
        askablePlusUtils.hasDiscoveryInterviewType(askablePlusBriefStateObj) ||
        askablePlusUtils.hasUsabilityTestingType(askablePlusBriefStateObj) ||
        askablePlusUtils.hasLongitudinalStudyType(askablePlusBriefStateObj)
      )
    ) {
      askablePlusBriefStateObj.askable_plus = {
        ...askablePlusBriefStateObj.askable_plus,
        deliverables: {
          ...askablePlusBriefStateObj.askable_plus.deliverables,
          highlight_reel: null,
          transcriptions: isContinuousDiscoverType,
          video_recordings: null,
          other: null,
          other_description: null,
        },
      };
    }

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/deliverables`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent researchTypeContainer">
      <h1 className="title">What type of research needs to be done?</h1>
      {CONTINUOUS_DISCOVERY && (
        <div className="researchTypeGroup">
          <div className="researchTypeTasks">
            <Card
              id="__discoveryInterviewCard"
              title={
                <div className="newHeader">
                  <h2>Continuous Discovery</h2>
                  <BetaBadge align="end" className="ml-3" title="New" />
                </div>
              }
              className="newResearchTypeCard"
              vertical
              content={[
                {
                  label: (
                    <p>
                      Continuously learn and understand your customers, users and market in order to make better product
                      decisions.{' '}
                      <a target="_blank" href="/" className="learnMoreLink">
                        Learn more
                      </a>
                    </p>
                  ),
                },
                {
                  label: (
                    <ul>
                      <li>A mixture of moderated and AI moderated interviews conducted weekly</li>
                      <li>
                        Receive weekly reports - providing a wealth of insights that keep your team focussed on
                        opportunities.
                      </li>
                      <li>Optimise your brief as you learn</li>
                      <li>
                        A way to deeply understanding your market, use cases, user journeys, workflows and paint points
                      </li>
                    </ul>
                  ),
                },
              ]}
              image="/illustrations/ai-moderated.svg"
              selected={_.get(researchType, 'continuous_ai_moderated.quota')}
              onClickCard={onChangeResearchType(ResearcherMethods.ContinuousAiModerated)}
            />
          </div>
        </div>
      )}
      <p className="label">{CONTINUOUS_DISCOVERY ? 'Or choose one or more of' : 'You may choose more than one.'}</p>
      <div className="researchTypeGroup">
        <div className="researchTypeTasks">
          <Card
            id="__discoveryInterviewCard"
            title="Discovery Interview"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Problem space research,' }, { label: 'gathering evidence' }]}
            image="/askablePlus/illustrations/discoveryInterviewType.png"
            selected={_.get(researchType, 'discovery.quota')}
            onClickCard={onChangeResearchType(ResearcherMethods.Discovery)}
          />
          <Card
            id="__usabilityTestingCard"
            title="Usability Testing"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Testing with prototypes, live' }, { label: 'apps, and other stimuli' }]}
            image="/askablePlus/illustrations/usabilityTestingType.png"
            selected={_.get(researchType, 'usability.quota')}
            onClickCard={onChangeResearchType(ResearcherMethods.Usability)}
          />
          <Card
            id="__competitiveAnalysisCard"
            title="Competitor Analysis"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Identify strengths and' }, { label: 'weaknesses of similar products' }]}
            image="/askablePlus/illustrations/competitiveAnalysisType.svg"
            selected={_.get(researchType, 'competitive_analysis.quota')}
            onClickCard={onChangeResearchType(ResearcherMethods.CompetitiveAnalysis)}
          />
        </div>
      </div>
      <div className="researchTypeGroup">
        <div className="researchTypeTasks mtop20">
          <Card
            id="__surveyCard"
            title="Survey"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Survey Monkey, Typeform,' }, { label: 'Google Forms, etc' }]}
            image="/askablePlus/illustrations/surveyType.svg"
            selected={_.get(researchType, 'survey.quota')}
            onClickCard={onChangeResearchType(ResearcherMethods.Survey)}
          />
          <Card
            id="__longitudinalStudyCard"
            title="Longitudinal study"
            className="researchTypeCard"
            vertical
            content={[{ label: 'Participants do multiple tasks' }, { label: 'over a longer time' }]}
            image="/askablePlus/illustrations/diaryStudyType.svg"
            selected={_.get(researchType, 'longitudinal.quota')}
            onClickCard={onChangeResearchType(ResearcherMethods.Longitudinal)}
          />
        </div>
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ResearchType);

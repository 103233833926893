import { createLucideIcon } from 'lucide-react';

export const MousePointerClickSolid = createLucideIcon('MousePointerClickSolid', [
  [
    'path',
    {
      d: 'M7.2 1.2a1 1 0 0 0-.3 0 1 1 0 0 0-.7 1.3L7 5.4a1 1 0 1 0 2-.6L8.2 2a1 1 0 0 0-1-.7v-.1ZM14 3.1a1 1 0 0 0-.7.3l-2 1.9a1 1 0 1 0 1.4 1.4l2-1.9a1 1 0 0 0-.7-1.7ZM2.2 6.2a1 1 0 1 0-.3 2L5 9a1 1 0 1 0 .5-2l-3-.8h-.3ZM9 8a1 1 0 0 0-1 1.4l5 12a1 1 0 0 0 2 0l1.6-4.8 4.7-1.7a1 1 0 0 0 0-1.8l-12-5L9 8Zm-3 3a1 1 0 0 0-.7.3l-2 2a1 1 0 1 0 1.5 1.4l2-2A1 1 0 0 0 6 11Z',
      fill: 'currentColor',
      stroke: 'none',
    },
  ],
]);

import { Alert, AlertDescription } from '@askable/ui/core/alert';
import { Button } from '@askable/ui/core/button';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/core/select';
import { toast } from '@askable/ui/core/sonner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingOverlay } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useFetchCountryListQuery, useFetchTeamByIdQuery, useUpdateTeamMutation } from 'generated/graphql';
import { teamUtils } from 'lib/teams';

import { SectionHeader } from '../../components/SectionHeader';
import { SettingsContainer } from '../../components/SettingsContainer';

import { RemainingCredits } from './components/RemainingCredits';

export const SettingsBilling = () => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const {
    data,
    loading: loadingTeam,
    refetch: refetchTeam,
  } = useFetchTeamByIdQuery({
    variables: {
      _id: details?.team?.id,
    },
  });

  const [updateTeam] = useUpdateTeamMutation();
  const resultCountryList = useFetchCountryListQuery();
  const taxesForCountry = teamUtils.getTax();

  const [city, setCity] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [level, setLevel] = useState('');
  const [saving, setSaving] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [registration, setRegistration] = useState('');
  const [state, setState] = useState('');
  const [street1, setStreet1] = useState('');

  const teamById = data?.teamById;
  const countryList = resultCountryList.data?.countryList;

  useEffect(() => {
    if (teamById?.settings?.billing) {
      const { billing } = teamById.settings;

      setCity(billing.location?.city || '');
      setCompanyName(billing.company_name || '');
      setContactEmail(billing.contact_email || '');
      setContactFirstName(billing.contact_first_name || '');
      setContactLastName(billing.contact_last_name || '');
      setCountry(billing.location?.country || '');
      setLevel(billing.location?.level || '');
      setPostalCode(billing.location?.postal_code || '');
      setRegistration(billing.registration || '');
      setState(billing.location?.state || '');
      setStreet1(billing.location?.street1 || '');
    }
  }, [teamById]);

  const onSave = async (event: any) => {
    event.preventDefault();

    if (!details?.ConnectedTeam?._id) {
      return;
    }

    setError('');
    setSaving(true);

    try {
      await updateTeam({
        variables: {
          _id: details.ConnectedTeam._id,
          team: {
            settings: {
              billing: {
                registration,
                company_name: companyName,
                contact_first_name: contactFirstName,
                contact_last_name: contactLastName,
                contact_email: contactEmail,
                location: {
                  street1,
                  level,
                  city,
                  state,
                  postal_code: postalCode,
                  country,
                },
              },
            },
          },
        },
      });

      teamUtils.save({
        currency: details?.ConnectedTeam?.settings?.billing?.currency ?? undefined,
        tax: details?.ConnectedTeam?.settings?.billing?.tax ?? undefined,
      });

      await refetchTeam();
      toast.success(t('confirmations.updated', { title: t('sections.settings.billing.details') }));
    } catch (err: any) {
      setError(err?.graphQLErrors[0]);
    } finally {
      setSaving(false);
    }
  };

  return (
    <SettingsContainer title={t('sections.settings.billing.title')}>
      {loadingTeam ? <LoadingOverlay style={{ opacity: 0.8 }} /> : null}

      {error ? (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : null}

      {details?.ConnectedTeam?._id ? <RemainingCredits teamId={details?.ConnectedTeam?._id} /> : null}

      <form onSubmit={onSave} className="flex max-w-md flex-col gap-8">
        <section className="flex flex-col gap-4">
          <SectionHeader title={t('sections.settings.billing.contact')} />

          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            <div className="flex flex-col gap-1">
              <Label htmlFor="__billing_contact_firstname">{t('formFields.firstName')}</Label>
              <Input
                id="__billing_contact_firstname"
                value={contactFirstName}
                onChange={e => setContactFirstName(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-1">
              <Label htmlFor="__billing_contact_lastname">{t('formFields.lastName')}</Label>
              <Input
                id="__billing_contact_lastname"
                value={contactLastName}
                onChange={e => setContactLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_contact_email" className="flex flex-col gap-1">
              {t('formFields.email')}
              <div className="text-sm font-normal text-muted-foreground">
                {t('formFields.emailDescriptionInvoices')}
              </div>
            </Label>
            <Input
              id="__billing_contact_email"
              type="email"
              value={contactEmail}
              onChange={e => setContactEmail(e.target.value)}
            />
          </div>
        </section>

        <section className="flex flex-col gap-4">
          <SectionHeader title={t('sections.settings.billing.companyInfo')} />

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_companyname">{t('formFields.companyName')}</Label>
            <Input id="__billing_companyname" value={companyName} onChange={e => setCompanyName(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_registration">{taxesForCountry?.tax_number_label ?? 'EIN'}</Label>
            <Input id="__billing_registration" value={registration} onChange={e => setRegistration(e.target.value)} />
          </div>
        </section>

        <section className="flex flex-col gap-4">
          <SectionHeader title={t('sections.settings.billing.address')} />

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_street">{t('formFields.streetAddress')}</Label>
            <Input id="__billing_address_street" value={street1} onChange={(e: any) => setStreet1(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_level">{t('formFields.officeLevelFloor')}</Label>
            <Input id="__billing_address_level" value={level} onChange={e => setLevel(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_city">{t('formFields.city')}</Label>
            <Input id="__billing_address_city" value={city} onChange={e => setCity(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_state">{t('formFields.state')}</Label>
            <Input id="__billing_address_city" value={state} onChange={e => setState(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_postcode">{t('formFields.postalCode')}</Label>
            <Input id="__billing_address_postcode" value={postalCode} onChange={e => setPostalCode(e.target.value)} />
          </div>

          <div className="flex flex-col gap-1">
            <Label htmlFor="__billing_address_country">{t('formFields.country')}</Label>
            <Select
              value={country}
              onValueChange={setCountry}
              disabled={!!(teamById?.settings?.billing?.xero_id && teamById?.settings?.billing?.xero_id.length > 0)}
            >
              <SelectTrigger id="__billing_address_country">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {countryList?.map((item: any) => {
                  return item ? (
                    <SelectItem key={item._id} value={item.country_code}>
                      {item.name} ({item.country_code})
                    </SelectItem>
                  ) : null;
                })}
              </SelectContent>
            </Select>
          </div>
        </section>

        <Button type="submit" variant="primary" isLoading={saving}>
          {t('global.save')}
        </Button>
      </form>
    </SettingsContainer>
  );
};

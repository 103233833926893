import { Button } from '@askable/ui/core/button';
import { Label } from '@askable/ui/core/label';
import { Textarea } from '@askable/ui/core/textarea';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';

function ProjectBrief(props: any) {
  const askablePlusBriefSteps = _.get(props, 'askablePlusBriefSteps');
  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [participantCriteria, setParticipantCriteria] = useState(
    _.get(askablePlusBrief, 'askable_plus.audience.description'),
  );
  const [participantCriteriaError, setParticipantCriteriaError] = useState('');

  useEffect(() => {
    if (
      _.size(_.get(props, 'askablePlusBrief.askable_plus.audience.booking_config.criteria.filters_order')) > 0 ||
      _.size(_.get(props, 'askablePlusBrief.askable_plus.audience.booking_config.question')) > 0
    ) {
      props.history.push(`/askable-plus/${askablePlusBrief._id}/audience/demographic-filters`);
    } else {
      props.updateLastStep({
        step: 'Audience',
        subStep: `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`,
        stepId: 'audience_participant_criteria',
      });
      props.renderRightContent(rightContent());
      if (_.get(askablePlusBriefSteps, 'audience_participant_criteria') === 'error') {
        setParticipantCriteriaError('Required');
      }
    }
  }, []);

  const rightContent = () => {
    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
      </Fragment>
    );
  };

  const onChangeParticipantCriteria = (value: any, criteriaType = 1) => {
    if (_.size(value) === 0) {
      setParticipantCriteriaError('Required');
    } else {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      setParticipantCriteriaError();
    }

    setParticipantCriteria(value);

    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          description: value || '',
        },
      },
    };

    if (criteriaType === 2) {
      askablePlusBriefStateObj.askable_plus.audience = {
        ...askablePlusBriefState.askable_plus.audience,
        description: null,
        booking_config: {
          ...askablePlusBriefState.askable_plus.audience.booking_config,
          criteria: {
            ...askablePlusBriefState.askable_plus.audience.booking_config.criteria,
            exclude_participants_from_time: 3,
          },
        },
      };
    }
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/incentives`;
    props.history.push({ pathname: redirectTo });
  };

  const onClickLinkDemographicFilters = () => {
    onChangeParticipantCriteria('', 2);
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/demographic-filters`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Participant criteria</h1>
      <div className="fieldContainer flex flex-col gap-2">
        <Label htmlFor="__participantCriteria" className="leading-normal">
          Describe the audience you would like to target. You may provide it as a dot point brief or a series of
          demographic filters and screener questions.
        </Label>
        <Textarea
          id="__participantCriteria"
          placeholder="Describe the audience you would like the researcher to target. You may provide it as a dot point brief or a series of demographic filters and screener questions."
          value={participantCriteria}
          rows={4}
          maxRows={12}
          onChange={e => {
            onChangeParticipantCriteria(e.target.value);
          }}
          variant={participantCriteriaError ? 'error' : undefined}
        />
        {participantCriteriaError ? <div className="text-xs text-destructive">Required</div> : null}
      </div>

      <div className="linkTextContainer mtop20" onClick={onClickLinkDemographicFilters}>
        <span className="linkTextLabel">I'll provide demographic filters and screener questions</span>
      </div>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(ProjectBrief);

import { FormControl, FormItem } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';

import { FieldLabel } from './FieldLabel';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

/**
 * data-1p-ignore: force 1Password to not autofill field: https://developer.1password.com/docs/web/compatible-website-design/
 */

interface InputFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isDisabledSelectable?: boolean; // Allow text selection when disabled
  isFocused?: boolean;
  label: string;
  maxLength?: number;
  name: string;
  placeholder?: string;
}

const InputFieldComponent = ({
  description,
  infoPopover,
  isDisabled,
  isDisabledSelectable,
  isFocused,
  label,
  maxLength,
  name,
  placeholder,
}: InputFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  const currentLength = field.value?.length || 0;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    field.onChange(value.trim());
  };

  return (
    <FormItem>
      <FieldLabel name={name} label={label} description={description} infoPopover={infoPopover} />

      <FormControl>
        <Input
          {...field}
          onBlur={handleBlur}
          aria-describedby={description ? `description_${name}` : undefined}
          autoComplete="off"
          autoFocus={isFocused}
          className={cn('text-md lg:text-sm', {
            'bg-accent text-foreground disabled:cursor-default disabled:border-border disabled:opacity-100':
              isDisabled && isDisabledSelectable,
          })}
          data-1p-ignore
          disabled={isDisabled}
          id={`input_${name}`}
          placeholder={isDisabled && isDisabledSelectable ? '' : placeholder}
          maxLength={maxLength}
        />
      </FormControl>

      {maxLength !== undefined ? (
        <div className="text-sm">
          <div className="text-muted-foreground">
            {currentLength} / {maxLength}
          </div>
        </div>
      ) : null}

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
    </FormItem>
  );
};

InputFieldComponent.displayName = 'InputField';

export const InputField = memo(InputFieldComponent);

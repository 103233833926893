export const FigmaLogo = ({ size = 48 }: { size: number }) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" width={size} height={size} aria-hidden="true">
      <path fill="#1ABCFE" d="M11.7 12a3.3 3.3 0 1 1 6.6 0 3.3 3.3 0 0 1-6.6 0Z" />
      <path fill="#0ACF83" d="M5 18.7a3.3 3.3 0 0 1 3.3-3.4h3.4v3.4a3.3 3.3 0 0 1-6.7 0Z" />
      <path fill="#FF7262" d="M11.7 2v6.7H15A3.3 3.3 0 0 0 15 2h-3.3Z" />
      <path fill="#F24E1E" d="M5 5.3a3.3 3.3 0 0 0 3.3 3.4h3.4V2H8.3A3.3 3.3 0 0 0 5 5.3Z" />
      <path fill="#A259FF" d="M5 12a3.3 3.3 0 0 0 3.3 3.3h3.4V8.7H8.3A3.3 3.3 0 0 0 5 12Z" />
    </svg>
  );
};

import { Card, CardContent, CardHeader, CardTitle } from '@askable/ui/core/card';
import { Skeleton } from '@askable/ui/core/skeleton';
import { Group } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { DiscoverThemesQuery } from './data/DiscoverThemes.query';

import type { FilterDateProps } from 'containers/Discover/DiscoverContainer';
import type { ResultOf } from 'gql.tada';

type AnalysesThemesProps = {
  filterDate: FilterDateProps;
};

export const AnalysesThemes = ({ filterDate }: AnalysesThemesProps) => {
  const { t } = useTranslation();
  const [{ data, fetching }] = useQuery({
    query: DiscoverThemesQuery,
    variables: {
      filter: {
        created: { gte: filterDate.date },
      },
      first: 6,
    },
  });

  const themes = useMemo<ResultOf<typeof DiscoverThemesQuery>['analysesThemes']['nodes']>(() => {
    return data?.analysesThemes.nodes ?? [];
  }, [data]);

  const [themeBarWidths, setThemeBarWidths] = useState<number[]>(new Array(themes.length).fill(10));

  useEffect(() => {
    if (!themes.length) {
      return;
    }

    // Set the relative widths of the bars based on the theme count
    const maxCount = Math.max(...themes.map(val => val?.count!));
    const widths = themes.map(theme => ((theme?.count ?? 0) / maxCount) * 100);

    setThemeBarWidths(widths);
  }, [themes]);

  return (
    <Card className="@container">
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>{t('sections.discover.topThemes')}</CardTitle>
          <Group className="h-6 w-6 text-muted-foreground" />
        </div>
      </CardHeader>
      <CardContent>
        {fetching ? (
          <ol className="columns-1 gap-4 @sm:columns-2">
            <li className="pt-4">
              <Skeleton className="h-4" />
            </li>
            <li className="w-4/5 pt-4">
              <Skeleton className="h-4" />
            </li>
            <li className="w-3/5 pt-4">
              <Skeleton className="h-4" />
            </li>
            <li className="w-2/5 pt-4">
              <Skeleton className="h-4" />
            </li>
          </ol>
        ) : (
          <ol className="columns-1 gap-4 @sm:columns-2">
            {themes.map((item, index) => {
              return item ? (
                <li
                  className="grid grid-cols-1 content-center gap-x-2 gap-y-0 pt-2 text-xs @sm:grid-cols-2"
                  key={item._id}
                >
                  <div>{item.name}</div>
                  <div className=" flex items-center gap-2">
                    <div
                      className="duration-400 h-1 rounded bg-primary transition-all ease-in-out"
                      style={{
                        backgroundColor: item.accent || '',
                        transitionDelay: `${index * 50}ms`,
                        width: `${themeBarWidths[index]}%`,
                      }}
                    />
                    <div className="w-12">{item.count}</div>
                  </div>
                </li>
              ) : null;
            })}
          </ol>
        )}
      </CardContent>
    </Card>
  );
};

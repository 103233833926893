import { cn } from '../lib/utils';

import { Check, Figma, Hand, Heart, Message, Plus, Star } from './icons';

const iconSizesInPx = {
  xl: 96,
  lg: 32,
  md: 20,
  sm: 14,
} as const;

const blockTypes = {
  add: { Icon: Plus, bgClass: 'bg-secondary' },
  welcome: { Icon: Hand, bgClass: 'bg-secondary' },
  thank_you: { Icon: Heart, bgClass: 'bg-secondary' },
  figma_prototype: { Icon: Figma, bgClass: 'bg-accent-11' },
  multiple_choice_question: { Icon: Check, bgClass: 'bg-accent-12' },
  open_answer: { Icon: Message, bgClass: 'bg-accent-9' },
  opinion_scale: { Icon: Star, bgClass: 'bg-accent-5' },
} as const;

interface Props {
  bgColor?: string;
  hasBackground?: boolean;
  size?: keyof typeof iconSizesInPx;
  type: keyof typeof blockTypes;
  isActive?: boolean;
}

export const IconBlock = ({ bgColor, hasBackground = true, size = 'md', type, isActive }: Props) => {
  const config = blockTypes[type];
  const iconSize = iconSizesInPx[size];

  return (
    <div
      className={cn('rounded-md p-2', {
        [config.bgClass]: hasBackground,
        'outline outline-2 outline-offset-[3px] outline-primary': isActive,
      })}
    >
      {config?.Icon ? (
        <config.Icon
          size={iconSize}
          bgColor={bgColor ? bgColor : hasBackground ? 'darkest' : undefined}
          fgColor={hasBackground ? 'lightest' : undefined}
        />
      ) : null}
    </div>
  );
};

import { Center } from '@chakra-ui/react';
import { memo } from 'react';
import ReactPlayer from 'react-player';

import type { FC, LegacyRef, ReactNode } from 'react';
import type { Config, ReactPlayerProps } from 'react-player';

type Props = Pick<ReactPlayerProps, 'onDuration' | 'volume' | 'onProgress' | 'onEnded'> & {
  alert?: ReactNode;
  onBufferingChange: (b: boolean) => void;
  onPlayerError: (error: any) => void;
  playbackRate: number;
  playerRef: LegacyRef<ReactPlayer>;
  playing: boolean;
  url: string | undefined;
  onReady?: (player: ReactPlayer) => void;
  config?: Config;
};

export const VideoPlayerMedia: FC<Props> = memo(function VideoPlayerMediaMemo({
  onReady,
  alert,
  onBufferingChange,
  onDuration,
  onPlayerError,
  onProgress,
  onEnded,
  playbackRate,
  playerRef,
  playing,
  url,
  volume,
  config,
}) {
  if (alert) {
    return (
      <Center width="full" height="full">
        {alert}
      </Center>
    );
  }

  if (!url) {
    return null;
  }

  return (
    <ReactPlayer
      ref={playerRef}
      url={url}
      controls={false}
      playbackRate={playbackRate || undefined}
      width="auto"
      height="100%"
      volume={volume}
      config={config}
      onDuration={onDuration}
      playing={playing}
      onEnded={onEnded}
      progressInterval={50}
      onReady={onReady}
      onProgress={onProgress}
      onBuffer={() => {
        if (onBufferingChange) {
          onBufferingChange(true);
        }
      }}
      onBufferEnd={() => {
        if (onBufferingChange) {
          onBufferingChange(false);
        }
      }}
      onError={error => {
        onPlayerError(error);
      }}
    />
  );
});

import { Button } from '@askable/ui/core/button';
import { TableNoDataToDisplay as TableNoDataToDisplayComp } from '@askable/ui/core/table-no-data-to-display';
import { Link } from 'react-router-dom';

import type { TableNoDataToDisplayProps as TableNoDataToDisplayCompProps } from '@askable/ui/core/table-no-data-to-display';

export type TableNoDataToDisplayProps = Pick<
  TableNoDataToDisplayCompProps,
  'title' | 'description1' | 'description2'
> & {
  createButton?: {
    text: string;
    link: string;
  };
};

export const TableNoDataToDisplay = ({ createButton, ...props }: TableNoDataToDisplayProps) => {
  return (
    <TableNoDataToDisplayComp
      footer={
        createButton ? (
          <Link to={createButton.link}>
            <Button variant="primary">{createButton.text}</Button>
          </Link>
        ) : null
      }
      {...props}
    />
  );
};

import { Spinner } from '@askable/ui/core/spinner';

export const LoadingState = ({ title }: { title?: string }) => {
  return (
    <div className="flex h-full min-h-40 w-full flex-col items-center justify-center gap-2 p-4 text-sm text-muted-foreground">
      <Spinner className="h-5 w-5" />
      {title}
    </div>
  );
};

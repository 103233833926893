import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@askable/ui/core/table';
import { cn } from '@askable/ui/lib/utils';
import { Ellipsis } from 'lucide-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHandleCombinedError } from 'src/hooks/useHandleCombinedError';
import { useMutation, useQuery } from 'urql';

import { TableLoading } from 'components/Loading/TableLoading';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { teamUtils } from 'lib/teams';

import { ChangeUserRoleMutation } from '../data/SettingsChangeRole.mutation';
import { SettingsTeamMembersQuery } from '../data/SettingsTeamMembers.query';
import { ChangeUserStatusMutation } from '../data/TeamUserStatus.mutation';

type TeamMembersProps = {
  teamId: string;
  refetch: () => void;
  context: any;
};

export const TeamMembers = ({ teamId, refetch, context }: TeamMembersProps) => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();
  const [{ data, fetching }] = useQuery({
    query: SettingsTeamMembersQuery,
    variables: {
      _id: teamId,
      researcherProjectFilter: {
        _team_id: { eq: teamId },
      },
    },
  });

  const [{ error: changeRoleError }, changeRole] = useMutation(ChangeUserRoleMutation);
  const [{ error: changeStatusError }, changeStatus] = useMutation(ChangeUserStatusMutation);

  const users = useMemo(() => {
    return (
      data?.teamById?.users?.sort(a => {
        return a?.status === 0 ? 1 : -1;
      }) ?? []
    );
  }, [data?.teamById?.users]);

  useHandleCombinedError(changeRoleError);
  useHandleCombinedError(changeStatusError);

  const handleRoleChange = async (role: number, userId: string) => {
    const response = await changeRole({
      _id: teamId,
      userId,
      role,
    });

    if (!response.error) {
      toast.success(t('confirmations.updated', { title: 'Role' }));
    }
  };

  const handleStatusChange = async (userId: string, currentStatus: number) => {
    const response = await changeStatus({
      _id: teamId,
      status: currentStatus === 0 ? 1 : 0,
      userId,
    });

    if (!response.error) {
      toast.success(t('sections.settings.team.deactivated'));
    }
  };

  const adminCount = users.filter(user => user?.role === 1).length;

  return (
    <section className="flex flex-col gap-2">
      <header className="flex flex-col">
        <div className="flex items-center justify-between gap-2">
          <h3 className="text-md font-semibold">{t('sections.settings.team.manageMembers')}</h3>
          <Button
            onClick={() => {
              context.onOpenInviteTeamMemberModal(refetch);
            }}
          >
            {t('sections.settings.team.invitePeople')}
          </Button>
        </div>
      </header>
      {fetching ? (
        <TableLoading className="py-6" />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('sections.settings.team.name')}</TableHead>
              <TableHead>{t('sections.settings.team.role')}</TableHead>
              <TableHead>&nbsp;</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center text-muted-foreground">
                  {t('sections.settings.billing.emptyTable')}
                </TableCell>
              </TableRow>
            ) : (
              users?.map(user => {
                const teamUserStatus = teamUtils.getTeamUserStatus(user);
                const projects = user?.User?.researcher?.projects?.nodes;

                return (
                  <TableRow
                    key={user?._id}
                    data-testid={user?._id}
                    className={cn({
                      'text-muted-foreground/50': teamUserStatus === 'Deactivated',
                    })}
                    applyDefaultHoverStyle={teamUserStatus !== 'Deactivated'}
                  >
                    <TableCell>
                      <div className="flex flex-col">
                        <div className="font-medium">{user?.User?.displayName}</div>
                        <div
                          className={cn('text-muted-foreground', {
                            'text-muted-foreground/50': teamUserStatus === 'Deactivated',
                          })}
                        >
                          {user?.User?.email}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {teamUserStatus === 'Deactivated' ? (
                        t('sections.settings.team.deactivated')
                      ) : (
                        <div className="flex flex-col">
                          <div>{teamUtils.getRoleDescription(user)}</div>

                          {projects && projects.length > 0 ? (
                            <div className="flex items-center text-muted-foreground">
                              <Link
                                to={`/manage-askable-plus-project/${projects[0]?._id}`}
                                className="hover:text-foreground focus:text-foreground"
                              >
                                {projects[0]?.name}
                              </Link>

                              {projects.length > 1 ? (
                                <>
                                  ,
                                  <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                      <Button
                                        variant="link"
                                        className="-my-2 h-auto px-2 py-2 font-normal leading-none text-muted-foreground hover:text-foreground focus:text-foreground
                                          active:text-foreground"
                                      >
                                        {t('global.plusMore', {
                                          count: projects.length - 1,
                                        })}
                                      </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                      {projects.slice(1).map(project => {
                                        return (
                                          <DropdownMenuItem key={project?._id} asChild>
                                            <Link to={`/manage-askable-plus-project/${project?._id}`}>
                                              {project?.name}
                                            </Link>
                                          </DropdownMenuItem>
                                        );
                                      })}
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {details?.teamRole === 1 && (adminCount > 1 || user?.role !== 1) ? (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="ml-auto">
                              <Ellipsis className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {user?.status === 1 ? (
                              <>
                                {user?.role !== 3 ? (
                                  <DropdownMenuItem onClick={() => handleRoleChange(3, user?.User?._id!)}>
                                    {t('sections.settings.team.setRoleGuest')}
                                  </DropdownMenuItem>
                                ) : null}

                                {user?.role !== 2 ? (
                                  <DropdownMenuItem onClick={() => handleRoleChange(2, user?._id!)}>
                                    {t('sections.settings.team.setRoleMember')}
                                  </DropdownMenuItem>
                                ) : null}

                                {user?.role !== 1 ? (
                                  <DropdownMenuItem onClick={() => handleRoleChange(1, user?._id!)}>
                                    {t('sections.settings.team.setRoleAdmin')}
                                  </DropdownMenuItem>
                                ) : null}

                                <DropdownMenuSeparator />
                              </>
                            ) : null}

                            <DropdownMenuItem onClick={() => handleStatusChange(user?._id!, user?.status!)}>
                              {user?.status === 1
                                ? t('sections.settings.team.deactivate')
                                : t('sections.settings.team.activate')}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      )}
    </section>
  );
};

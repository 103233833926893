import { createLucideIcon } from 'lucide-react';

import { cn } from '../lib/utils';

import type { LucideProps } from 'lucide-react';

export const CompletedStatusComp = createLucideIcon('CompleteStatus', [
  [
    'path',
    {
      stroke: 'transparent',
      fill: 'currentColor',
      fillRule: 'evenodd',
      clipRule: '2px',
      d: 'M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm5.663-13.337a.938.938 0 0 0-1.326-1.326l-6.212 6.212-2.462-2.462a.938.938 0 0 0-1.326 1.326l3.125 3.125a.938.938 0 0 0 1.326 0l6.875-6.875Z',
    },
  ],
]);

export const CompletedStatus = ({ className, ...props }: LucideProps) => {
  return <CompletedStatusComp className={cn('text-success', className)} {...props} />;
};

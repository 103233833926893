import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';

import { Wysiwyg } from 'components/common';
import ParticipantsAppPreview from 'components/createBooking/components/participantsAppPreview';
import { bookingUtils } from 'lib/booking';
import { utils } from 'lib/utils';

import './styles/additionalInstructionsStyles.scss';

function AdditionalInstructions(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingData, setBookingData] = useState(null);
  const [bookingState, setBookingState] = useState(booking);
  const [showAddAdditionalInstructions, setShowAddAdditionalInstructions] = useState(
    !_.get(booking, 'config.information.special_instructions'),
  );
  const [additionalInstructions, setAdditionalInstructions] = useState(
    _.get(booking, 'config.information.special_instructions'),
  );
  // const [showUploadAgreement, setShowUploadAgreement] = useState(!_.get(booking, 'config.information.confidentiality_agreement_file'));

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/additional-instructions`,
      stepId: 'additional_info_additional_instructions',
    });
    if (bookingUtils.isOnlineTask(booking)) {
      props.history.push({ pathname: `/booking-setup/${booking._id}/additional-info/description` });
    }
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    props.renderRightContent(null);
    props.renderRightAppPreview(rightAppPreview());
    if (_.get(booking, 'config.information.special_instructions')) setFieldFocus();
  }, [booking, bookingState]);

  const rightAppPreview = () => {
    // Remove this block after participants app 2 is live
    const showAppPreview = false;
    if (!showAppPreview) return null;

    return (
      <Fragment>
        <p className="previewTitle additionalTitle">Preview</p>
        <ParticipantsAppPreview pageUrl="opportunity-details" bookingId={booking._id} hideActions />
      </Fragment>
    );
  };

  const onChangeValue = (value: any) => {
    setAdditionalInstructions(value);
    const bookingStateObj = {
      ...bookingState,
      config: {
        ...bookingState.config,
        information: {
          ...bookingState.config.information,
          special_instructions: value,
        },
      },
    };
    setBookingState(bookingStateObj);

    if (value !== _.get(booking, 'config.information.special_instructions')) {
      const bookingObj = {
        config: {
          information: {
            special_instructions: value,
          },
        },
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ config: { information: { speci... Remove this comment to see the full error message
      setBookingData(bookingObj);
      props.history.replace({ booking: bookingObj, bookingState: bookingStateObj });
    } else {
      setBookingData(null);
      props.history.replace({ booking: null, bookingState: null });
    }
  };

  const onClickNext = () => {
    const redirectTo = `/booking-setup/${booking._id}/additional-info/contacts`;
    props.history.push({ pathname: redirectTo, booking: bookingData, bookingState });
  };

  const setFieldFocus = () => {
    setTimeout(() => {
      const target = document.getElementById('rdw-wrapper-additionalInstructions');
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const elements = target.querySelectorAll('div div div div div div div span [data-text=true]');
      if (elements) {
        if (elements.length === 1) {
          utils.placeCaretAtEnd(elements[0]);
        } else {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          utils.placeCaretAtEnd(elements[elements.length - 1], 1);
        }
      }
    }, 20);
  };

  return (
    <div className="createBookingContent">
      <h1 id="__pageTitle" className="title">
        Additional instructions for confirmed participants
      </h1>
      <p className="info">
        Special instructions are only displayed to participants who you select to participate. This can include stuff
        like:
      </p>
      <p className="info">
        <span>
          • Visitor parking or transport info
          <br />
        </span>
        <span>
          • Sign in at reception downstairs
          <br />
        </span>
        <span>
          • Stuff to bring with them to the session
          <br />
        </span>
        <span>
          • Covid-19 instructions or restrictions
          <br />
        </span>
        {/* <span>• Confidentiality agreements that they need to sign</span> */}
      </p>
      {showAddAdditionalInstructions && (
        <div
          id="buttonAddAdditionalInstructions"
          className="largeAddButton additionalInstructions"
          onClick={() => {
            setShowAddAdditionalInstructions(false);

            setFieldFocus();
          }}
        >
          <img className="addFiltersIcon" src="/icons/blackPlusIcon.svg" alt="addFiltersIcon" />
          <div className="addFiltersContent">
            <p className="addFiltersTitle">Add additional instructions</p>
            {/* <p className="addFiltersSubtitle">Write text, attach a PDF</p> */}
          </div>
        </div>
      )}
      {!showAddAdditionalInstructions && (
        <Fragment>
          <Wysiwyg
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ wrapperId: string; className: string; edit... Remove this comment to see the full error message
            wrapperId="additionalInstructions"
            className="wysiwygContainerField"
            editorClassName="wysiwygEditor"
            value={additionalInstructions || ''}
            onBlurComponent={(value: any) => onChangeValue(value)}
          />
          {/* {showUploadAgreement &&
                        <a
                            onClick={() => {
                                // Track Event Analytics
                                segment.trackEvent(
                                    'booking_add_confidentiality_agreement_clicked',
                                    {
                                        page: window.location.href,
                                        action: 'Add confidentiality agreement clicked',
                                        bookingId: booking._id,
                                        bookingName: booking.name,
                                    }
                                );
                                setShowUploadAgreement(false);
                            }}
                            className="uploadConfidentialityAgreementFile"
                        >
                            <img
                                className="uploadFileIcon"
                                src="/icons/uploadFileIcon.svg"
                                alt="uploadFileIcon"
                            />
                            <span className="uploadFileText">Add confidentiality agreement (PDF only)</span>
                        </a>
                    }
                    {!showUploadAgreement &&
                        <Fragment>
                            <p className="label additionalField">Confidentiality agreement</p>
                            <p className="info">You&apos;ll be able to download signed copies of agreements on your study dashboard.</p>
                            <a
                                onClick={() => {
                                    // Track Event Analytics
                                    segment.trackEvent(
                                        'booking_reupload_confidentiality_agreement_clicked',
                                        {
                                            page: window.location.href,
                                            action: 'Re-upload confidentiality agreement clicked',
                                            bookingId: booking._id,
                                            bookingName: booking.name,
                                        }
                                    );
                                }}
                                className="uploadConfidentialityAgreementFile"
                            >
                                <span className="uploadFileText">Re-upload PDF</span>
                            </a>
                        </Fragment>
                    } */}
        </Fragment>
      )}
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(AdditionalInstructions);

import { Button } from '@askable/ui/core/button';
import { cn } from '@askable/ui/lib/utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useEffect, useRef, useState, Children } from 'react';
import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

/**
 * Wrap a list of items in a horizontal scrollable container
 * Optionally set a width for each item
 */

interface ScrollableListProps {
  children: ReactNode;
  isCentered?: boolean;
  itemWidth?: number;
}

export const ScrollableList = ({ children, isCentered = false, itemWidth = 192 }: ScrollableListProps) => {
  const { t } = useTranslation();
  const scrollListRef = useRef<HTMLOListElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  const checkIsScrollable = () => {
    const element = scrollListRef.current;
    if (!element) {
      return;
    }

    const hasOverflow = element.scrollWidth > element.clientWidth;
    setIsScrollable(hasOverflow);

    setIsAtStart(element.scrollLeft <= 0);
    setIsAtEnd(Math.ceil(element.scrollLeft) >= element.scrollWidth - element.clientWidth);
  };

  useEffect(() => {
    checkIsScrollable();
  }, [children]);

  useEffect(() => {
    const handleResize = () => {
      checkIsScrollable();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onScroll = () => {
    const element = scrollListRef.current;
    if (!element) return;

    setIsAtStart(element.scrollLeft === 0);
    setIsAtEnd(element.scrollLeft >= element.scrollWidth - element.clientWidth);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    const element = scrollListRef.current;
    if (!element) {
      return;
    }

    const distance = direction === 'left' ? -itemWidth * 2 : itemWidth * 2;
    const targetX = element.scrollLeft + distance;
    let scrollDestination;

    if (direction === 'left') {
      // If going left and would scroll past the start, go to the start
      scrollDestination = targetX < 0 ? 0 : targetX;
    } else {
      // If going right and would scroll past the end, go to the end
      const maxScroll = element.scrollWidth - element.clientWidth;
      scrollDestination = targetX > maxScroll ? maxScroll : targetX;
    }

    element.scrollTo({
      left: scrollDestination,
      behavior: 'smooth',
    });
  };

  return (
    <div className="relative">
      <Button
        aria-label={t('sections.studies.scrollLeft')}
        className={cn(
          'absolute left-1 top-1/2 z-10 -translate-y-1/2 border-0.5 bg-background/80 shadow-sm hover:bg-background',
          {
            hidden: !isScrollable || isAtStart,
          },
        )}
        onClick={() => handleScroll('left')}
        size="icon"
      >
        <ChevronLeft />
      </Button>

      <ol
        ref={scrollListRef}
        onScroll={onScroll}
        className={cn('scroll-snap-x scroll-snap-mandatory grid grid-flow-col gap-3 overflow-x-scroll p-[1px] pb-4', {
          'px-2': isCentered,
          'justify-center': !isScrollable && isCentered,
        })}
        style={{
          gridTemplateColumns: `repeat(${Children.count(children)}, ${itemWidth}px)`,
          gridAutoColumns: `${itemWidth}px`,
        }}
      >
        {children}
      </ol>

      <Button
        aria-label={t('sections.studies.scrollRight')}
        className={cn(
          'absolute right-1 top-1/2 z-10 -translate-y-1/2 border-0.5 bg-background/80 shadow-sm hover:bg-background',
          {
            hidden: !isScrollable || isAtEnd,
          },
        )}
        onClick={() => handleScroll('right')}
        size="icon"
      >
        <ChevronRight />
      </Button>
    </div>
  );
};

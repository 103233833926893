import { t } from 'i18next';
import { X } from 'lucide-react';

import { Button } from './/button';
import { Input } from './/input';

import type { ReactNode } from 'react';

type Props = {
  pageCount: number;
  searchValue: string;
  onSearchChange: (value: string) => void;
  onResetColumnFilters: () => void;
  isFiltered: boolean;
  children: ReactNode;
};

export const Toolbar = ({
  isFiltered,
  children,
  onResetColumnFilters,
  onSearchChange,
  pageCount,
  searchValue,
}: Props) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center gap-2">
        <Input
          disabled={pageCount === 0 && !isFiltered}
          placeholder={t('global.search', { defaultValue: 'Search', ns: 'components' })}
          value={searchValue}
          onChange={event => onSearchChange(event.target.value)}
          className="h-8 w-[150px] sm:w-[288px]"
        />
        {children}
        {isFiltered && (
          <Button variant="ghost" onClick={onResetColumnFilters} className="hidden h-8 px-2 sm:inline-flex lg:px-3">
            {t('global.reset', { defaultValue: 'Reset', ns: 'components' })}
            <X className="ml-2" size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { HeatmapContent } from 'containers/Studies/Results/components/Heatmap/HeatmapContent';
import { RecordingContent } from 'containers/Studies/Results/components/Recording/RecordingContent';
import { Stat } from 'containers/Studies/Results/components/Stat';
import { FigmaPrototypeResultDialogHeader } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogHeader';
import { FigmaPrototypeResultDialogLoading } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogLoading';
import { FigmaPrototypeResultPath } from 'containers/Studies/Results/data/FigmaPrototypeResultPath.query';
import { FigmaPrototypeResultPaths } from 'containers/Studies/Results/data/FigmaPrototypeResultPaths.query';

import type { BookingTaskResultStatus, BookingTaskResultFigmaPrototype } from 'generated/graphql';

interface FigmaPrototypeResultPathQueryParams {
  _booking_id: string;
  _path_id: string;
  _user_id?: string;
  has_results: boolean;
}

/**
 * Render recording and screens for a single path item
 */
export const FigmaPrototypeResultDialogContentPath = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { studyId, taskId, participantId } = params;
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') ?? 'recording');

  const nodeId = searchParams.get('node_id');
  const pathId = searchParams.get('path_id');
  const pathItemIndex = Number(searchParams.get('path_index') ?? 0);

  const variables: FigmaPrototypeResultPathQueryParams = {
    _booking_id: studyId ?? '',
    _path_id: pathId ?? '',
    has_results: true,
  };

  if (participantId) {
    variables._user_id = participantId;
  }

  // Paths are loaded on the Task screen and on the single participant screen. So query is dependent on either taskId or participantId
  const [{ data, fetching, error }] = useQuery({
    query: FigmaPrototypeResultPath,
    variables,
    pause: !(taskId || participantId) || !studyId || !pathId,
  });

  // Only fetch paths for navigation if we're not showing a single participant
  const [{ data: dataPaths }] = useQuery({
    query: FigmaPrototypeResultPaths,
    variables: {
      _booking_id: studyId ?? '',
      _task_id: taskId ?? '',
      has_results: false,
    },
    pause: !taskId || !!participantId,
  });

  const resultData = data?.figmaPrototypeResultPath;

  // For heatmap and video chapters
  const screens = resultData?.screens ?? [];
  const activeScreen =
    screens.find(item => item.id === nodeId) ?? screens[Math.max(0, Math.min(pathItemIndex, screens.length - 1))];

  // For recording
  const activeResult = resultData?.results?.at(0);

  // Hide related results for individual participant results
  const relatedResults = resultData?.results as BookingTaskResultFigmaPrototype[];
  const status = resultData?.result_status as BookingTaskResultStatus;

  // For header: title, navigation and stats
  const pathsWithSameStatus = dataPaths?.figmaPrototypeResultPaths?.filter(path => path.result_status === status) ?? [];
  const currentPathIndex = pathsWithSameStatus ? (pathsWithSameStatus.findIndex(p => p._id === pathId) ?? 0) : 0;
  const goalStatus = status === 'completed' ? 'reachedGoal' : 'reachedGoalFailed';

  const pathTitle = `${t('sections.studies.results.paths', { count: 1 })} ${currentPathIndex >= 0 ? currentPathIndex + 1 : ''}`;
  const participantName = activeResult?.user?.displayName || t('global.unknownUser');
  const title = participantId ? `${participantName}: ${pathTitle}` : pathTitle;

  const nextResultId =
    currentPathIndex < (pathsWithSameStatus?.length ?? 0) - 1 ? pathsWithSameStatus[currentPathIndex + 1]._id : null;
  const previousResultId = currentPathIndex > 0 ? pathsWithSameStatus[currentPathIndex - 1]._id : null;
  const stats = resultData?.stats;

  if (fetching && !resultData) {
    return <FigmaPrototypeResultDialogLoading />;
  }

  if (!fetching && error) {
    return <div className="p-4 text-destructive">{error.message}</div>;
  }

  return !fetching && activeScreen ? (
    <>
      <FigmaPrototypeResultDialogHeader
        activeTab={activeTab}
        hasNavigation={!!taskId}
        isLoading={fetching}
        nextResultId={nextResultId}
        previousResultId={previousResultId}
        title={title}
        type="path"
        onTabChange={setActiveTab}
      >
        <>
          <Stat type={goalStatus} value={t(`sections.studies.results.${goalStatus}`)} variant="inline" />
          <Stat type="responses" value={stats?.total_responses ?? 0} />
          <Stat type="misclickRate" value={stats?.misclick_rate ?? 0} variant="inline" />
          <Stat type="averageDuration" value={stats?.average_duration ?? 0} variant="inline" />
        </>
      </FigmaPrototypeResultDialogHeader>

      {activeTab === 'heatmap' ? (
        <HeatmapContent screens={screens} activeScreen={activeScreen} />
      ) : (
        <RecordingContent
          results={relatedResults ?? resultData?.results}
          screens={screens}
          showRelatedResults={!participantId}
          title={title}
        />
      )}
    </>
  ) : (
    <EmptyState />
  );
};

import { Button } from '@askable/ui/core/button';
import { ArrowLeft } from 'lucide-react';
import { Outlet, useMatch } from 'react-router-dom';
import { Box, Container, Flex, Heading, Image, Stack, Text, Tooltip, VStack } from 'ui';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';

import { useResearcherRegister } from './ResearcherAuthProvider';

import type { FC, ReactNode } from 'react';
import type { BoxProps } from 'ui';

type PageWrapperProps = BoxProps & {
  title: string;
  helperText?: string | ReactNode;
  illustration?: string;
};

export const ForgotPasswordContainer: FC<BoxProps> = ({ ...props }) => {
  return (
    <Flex flexDirection="column" justifyContent="flex-start" w="full">
      <VStack alignItems="flex-start" spacing="4" mb="10" {...props}>
        <Heading fontSize="3xl">Forgot password</Heading>
      </VStack>
      <Outlet />
    </Flex>
  );
};

export const ResearcherRegisterContainer: FC<PageWrapperProps> = ({
  children,
  illustration,
  title,
  helperText,
  ...props
}) => {
  // Use context here to get page info
  return (
    <Flex flexDirection="column" justifyContent="flex-start" w="full">
      <VStack alignItems="flex-start" spacing="4" mb="10" {...props}>
        {illustration && <Image src={illustration} height={200} mb="4" />}
        <Heading fontSize="3xl">{title}</Heading>
        {helperText && <Text>{helperText}</Text>}
      </VStack>
      <Outlet />
    </Flex>
  );
};

export const ResearcherAuthContainer: FC = () => {
  const { canGoBack, step, actions } = useResearcherRegister();
  const isCertification = useMatch('/researcher/certification');

  const containerMaxWidth = isCertification ? '5xl' : 'md';

  return (
    <MinimalPage>
      <Stack
        position="relative"
        justifyContent={['flex-start', 'center']}
        w="full"
        pt="8%"
        direction={['column', 'row']}
        spacing={[6, 0]}
      >
        {canGoBack && (
          <Box>
            <Tooltip label="Navigate back" openDelay={500} placement="auto-start">
              <Button aria-label="Navigate back" size="lg" variant="ghost" onClick={actions.goBack}>
                <ArrowLeft className="h-4 w-4" />
              </Button>
            </Tooltip>
          </Box>
        )}
        <Container
          display="flex"
          height="100%"
          maxW={containerMaxWidth}
          alignItems="flex-start"
          flexDirection="column"
          px={[6, 6, 0]}
        >
          {step !== null && (
            <Text mb="3" color="green.500">
              {step}
            </Text>
          )}

          <Outlet />
        </Container>
      </Stack>
    </MinimalPage>
  );
};

import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { TooltipTrigger, Tooltip, TooltipContent } from '@askable/ui/core/tooltip';
import { PanelLeftClose, PanelLeftOpen } from 'lucide-react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  ButtonGroup,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Pagination,
  PaginationCount,
  useClipboard,
  useDisclosure,
} from 'ui';

import { PageLayout } from 'components/Layout/PageLayout';
import { useBooking } from 'containers/Booking/BookingContainer';
import { useConnectedClient } from 'context/ConnectedClientContext';

import { useViewerUI } from '../../../../state/viewer-ui-state';

import { BookingParticipantMigrationErrorModal } from './components/BookingParticipantMigrationErrorModal';
import { BookingParticipantsNewInformationPopup } from './components/BookingParticipantsNewInformationPopup';
import { BookingParticipantsTable } from './components/BookingParticipantsTable/BookingParticipantsTable';
import {
  BookingSubmissionMarkCompleteAlert,
  useBookingSubmissionMarkCompleteModal,
} from './components/BookingSubmissionMarkCompleteAlert';
import { useBookingParticipantsTableData } from './hooks/useBookingParticipantsTableData';
import { DeleteSegmentModal } from './modals/DeleteSegmentModal';
import { RenameSegmentModal } from './modals/RenameSegmentModal';
import { bookingParticipantSelectors, useBookingParticipantState } from './state/booking-participant-state';
import { getBookingColumnConfig } from './utils/booking-participant-utils';

import type { Maybe, Segment, Booking } from 'generated/graphql';
import type { FlexProps } from 'ui';

type SegmentActionProps = {
  segment: Maybe<Segment>;
  bookingId: string;
};

const SegmentAction = memo(function SegmentActionMemo({ segment, bookingId }: SegmentActionProps) {
  const { onCopy } = useClipboard(window.location.href);

  const { isOpen: renameSegmentOpen, onOpen: openSegmentRename, onClose: closeSegmentRename } = useDisclosure();
  const {
    isOpen: deleteSegmentIsOpen,
    onOpen: openDeleteSegmentModal,
    onClose: closeDeleteSegmentModal,
  } = useDisclosure();

  const handleCopy = useCallback(() => {
    onCopy();

    toast.info('Link copied to clipboard');
  }, []);

  return (
    <>
      <Menu isLazy>
        <MenuButton as={Button} variant="ghost" size="icon">
          <ChevronDownIcon className="h-4 w-4" />
        </MenuButton>
        <MenuList zIndex="modal" fontSize="sm" maxW="sm">
          <MenuItem onClick={handleCopy}>Copy share link</MenuItem>
          <MenuItem onClick={openSegmentRename}>Rename</MenuItem>
          <MenuDivider />
          <MenuItem onClick={openDeleteSegmentModal}>Delete segment</MenuItem>
        </MenuList>
      </Menu>
      <RenameSegmentModal
        segment={segment!}
        bookingId={bookingId}
        isOpen={renameSegmentOpen}
        onClose={closeSegmentRename}
      />
      <DeleteSegmentModal
        segment={segment!}
        bookingId={bookingId}
        isOpen={deleteSegmentIsOpen}
        onClose={closeDeleteSegmentModal}
      />
    </>
  );
});

type ParticipantHeadingProps = FlexProps & {
  bookingId?: string;
};

const ParticipantHeading = memo(function ParticipantHeadingMemo({ bookingId }: ParticipantHeadingProps) {
  const { segmentsOpen, toggleSegmentsPanel } = useViewerUI();
  const segment = useBookingParticipantState(bookingParticipantSelectors.segment);
  const navigate = useNavigate();
  const location = useLocation();

  const hasHash = () => location.hash === '#segments';
  const toggleHash = () => {
    if (hasHash()) {
      navigate(location.pathname, { replace: true });
    } else {
      navigate(`${location.pathname}#segments`, { replace: true });
    }
  };

  return (
    <header className="flex items-center gap-2 px-4 pl-2 md:px-4">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            aria-label="Toggle segments"
            variant="ghost"
            size="icon"
            onClick={() => {
              toggleSegmentsPanel();
              toggleHash();
            }}
          >
            {segmentsOpen ? <PanelLeftClose className="h-4 w-4" /> : <PanelLeftOpen className="h-4 w-4" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent side="bottom">{segmentsOpen ? 'Collapse sidebar' : 'Open sidebar'}</TooltipContent>
      </Tooltip>

      <h2 className="text-lg font-semibold text-foreground">{segment?.name}</h2>

      {segment?._id !== 'all' ? <SegmentAction segment={segment!} bookingId={bookingId!} /> : null}
    </header>
  );
});

type BookingParticipantsTableContainerProps = {
  booking?: Booking;
};

const BookingParticipantsTableContainer = memo(function BookingParticipantsTableContainerMemo({
  booking: bookingProp,
}: BookingParticipantsTableContainerProps) {
  const bookingFromHook = useBooking();
  const booking = bookingProp || bookingFromHook;

  const viewer = useConnectedClient();
  const setPanelState = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
  const navigate = useNavigate();

  const {
    isOpen: isBookingParticipantMigrationErrorModalOpen,
    onOpen: onBookingParticipantMigrationErrorModalOpen,
    onClose: onBookingParticipantMigrationErrorModalClose,
  } = useDisclosure();

  const isSessions = booking?.config?.remote?.askable_live ?? false;
  const isCustomNDA = booking?.config?.participant_agreement?.type === 1;

  const columnConfig = getBookingColumnConfig(booking);

  const {
    isOpen: isMarkAsCompleteModalOpen,
    onClose: onMarkAsCompleteModalClose,
    onOpen: onMarkAsCompleteModalOpen,
    submissionId,
  } = useBookingSubmissionMarkCompleteModal();
  const {
    fetching,
    onBackClick,
    submissions,
    pageInfo,
    refreshData,
    perPageCount,
    setCurrentPage,
    onForwardClick,
    currentPage,
    count,
    isMigrationError,
  } = useBookingParticipantsTableData({
    isCustomNDA,
    columnConfig,
    bookingId: booking?._id!,
  });

  useEffect(() => {
    if (isMigrationError) {
      onBookingParticipantMigrationErrorModalOpen();
    }
  }, [isMigrationError]);

  const onNextClick = async () => {
    if (currentPage * perPageCount >= (count ?? 0)) {
      return;
    }

    setCurrentPage('forward');

    onForwardClick();
  };

  const onPreviousClick = async () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPage('back');

    onBackClick();
  };

  const pptActions = useMemo(() => {
    return {
      setPanelState,
      onMarkAsCompleteMenuItemClick: onMarkAsCompleteModalOpen,
    };
  }, [onMarkAsCompleteModalOpen]);

  if (!booking) {
    return null;
  }

  return (
    <PageLayout
      className="participantsContainer !mt-0 !pt-3"
      overflow="hidden"
      w="full!"
      maxW="full"
      heading={<ParticipantHeading bookingId={booking?._id!} px="5" />}
      p="0"
      h="full"
    >
      <Box overflow="hidden" w="full" h="full">
        <BookingParticipantsTable
          pptActions={pptActions}
          isLoading={fetching}
          isSessions={isSessions}
          isCustomNDA={isCustomNDA}
          booking={booking}
          pagination={
            <Pagination className="flex items-center gap-2">
              <PaginationCount perPageCount={perPageCount ?? 0} total={count! ?? 0} currentPage={currentPage ?? 1} />
              <ButtonGroup size="sm" spacing="1">
                <Button
                  aria-label="Previous page"
                  className="aspect-square"
                  disabled={currentPage === 1 || fetching}
                  onClick={onPreviousClick}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>

                <Button
                  aria-label="Next page"
                  className="aspect-square"
                  disabled={!pageInfo?.hasNextPage || fetching}
                  onClick={onNextClick}
                >
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
              </ButtonGroup>
            </Pagination>
          }
          submissions={submissions}
        />
      </Box>
      <BookingParticipantMigrationErrorModal
        isOpen={isBookingParticipantMigrationErrorModalOpen}
        onClose={() => [onBookingParticipantMigrationErrorModalClose(), navigate('/')]}
        key={1}
      />
      <BookingParticipantsNewInformationPopup
        viewerId={viewer?.details?.id!}
        bookingId={booking._id!}
        onRefresh={refreshData}
      />
      <BookingSubmissionMarkCompleteAlert
        bookingId={booking._id}
        submissionId={submissionId}
        isOpen={isMarkAsCompleteModalOpen}
        onClose={onMarkAsCompleteModalClose}
      />
    </PageLayout>
  );
});

export default BookingParticipantsTableContainer;

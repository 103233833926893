import { AnchorButton } from '@askable/ui/core/button';
import { Tabs, TabsList, TabsTrigger } from '@askable/ui/core/tabs';
import { Plus } from 'lucide-react';
import { Outlet, useLocation, Link } from 'react-router-dom';

import { useConnectedClient } from 'context/ConnectedClientContext';

export function RootContainer() {
  const location = useLocation();
  const { details } = useConnectedClient();

  const { pathname } = location;
  const view = pathname.split('/')[1] || 'studies';

  return (
    <div className="w-full overflow-auto p-4">
      <div className="mb-4 flex flex-row justify-between">
        <Tabs defaultValue="account" value={view}>
          <TabsList className="w-full">
            <TabsTrigger asChild value="studies">
              <Link to="/studies" replace>
                Studies
              </Link>
            </TabsTrigger>
            <TabsTrigger asChild value="askable-plus">
              <Link to="/askable-plus" replace>
                Askable Plus
              </Link>
            </TabsTrigger>
          </TabsList>
        </Tabs>

        {!details?.type?.researcher ? (
          <div className="hidden sm:inline-block">
            <AnchorButton href="/booking-setup/recruitment-type" size="lg" variant="primary" title="Create new booking">
              <Plus className="h-5 w-5" />
              New
            </AnchorButton>
          </div>
        ) : null}
      </div>

      <Outlet />
    </div>
  );
}

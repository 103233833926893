import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Checkbox = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="m45.3 6.7-22-.5A14 14 0 0 0 9 19.9l-.5 23a14 14 0 0 0 13.7 14.3l22 .5A14 14 0 0 0 58.5 44l.5-23A14 14 0 0 0 45.3 6.7Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M8 17.7c0-6.3 5.4-11.4 11.7-11.2l5.3.3 14.8.1a12 12 0 0 1 11.9 12.3l-.5 20.7a12 12 0 0 1-12.3 11.7L19 51A12 12 0 0 1 7.3 39l.2-10.4.5-10.8Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M8 17.7c0-6.3 5.4-11.4 11.7-11.2l5.3.3 14.8.1a12 12 0 0 1 11.9 12.3l-.5 20.7a12 12 0 0 1-12.3 11.7L19 51A12 12 0 0 1 7.3 39l.2-10.4.5-10.8Zm-2 0c0-7.5 6.3-13.5 13.8-13.2l5.2.3 14.8.1a14 14 0 0 1 13.9 14.3L53.2 40a14 14 0 0 1-14.3 13.7l-20-.5A14 14 0 0 1 5.4 38.8l.2-10.4.5-10.7Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M43.4 18.4a2.3 2.3 0 0 1 3.2 3.3l-20.9 22a2.3 2.3 0 0 1-3.4 0l-10-12a2.3 2.3 0 0 1 3.1-3.2l8.6 6.4 19.4-16.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

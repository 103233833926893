import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { Label } from '@askable/ui/core/label';
import { toast } from '@askable/ui/core/sonner';
import { Textarea } from '@askable/ui/core/textarea';
import { Plus, Send } from 'lucide-react';
import { template } from 'radash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { BatchMessage } from '../../BookingParticipantsContainer/data/BatchMessage.mutation';

/**
 * Send a new message to multiple users. . Used in PanelBatchMessages
 */

interface BatchMessageAddProps {
  bookingId: string;
  userIds: string[];
  onClose: () => void;
}

enum DynamicField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  SESSION_START = 'SESSION_START',
  SESSION_DURATION = 'SESSION_DURATION',
  INCENTIVE_AMOUNT = 'INCENTIVE_AMOUNT',
  BOOKING_LINK = 'BOOKING_LINK',
}

export const BatchMessageAdd = ({ bookingId, userIds, onClose }: BatchMessageAddProps) => {
  const { t } = useTranslation();
  const [cursorPosition, setCursorPosition] = useState(18);
  const [focus, setFocus] = useState<boolean | undefined>(undefined);
  const [messageData, setMessageData] = useState(`Hi {{${DynamicField.FIRST_NAME}}},`);
  const [{ error, fetching: isSending }, createBatchMessages] = useMutation(BatchMessage);

  const DYNAMIC_FIELD_CONFIG: Record<DynamicField, { label: string; key: string }> = {
    [DynamicField.FIRST_NAME]: {
      label: t('sections.messages.dynamicFields.firstName'),
      key: '{{user.meta.identity.firstname}}',
    },
    [DynamicField.LAST_NAME]: {
      label: t('sections.messages.dynamicFields.lastName'),
      key: '{{user.meta.identity.lastname}}',
    },
    [DynamicField.SESSION_START]: {
      label: t('sections.messages.dynamicFields.sessionStart'),
      key: '{{session_start}}',
    },
    [DynamicField.SESSION_DURATION]: {
      label: t('sections.messages.dynamicFields.sessionDuration'),
      key: '{{session_duration}}',
    },
    [DynamicField.INCENTIVE_AMOUNT]: {
      label: t('sections.messages.dynamicFields.incentiveAmount'),
      key: '{{incentive}}',
    },
    [DynamicField.BOOKING_LINK]: {
      label: t('sections.messages.dynamicFields.bookingLink'),
      key: '{{recruitment_shorlink}}',
    },
  };

  const DYNAMIC_FIELD_KEY_PAIRS = Object.fromEntries(
    Object.entries(DYNAMIC_FIELD_CONFIG).map(([field, { key }]) => [field, key]),
  );

  const addMessage = async (message: string) => {
    if (message === '' || !bookingId || userIds.length === 0) {
      return;
    }

    await createBatchMessages({
      _user_ids: userIds,
      _booking_id: bookingId,
      message: template(message, DYNAMIC_FIELD_KEY_PAIRS),
    });

    if (error) {
      toast.error('Sending message failed. Try again later.');
      return;
    }

    setMessageData('');
    toast.success(t('confirmations.sent', { title: t('sections.messages.title') }));
    onClose();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addMessage(messageData);
  };

  const insertDynamicField = (field: DynamicField) => {
    const fieldKey = ` {{${field}}}`;
    if (cursorPosition !== null) {
      const newMessage = `${messageData.slice(0, cursorPosition) + fieldKey + messageData.slice(cursorPosition)} `;
      setMessageData(newMessage);

      // Update cursor position after insertion
      setCursorPosition(cursorPosition + fieldKey.length);
    } else {
      // If no cursor position, append to the end
      setMessageData(prevMessage => prevMessage + fieldKey);
    }

    setFocus(undefined);
    setTimeout(() => setFocus(true), 200);
  };

  useEffect(() => {
    setTimeout(() => {
      setFocus(userIds.length > 0 ? true : undefined);
    }, 400);
  }, [userIds]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-1 flex-col">
      <Label htmlFor="add-message" className="sr-only">
        {t('sections.messages.writeAMessage')}
      </Label>

      <div className="h-full">
        <Textarea
          className="p-4"
          disabled={isSending}
          focus={focus}
          id="add-message"
          inputSize="md"
          maxRows={20}
          placeholder={t('sections.messages.writeAMessage')}
          rows={10}
          value={messageData}
          variant="borderless"
          onChange={e => setMessageData(e.target.value)}
          onCursorChange={position => setCursorPosition(position)}
        />
      </div>

      <div className="flex w-full justify-between gap-2 px-4 py-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" data-testid="menu-button">
              <Plus className="h-4 w-4" />
              {t('sections.messages.insertDynamicField')}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            {Object.entries(DYNAMIC_FIELD_CONFIG).map(([field, { label }]) => (
              <DropdownMenuItem key={field} onClick={() => insertDynamicField(field as DynamicField)}>
                {label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <Button isLoading={isSending} type="submit" variant="primary" disabled={messageData.length === 0}>
          <Send className="h-4 w-4" /> {t('sections.messages.sendMessage')}
        </Button>
      </div>
    </form>
  );
};

import { graphql } from 'src/graphql';

export const LoadFigmaImages = graphql(`
  mutation LoadFigmaImages($fileId: String!, $nodeIds: [String!]!) {
    loadFigmaImages(fileId: $fileId, nodeIds: $nodeIds) {
      _id
      frames {
        node_id
        image_url
      }
    }
  }
`);

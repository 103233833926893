import { z } from 'zod';

import {
  BookingDevice,
  BookingRecruitBusinessOwnership,
  BookingRecruitEducationLevel,
  BookingRecruitGender,
  BookingRecruitLocationType,
  BookingRecruitMaritalStatus,
  BookingRecruitOccupationalStatus,
  BookingRecruitParticipantSelection,
  BookingRecruitParticipantSource,
  BookingRecruitQuestionType,
  Locale,
  LocaleProficiency,
} from 'generated/graphql';

export const AGE_LIMIT = { min: 18, max: 99 };
export const PARTICIPANTS_LIMIT = { min: 1, max: 50 };
export const DEVICE_OPTIONS = [BookingDevice.AnyDevice, BookingDevice.Mobile, BookingDevice.Desktop] as const;

export const PARTICIPANT_SELECTION_OPTIONS = [
  BookingRecruitParticipantSelection.HandPicking,
  BookingRecruitParticipantSelection.Automated,
] as const;

export const PARTICIPANT_SOURCE_OPTIONS = [
  BookingRecruitParticipantSource.Panel,
  BookingRecruitParticipantSource.List,
] as const;

export const LANGUAGE_FLUENCY_OPTIONS = [
  LocaleProficiency.Native,
  LocaleProficiency.Fluent,
  LocaleProficiency.Conversational,
  LocaleProficiency.Beginner,
] as const;

export const LOCATION_TYPES = [
  BookingRecruitLocationType.Country,
  BookingRecruitLocationType.State,
  BookingRecruitLocationType.Bounds,
] as const;

export const EDUCATION_LEVEL_OPTIONS = [
  BookingRecruitEducationLevel.HighSchool,
  BookingRecruitEducationLevel.SomeUniversity,
  BookingRecruitEducationLevel.Undergraduate,
  BookingRecruitEducationLevel.Postgraduate,
  BookingRecruitEducationLevel.Apprenticeship,
] as const;

export const MARITAL_STATUS_OPTIONS = [
  BookingRecruitMaritalStatus.Single,
  BookingRecruitMaritalStatus.Married,
  BookingRecruitMaritalStatus.Divorced,
  BookingRecruitMaritalStatus.Widowed,
] as const;

export const OCCUPATIONAL_STATUS_OPTIONS = [
  BookingRecruitOccupationalStatus.FullTime,
  BookingRecruitOccupationalStatus.PartTime,
  BookingRecruitOccupationalStatus.Student,
  BookingRecruitOccupationalStatus.HomeDuties,
  BookingRecruitOccupationalStatus.Retired,
  BookingRecruitOccupationalStatus.Unemployed,
] as const;

export const BUSINESS_OWNERSHIP_OPTIONS = [
  BookingRecruitBusinessOwnership.Employee,
  BookingRecruitBusinessOwnership.SoleTrader,
  BookingRecruitBusinessOwnership.BusinessOwner,
] as const;

export const SCREENER_QUESTION_TYPES = [
  BookingRecruitQuestionType.ShortAnswer,
  BookingRecruitQuestionType.MultipleChoice,
] as const;

// NOTE: in the UI we want to show an `any gender` option, we do not store this on the BE, instead that's
// represented by an empty array
export const GENDER_OPTIONS = [
  'any_gender',
  BookingRecruitGender.Male,
  BookingRecruitGender.Female,
  BookingRecruitGender.NonBinary,
] as const;

// NOTE: this is an integer on the BE, but because our select currently only supports string values
// we use string here atm
export const PARTICIPANTS_EXCLUDED_OPTIONS = ['3', '6', '12', 'null'] as const;

const SCREENER_QUESTION_MIN_OPTIONS = 2;

const industryAndOccupationItemSchema = z.object({
  _id: z.string(),
  name: z.string(),
  subcategories: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
    }),
  ),
});

const screenerQuestionOptionSchema = z.object({
  _id: z.string(),
  label: z.string().min(1, { message: 'screenerQuestions.optionLabelRequired' }),
  is_qualified: z.boolean(),
});

const screenerQuestionSchema = z
  .object({
    _id: z.string(),
    title: z.string().min(1, { message: 'screenerQuestions.titleRequired' }),
    description: z.string().nullable().optional(),
    type: z.enum(SCREENER_QUESTION_TYPES),
    is_multiple_selection: z.boolean().optional(),
    options: z
      .array(
        screenerQuestionOptionSchema.superRefine((option, ctx) => {
          if (!option.label || option.label.trim() === '') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'screenerQuestions.optionRequired',
            });
          }
        }),
      )
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.type === 'multiple_choice') {
      // Check minimum options requirement
      const optionsLength = data.options?.length ?? 0;
      if (optionsLength < SCREENER_QUESTION_MIN_OPTIONS) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'screenerQuestions.minimumOptions',
        });
      }
    }
  });

const googleViewportTypeSchema = z.object({
  lat: z.number().optional().nullable(),
  lng: z.number().optional().nullable(),
});

const googleGeometrySchema = z.object({
  latitude_ne: z.number().optional().nullable(),
  longitude_ne: z.number().optional().nullable(),
  latitude_sw: z.number().optional().nullable(),
  longitude_sw: z.number().optional().nullable(),
  width: z.number().optional().nullable(),
  height: z.number().optional().nullable(),
  radius_min: z.number().optional().nullable(),
  radius_max: z.number().optional().nullable(),
});

const googleAddressComponentSchema = z.object({
  long_name: z.string().optional().nullable(),
  short_name: z.string().optional().nullable(),
  types: z.array(z.string().nullable()).optional().nullable(),
});

const googleLocationSchema = z.object({
  _place_id: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  map_polygon_id: z.string().optional().nullable(),
  geometry: googleGeometrySchema.optional().nullable(),
  address_components: z.array(googleAddressComponentSchema.nullable()).optional().nullable(),
  viewport: z
    .object({
      northeast: googleViewportTypeSchema.optional().nullable(),
      southwest: googleViewportTypeSchema.optional().nullable(),
    })
    .optional()
    .nullable(),
});

const locationSchema = z.object({
  type: z.enum(LOCATION_TYPES, { required_error: 'required' }),
  location: z.object({
    city: z.string().nullable(),
    country: z.string().nullable(),
    formatted_address: z.string(),
    google_location: googleLocationSchema.nullable(),
    latitude: z.number().nullable(),
    longitude: z.number().nullable(),
    postal_code: z.string().nullable(),
    region: z.string().nullable(),
    state: z.string().nullable(),
    street1: z.string().nullable(),
  }),
});

const languagesSchema = z.object({
  locale: z.nativeEnum(Locale).optional(),
  fluency: z.array(z.enum(LANGUAGE_FLUENCY_OPTIONS)),
});

const baseRecruitSchema = z.object({
  participants: z
    .number()
    .min(PARTICIPANTS_LIMIT.min, { message: 'participants.min' })
    .max(PARTICIPANTS_LIMIT.max, { message: 'participants.max' }),
  device: z.enum(DEVICE_OPTIONS, {
    required_error: 'device.required',
  }),
  locations: z.array(locationSchema).nonempty({ message: 'locations.required' }),
  age_range: z.tuple([
    z.number().min(AGE_LIMIT.min, { message: 'ageRange.min' }),
    z.number().max(AGE_LIMIT.max, { message: 'ageRange.max' }),
  ]),
  gender: z
    .array(z.enum(GENDER_OPTIONS), {
      required_error: 'gender.required',
      invalid_type_error: 'gender.required',
    })
    .nonempty({ message: 'gender.required' }),
  industry_and_occupation: z.array(industryAndOccupationItemSchema).optional(),
  languages: z.array(languagesSchema).optional(),
  education_level: z.array(z.enum(EDUCATION_LEVEL_OPTIONS)).optional(),
  marital_status: z.array(z.enum(MARITAL_STATUS_OPTIONS)).optional(),
  occupational_status: z.array(z.enum(OCCUPATIONAL_STATUS_OPTIONS)).optional(),
  business_ownership: z.array(z.enum(BUSINESS_OWNERSHIP_OPTIONS)).optional(),
  participant_selection: z.enum(PARTICIPANT_SELECTION_OPTIONS, { required_error: 'required' }),
  participant_source: z.enum(PARTICIPANT_SOURCE_OPTIONS, { required_error: 'required' }),
  // TODO: in the database this is `int | null`, ideally we can do the same within the form, but the Select component
  // currently only allows string values :|
  participants_excluded_months: z.enum(PARTICIPANTS_EXCLUDED_OPTIONS, { required_error: 'required' }),
  custom_terms_template_id: z.string().nullable().optional(),
  custom_terms_title: z.string().optional(),
  custom_terms_recipients: z.string().optional(),
  screener_questions: z.array(screenerQuestionSchema).optional(),
  _primary_contact_id: z.string().min(1, { message: 'required' }),
  listing_title: z.string().min(1, { message: 'listing_information.title.required' }).max(240, { message: 'max' }),
  listing_description: z.string().min(1, { message: 'listing_information.description.required' }),
});

export const recruitSchema = baseRecruitSchema;
export const recruitFormSchema = baseRecruitSchema.extend({
  enable_custom_terms: z.boolean(),
  enable_advanced_filters: z.boolean(),
});

export type RecruitFormFields = z.infer<typeof recruitFormSchema>;

import { Button } from '@askable/ui/core/button';
import { Survey } from '@askable/ui/unmod/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PhoneFrame } from 'containers/Studies/components/PhoneFrame';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

const CURRENCY_SYMBOL = '$';

const RewardDurationSection = () => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-4 text-sm">
      <div className="flex flex-col gap-1">
        <span>{t('sections.studies.recruit.reward')}</span>
        <div className="flex h-7 items-center gap-1 text-lg font-semibold">
          {CURRENCY_SYMBOL}
          <div className="h-full w-10 rounded-sm bg-darkest/10"></div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <span>{t('sections.studies.recruit.duration')}</span>
        <div className="h-7 w-14 rounded-sm bg-darkest/10"></div>
      </div>
    </div>
  );
};

export const OpportunityListingPreview = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<RecruitFormFields>();
  const [title, description] = useWatch({ control, name: ['listing_title', 'listing_description'] });

  return (
    <PhoneFrame backgroundColor="bg-brand-accent-4">
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex flex-col justify-between gap-4 p-4 text-darkest">
          {title ? (
            <div className="text-balance font-bricolage-grotesque text-3xl font-semibold text-darkest md:text-4xl">
              {title}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="h-10 w-full rounded-sm bg-darkest/10"></div>
              <div className="h-10 w-full rounded-sm bg-darkest/10"></div>
              <div className="h-10 w-2/3 rounded-sm bg-darkest/10"></div>
            </div>
          )}

          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-2 text-sm font-semibold">
              <Survey size={24} />
              <span>{t('sections.studies.recruit.onlineTask')}</span>
            </div>

            <RewardDurationSection />
          </div>
        </div>

        <div className="flex flex-1 flex-col gap-1 rounded-t-md bg-background p-4">
          <h2 className="text-lg font-semibold text-foreground">
            {t('sections.studies.recruit.aboutThisOpportunity')}
          </h2>
          <div className="whitespace-pre-wrap text-sm">
            {description ? description : <span className="text-muted-foreground">...</span>}
          </div>
        </div>
      </div>

      <footer className="flex items-center justify-between gap-4 border-t border-border bg-background p-4">
        <div className="flex h-7 items-center gap-1 text-lg font-semibold">
          {CURRENCY_SYMBOL}
          <div className="h-full w-8 rounded-sm bg-foreground/10"></div>
        </div>

        <Button disabled variant="primary" className="flex-1 rounded-xl">
          {t('sections.studies.recruit.apply')}
        </Button>
      </footer>
    </PhoneFrame>
  );
};

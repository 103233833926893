import { ActiveStatus } from '@askable/ui/icons/ActiveStatus';
import { ArchiveStatus } from '@askable/ui/icons/ArchiveStatus';
import { CompletedStatus } from '@askable/ui/icons/CompleteStatus';
import { DraftStatus } from '@askable/ui/icons/DraftStatus';

import { ProjectStatus } from 'generated/graphql';

import { CellStatus } from '../components/CellStatus';

import type { LucideProps } from 'lucide-react';
import type { ReactNode } from 'react';

export const statusData: Record<ProjectStatus, { Icon: (props: LucideProps) => ReactNode; title: string }> = {
  [ProjectStatus.Active]: {
    Icon: ActiveStatus,
    title: 'Active',
  },
  [ProjectStatus.Draft]: {
    Icon: DraftStatus,
    title: 'Draft',
  },
  [ProjectStatus.Completed]: {
    Icon: CompletedStatus,
    title: 'Completed',
  },
  [ProjectStatus.Archived]: {
    Icon: ArchiveStatus,
    title: 'Archived',
  },
};

export const ProjectsCellStatus = ({ status }: { status: ProjectStatus }) => {
  const { Icon, title } = statusData[status];

  return <CellStatus Icon={<Icon size={16} />} title={title} />;
};

import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingSubmissions = graphql(`
  query BookingSubmissions($first: Int, $filter: BookingSubmissionConnectionFilter, $after: ID, $before: ID) {
    bookingSubmissionConnection(first: $first, filter: $filter, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        _id
        applicant {
          _id
          firstname
          lastname
        }
        status
        status_updated
      }
    }
  }
`);

export type BookingSubmissionParticipants = NonNullable<
  NonNullable<ResultOf<typeof BookingSubmissions>['bookingSubmissionConnection']>['nodes']
>;

import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Figma = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={bgHsl}
        d="M8 18.8c-.8-2.4 0-5 .7-6.7.9-2.2 2.9-5.7 7.9-6.7l7.8 2 6.2 17.8-9.7 1.2A12.3 12.3 0 0 1 8 18.8Z"
      />
      <path
        fill={bgHsl}
        d="M24.4 7.4 34 6.2a13 13 0 0 1 11 4.3c.8 1 1.5 2.2 1.9 3.3.4 1.2.6 2.4.4 3.5a7 7 0 0 1-3.5 5.5c-1 .6-2.2 1-3.5 1.2l-9.7 1.2-6.2-17.8Z"
      />
      <path
        fill={bgHsl}
        d="M33.7 34a8.2 8.2 0 0 1-.5-3.4 7 7 0 0 1 3.6-5.5 8 8 0 0 1 3.5-1.1 13 13 0 0 1 10.9 4.3c.8 1 3 .1 3.4 1.3 1 2.7-.7 6.4-2.2 8.7a7.6 7.6 0 0 1-5.9 3.4 13 13 0 0 1-10.9-4.3c-.9-1-1.5-2.1-2-3.3ZM20.4 54.4c-.8-2.4-.5-4.8.7-6.7a8.2 8.2 0 0 1 6-3.5l9.7-1.2 4.3 6.6c.8 2.3 2 3-1.9 9a8.2 8.2 0 0 1-6 3.4 12.3 12.3 0 0 1-12.8-7.6Z"
      />
      <path
        fill={bgHsl}
        d="M14.3 36.6c-.9-2.4-.6-4.8.6-6.7a8.2 8.2 0 0 1 6-3.5l9.7-1.2L36.8 43 27 44.2a12.3 12.3 0 0 1-12.8-7.6Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M16.4 4.4h.4l7.6 2L34 5.2c1.4-.2 2.8-.1 4.3.2a14 14 0 0 1 7.4 4.5c1 1 1.7 2.3 2.1 3.6.5 1.3.7 2.6.5 4a8 8 0 0 1-3.4 5.8 14 14 0 0 1 7 4.4l.3.1h.9l1.2.3c.5.2 1 .5 1.2 1.2a8 8 0 0 1-.1 5.1c-.5 1.7-1.4 3.2-2.1 4.4-.7 1-1.7 2-2.8 2.7a12.5 12.5 0 0 1-8.2 1 14 14 0 0 1-5.6-2.6l1 2.6 4.2 6.5.1.3.3.7.3.8c.2.6.4 1.2.3 2-.1 1.5-1 3.3-2.8 6.3a9.2 9.2 0 0 1-6.7 3.9 13.3 13.3 0 0 1-13.9-8.3 8 8 0 0 1 .8-7.5 6 6 0 0 1 2.2-2.3 14 14 0 0 1-3.7-1.6 12.5 12.5 0 0 1-5.5-6.4 8.3 8.3 0 0 1 3-9.8 14 14 0 0 1-3.7-1.5A12.5 12.5 0 0 1 7 19c-1-2.7 0-5.6.7-7.3.9-2.4 3.1-6.3 8.6-7.4Zm4.6 23a7.2 7.2 0 0 0-5.3 3 6.5 6.5 0 0 0-.5 5.8c.7 2.1 2.4 4.1 4.6 5.4 2.2 1.4 4.8 2 7.1 1.6l8.5-1-2.7-7.8-2.8-8.1-9 1.1ZM32 26l.7 2.4a7.9 7.9 0 0 1 1.7-2.7L32 26Zm-.7-1.9L25.7 8.2l8.5-1a12 12 0 0 1 10 4c.8.9 1.4 2 1.8 3 .3 1 .4 2 .3 3a6 6 0 0 1-3 4.7c-1 .6-2 1-3.1 1l-9 1.2ZM23.6 8.2l5.6 16.2-8.5 1c-2.3.3-4.9-.2-7.1-1.6A10.5 10.5 0 0 1 9 18.5c-.7-2 0-4.3.6-6 .8-2 2.6-5.1 7-6l7 1.7Zm12.7 35.9-9.1 1.1a7.2 7.2 0 0 0-5.3 3 6.5 6.5 0 0 0-.5 5.8c.7 2.1 2.4 4 4.6 5.4 2.2 1.3 4.8 2 7.1 1.6a7.2 7.2 0 0 0 5.3-3c1.9-3 2.4-4.4 2.5-5.4 0-.4 0-.7-.2-1.1l-.2-.7-.3-.8-4-6Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M36.3 2a12 12 0 0 1 12.2 7.6c.4 1.3.5 2.5.4 3.6a7.2 7.2 0 0 1-3.4 5.4c-1 .6-2.1 1-3.3 1.2 1.2-.2 2.5-.1 3.7.2a12 12 0 0 1 8.5 7.4c.4 1.2.5 2.4.4 3.5a7.2 7.2 0 0 1-3.4 5.5 9.9 9.9 0 0 1-7.1.9 12 12 0 0 1-8.4-7.4 8.5 8.5 0 0 1-.5-3.5 7.2 7.2 0 0 1 3.4-5.5c1-.6 2.1-1 3.3-1.1L33 21l3 8.9 5.9 17.7c.8 2.3.5 4.7-.7 6.6a7.7 7.7 0 0 1-5.6 3.5A11.6 11.6 0 0 1 23.4 50c-.8-2.3-.6-4.7.6-6.6a7.7 7.7 0 0 1 5.7-3.4 11 11 0 0 1-7.4-1.8 11 11 0 0 1-4.8-5.9c-.8-2.3-.6-4.7.6-6.6a7.7 7.7 0 0 1 5.6-3.4c-2.4.3-5-.3-7.3-1.8a11 11 0 0 1-4.8-5.9c-.8-2.3-.6-4.7.6-6.6a7.7 7.7 0 0 1 5.7-3.4L36.3 2Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M19.2 23a13 13 0 0 1-8.8-8.2c-.8-2.6-.6-5.2.7-7.3 1.3-2 3.6-3.5 6.3-3.8l9.3-1.3 9.2-1.2c1.4-.2 2.8-.1 4.2.2a13.3 13.3 0 0 1 9.3 8.2c.5 1.3.6 2.6.5 3.9a8 8 0 0 1-3.4 5.8 13.4 13.4 0 0 1 6.8 4.4 10 10 0 0 1 2 3.6c.5 1.3.6 2.6.5 3.9a8 8 0 0 1-3.8 6c-1 .7-2.3 1.1-3.6 1.3-1.4.2-2.8.1-4.2-.2a13.3 13.3 0 0 1-5.4-2.7l1 3 3 9c.8 2.5.5 5.2-.8 7.2a8.6 8.6 0 0 1-6.2 3.9c-2.8.3-5.7-.3-8.2-2a12.2 12.2 0 0 1-5.4-6.5c-.8-2.6-.6-5.2.7-7.3.6-.9 1.4-1.7 2.2-2.3a12.8 12.8 0 0 1-8.8-8.1c-.8-2.6-.6-5.2.7-7.3a8 8 0 0 1 2.2-2.3ZM18 5.5l8.3-1.1 5.3 15.8-8.3 1c-2.2.4-4.6-.2-6.6-1.5a10 10 0 0 1-4.3-5.2 7 7 0 0 1 .6-6 7 7 0 0 1 5-3Zm6 17.7a7 7 0 0 0-5.1 3 7 7 0 0 0-.6 6c.7 2 2.3 4 4.3 5.2 2 1.3 4.4 1.9 6.6 1.6l8.3-1.1-5.3-15.8-8.3 1ZM29.8 41a7 7 0 0 0-5.1 3 7 7 0 0 0-.6 6A10.4 10.4 0 0 0 35 56.8a7 7 0 0 0 5-3.1 7 7 0 0 0 .6-6L38.1 40l-8.2 1Zm7-11.2c.3 1 .8 2 1.6 3a10.8 10.8 0 0 0 9.2 3.8c1.1-.1 2.1-.5 3-1a6.4 6.4 0 0 0 3-4.9c.1-1 0-2-.4-3a8.7 8.7 0 0 0-1.6-3 10.8 10.8 0 0 0-9.2-3.9c-1.1.2-2.1.5-3 1a6.4 6.4 0 0 0-3 4.9c-.1 1 0 2 .3 3.1Zm5-10.9c1-.1 2-.5 2.9-1a6.4 6.4 0 0 0 3-4.9c.1-1 0-2-.4-3.1-.3-1-.9-2-1.6-3a10.8 10.8 0 0 0-9.3-3.8l-8.2 1 5.3 16 8.2-1.2Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

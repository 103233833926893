import { Toolbar as ToolbarComp } from '@askable/ui/core/table-toolbar';

import type { Table } from '@tanstack/react-table';
import type { ReactNode } from 'react';

type Props<TData> = {
  table: Table<TData>;
  children: ReactNode;
  searchId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Toolbar = <TData extends unknown>({ searchId, children, table }: Props<TData>) => {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <ToolbarComp
      isFiltered={isFiltered}
      searchValue={(table.getColumn(searchId)?.getFilterValue() as string) || ''}
      onSearchChange={value => table.getColumn(searchId).setFilterValue(value)}
      onResetColumnFilters={() => table.resetColumnFilters()}
      pageCount={table.getPageCount()}
    >
      {children}
    </ToolbarComp>
  );
};

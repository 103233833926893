import { Link } from '@askable/ui/core/link';
import { cn } from '@askable/ui/lib/utils';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import ReactMarkdown from 'react-markdown';

import { bookingUtils } from 'lib/booking';

import type { Notifications } from 'generated/graphql';

type Props = {
  item: Notifications;
  onClick: (itemId: string) => void;
};
const NotificationItem = ({ item, onClick }: Props) => {
  return (
    <Link
      href={item.link}
      className={cn(
        `relative flex w-full flex-col gap-1 px-8 py-3 text-left font-normal text-muted-foreground no-underline
        hover:no-underline focus:bg-slate-100 active:bg-slate-100`,
      )}
      onClick={() => onClick(item._id)}
    >
      <div className="flex flex-col gap-1">
        <div className="wrap flex items-center">
          <span
            data-testid="read_notification"
            data-read={item.read}
            className={cn('-ml-5 mr-3 h-2 w-2 rounded-full', {
              'bg-stroke': item.read,
              'bg-destructive': !item.read,
            })}
          />
          <ReactMarkdown
            className={cn('whitespace-normal text-sm', {
              'font-semibold': !item.read,
            })}
          >
            {item.message}
          </ReactMarkdown>
        </div>
        {item.context ? (
          <>
            <div className="flex items-center gap-2">
              <div className="h-4 w-4">{bookingUtils.getBookingTypeSettings(item.context.booking)?.defaultIcon}</div>
              {item.context.booking?.name ? (
                <div className="whitespace-normal text-sm">{item.context.booking.name}</div>
              ) : null}
            </div>

            {item?.type === 4 && item.subtype === 1 && item.context.booking_participant?.feedback?.participant ? (
              <div>{item.context.booking_participant.feedback.participant}</div>
            ) : null}

            {item.type === 2 && item.subtype === 3 && item.context.messages?.body ? (
              <div>{item.context.messages.body}</div>
            ) : null}

            {item.type === 2 && item.subtype === 2 && item.context.booking_participant?.cancel_reason ? (
              <div>{item.context.booking_participant.cancel_reason}</div>
            ) : null}

            {item.context.participant_session?.start ? (
              <div className="flex gap-2">
                <time dateTime={item.context.participant_session.start.toString()}>
                  {momentTimezone(item.context.participant_session.start)
                    .tz(item.context.booking?.config?.timezone || 'Australia/Brisbane')
                    .format('dddd h:mm')}
                </time>
                -
                {item.context.participant_session.end ? (
                  <time dateTime={item.context.participant_session.end.toString()}>
                    {momentTimezone(item.context.participant_session.end)
                      .tz(item.context.booking?.config?.timezone || 'Australia/Brisbane')
                      .format('h:mma')}
                  </time>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <time dateTime={item.created?.toString()} className="mt-1 text-xs">
        {moment(item.created).fromNow()}
      </time>
    </Link>
  );
};

export default NotificationItem;

/* eslint-disable max-lines */
import { Button } from '@askable/ui/core/button';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Flex, HStack, Heading, HeartFilledIcon, useDisclosure } from 'ui';
import { useQuery } from 'urql';

import BookingOverviewContainer from 'containers/Booking/containers/BookingOverviewContainer';
import { SidePanelSubmissionDocument } from 'generated/graphql';
import { utils } from 'lib/utils';

import { bookingParticipantSelectors, useBookingParticipantState } from '../../state/booking-participant-state';
import {
  BookingParticipantInviteWaitistedModal,
  useBookingParticipantInviteWaitlistedModalDisclosure,
} from '../BookingParticipantInviteWaitlistedModal';

import { BookingParticipantBulkInviteModerateSidePanel } from './BookingParticipantBulkInviteModerateSidePanel';
import { BookingParticipantBulkInviteUnModerateSidePanel } from './BookingParticipantBulkInviteUnModerateSidePanel';
import { BookingParticipantBulkMessageSidePanel } from './BookingParticipantBulkMessageSidePanel';
import { BookingParticipantCancel } from './BookingParticipantCancel';
import { BookingParticipantDetailsSidePanel } from './BookingParticipantDetailsSidePanel';
import { BookingParticipantInvitePanel } from './BookingParticipantInvitePanel';
import { BookingParticipantMessagesPanel } from './BookingParticipantMessages';
import { BookingParticipantResendInvite } from './BookingParticipantResendInvite';
import { BookingParticipantSessionDetails } from './BookingParticipantSessionDetails';
import { BookingParticipantsReport } from './BookingParticipantsReport';
import { BookingParticipantReschedulePanel } from './BookingparticipantReschedulePanel';

import type {
  BookingParticipantBulkPanelName,
  BookingParticipantSinglePanelType,
  BookingDetailsPanelType,
} from '../../state/booking-participant-state';
import type { OpenInviteWaitlistedModalFunc } from '../BookingParticipantInviteWaitlistedModal';
import type { SidePanelSubmissionQuery, SidePanelSubmissionQueryVariables } from 'generated/graphql';
import type { FC, ReactNode } from 'react';
import type { FlexProps } from 'ui';

/**
 * Stepper or single type flow
 */
type CombinedPanels = BookingParticipantBulkPanelName | BookingParticipantSinglePanelType | BookingDetailsPanelType;

const PANEL_TYPES: { [key in CombinedPanels]: 'stepper' | 'single' } = {
  'booking-details': 'single',
  details: 'single',
  'report-issue': 'stepper',
  message: 'single',
  cancel: 'stepper',
  reschedule: 'stepper',
  'resend-invite': 'stepper',
  invite: 'stepper',
  'session-joining-links': 'single',
  'bulk-invite': 'stepper',
  'bulk-message': 'stepper',
};

type BookingParticipantSidePanelHeaderProps = FlexProps & {
  heading?: string | ReactNode;
  handleClose: () => void;
};
const BookingParticipantSidePanelHeader: FC<BookingParticipantSidePanelHeaderProps> = ({ handleClose, heading }) => {
  const panelState = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);

  if (PANEL_TYPES[panelState?.panel!] === 'stepper' || panelState === null) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-between gap-2 border-b border-border px-4 py-2">
      {typeof heading === 'string' ? <h3 className="text-lg font-semibold">{heading}</h3> : heading}
      <Button variant="ghost" size="icon" onClick={handleClose}>
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
};

type BulkPanelProps = {
  bookingId: string;
  onClose: () => void;
  isModeratedBooking: boolean;
};

const BulkPanelContainer: FC<BulkPanelProps> = ({ onClose, bookingId, isModeratedBooking }) => {
  const panelState = useBookingParticipantState(bookingParticipantSelectors.bulkSidePanelState);
  const setPanelState = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
  const onBulkActionComplete = useBookingParticipantState(bookingParticipantSelectors.completeBulkAction);

  const handleClose = () => {
    onClose();

    setPanelState(null);
  };

  const onActionComplete = () => {
    onClose();

    onBulkActionComplete();
  };
  const body = useMemo(() => {
    switch (panelState?.panel) {
      case 'bulk-invite':
        if (isModeratedBooking) {
          return (
            <BookingParticipantBulkInviteModerateSidePanel
              onComplete={onActionComplete}
              bookingId={bookingId}
              onClose={handleClose}
              submissionIds={panelState.data.submissionIds}
            />
          );
        }
        return (
          <BookingParticipantBulkInviteUnModerateSidePanel
            onComplete={onActionComplete}
            bookingId={bookingId}
            onClose={handleClose}
            submissionIds={panelState.data.submissionIds}
          />
        );
      case 'bulk-message':
        return (
          <BookingParticipantBulkMessageSidePanel
            onComplete={onActionComplete}
            bookingId={bookingId}
            onClose={handleClose}
            submissionIds={panelState.data.submissionIds}
          />
        );
      default:
        return null;
    }
  }, [panelState, bookingId]);

  return (
    <Flex
      h="full"
      overflowX="hidden"
      justifyContent="space-between"
      alignItems="center"
      flexDir="column"
      w="full"
      whiteSpace="normal"
    >
      <BookingParticipantSidePanelHeader
        w="full"
        px="6"
        py="3"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="gray.200"
        handleClose={handleClose}
      />
      <Flex h="full" w={WIDTHS[panelState?.panel!]}>
        {body}
      </Flex>
    </Flex>
  );
};

type SinglePanelProps = {
  bookingId: string;
  isModeratedBooking: boolean;
  onClose: () => void;
  openInviteWaitlistedModal: OpenInviteWaitlistedModalFunc;
  description: string | null;
};

const SinglePanelContainer: FC<SinglePanelProps> = ({
  onClose,
  isModeratedBooking,
  bookingId,
  openInviteWaitlistedModal,
  description,
}) => {
  const panelState = useBookingParticipantState(bookingParticipantSelectors.singleSidePanelState);
  const setPanelState = useBookingParticipantState(bookingParticipantSelectors.setPanelState);

  const handleClose = () => {
    onClose();
    setPanelState(null);
  };

  const [{ data: submission, fetching }] = useQuery<SidePanelSubmissionQuery, SidePanelSubmissionQueryVariables>({
    query: SidePanelSubmissionDocument,
    pause: !!panelState && panelState.panel === 'booking-details',
    variables: {
      id: panelState?.panel && panelState?.panel !== 'booking-details' ? panelState.submissionId : '',
    },
  });

  const displayName = submission?.bookingSubmissionById?.applicant.firstname ?? '';

  const body = useMemo(() => {
    if (fetching) {
      return null;
    }

    const timezone = submission?.bookingSubmissionById?.applicant?.timezone ?? utils.getCurrentTimezone();

    switch (panelState?.panel) {
      case 'details':
        return (
          <BookingParticipantDetailsSidePanel
            name={displayName}
            status={submission?.bookingSubmissionById?.status!}
            submissionId={panelState.submissionId}
          />
        );
      case 'booking-details':
        return <BookingOverviewContainer />;
      case 'message':
        return (
          <BookingParticipantMessagesPanel
            bookingId={bookingId}
            user={{
              _id: submission?.bookingSubmissionById?.applicant._id!,
              displayName,
            }}
          />
        );
      case 'invite':
        return (
          <BookingParticipantInvitePanel
            bookingId={bookingId}
            applicantName={displayName}
            isModeratedBooking={isModeratedBooking}
            pptTimezone={timezone}
            onClose={handleClose}
            submissionId={panelState.submissionId}
            userId={submission?.bookingSubmissionById?.applicant?._id!}
          />
        );
      case 'reschedule':
        return (
          <BookingParticipantReschedulePanel
            bookingId={bookingId}
            title={displayName}
            pptTimezone={timezone}
            onClose={handleClose}
            submissionId={panelState.submissionId}
          />
        );

      case 'report-issue':
        return (
          <BookingParticipantsReport
            onClose={handleClose}
            displayName={displayName}
            bookingId={bookingId}
            submissionStatus={submission?.bookingSubmissionById?.status!}
            userId={submission?.bookingSubmissionById?.applicant._id!}
            submissionId={panelState.submissionId}
            sessionId={submission?.bookingSubmissionById?.session?._session_id}
            openInviteWaitlistedModal={openInviteWaitlistedModal}
            sessionStartTime={submission?.bookingSubmissionById?.session?.session?.start}
            isModeratedBooking={isModeratedBooking}
          />
        );

      case 'session-joining-links': {
        return (
          <BookingParticipantSessionDetails
            userId={submission?.bookingSubmissionById?.applicant._id!}
            confirmedSessionId={submission?.bookingSubmissionById?.session?._session_id ?? ''}
            bookingId={bookingId}
          />
        );
      }
      case 'resend-invite':
        return (
          <BookingParticipantResendInvite
            bookingId={bookingId}
            onClose={handleClose}
            session={submission?.bookingSubmissionById?.session}
            pptDisplayname={submission?.bookingSubmissionById?.applicant.firstname || ''}
            pptTimezone={submission?.bookingSubmissionById?.applicant.timezone || ''}
            isModeratedBooking={isModeratedBooking}
            submissionStatus={submission?.bookingSubmissionById?.status!}
            submissionId={submission?.bookingSubmissionById?._id!}
            incentive={submission?.bookingSubmissionById?.incentive}
            description={description}
          />
        );
      case 'cancel':
        return (
          <BookingParticipantCancel
            isModeratedBooking={isModeratedBooking}
            bookingId={bookingId}
            onClose={handleClose}
            submissionId={submission?.bookingSubmissionById?._id}
            session={submission?.bookingSubmissionById?.session}
            pptDisplayname={displayName}
            pptTimezone={timezone}
            openInviteWaitlistedModal={openInviteWaitlistedModal}
            submissionStatus={submission?.bookingSubmissionById?.status}
          />
        );
      default:
        return null;
    }
  }, [panelState, bookingId, submission]);

  const title = useMemo(() => {
    switch (panelState?.panel) {
      case 'details':
        return (
          <HStack>
            <Heading size="md" noOfLines={1}>
              {submission?.bookingSubmissionById?.applicant.firstname}{' '}
              {submission?.bookingSubmissionById?.applicant.lastname}
            </Heading>
            {submission?.bookingSubmissionById?.shortlisted && <HeartFilledIcon ml="2" color="red.500" />}
          </HStack>
        );
      case 'booking-details':
        return 'Overview';
      case 'message':
        return `Message ${displayName}`;
      case 'invite':
        return `Invite ${displayName}`;
      case 'session-joining-links':
        return 'Session links';
      default:
        return '';
    }
  }, [submission, panelState]);

  return (
    <Flex
      h="full"
      overflowX="hidden"
      justifyContent="space-between"
      alignItems="center"
      flexDir="column"
      w="full"
      whiteSpace="normal"
    >
      <BookingParticipantSidePanelHeader
        w="full"
        px="6"
        heading={title}
        py="3"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="gray.200"
        handleClose={handleClose}
      />
      <Flex h="full" w={WIDTHS[panelState?.panel!]} overflow="auto" alignItems="start">
        {body}
      </Flex>
    </Flex>
  );
};

type Props = {
  bookingId: string;
  isModeratedBooking: boolean;
  description: string | null;
};

const WIDTHS = Object.keys(PANEL_TYPES).reduce(
  (acc, key) => {
    const typed = key as CombinedPanels;
    acc[typed] = key === 'details' ? 512 : 385;

    return acc;
  },
  {} as { [key in CombinedPanels]: number },
);

export const BookingParticipantDrawer: FC<Props> = ({ bookingId, isModeratedBooking, description }) => {
  const panelState = useBookingParticipantState(bookingParticipantSelectors.sidePanelState);
  const ref = useRef<HTMLDivElement | null>(null);
  const [hidden, setHidden] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isInviteWaitlistedModalOpen,
    openInviteWaitlistedModal,
    closeInviteWaitlistedModal,
    sessionId,
    sessionStartTime,
    submissionId,
    waitlistedSubmissionsWithSameSession,
  } = useBookingParticipantInviteWaitlistedModalDisclosure();

  useEffect(() => {
    if (isOpen && panelState === null) {
      onClose();
    }
  }, [panelState]);

  useEffect(() => {
    if (!panelState) {
      return;
    }

    onOpen();
  }, [panelState, onOpen]);

  return (
    <Box
      as={motion.div}
      hidden={hidden}
      height="full"
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => {
        setHidden(!isOpen);
        if (isOpen && ref) {
          ref.current?.focus();
        }
      }}
      tabIndex={0}
      animate={{ width: isOpen ? WIDTHS[panelState?.panel!] : 0 }}
      borderLeft="1px"
      flexGrow={0}
      flexShrink={0}
      borderColor="gray.200"
      overflow="hidden"
      whiteSpace="nowrap"
      display="flex"
      ref={ref}
    >
      {panelState?.type === 'single' ? (
        <SinglePanelContainer
          isModeratedBooking={isModeratedBooking}
          bookingId={bookingId}
          onClose={onClose}
          openInviteWaitlistedModal={openInviteWaitlistedModal}
          description={description}
        />
      ) : (
        <BulkPanelContainer isModeratedBooking={isModeratedBooking} bookingId={bookingId} onClose={onClose} />
      )}
      <BookingParticipantInviteWaitistedModal
        bookingId={bookingId}
        isOpen={isInviteWaitlistedModalOpen}
        onClose={closeInviteWaitlistedModal}
        sessionStartTime={sessionStartTime}
        sessionId={sessionId}
        submissionId={submissionId}
        waitlistedSubmissionsWithSameSession={waitlistedSubmissionsWithSameSession}
      />
    </Box>
  );
};

import { Button } from '@askable/ui/core/button';
import { Card, CardContent } from '@askable/ui/core/card';
import { cn } from '@askable/ui/lib/utils';

import type { ReactNode } from 'react';

type Props = {
  teamName: string;
  teamId: string;
  children?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  onJoinTeam: () => void;
};

export const JoinTeamCard = ({ children, disabled, teamName, isLoading, onJoinTeam }: Props) => {
  return (
    <Card className={cn({ 'pointer-events-none opacity-50': disabled })}>
      <CardContent className="flex items-center justify-between p-4">
        <div className="flex items-center justify-start space-x-4">
          <div>
            <div
              className="flex h-12 w-12 items-center justify-center rounded-sm border-border bg-accent font-medium uppercase
                text-accent-foreground"
            >
              {teamName[0]}
            </div>
          </div>
          <div className="space-y-1">
            <p className="line-clamp-2 text-sm font-medium">{teamName}</p>
            <p className="line-clamp-1 text-xs text-muted-foreground">{children}</p>
          </div>
        </div>
        <Button className="ml-4 mr-2 !opacity-100" onClick={onJoinTeam} isLoading={isLoading}>
          Join
        </Button>
      </CardContent>
    </Card>
  );
};

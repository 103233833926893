import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/core/select';

type Props = {
  minAge: number;
  maxAge: number;
  /**
   *
   * @param v Year of birth
   * @returns
   */
  onMinChange: (v: number) => void;
  /**
   *
   * @param v Year of birth
   */
  onMaxChange: (v: number) => void;
};

const currentYear = new Date().getFullYear();

const minAges = Array.from({ length: 83 }, (_, i) => {
  const age = i + 18;
  return {
    value: currentYear - age,
    label: `${age}`,
  };
});

const maxAges = Array.from({ length: 83 }, (_, i) => {
  const age = i + 18;
  return {
    value: currentYear - age,
    label: `${age}`,
  };
});

export const AgeRange = ({ onMinChange, onMaxChange, minAge, maxAge }: Props) => {
  return (
    <div className="flex items-center space-x-2">
      <Select defaultValue={`${minAge}`} onValueChange={value => onMinChange(Number(value))}>
        <SelectTrigger className="w-[6rem]">
          <SelectValue placeholder="Min age" />
        </SelectTrigger>
        <SelectContent>
          {minAges
            .filter(b => b.value >= maxAge)
            .map(item => (
              <SelectItem key={item.value} value={`${item.value}`}>
                {item.label}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      <span>-</span>
      <Select defaultValue={`${maxAge}`} onValueChange={value => onMaxChange(Number(value))}>
        <SelectTrigger className="w-[6rem]">
          <SelectValue placeholder="Max age" />
        </SelectTrigger>
        <SelectContent>
          {maxAges
            .filter(a => a.value <= minAge)
            .map(item => (
              <SelectItem key={item.value} value={`${item.value}`}>
                {item.label}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  );
};

import { Button } from '@askable/ui/core/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Calendar } from 'lucide-react';
import momentTimezone from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { MiniCalendar } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import { askablePlusUtils } from 'lib/askablePlus';

import './styles/timeframeStyles.scss';

function DueDate(props: any) {
  const timezone = momentTimezone.tz.guess();
  const intercom = useIntercom();

  const askablePlusBrief = _.get(props, 'askablePlusBrief');
  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [openMiniCalendar, setOpenMiniCalendar] = useState(false);
  const [endDate, setEndDate] = useState(_.get(askablePlusBrief, 'askable_plus.due_date'));

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(askablePlusBrief);

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Project Setup',
        subStep: `/askable-plus/${askablePlusBrief._id}/project-setup/due-date`,
        stepId: 'project_setup_due_date',
      });
    }
  }, []);

  useEffect(() => {
    props.renderRightContent(rightContent());
  }, [askablePlusBriefState]);

  const rightContent = () => {
    const cardTexts = [
      {
        cardText: "We recommend setting a deadline anyway even if it's rough. You can always update it later.",
      },
    ];
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__dueDateCard1"
          cardClass="slideInAnimationDelay80"
          cardTitle="What if I don't have an exact due date in mind?"
          cardTexts={cardTexts}
          onLinkClick={() => {
            intercom.showNewMessages();
          }}
          linkText="Chat to us"
          hideArrow
        />
      </>
    );
  };

  const onClickNext = () => {
    let redirectTo;
    if (totalResearchTypesSelected === 0) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    } else if (totalResearchTypesSelected === 1) {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/project-setup/quota`;
    } else {
      redirectTo = `/askable-plus/${askablePlusBrief._id}/discovery-interview/quota`;
    }
    props.history.push({ pathname: redirectTo });
  };

  const onChangeDate = (day: any) => {
    const selectedDay = momentTimezone(day).tz(timezone).endOf('day');
    setEndDate(selectedDay.valueOf());
    setOpenMiniCalendar(false);

    const askablePlusBriefStateObj = {
      ...askablePlusBrief,
      askable_plus: {
        ...askablePlusBrief.askable_plus,
        due_date: selectedDay.valueOf(),
      },
    };
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  return (
    <div className="createAskablePlusBriefContent">
      <h1 className="title">Due date</h1>
      <h1 className="label">Let us know by which date you’re aiming to have this project completed.</h1>
      <Popover open={openMiniCalendar} onOpenChange={setOpenMiniCalendar}>
        <PopoverTrigger>
          <div
            id="dueDateContainer"
            className="flex w-64 items-center justify-between rounded-sm border p-2"
            onClick={() => setOpenMiniCalendar(true)}
          >
            <p id="__dueDate" className="pl-2">
              {momentTimezone(endDate).tz(timezone).format('dddd, Do MMMM')}
            </p>
            <Calendar color="#666" className="h-4 w-4" />
          </div>
        </PopoverTrigger>
        <PopoverContent align="start">
          <MiniCalendar
            timezone={timezone}
            month={momentTimezone(endDate).tz(timezone).month()}
            year={momentTimezone(endDate).tz(timezone).year()}
            selectedDay={endDate}
            onClickDay={(day: any) => {
              onChangeDate(day);
              setOpenMiniCalendar(false);
            }}
            disablePastDates
          />
        </PopoverContent>
      </Popover>

      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default deprecatedWithRouter(DueDate);

import { Button } from '@askable/ui/core/button';
import { Skeleton } from '@askable/ui/core/skeleton';
import { toast } from '@askable/ui/core/sonner';
import { Upload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClient, useQuery } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { InputField } from 'containers/Studies/components/Fields/InputField';

import { EsignaturePreviewUrl } from '../data/EsignaturePreviewUrl.query';
import { EsignatureTemplate } from '../data/EsignatureTemplate.query';

import { CustomTermsUpload } from './CustomTermsUpload';
import { RadioGroupField } from './Fields/RadioGroupField';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

interface CustomTermsProps {
  isDisabled?: boolean;
}

interface Template {
  label: string;
  value: string;
  hasPreview: boolean;
}

export const CustomTerms = ({ isDisabled }: CustomTermsProps) => {
  const { t } = useTranslation();
  const client = useClient();
  const { study } = useStudyContext();

  /**
   * REFACTOR COMPONENT TO DEAL WITH THE FORM VALUE IN A SINGLE FORM FIELD, INSTEAD OF
   * AWKWARDLY SPLIT UP ACROSS MULTIPLE FIELDS
   */
  const templateIdController = useController<RecruitFormFields>({ name: 'custom_terms_template_id' });

  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [previewLoadingId, setPreviewLoadingId] = useState<string | null>(null);

  // HACK: using state to handle templates being uploaded
  const [templates, setTemplates] = useState<Template[]>([]);

  const [templateQuery] = useQuery({
    query: EsignatureTemplate,
    pause: !study?._id,
    variables: {
      id: study?._id,
    },
  });

  useEffect(() => {
    setTemplates(
      templateQuery.data?.getBookingESignatureTemplates?.map(item => ({
        label: `${item?.name ?? ''}${!item?.provider?.reference ? ` (${t('sections.studies.status.in_review')})` : ''}`,
        value: item?._id ?? '',
        hasPreview: !!item?.provider?.reference,
      })) || [],
    );
  }, [t, templateQuery.data?.getBookingESignatureTemplates]);

  const handlePreview = async (id: string) => {
    setPreviewLoadingId(id);

    try {
      const previewResult = await client
        .query(EsignaturePreviewUrl, { id }, { requestPolicy: 'network-only' })
        .toPromise();

      if (previewResult.data && previewResult.data.getESignaturePreviewURL) {
        window.open(previewResult.data.getESignaturePreviewURL);
      } else {
        throw new Error(t('sections.studies.failedToGetPreviewLink'));
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : t('sections.studies.failedToGetPreviewLink'));
    } finally {
      setPreviewLoadingId(null);
    }
  };

  const handleTermsUpload = ({ _id, name }: { _id: string; name: string }) => {
    const newLabel = `${name} (${t('sections.studies.status.in_review')})`;
    setTemplates(prev => [
      ...prev,
      {
        label: newLabel,
        value: _id,
        hasPreview: false,
      },
    ]);

    templateIdController.field.onChange(_id);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        {templateQuery.fetching ? <Skeleton className="h-8 w-2/3" /> : null}

        {templates.length > 0 ? (
          <RadioGroupField
            name="custom_terms_template_id"
            label=""
            options={templates}
            variant="stacked"
            isDisabled={isDisabled}
            previewLoadingId={previewLoadingId}
            onPreview={handlePreview}
          />
        ) : null}

        <Button variant="secondary" className="h-10 w-full" onClick={() => setIsOpenUpload(true)}>
          <Upload className="h-4 w-4" />
          {t('sections.studies.recruit.fields.customTermsCta')}
        </Button>

        <InputField
          label={t('sections.studies.recruit.fields.customTermsRecipientsTitle')}
          description={t('sections.studies.recruit.fields.customTermsRecipientsDescription')}
          infoPopover={{
            title: t('sections.studies.recruit.fields.customTermsRecipientsCalloutTitle'),
            description: t('sections.studies.recruit.fields.customTermsRecipientsCalloutDescription'),
            moreLink: '/settings/resources',
            moreLinkLabel: t('sections.studies.recruit.goToResources'),
          }}
          name="custom_terms_recipients"
          placeholder={t('sections.studies.recruit.fields.customTermsRecipientsPlaceholder')}
        />
      </div>

      <CustomTermsUpload isOpen={isOpenUpload} onClose={() => setIsOpenUpload(false)} onSuccess={handleTermsUpload} />
    </>
  );
};

import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

interface PhoneFrameProps {
  children: ReactNode;
  backgroundColor?: string;
}

const now = new Date();
const hours = now.getHours() % 12 || 12;
const minutes = String(now.getMinutes()).padStart(2, '0');

export const PhoneFrameHeader = ({ backgroundColor = 'bg-background' }: { backgroundColor?: string }) => {
  return (
    <div
      className={`grid grid-cols-3 items-center ${backgroundColor} px-4 py-2 text-sm font-semibold leading-none text-darkest`}
    >
      <div>{`${hours}:${minutes}`}</div>
      <div>
        <div className="h-6 w-full rounded-full bg-darkest"></div>
      </div>
      <div className="flex justify-end gap-1">
        <div className="h-[9px] w-4 rounded-sm bg-darkest/30">
          <div className="h-full w-3 rounded-l-sm bg-darkest"></div>
        </div>
      </div>
    </div>
  );
};

export const PhoneFrame = ({ backgroundColor = 'bg-background', children }: PhoneFrameProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`m-auto flex aspect-[9/18] w-full max-w-96 select-none flex-col overflow-hidden rounded-[30px] border-[6px]
      border-darkest ${backgroundColor} shadow-md`}
      aria-label={t('global.preview')}
    >
      <PhoneFrameHeader backgroundColor={backgroundColor} />
      {children}
    </div>
  );
};

import { toast } from '@askable/ui/core/sonner';
import { Cog, Explore, Target } from '@askable/ui/unmod/icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { SectionAudience } from 'containers/Studies/Recruit/components/SectionAudience';
import { SectionContainer } from 'containers/Studies/Recruit/components/SectionContainer';
import { SectionOpportunityListing } from 'containers/Studies/Recruit/components/SectionOpportunityListing';
import { SectionParticipants } from 'containers/Studies/Recruit/components/SectionParticipants';
import { SectionScreenerQuestions } from 'containers/Studies/Recruit/components/SectionScreenerQuestions';
import { SectionSettings } from 'containers/Studies/Recruit/components/SectionSettings';
import { mapFormFieldsToBooking } from 'containers/Studies/Recruit/utils/mapFormFieldsToBooking';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { UpdateBookingConfig } from 'containers/Studies/data/UpdateBookingConfig.mutation';
import { useFormAutoSave } from 'containers/Studies/utils/useFormAutoSave';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { UseFormReturn } from 'react-hook-form';

type RecruitFormProps = {
  form: UseFormReturn<RecruitFormFields>;
  isDisabled?: boolean;
};

export const RecruitForm = ({ form, isDisabled }: RecruitFormProps) => {
  const { t } = useTranslation();
  const { studyId, setIsSaving, hasSubmitted } = useStudyContext();

  const [, updateBookingConfig] = useMutation(UpdateBookingConfig);

  const handleSubmit: Parameters<typeof useFormAutoSave<RecruitFormFields>>[0]['handleSubmit'] = useCallback(
    async ({ values, dirtyValues }) => {
      try {
        setIsSaving(true);

        const input = mapFormFieldsToBooking({ values, dirtyValues });
        if (!input) {
          return;
        }

        const updateBookingResult = await updateBookingConfig({ _id: studyId, input });
        if (updateBookingResult.error) {
          throw new Error(updateBookingResult.error?.graphQLErrors[0].message ?? 'Error updating study');
        }
      } catch (e: unknown) {
        toast.error(e instanceof Error ? e.message : t('sections.errorBoundary.default'));
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, studyId, t, updateBookingConfig],
  );

  // Auto submit the form on every change
  useFormAutoSave({ form, handleSubmit, preventTrigger: !hasSubmitted });

  return (
    <div className="flex flex-col gap-12">
      <SectionContainer id="section-participants">
        <SectionParticipants isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer
        id="section-audience"
        title={t('sections.studies.recruit.audience')}
        icon={<Target size={28} />}
      >
        <SectionAudience isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer id="section-screener-questions">
        <SectionScreenerQuestions isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer id="section-settings" title={t('sections.studies.recruit.settings')} icon={<Cog size={28} />}>
        <SectionSettings isDisabled={isDisabled} />
      </SectionContainer>

      <SectionContainer
        id="section-opportunity-listing"
        title={t('sections.studies.recruit.opportunityListing')}
        icon={<Explore size={28} />}
      >
        <SectionOpportunityListing isDisabled={isDisabled} />
      </SectionContainer>
    </div>
  );
};

import { createLucideIcon } from 'lucide-react';

export const Columns3 = createLucideIcon('Columns3', [
  [
    'path',
    {
      d: 'M 5 3.9375 C 4.414062 3.9375 3.9375 4.414062 3.9375 5 L 3.9375 19 C 3.9375 19.585938 4.414062 20.0625 5 20.0625 L 8.0625 20.0625 L 8.0625 3.9375 Z M 5 2.0625 C 3.378906 2.0625 2.0625 3.378906 2.0625 5 L 2.0625 19 C 2.0625 20.621094 3.378906 21.9375 5 21.9375 L 19 21.9375 C 20.621094 21.9375 21.9375 20.621094 21.9375 19 L 21.9375 5 C 21.9375 3.378906 20.621094 2.0625 19 2.0625 Z M 9.75 20.0625 L 9.75 3.9375 L 14.25 3.9375 L 14.25 20.0625 Z M 16.125 20.0625 L 19 20.0625 C 19.585938 20.0625 20.0625 19.585938 20.0625 19 L 20.0625 5 C 20.0625 4.414062 19.585938 3.9375 19 3.9375 L 16.125 3.9375 Z M 16.125 20.0625 ',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      fill: 'currentColor',
      strokeWidth: '0.2px',
    },
  ],
]);

/* eslint-disable max-lines */
import { useQuery } from '@apollo/client';
import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
} from '@askable/ui/core/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Bell, Menu as MenuIcon, Heart, Newspaper, Headset, Rocket } from 'lucide-react';
import { useTheme } from 'next-themes';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { HubbleSurveys, useHubble } from 'shared-utils/hooks';
import { graphql } from 'src/graphql';
import { HelpIcon, Tooltip, useDisclosure } from 'ui';

import { CreditsModal } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchUnreadNotificationsCountByTeamSubscription from 'data/subscriptions/notifications/unreadNotificationsCountByTeam';
import fetchTeamByIdSubscription from 'data/subscriptions/teams/teamById';
import { teamUtils } from 'lib/teams';

import { MenuContainer } from './MenuContainers';
import Notifications from './Notifications';
import { NotificationsBellIndicator } from './NotificationsBellIndicator';
import CreditsContainer from './creditsContainer';
import RequestCredits from './requestCredits';

import type { Teams } from 'generated/graphql';
import type { ResultOf } from 'gql.tada';

const FetchUnreadNotificationsByTeamQuery = graphql(`
  query FetchUnreadNotificationsByTeam($_team_id: ID!) {
    unreadNotificationsCountByTeam(_team_id: $_team_id) {
      _id
      unread
    }
  }
`);

const headerViewerQuery = graphql(`
  query HeaderViewer {
    viewer {
      _id
      _default_team
      type {
        researcher
        client
      }
      Teams {
        _id
        users {
          status
          _id
        }
      }
      ConnectedTeam {
        _id
        parent_team
      }
    }
  }
`);

const headerTeamByIdQuery = graphql(`
  query HeaderTeamById($id: ID!) {
    teamById(_id: $id) {
      _id
      parent_team
      settings {
        billing {
          subscription {
            credit {
              remaining
            }
          }
        }
      }
    }
  }
`);

const headerParentTeamQuery = graphql(`
  query HeaderParentTeamById($id: ID!) {
    teamById(_id: $id) {
      _id
      users {
        _id
        role
      }
    }
  }
`);

const organisationSettingsQuery = graphql(`
  query OrganisationSettingsByTeamId($id: ID!) {
    teamById(_id: $id) {
      _id
      Organisation {
        _id
        settings {
          disallow_multiple_team_sign_on
        }
      }
    }
  }
`);

const HeaderNotifications = () => {
  const { details: clientDetails } = useConnectedClient();
  const { t } = useTranslation();
  // const { isOpen, onClose, onOpen } = useDisclosure();
  // const containerRef = useRef<HTMLDivElement>(null);

  const { data: notificationData, subscribeToMore: subscribeToMoreNotification } = useQuery(
    FetchUnreadNotificationsByTeamQuery,
    {
      variables: {
        _team_id: clientDetails?.team?.id!,
      },
    },
  );

  const handleOnClickBell = () => {
    // onOpen();
    // Track Event Analytics
  };

  const handleSubscribeToMore = () => {
    subscribeToMoreNotification({
      document: fetchUnreadNotificationsCountByTeamSubscription,
      variables: { _team_id: clientDetails?.team?.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        return subscriptionData.data;
      },
    });
  };

  return (
    <Popover>
      <Tooltip label={t('sections.navigation.notifications')}>
        <PopoverTrigger asChild>
          <Button variant="ghost" size="icon" onClick={handleOnClickBell}>
            <Bell className="h-4 w-4" />
            <NotificationsBellIndicator
              unread={notificationData?.unreadNotificationsCountByTeam?.[0]?.unread ?? 0}
              subscribeToNewUnreadNotifications={handleSubscribeToMore}
            />
            <div className="sr-only">{t('sections.navigation.notifications')}</div>
          </Button>
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent align="end" className="w-96 p-0">
        {clientDetails?.team?.id && <Notifications team_id={clientDetails.team.id} />}
      </PopoverContent>
    </Popover>
  );
};

const Header = memo(function HeaderMemo() {
  const { t } = useTranslation();
  const hubble = useHubble();
  const { setTheme } = useTheme();
  const intercom = useIntercom();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { details: clientDetails, authState } = useConnectedClient();
  const currentPath = location.pathname;

  const [openRequestCredits, setOpenRequestCredits] = useState(false);

  const { isOpen: creditsModalOpen, onOpen: openCreditsModal, onClose: closeCreditsModal } = useDisclosure();

  const { data: viewerData, loading: viewerDataLoading } = useQuery(headerViewerQuery, {
    fetchPolicy: 'no-cache',
    skip: authState !== 'authenticated',
  });

  const { data: teamByIdData, subscribeToMore: teamByIdDataSubscribeToMore } = useQuery(headerTeamByIdQuery, {
    skip: !clientDetails?.team?.id,
    variables: {
      id: clientDetails?.team?.id!,
    },
  });

  const parentTeamData = useQuery(headerParentTeamQuery, {
    skip: !teamByIdData?.teamById?.parent_team,
    fetchPolicy: 'no-cache',
    variables: {
      id: teamByIdData?.teamById?.parent_team!,
    },
  });

  const { data: organisationData } = useQuery(organisationSettingsQuery, {
    fetchPolicy: 'no-cache',
    skip: !teamByIdData?.teamById?._id,
    variables: {
      id: teamByIdData?.teamById?._id!,
    },
  });

  useEffect(() => {
    // If not the new study page, set the theme to light
    if (!params?.studyId) {
      setTheme('light');
    }

    if (viewerDataLoading || !viewerData?.viewer) {
      return;
    }

    const connectedTeamId = viewerData?.viewer?.ConnectedTeam?._id;
    const availableTeamsForUserToBeConnected = viewerData?.viewer?.Teams?.filter(team => {
      const userStatus = team?.users?.find(user => user?._id === viewerData?.viewer?._id);

      return userStatus?.status !== 0;
    });

    const hasAvailableTeamToConnect = availableTeamsForUserToBeConnected?.find(
      team => team?._id === clientDetails?.team?.id,
    );

    if (
      !clientDetails?.type?.researcher &&
      clientDetails?.team?.id !== connectedTeamId &&
      !hasAvailableTeamToConnect &&
      !currentPath.includes('join')
    ) {
      onSignOut();
    }
  }, [viewerData, viewerDataLoading]);

  const hideHeader = currentPath === '/register/about' && !_.get(clientDetails, 'email');
  if (hideHeader) {
    return null;
  }

  const onSignOut = () => {
    navigate('/logout', { replace: true });
  };

  const shouldRenderRequestCreditsOption = (parentTeam?: ResultOf<typeof headerParentTeamQuery>['teamById']) => {
    if (!parentTeam) {
      return false;
    }

    const hasSuperAdmins = teamUtils.hasSuperAdmins({
      users: (parentTeam.users ?? []) as { _id: string; role: number }[],
    });

    if (!hasSuperAdmins) {
      return false;
    }

    return true;
  };

  const onRequestCreditsClick = () => {
    setOpenRequestCredits(true);
  };

  const renderRightContainer = () => {
    if (!clientDetails?.type?.client) {
      return null;
    }

    const connectedTeamId = viewerData?.viewer?.ConnectedTeam?._id;
    const parentTeam = parentTeamData.data?.teamById;

    // It should test whether the stored team id is the same as the default one coming from graphql
    // Usually if they are not it means that the client has been blocked by another admin to access that team
    // In that case we should just sign the user out
    // refetchQuery();

    return (
      <>
        {!viewerData?.viewer?.type?.researcher && shouldRenderRequestCreditsOption(parentTeam) ? (
          <Button
            variant="ghost"
            className="hidden sm:flex"
            onClick={() => {
              setOpenRequestCredits(true);
            }}
          >
            {t('sections.navigation.requestCredits')}
          </Button>
        ) : null}

        {!viewerData?.viewer?.type?.researcher && connectedTeamId && teamByIdData ? (
          <div className="hidden sm:flex">
            <CreditsContainer
              openCreditsModal={openCreditsModal}
              team={teamByIdData?.teamById as Teams}
              subscribeToNewCredits={() => {
                teamByIdDataSubscribeToMore({
                  document: fetchTeamByIdSubscription,
                  variables: {
                    _id: _.get(teamByIdData, 'teamById._id') || clientDetails?.team?.id,
                  },
                  updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData.data) return prev;
                    const newTeamItem = subscriptionData.data;
                    return {
                      ...newTeamItem,
                    };
                  },
                });
              }}
            />
          </div>
        ) : null}

        {connectedTeamId && !viewerData?.viewer?.type?.researcher ? <HeaderNotifications /> : null}

        <DropdownMenu>
          <Tooltip label="Get help">
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="mr-2">
                <MenuIcon className="h-4 w-4 sm:!hidden" />
                <HelpIcon className="!hidden h-4 w-4 sm:!flex" />
              </Button>
            </DropdownMenuTrigger>
          </Tooltip>

          <DropdownMenuContent align="end">
            {shouldRenderRequestCreditsOption(parentTeam) ? (
              <DropdownMenuItem onClick={onRequestCreditsClick} className="sm:!hidden">
                {t('sections.navigation.requestCredits')}
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem
              className="sm:!hidden"
              onClick={() => {
                openCreditsModal();
              }}
            >
              {t('sections.navigation.buyCredits')}
            </DropdownMenuItem>
            <DropdownMenuSeparator className="sm:hidden" />
            <DropdownMenuGroup>
              <DropdownMenuItem asChild>
                <a href="https://updates.askable.com/changelog" target="_blank" rel="noreferrer">
                  <Rocket className="h-4 w-4" />
                  {t('sections.navigation.whatsNewUpdates')}
                </a>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <a href="https://help.askable.com" target="_blank" rel="noreferrer">
                  <Newspaper className="h-4 w-4" />
                  {t('sections.navigation.viewHelpArticles')}
                </a>
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  hubble.show(HubbleSurveys.AlwaysOn);
                }}
              >
                <Heart className="h-4 w-4" />
                {t('sections.navigation.giveFeedback')}
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  intercom.showNewMessages();
                }}
              >
                <Headset className="h-4 w-4" />
                {t('sections.navigation.contactSupport')}
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>

        <MenuContainer
          hideSignIntoAnotherTeam={
            organisationData?.teamById?.Organisation?.settings?.disallow_multiple_team_sign_on ?? false
          }
          onSignOut={onSignOut}
        />
      </>
    );
  };

  return (
    <>
      <header
        id="header"
        className="flex w-full flex-wrap items-center justify-between border-b-0.5 border-border bg-background text-foreground"
      >
        <Link to="/" title="Back" className="px-4 py-1 text-brand">
          <svg width="32" height="32" fill="none" viewBox="0 0 48 48">
            <title>Askable</title>
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24.03 1.5c14.7.02 18.93 4.25 18.9 18.9 0 5.91-.7 10.12-2.5 13.04-3.89 8.2-12.55 12.12-16.48 13.06l.01-7.25C9.26 39.22 5.03 35 5.06 20.35 5.07 5.68 9.31 1.47 24.02 1.5Zm-.08 15.67a3.79 3.79 0 1 1-.01-7.58 3.79 3.79 0 0 1 0 7.58Zm0 2.6c3.14 0 8.22.64 8.21 3.77 0 3.13-3.39 6.93-8.22 6.93-4.82 0-8.21-3.8-8.21-6.93s5.08-3.78 8.22-3.78Z"
              clipRule="evenodd"
            />
          </svg>
        </Link>

        <div className="flex items-center gap-1">
          {renderRightContainer()}

          <RequestCredits
            isOpen={openRequestCredits}
            onClose={() => setOpenRequestCredits(false)}
            connectedTeamId={viewerData?.viewer?.ConnectedTeam?._id as string}
          />
        </div>
      </header>
      <CreditsModal open={creditsModalOpen} onClose={closeCreditsModal} />
    </>
  );
});

export default deprecatedWithRouter(Header);

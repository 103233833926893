import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { ChevronRight, EyeOff } from 'lucide-react';
import { useState, useRef, useEffect, memo, useLayoutEffect, isValidElement } from 'react';

import { Button } from '../../core/button';
import { cn } from '../../lib/utils';

import { useContainer } from './hooks';
import { TaskCardDraggableHeader } from './task-card-draggable-header';

import type { ComponentProps, CSSProperties, Dispatch, ReactNode, SetStateAction } from 'react';

export const CONFIG = {
  CONTAINER: '.drag-container',
  COLLAPSED_SIZE: { h: 60, w: 36 }, // Size of the collapsed card
  COLLAPSED_Y: 84, // Distance from the bottom of the container when collapsed
  DRAGGABLE_MIN_WIDTH: 480,
  EDGE_OFFSET: 8,
  INITIAL_POSITION: { x: 8, y: 8 },
  SNAP_THRESHOLD: 24, // Snap to edge when within this distance
};

type Props = Omit<Partial<ComponentProps<typeof TaskCardDraggableHeader>>, 'title'> & {
  id: string;
  title: string;
  children: ReactNode;
  isCollapsed?: boolean;
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>;
  collapseLabel?: string;
  isDraggable?: boolean;
  isPositionSaved?: boolean;
  action?: ReactNode;
  position: {
    x: number;
    y: number;
  };
};

export const TaskCardDraggableInner = memo(function TaskCardDraggableInnerMemo({
  id,
  title,
  children,
  isDisabled,
  isCollapsed,
  setIsCollapsed,
  collapseLabel = 'Hide instructions',
  isDraggable: isDraggableProp,
  action,
  position,
  cameraPreview,
  recordingStarted,
  recordingEnded,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { containerWidth } = useContainer(`card-${id}`, true);

  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isFinishedDragging, setIsFinishedDragging] = useState(false);

  // Disable dragging on mobile
  const isDraggable = isDraggableProp && containerWidth >= CONFIG.DRAGGABLE_MIN_WIDTH;
  const disabled = isDisabled || !isDraggable || isCollapsed;
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({ id, disabled });

  const cardStyle: CSSProperties = {
    position: 'absolute',
    height: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.h}px` : contentHeight ? `${contentHeight}px` : 'auto',
    width: isCollapsed ? `${CONFIG.COLLAPSED_SIZE.w}px` : 'calc(100% - 16px)',
    left: isCollapsed ? '-0.5px' : `${position.x}px`,
    bottom: isCollapsed ? `${CONFIG.COLLAPSED_Y}px` : `${position.y}px`,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
  };

  // We only want to trigger this when the title or instructions change
  // Need to check for valid child elements
  useLayoutEffect(() => {
    if (!isValidElement(children)) {
      return;
    }

    // Force element to recalculate its offsetHeight
    setContentHeight(null);
    // Disable the delay when resizing
    setIsResizing(true);

    // Delay so content renders before getting new height
    setTimeout(() => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.offsetHeight + 1);
        setIsResizing(false);
      }
    }, 0);
  }, [
    isValidElement(children) ? children.props.title : undefined,
    isValidElement(children) ? children.props.instructions : undefined,
  ]);

  // When not dragging add an duration for the collapsible animation
  const finishedDraggingTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    clearTimeout(finishedDraggingTimer.current);
    if (isDragging) {
      setIsFinishedDragging(false);
    } else {
      finishedDraggingTimer.current = setTimeout(() => setIsFinishedDragging(true), 501);
    }
  }, [isDragging]);

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={cardStyle}
      className={cn(
        `z-20 grid max-w-[480px] origin-center overflow-hidden rounded-xl border-0.5 border-border bg-background shadow-lg
        sm:max-w-[380px]`,
        {
          'rounded-l-none focus-within:shadow-lg hover:shadow-xl': isCollapsed,
          'w-[calc(100%_-_16px)]': !isCollapsed,
          'shadow-xl': isDragging,
          'duration-500 ease-anticipation motion-reduce:transition-none': isFinishedDragging,
        },
      )}
    >
      <div
        ref={contentRef}
        className={cn(
          `col-start-1 col-end-2 row-start-1 row-end-2 flex w-full max-w-[480px] flex-col justify-between
          motion-reduce:transition-none sm:max-w-[380px]`,
          {
            'w-[350px] overflow-hidden opacity-0': isCollapsed,
            'transition-all delay-200': !isResizing,
          },
        )}
      >
        <TaskCardDraggableHeader
          title={title}
          isDraggable={isDraggable}
          isDisabled={isDisabled}
          dragHandleProps={{ ...attributes, ...listeners }}
          cameraPreview={cameraPreview}
          recordingStarted={recordingStarted}
          recordingEnded={recordingEnded}
        />

        <div className="flex max-h-[50vh] flex-1 flex-col gap-4 overflow-auto px-4 pb-4">{children}</div>

        <footer className="flex items-center justify-between gap-2 border-t-0.5 border-border px-4 pb-4 pt-4">
          {setIsCollapsed && collapseLabel ? (
            <Button
              variant="ghost"
              size="lg"
              className="gap-2 text-muted-foreground"
              onClick={() => setIsCollapsed(true)}
            >
              <EyeOff className="h-4 w-4" />
              {collapseLabel}
            </Button>
          ) : (
            <div />
          )}

          {action}
        </footer>
      </div>

      {setIsCollapsed ? (
        <button
          // TODO: see how we can more easily bring translations into the ui library
          aria-label={isCollapsed ? 'Expand' : 'Collapse'}
          aria-expanded={!isCollapsed}
          className={cn(
            `pointer-events-none z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex -translate-x-2 flex-col items-center
            justify-center opacity-0 transition-all focus:outline-none focus:ring-2 focus:ring-primary`,
            {
              [`opacity-200 pointer-events-auto translate-x-0 cursor-pointer delay-300 hover:scale-110 focus:scale-110
              motion-reduce:transition-none`]: isCollapsed,
            },
          )}
          style={{ height: `${CONFIG.COLLAPSED_SIZE.h}px`, width: `${CONFIG.COLLAPSED_SIZE.w}px` }}
          onClick={() => setIsCollapsed(false)}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      ) : null}
    </div>
  );
});

import { toast } from '@askable/ui/core/sonner';
import { useEffect } from 'react';

import type { CombinedError } from 'urql';

export function useHandleCombinedError(er?: CombinedError) {
  const reuse = (err: { message: string }) => {
    toast.error(err.message ?? 'Unknown error');
  };

  useEffect(() => {
    if (!er) {
      return;
    }

    reuse(er);
  }, [er, toast]);

  return {
    reuse,
  };
}

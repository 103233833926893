import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@askable/ui/core/alert-dialog';
import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { toast } from '@askable/ui/core/sonner';
import { useFeatureFlags } from 'feature-flags';
import { Archive, CircleCheckBig, CirclePlay, Copy, Ellipsis, ExternalLink } from 'lucide-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useMutation } from 'urql';

import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { PreviewLink } from 'containers/Studies/components/PreviewLink';
import { ArchiveBooking } from 'containers/Studies/data/ArchiveBooking.mutation';
import { DuplicateUnmoderatedBooking } from 'containers/Studies/data/DuplicateUnmoderatedBooking.mutation';
import { UpdateUnmoderatedBooking } from 'containers/Studies/data/UpdateUnmoderatedBooking.mutation';
import { bookingUtils } from 'lib/booking';
import { BOOKING_STATUS } from 'lib/constants';
import { getUnmodUrl } from 'utils/url-utils';

import type { Booking } from 'generated/graphql';

type StudyActionsProps = {
  study: Booking;
};

export const StudyActions = ({ study }: StudyActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSaving, setIsSaving } = useStudyContext();
  const { UNMODERATED_STUDY_DEV } = useFeatureFlags(['UNMODERATED_STUDY_DEV']);

  const [, updateBooking] = useMutation(UpdateUnmoderatedBooking);
  const [, duplicateBooking] = useMutation(DuplicateUnmoderatedBooking);
  const [, archiveBooking] = useMutation(ArchiveBooking);

  const handleDuplicate = useCallback(async () => {
    try {
      const { error, data } = await duplicateBooking({ _id: study._id });
      if (error || !data?.duplicateUnmoderatedBooking?._id) {
        // TODO (ASK-9750): Translate error messages
        throw new Error(`Duplication failed. ${error ? error?.message : 'Duplicated booking id not found'}`);
      }

      const newBookingId = data?.duplicateUnmoderatedBooking?._id;
      navigate(`/studies/${newBookingId}/build`);
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [duplicateBooking, navigate, study._id]);

  const [isOpenConfirmArchive, setIsOpenConfirmArchive] = useState(false);
  const handleArchive = useCallback(async () => {
    try {
      const { error } = await archiveBooking({ booking_id: study._id });
      if (error) {
        // TODO (ASK-9750): Translate error messages
        throw new Error('Duplication failed.');
      }

      toast.success('Booking archived');
      setIsOpenConfirmArchive(false);
    } catch (e: any) {
      toast.error(e.message);
    }
  }, [archiveBooking, study._id]);

  const [isOpenConfirmComplete, setIsOpenConfirmComplete] = useState(false);

  // THIS IS ALL TEMPORARY FOR DEMOS / TESTING, EVENTUALLY THE BOOKING STATUS WILL BE DRIVEN
  // BY ACTUAL WORKFLOWS
  const handleUpdateStatus = useCallback(
    async (status: number) => {
      try {
        setIsSaving(true);
        const { error } = await updateBooking({ input: { _id: study._id, status } });
        if (error) {
          throw new Error(error.graphQLErrors?.[0]?.message || t('sections.errorBoundary.default'));
        }
      } catch (err: any) {
        toast.error(err?.message || t('sections.errorBoundary.default'));
      } finally {
        setIsSaving(false);
      }
    },
    [setIsSaving, study._id, updateBooking, t],
  );

  // TODO: for now we're using the same utils as the bookings table, we should look into cleaning this up
  const canDuplicate = bookingUtils.canDuplicate({ config: { demo: !!study.config?.demo } });
  const canArchive = bookingUtils.canArchive({ status: study.status, config: { demo: !!study.config?.demo } });

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost">
            <Ellipsis className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-[2101]" align="end">
          <DropdownMenuItem className="lg:!hidden" asChild>
            <PreviewLink />
          </DropdownMenuItem>

          {/* THIS IS ALL TEMPORARY FOR DEMOS / TESTING, EVENTUALLY THE BOOKING STATUS WILL BE DRIVEN BY ACTUAL WORKFLOWS */}
          {/* TODO: PULL STUDY ACTIONS OUT INTO SEPARATE COMPONENT */}
          {UNMODERATED_STUDY_DEV
            ? match(study.status as any)
                .with(BOOKING_STATUS.ACTIVE, () => (
                  <DropdownMenuItem onClick={() => handleUpdateStatus(0)} disabled={isSaving}>
                    <Archive className="h-4 w-4" /> {t('sections.studies.actions.redraft')}
                  </DropdownMenuItem>
                ))
                .with(BOOKING_STATUS.IN_REVIEW, () => (
                  <>
                    <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                      <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.approve')}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => handleUpdateStatus(0)} disabled={isSaving}>
                      <Archive className="h-4 w-4" /> {t('sections.studies.actions.redraft')}
                    </DropdownMenuItem>
                  </>
                ))
                .with(BOOKING_STATUS.COMPLETED, () => (
                  <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                    <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.reopen')}
                  </DropdownMenuItem>
                ))
                .with(BOOKING_STATUS.ARCHIVED, () => (
                  <DropdownMenuItem onClick={() => handleUpdateStatus(1)} disabled={isSaving}>
                    <CirclePlay className="h-4 w-4" /> {t('sections.studies.actions.reopen')}
                  </DropdownMenuItem>
                ))
                .otherwise(() => null)
            : null}

          {match(study.status)
            .with(BOOKING_STATUS.ACTIVE, () => (
              <DropdownMenuItem onClick={() => setIsOpenConfirmComplete(true)} disabled={isSaving}>
                <CircleCheckBig className="h-4 w-4" /> {t('sections.studies.actions.complete')}
              </DropdownMenuItem>
            ))
            .otherwise(() => null)}

          {canArchive ? (
            <DropdownMenuItem onClick={() => setIsOpenConfirmArchive(true)} disabled={isSaving}>
              <Archive className="h-4 w-4" /> {t('sections.studies.actions.archive')}
            </DropdownMenuItem>
          ) : null}
          {canDuplicate ? (
            <DropdownMenuItem onClick={handleDuplicate} disabled={isSaving}>
              <Copy className="h-4 w-4" /> {t('sections.studies.actions.duplicate')}
            </DropdownMenuItem>
          ) : null}
          {UNMODERATED_STUDY_DEV ? (
            <DropdownMenuItem onClick={() => window.open(getUnmodUrl({ studyId: study._id, mode: 'demo' }), '_blank')}>
              <ExternalLink className="h-4 w-4" /> {t('sections.studies.actions.demo')}
            </DropdownMenuItem>
          ) : null}
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={isOpenConfirmArchive}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.archiveConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>{t('sections.studies.actions.archiveConfirmDescription')}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirmArchive(false)} className="w-full sm:w-fit">
              {t('global.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleArchive} className="w-full sm:w-fit">
              {t('sections.studies.actions.archiveConfirmCta')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={isOpenConfirmComplete}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('sections.studies.actions.completeConfirmTitle')}</AlertDialogTitle>
            <AlertDialogDescription>{t('sections.studies.actions.completeConfirmDescription')}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsOpenConfirmComplete(false)} className="w-full sm:w-fit">
              {t('global.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                try {
                  await handleUpdateStatus(5);
                  setIsOpenConfirmComplete(false);
                } catch (e: any) {
                  toast.error(e.message);
                }
              }}
              className="w-full sm:w-fit"
            >
              {t('sections.studies.actions.completeConfirmCta')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

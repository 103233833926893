import { Button } from '@askable/ui/core/button';
import { Label } from '@askable/ui/core/label';
import { toast } from '@askable/ui/core/sonner';
import { Textarea } from '@askable/ui/core/textarea';
import { Send } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import { CreateMessage } from '../data/CreateMessage.mutation';

/**
 * Send a new message to a user
 */

type MessageAddProps = {
  active: boolean;
  bookingId: string;
  fromUserId: string;
  userId: string;
  onUpdated: () => void;
};

export const MessageAdd = ({ active, bookingId, userId, fromUserId, onUpdated }: MessageAddProps) => {
  const { t } = useTranslation();
  const [focus, setFocus] = useState<boolean | undefined>(undefined);
  const [messageData, setMessageData] = useState('');

  const [{ error, fetching: isSending }, createMessage] = useMutation(CreateMessage);

  const addMessage = async (message: string) => {
    if (message === '') {
      return;
    }

    await createMessage({
      message: {
        type: 1,
        direction: 3,
        _to_user_id: userId,
        body: message,
        _from_user_id: fromUserId,
        context: {
          _booking_id: bookingId,
          _user_id: userId,
        },
        sms: true,
      },
    });

    if (error) {
      toast.error('Sending message failed');
      return;
    }

    setMessageData('');
    setFocus(undefined);
    window.setTimeout(() => setFocus(true), 0);
    onUpdated();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addMessage(messageData.trim());
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      // Shift + Enter = add a new line
      e.preventDefault();
      setMessageData(prevMessageData => `${prevMessageData}\n`);
    } else if (e.key === 'Enter') {
      // Enter = submit form
      e.preventDefault();
      addMessage(messageData.trim());
    }
  };

  // Focus the input when panel is opened
  useEffect(() => {
    setFocus(active ? true : undefined);
  }, [active]);

  return (
    <form onSubmit={handleSubmit} className="relative">
      <Label htmlFor="add-message" className="sr-only">
        {t('sections.messages.writeAMessage')}
      </Label>

      <Textarea
        disabled={isSending}
        focus={focus}
        id="add-message"
        inputSize="md"
        maxRows={8}
        onChange={e => setMessageData(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={t('sections.messages.writeAMessage')}
        rows={1}
        value={messageData}
        className="pr-9"
      />

      {messageData.length > 0 ? (
        <Button
          aria-label={t('sections.messages.sendMessage')}
          className="absolute bottom-[5px] right-[5px]"
          isLoading={isSending}
          size="icon"
          type="submit"
          variant="primary"
        >
          <Send className="h-4.5 w-4.5" />
        </Button>
      ) : null}
    </form>
  );
};

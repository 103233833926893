import { useEffect, useMemo, useState } from 'react';

import type { ReactNode } from 'react';

interface Props {
  recordingStarted?: number | null;
  recordingEnded?: number | null;
  cameraPreview?: ReactNode;
}

export const CameraRecordingPreview = ({ recordingStarted, recordingEnded, cameraPreview }: Props) => {
  const [recordingTimer, setRecordingTimer] = useState(0);
  useEffect(() => {
    if (recordingStarted && !recordingEnded) {
      const interval = setInterval(() => {
        const seconds = Math.floor((Date.now() - recordingStarted) / 1000);
        setRecordingTimer(seconds);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [recordingStarted, recordingEnded]);

  const [recordingMinutes, recordingSeconds] = useMemo(() => {
    const totalSeconds =
      recordingStarted && recordingEnded ? (recordingEnded - recordingStarted) / 1000 : recordingTimer;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')];
  }, [recordingTimer, recordingStarted, recordingEnded]);

  return (
    <div className="relative flex flex-row items-center gap-1">
      <div className="relative flex h-2 w-2">
        {recordingStarted && !recordingEnded && (
          <>
            <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-30" />
            <div className="relative inline-flex h-full w-full rounded-full bg-red-500" />
          </>
        )}
      </div>
      <span className="text-xs tabular-nums text-muted-foreground">
        {recordingMinutes}:{recordingSeconds}
      </span>
      <div className="ml-1 flex h-7 w-7 scale-x-[-1] overflow-hidden rounded-full bg-gray-200">{cameraPreview}</div>
    </div>
  );
};

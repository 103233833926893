import { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { BOOKING_STATUS } from 'lib/constants';

type Section = 'build' | 'recruit' | 'results' | 'preview';

export function useStudyNavigation({ status }: { status?: number | null } = {}) {
  const location = useLocation();
  const navigate = useNavigate();

  // TODO: Clean this up, this is pretty naughty
  const bookingId = matchPath('/studies/:bookingId/*', location.pathname)?.params.bookingId;
  const currentSection = matchPath('/studies/:bookingId/:section/*', location.pathname)?.params.section;
  const currentSubSection = matchPath('/studies/:bookingId/:section/:subSection/*', location.pathname)?.params
    .subSection;

  const hasStatus = typeof status === 'number';

  // Automatically redirect to the correct section based on the status
  useEffect(() => {
    if (bookingId && hasStatus && !currentSection) {
      navigate(status === BOOKING_STATUS.DRAFT ? `/studies/${bookingId}/build` : `/studies/${bookingId}/recruit`);
    }
  }, [bookingId, currentSection, navigate, status, hasStatus]);

  return useMemo(
    () => ({
      currentSection: match(currentSection)
        .with('build', 'recruit', 'results', 'preview', () => currentSection as Section)
        .otherwise(() => undefined),
      currentSubSection,
    }),
    [currentSection, currentSubSection],
  );
}

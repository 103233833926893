import { IconBlock } from '@askable/ui/unmod/icon-block';
import { CameraRecordingPreview } from '@askable/ui/unmod/task-card/camera-recording-preview';

import type { StudyBlockType } from '@askable/ui/unmod/types';
import type { ComponentProps } from 'react';

type Props = ComponentProps<typeof CameraRecordingPreview> & {
  instructions: string;
  subtitle?: string;
  title: string;
  type: StudyBlockType;
};

export const TaskCardHeader = ({
  instructions,
  subtitle = 'Task',
  title,
  type,
  cameraPreview,
  recordingStarted,
}: Props) => {
  return (
    <header className="flex flex-col gap-12 p-4 md:px-8 md:pt-8">
      {type === 'welcome' || type === 'thank_you' ? (
        <div className="w-fit">
          <IconBlock type={type} size="xl" hasBackground={false} />
        </div>
      ) : null}

      <div className="flex flex-col gap-3">
        <div className="flex h-7 flex-row items-center justify-between">
          {type !== 'welcome' && type !== 'thank_you' ? (
            <div className="w-fit rounded-lg bg-background-subtle px-3 py-2 text-xs font-medium leading-none">
              {subtitle}
            </div>
          ) : null}
          {cameraPreview && (
            <CameraRecordingPreview cameraPreview={cameraPreview} recordingStarted={recordingStarted} />
          )}
        </div>
        {title ? <h3 className="text-2xl font-semibold">{title}</h3> : null}
        {instructions ? <p className="max-w-text whitespace-pre-wrap text-lg">{instructions}</p> : null}
      </div>
    </header>
  );
};

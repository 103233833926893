import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@askable/ui/core/collapsible';
import { Skeleton } from '@askable/ui/core/skeleton';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { Stat } from 'containers/Studies/Results/components/Stat';
import { DownloadChart } from 'containers/Studies/Results/components/Task/DownloadChart';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { MultipleChoiceQuestionResult } from 'containers/Studies/Results/data/MultipleChoiceQuestionResult.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { BookingTaskResultDetailsType } from 'generated/graphql';

interface MultipleChoiceQuestionResultsProps {
  isMultipleSelect: boolean;
  taskId: string;
  title: string;
}

export const MultipleChoiceQuestionResults = ({
  isMultipleSelect,
  taskId,
  title,
}: MultipleChoiceQuestionResultsProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const chartRef = useRef<HTMLDivElement>(null);

  const [{ data, fetching, error }] = useQuery({
    query: MultipleChoiceQuestionResult,
    variables: {
      _booking_id: study._id,
      _task_id: taskId,
    },
    pause: !study._id || !taskId,
  });

  const totalResponses = data?.bookingTaskResultMultipleChoiceQuestion?.total_responses ?? 0;
  const otherAnswers = data?.bookingTaskResultMultipleChoiceQuestion?.other_answers;

  const getAnswerPercentage = (count: number) => {
    if (totalResponses === 0) {
      return 0;
    }

    return parseFloat(((count / totalResponses) * 100).toFixed(1));
  };

  if (!fetching && error) {
    return (
      <>
        <TaskHeader responses={0} title={title} type={BookingTaskResultDetailsType.MultipleChoiceQuestion} />
        <div className="text-destructive">{error.message}</div>
      </>
    );
  }

  return (
    <>
      {!fetching && data?.bookingTaskResultMultipleChoiceQuestion ? (
        <>
          <TaskHeader
            responses={totalResponses}
            subtitle={t(
              `sections.studies.build.formFields.multipleChoiceQuestion.${isMultipleSelect ? 'multiple' : 'single'}`,
            )}
            title={title}
            type={BookingTaskResultDetailsType.MultipleChoiceQuestion}
          />

          {totalResponses > 0 ? (
            <div className="flex flex-col gap-6">
              <div className="flex items-center justify-end gap-2">
                <DownloadChart chartRef={chartRef} title={title} studyName={study.name ?? study._id} />
              </div>

              <div ref={chartRef} className="flex flex-col gap-2">
                <div
                  data-print-only="true"
                  aria-hidden="true"
                  className="hidden items-center justify-between gap-2 pb-4"
                >
                  <div className="text-lg font-semibold">{title}</div>
                  <div className="text-sm text-muted-foreground">
                    {totalResponses}{' '}
                    {t('sections.studies.results.responses', { count: totalResponses }).toLocaleLowerCase()}
                  </div>
                </div>

                <ul className="flex flex-col gap-6">
                  {data?.bookingTaskResultMultipleChoiceQuestion?.answer_counts.map(answer => (
                    <li key={answer._id} className="flex flex-col gap-2">
                      <div className="flex items-center justify-between gap-2">
                        <div className="font-medium">{answer.value}</div>

                        <div className="flex items-center gap-2">
                          <div className="text-sm">
                            <Stat type="responses" value={answer.count} />
                          </div>
                          <div className="font-semibold">{getAnswerPercentage(answer.count)}%</div>
                        </div>
                      </div>

                      <div className="h-8 w-full overflow-hidden rounded-md bg-accent">
                        <div
                          className="h-full rounded-md bg-color-chart"
                          style={{ width: `${getAnswerPercentage(answer.count)}%` }}
                        ></div>
                      </div>
                    </li>
                  ))}

                  {otherAnswers && otherAnswers.length > 0 ? (
                    <Collapsible asChild>
                      <li className="flex flex-col gap-2">
                        <div className="flex items-center justify-between gap-2">
                          <div className="font-medium">
                            {t('components.multipleChoiceQuestion.other')} (
                            <CollapsibleTrigger asChild>
                              <button type="button" className="inline-block p-0 underline">
                                {t('sections.studies.results.viewResponses')}
                              </button>
                            </CollapsibleTrigger>
                            )
                          </div>

                          <div className="flex items-center gap-2">
                            <div className="text-sm">
                              <Stat type="responses" value={otherAnswers.length} />
                            </div>
                            <div className="font-semibold">{getAnswerPercentage(otherAnswers.length)}%</div>
                          </div>
                        </div>

                        <div className="h-8 w-full overflow-hidden rounded-md bg-accent">
                          <div
                            className="h-full rounded-md bg-color-chart"
                            style={{ width: `${getAnswerPercentage(otherAnswers.length)}%` }}
                          ></div>
                        </div>

                        <CollapsibleContent asChild>
                          <ul className="flex flex-col divide-y divide-accent text-sm">
                            {data?.bookingTaskResultMultipleChoiceQuestion?.other_answers?.map(answer => (
                              <li
                                key={answer._user_id}
                                className="grid grid-cols-1 items-start justify-between gap-x-2 p-2 hover:bg-secondary md:grid-cols-2"
                              >
                                <div className="text-muted-foreground md:text-primary">{answer.user?.displayName}</div>
                                <div>{answer.answer}</div>
                              </li>
                            ))}
                          </ul>
                        </CollapsibleContent>
                      </li>
                    </Collapsible>
                  ) : null}
                </ul>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <Skeleton className="h-12 w-full" />
          <div className="flex flex-col gap-6">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-10 w-3/5" />
          </div>
        </>
      )}
    </>
  );
};

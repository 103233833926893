import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import {
  Bot,
  BadgeCheck,
  Calendar,
  CircleAlert,
  CircleCheck,
  ExternalLink,
  FilePlus,
  Settings,
  Users,
} from 'lucide-react';
import { useState, useEffect, Fragment } from 'react';

import { bookingUtils } from 'lib/booking';

import { stepperMenuUtils } from './stepperMenuUtils';

import './styles/stepperMenuStyles.scss';

function CreateBookingStepperMenu(props: any) {
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const steps: any = [];

  const isSurvey = bookingUtils.isSurvey(props.booking);
  const isOnlineTask = bookingUtils.isOnlineTask(props.booking);
  const isAiModerated = bookingUtils.isAiModeration(props.booking);
  const isRemote = bookingUtils.isRemote(props.booking);
  const isInPerson = bookingUtils.isInPerson(props.booking);

  if (!props.booking) {
    steps.push({
      index: 0,
      label: 'Study Setup',
      icon: <Settings className="h-4 w-4 text-foreground" />,
      link: '/booking-setup/create',
      subSteps: stepperMenuUtils.projectSubSteps(props.booking, props.bookingSteps),
      completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'project_setup'),
      error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'project_setup'),
    });
  } else {
    steps.push({
      index: 10,
      label: 'Study Setup',
      icon: <Settings className="h-4 w-4 text-foreground" />,
      baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/project-setup`,
      link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/project-setup/project-title`,
      subSteps: stepperMenuUtils.projectSubSteps(props.booking, props.bookingSteps),
      completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'project_setup'),
      error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'project_setup'),
    });
  }
  steps.push({
    index: 20,
    label: 'Audience',
    icon: <Users className="h-4 w-4 text-foreground" />,
    baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/audience`,
    link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/audience/panel`,
    subSteps: stepperMenuUtils.audienceSubSteps(props.booking, props.bookingSteps),
    completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'audience'),
    error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'audience'),
  });
  if (isAiModerated) {
    const defaultAIModeratedPage = 'research-objective';
    steps.push({
      index: 22.5,
      label: 'AI Settings',
      icon: <Bot className="h-4 w-4 text-foreground" />,
      baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/ai-moderated`,
      link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/ai-moderated/${defaultAIModeratedPage}`,
      subSteps: stepperMenuUtils.aiModeratedSubSteps(props.booking, props.bookingSteps),
      completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'ai_moderated'),
      error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'ai_moderated'),
    });
  }
  if (isOnlineTask && !isAiModerated) {
    steps.push({
      index: 25,
      label: `${isSurvey ? 'Link to Survey' : 'Link to Task'}`,
      icon: <ExternalLink className="h-4 w-4 text-foreground" />,
      baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/link-to-task`,
      link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/link-to-task/${isSurvey ? 'survey' : 'online-task'}`,
      completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'link_to_task'),
      error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'link_to_task'),
    });
  }
  let defaultAdditionalInfoPage = 'your-timezone';
  if (props.booking && bookingUtils.isInPerson(props.booking)) defaultAdditionalInfoPage = 'session-location';
  if (props.booking && !bookingUtils.isRemote(props.booking) && !bookingUtils.isInPerson(props.booking)) {
    defaultAdditionalInfoPage = 'closing-date';
  }

  steps.push({
    index: 30,
    label: 'Additional Info',
    icon: <FilePlus className="h-4 w-4 text-foreground" />,
    baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/additional-info`,
    link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/additional-info/${defaultAdditionalInfoPage}`,
    subSteps: stepperMenuUtils.additionalInfoSubSteps(props.booking, props.bookingSteps),
    completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'additional_info'),
    error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'additional_info'),
  });
  if (!props.booking || isRemote || isInPerson) {
    steps.push({
      index: 35,
      label: 'Session Times',
      icon: <Calendar className="h-4 w-4 text-foreground" />,
      baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/session-times`,
      link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/session-times/calendar`,
      completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'session_times'),
      error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'session_times'),
    });
  }
  steps.push({
    index: 40,
    label: 'Review & Submit',
    icon: <BadgeCheck className="h-4 w-4 text-foreground" />,
    baseLink: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/review-submit`,
    link: `/booking-setup/${_.get(props, 'booking._id', ':bookingID')}/review-submit/confirm-booking`,
    completed: stepperMenuUtils.isStepCompleted(props.bookingSteps, 'review_submit'),
    error: stepperMenuUtils.stepHasErrors(props.bookingSteps, 'review_submit'),
  });

  useEffect(() => {
    const step = _.find(steps, (value: any) => props.location.pathname.includes(value.baseLink));
    const subStep = _.find(_.get(step, 'subSteps'), (value: any) => props.location.pathname.includes(value.link));
    setActiveStep(_.get(step, 'index') || 0);
    setActiveSubStep(_.get(subStep, 'index') || 0);
  });

  const onClickStep = (link: any, linkId: any) => {
    // Clicking on current step
    if (props.location.pathname === link) return null;

    // // Get saved state
    const historyChangesToSave = _.get(props.location, 'booking', null);
    const historyBookingState = _.get(props.location, 'bookingState', null);
    const historySessionData = _.get(props.location, 'bookingSession', null);
    props.history.replace(props.location.pathname, null);

    // If booking doesn't exists, create it
    if (!props.booking) {
      let extraInfo = {
        steps: {
          project_setup_project_title: 'seen',
          [linkId]: 'seen',
        },
      };
      if (historyChangesToSave) {
        extraInfo = {
          ...extraInfo,
          ...historyChangesToSave,
        };
      }
      props.createBooking(extraInfo, link);
    }

    if (props.booking) {
      if (link === '/') {
        if (historyChangesToSave) {
          props.updateBooking(historyBookingState, historyChangesToSave);
        }
        // Update booking with saved state
        if (historySessionData) {
          if (_.has(historySessionData, 'session._id')) {
            props.updateSession(
              historyBookingState,
              _.get(historySessionData, 'session'),
              _.pick(_.get(historySessionData, 'sessionTimes'), ['_id', 'start', 'end']),
            );
          } else {
            props.createSession(historyBookingState, _.pick(_.get(historySessionData, 'session'), ['start', 'end']));
          }
        }
        props.history.push({ pathname: link });
      } else {
        props.history.push({
          pathname: link,
          booking: historyChangesToSave,
          bookingState: historyBookingState,
          bookingSession: historySessionData,
        });
      }
    }
  };

  const renderSubSteps = (value: any, index: any) => {
    return (
      <a
        className={`subStep ${value.index === activeSubStep || (!props.booking && value.index === 5) ? 'active' : ''}`}
        key={`subStep_${index}`}
        onClick={() => {
          onClickStep(value.link, value.id);
        }}
      >
        <div className="labelContainer">
          <p className="label">{value.label}</p>
        </div>
        <div className="status">
          {_.get(value, 'completed') && !_.get(value, 'error') && <CircleCheck className="h-4 w-4 text-success" />}
          {_.get(value, 'error') && <CircleAlert className="h-4 w-4 text-destructive" />}
        </div>
      </a>
    );
  };

  const renderStep = (value: any, index: any) => {
    return (
      <Fragment key={`step_${index}`}>
        <a
          className={`step ${value.index === activeStep ? 'active' : ''}`}
          key={`step_${index}`}
          onClick={() => {
            const lastStep = _.find(props.lastStep, (item: any) => item.step === value.label);
            const linkTo = _.get(lastStep, 'subStep') || value.link;
            const linkToId = _.get(lastStep, 'id') || value.id;

            onClickStep(linkTo, linkToId);
          }}
        >
          <div className="icon">{value.icon}</div>
          <div className="labelContainer">
            <p className="label">{value.label}</p>
          </div>
          <div className="status">
            {_.get(value, 'completed') && !_.get(value, 'error') && <CircleCheck className="h-4 w-4 text-success" />}
            {_.get(value, 'completed') && _.get(value, 'error') && <CircleAlert className="h-4 w-4 text-destructive" />}
          </div>
        </a>
        {value.index === activeStep && _.get(value, 'subSteps') && (
          <div className="subSteps">
            {_.map(_.get(value, 'subSteps'), (item: any, key: any) => renderSubSteps(item, key))}
          </div>
        )}
      </Fragment>
    );
  };

  const onSaveExit = () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    onClickStep('/');
  };

  return (
    <>
      <div className="bookingStepperMenuContainer">
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type. */}
        {steps.map((value, index) => renderStep(value, index))}
      </div>
      <div className="mt-4 flex justify-center">
        <Button onClick={onSaveExit}>Save &amp; Exit</Button>
      </div>
    </>
  );
}

export default deprecatedWithRouter(CreateBookingStepperMenu);

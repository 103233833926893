import { cn } from '@askable/ui/lib/utils';
import { IconBlock } from '@askable/ui/unmod/icon-block';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { TaskBlockType } from 'generated/graphql';

export interface TaskItemProps {
  id: string;
  isActive?: boolean;
  title: string;
  type: TaskBlockType;
}

export const TaskItem = ({ id, isActive = false, title, type }: TaskItemProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to={{ pathname: `task/${id}`, hash: 'main' }}
      title={title}
      className={cn(
        'flex w-full items-center gap-2 rounded-xl border-0.5 border-border bg-background p-2 text-sm text-secondary-foreground',
        'hover:border-input focus:border-input active:border-primary active:ring-1 active:ring-primary',
        {
          'border-primary bg-background ring-1 ring-primary hover:border-primary focus:border-primary': isActive,
        },
      )}
    >
      <IconBlock type={type} />
      <div className="flex flex-col truncate text-left font-medium">
        <div className="truncate">{title ?? '...'}</div>
        <div className="truncate text-xs text-muted-foreground">{t(`sections.studies.build.blocks.${type}.title`)}</div>
      </div>
    </Link>
  );
};

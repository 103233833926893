import { AskableIconLoading } from '@askable/ui/core/askable-icon';
import { differenceInYears } from 'date-fns';
import { capitalize } from 'radash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { BookingParticipantDetailsSidePanelDocument, useFetchBookingByIdQuery } from 'generated/graphql';

import { formatEligibility } from '../../utils/booking-participant-utils';
import { BookingParticipantStatus } from '../BookingParticipantStatus';
import { ApplicantTechCheck } from '../BookingParticipantsTable/components/ApplicantTechCheck';
import { QuestionAnswerCell } from '../BookingParticipantsTable/components/QuestionAnswerCell';

import type {
  BookingParticipantDetailsSidePanelQuery,
  BookingParticipantDetailsSidePanelQueryVariables,
  Data,
  Submission,
  Booking,
} from 'generated/graphql';

const TODAY = new Date();

interface BookingParticipantDetailsSidePanelProps {
  name: string;
  status?: Submission['status'];
  submissionId: string;
}

interface ParticipantFeedbackProps {
  name: string;
  submission: BookingParticipantDetailsSidePanelQuery;
}

interface QuestionProps {
  data: Data[];
  booking: Booking;
}

const ParticipantFeedback = ({ submission, name }: ParticipantFeedbackProps) => {
  const { t } = useTranslation();
  const feedback = submission.bookingSubmissionById?.feedback?.participant;

  if (!feedback) {
    return null;
  }

  return (
    <div className="border-stroke w-100 flex w-full flex-col rounded-md bg-amber-50 p-2">
      <div className="text-sm font-semibold">{t('sections.booking.participant.leftFeedback', { name })}</div>
      <div>&quot;{feedback}&quot;</div>
    </div>
  );
};

const Questions = ({ booking, data }: QuestionProps) => {
  if (!booking?.config?.question) {
    return null;
  }

  return (
    <ul className="flex flex-col gap-4">
      {booking?.config?.question?.map(question => {
        const answers = data?.filter(a => a?._question_id === question?._id);

        return (
          <li className="flex flex-col gap-1" key={question?._id}>
            <div className="font-semibold">{question?.title}</div>
            <QuestionAnswerCell
              isFull
              answers={answers as Data[]}
              multiSelection={question?.config?.multiple_selection ?? false}
            />
          </li>
        );
      })}
    </ul>
  );
};

export const BookingParticipantDetailsSidePanel = ({
  name,
  status,
  submissionId,
}: BookingParticipantDetailsSidePanelProps) => {
  const params = useParams<{ bookingId: string; studyId: string }>();

  const { data: bookingData, loading: loadingBooking } = useFetchBookingByIdQuery({
    variables: {
      id: params.studyId ?? params.bookingId,
    },
  });

  const [{ data: submission }] = useQuery<
    BookingParticipantDetailsSidePanelQuery,
    BookingParticipantDetailsSidePanelQueryVariables
  >({
    query: BookingParticipantDetailsSidePanelDocument,
    variables: {
      id: submissionId,
    },
  });

  return (
    <div className="flex flex-col gap-6 p-3 sm:p-4">
      {submission ? <ParticipantFeedback submission={submission} name={name} /> : null}

      <ul className="flex flex-wrap items-start gap-4 text-sm">
        {status ? (
          <li>
            <BookingParticipantStatus
              submissionStatus={status}
              paymentDue={submission?.bookingSubmissionById?.transaction?.payment_due}
            />
          </li>
        ) : null}

        {submission?.bookingSubmissionById?.eligibility ? (
          <li>{formatEligibility(submission?.bookingSubmissionById?.eligibility)}</li>
        ) : null}

        <li className="flex items-center gap-1">
          <ApplicantTechCheck check={submission?.bookingSubmissionById?.applicant.setup_check} /> Tech check
        </li>

        {submission?.bookingSubmissionById?.applicant.gender ? (
          <li>{capitalize(submission?.bookingSubmissionById?.applicant.gender ?? '')}</li>
        ) : null}

        {submission?.bookingSubmissionById?.applicant.age ? (
          <li>{differenceInYears(TODAY, submission?.bookingSubmissionById?.applicant.age)}</li>
        ) : null}
      </ul>

      {!loadingBooking && bookingData?.bookingByID ? (
        <Questions
          booking={bookingData?.bookingByID as Booking}
          data={(submission?.bookingSubmissionById?.data ?? []) as Data[]}
        />
      ) : (
        <div className="flex items-center justify-center">
          <AskableIconLoading />
        </div>
      )}
    </div>
  );
};

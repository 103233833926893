import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  EDUCATION_LEVEL_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  OCCUPATIONAL_STATUS_OPTIONS,
  BUSINESS_OWNERSHIP_OPTIONS,
} from 'containers/Studies/data/schemas/recruitSchema';
import { getObjectId } from 'containers/Studies/utils/utils';

import { Collapsible } from './Collapsible';
import { CheckboxGroupField } from './Fields/CheckboxGroupField';
import { DualSliderField } from './Fields/DualSliderField';
import { SwitchField } from './Fields/SwitchField';
import { Gender } from './Gender';
import { IndustryAndOccupation } from './IndustryAndOccupation';
import { Languages } from './Languages';
import { LocationSearch } from './LocationSearch';

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';

export const SectionAudience = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext<RecruitFormFields>();

  const showAdvancedFilters = watch('enable_advanced_filters');

  // TODO: this should not be a side effect, it should be part of the switch handler
  useEffect(() => {
    const industryAndOccupation = getValues('industry_and_occupation');
    const languages = getValues('languages');
    // If the industry and occupation list is empty, add an initial item without validation
    if (showAdvancedFilters && (!industryAndOccupation || industryAndOccupation.length === 0)) {
      setValue('industry_and_occupation', [{ _id: getObjectId(), name: '', subcategories: [] }], {
        shouldValidate: false,
      });
    }

    if (showAdvancedFilters && (!languages || languages.length === 0)) {
      setValue('languages', [{ locale: undefined, fluency: [] }], {
        shouldValidate: false,
      });
    }

    if (!showAdvancedFilters) {
      // TODO: discuss with design, right now we're resetting the advanced fields when the user
      // toggles advanced filters on or off, which feels it would be pretty frustrating for misclicks
      setValue('industry_and_occupation', [], { shouldDirty: true });
      setValue('languages', [], { shouldDirty: true });
      setValue('education_level', [], { shouldDirty: true });
      setValue('marital_status', [], { shouldDirty: true });
      setValue('occupational_status', [], { shouldDirty: true });
      setValue('business_ownership', [], { shouldDirty: true });
    }
  }, [showAdvancedFilters, setValue]);

  const educationLevelOptions = EDUCATION_LEVEL_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.education_level.${option}`),
    value: option,
  }));

  const maritalStatusOptions = MARITAL_STATUS_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.marital_status.${option}`),
    value: option,
  }));

  const occupationStatusOptions = OCCUPATIONAL_STATUS_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.occupational_status.${option}`),
    value: option,
  }));

  const businessOwnershipOptions = BUSINESS_OWNERSHIP_OPTIONS.map(option => ({
    label: t(`sections.studies.recruit.options.business_ownership.${option}`),
    value: option,
  }));

  return (
    <>
      <LocationSearch isDisabled={isDisabled} />

      <DualSliderField
        name="age_range"
        label={t('sections.studies.recruit.fields.ageRangeTitle')}
        min={18}
        max={99}
        isDisabled={isDisabled}
      />

      <Gender isDisabled={isDisabled} />

      <hr className="my-1 border-t-0.5 border-secondary p-0" />

      <SwitchField
        name="enable_advanced_filters"
        label={t('sections.studies.recruit.fields.advancedFiltersTitle')}
        size="lg"
        description={t('sections.studies.recruit.fields.advancedFiltersDescription')}
        isDisabled={isDisabled}
      />

      {showAdvancedFilters ? (
        <div className="flex flex-col gap-3">
          <Collapsible
            title={t('sections.studies.recruit.fields.industryAndOccupationTitle')}
            name="industry_and_occupation"
          >
            <IndustryAndOccupation isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible title={t('sections.studies.recruit.fields.languagesTitle')} name="languages">
            <Languages isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible title={t('sections.studies.recruit.fields.educationLevelTitle')} name="education_level">
            <CheckboxGroupField name="education_level" options={educationLevelOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible title={t('sections.studies.recruit.fields.maritalStatusTitle')} name="marital_status">
            <CheckboxGroupField name="marital_status" options={maritalStatusOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible title={t('sections.studies.recruit.fields.occupationalStatusTitle')} name="occupational_status">
            <CheckboxGroupField name="occupational_status" options={occupationStatusOptions} isDisabled={isDisabled} />
          </Collapsible>

          <Collapsible title={t('sections.studies.recruit.fields.businessOwnershipTitle')} name="business_ownership">
            <CheckboxGroupField name="business_ownership" options={businessOwnershipOptions} isDisabled={isDisabled} />
          </Collapsible>
        </div>
      ) : null}
    </>
  );
};

import { createLucideIcon } from 'lucide-react';

export const UserSolid = createLucideIcon('UserSolid', [
  [
    'path',
    {
      d: 'M12 2C9.2 2 7 4.3 7 7s2.3 5 5 5 5-2.3 5-5-2.3-5-5-5ZM9 14a5 5 0 0 0-5 5v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2a5 5 0 0 0-5-5z',
      fill: 'currentColor',
      stroke: 'none',
    },
  ],
]);

import { graphql } from 'src/graphql';

export const SubmitBookingForReview = graphql(`
  mutation SubmitBookingForReview($_id: ID!) {
    submitBookingForReview(_id: $_id) {
      _id
      name
      type
      status
    }
  }
`);

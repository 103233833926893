import { t } from 'i18next';

import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../core/dropdown-menu';

import type { FC } from 'react';

type Props = {
  activeOrgId: string | undefined;
  teams: { _id: string; name?: string | null }[];
  handleSwitchTeams: (selectedTeamId: string) => void;
  onSignIntoAnotherTeam?: () => void;
  hideSignIntoAnotherTeam: boolean;
};

export const TeamSwitcher: FC<Props> = ({
  activeOrgId,
  teams,
  handleSwitchTeams,
  onSignIntoAnotherTeam,
  hideSignIntoAnotherTeam,
}) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        {t('teamSwitcher.switch', { defaultValue: 'Switch', ns: 'components' })}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuLabel>{t('teamSwitcher.teams', { defaultValue: 'Teams', ns: 'components' })}</DropdownMenuLabel>
          <DropdownMenuRadioGroup
            className="max-h-[70vh] overflow-y-auto"
            value={activeOrgId}
            onValueChange={handleSwitchTeams}
          >
            {teams?.map(team => {
              return (
                <DropdownMenuRadioItem value={team._id} key={team?._id}>
                  {team?.name}
                </DropdownMenuRadioItem>
              );
            })}
          </DropdownMenuRadioGroup>
          {!hideSignIntoAnotherTeam && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={onSignIntoAnotherTeam}>
                {t('teamSwitcher.signInToAnotherTeam', { defaultValue: 'Sign into another team', ns: 'components' })}
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};

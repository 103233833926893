import { graphql } from 'src/graphql';

import type { ResultOf } from 'src/graphql';

export const BookingTaskResult = graphql(`
  query BookingTaskResult($filter: BookingTaskResultFilterInput!) {
    bookingTaskResult(filter: $filter) {
      _id
      _booking_id
      _task_id
      created
      status
      _user_id
      task_ended
      task_started
      task_loaded
      user {
        _id
        displayName
        picture
      }
      video {
        _id
        code
        rooms {
          _id
          startedAt
          composition {
            status
            duration
            filesize
            progress {
              percentage_done
              seconds_remaining
            }
          }
        }
      }
      details {
        type

        ... on BookingTaskResultDetailsFigmaPrototype {
          _path_id
          path {
            _id
            node_id
            started
            ended
            clicks
            misclicks
            image {
              height
              name
              src
              width
            }
          }
        }

        ... on BookingTaskResultDetailsMultipleChoiceQuestion {
          answers {
            _id
            value
          }
          other_answer
        }

        ... on BookingTaskResultDetailsOpinionScale {
          answer
        }
      }
      previousResultId
      nextResultId
    }
  }
`);

export type BookingTaskResultPartial = NonNullable<
  NonNullable<ResultOf<typeof BookingTaskResult>['bookingTaskResult']>
>;

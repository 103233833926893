import { cn } from '../lib/utils';

/**
 * Progress
 * Show a red, stepped progress bar
 * @todo add variants. Basic replacement for Material UI component
 */

type ProgressProps = {
  id?: string;
  currentStep: number;
  steps: number;
};

const Progress = ({ currentStep, steps, id }: ProgressProps) => {
  return (
    <div
      className="w-full bg-secondary"
      role="progressbar"
      aria-valuenow={currentStep}
      aria-valuemin={0}
      aria-valuemax={steps}
      data-testid={id}
    >
      <div
        className={cn('h-2 bg-brand transition-all duration-100', currentStep < steps ? 'rounded-r' : undefined)}
        style={{ width: `${(currentStep / steps) * 100}%` }}
      />
      <div className="sr-only">{`Step ${currentStep} of ${steps}`}</div>
    </div>
  );
};

export { Progress };

import { v4 } from 'uuid';

/**
 * Accepts a value eg. 0.15
 * Returns a formatted percentage eg. 15%
 */
export const formatPercentage = (value: number | string): string => {
  const percentage = Number(value) * 100;
  return `${Number.isInteger(percentage) ? percentage : percentage.toFixed(1)}%`;
};

/**
 * Get a unique object ID for compatibility with MongoDB
 */
export const getObjectId = (): string => v4().replace(/-/g, '').slice(0, 24);

/**
 * Convert a number to an ordinal number
 * @todo localise this. Possibly use date-fns/locale to get the ordinal suffix
 */
export const getOrdinalNumber = (n: number): string => {
  const num = Math.abs(Math.floor(n));
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = num % 100;
  // For numbers ending in 0, 4-9, and 11-13: use 'th'
  // For numbers ending in 1, 2, 3 (except 11-13): use 'st', 'nd', 'rd' respectively
  return n + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
};

import { AnchorButton, Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@askable/ui/core/dropdown-menu';
import { Filter, ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface Props {
  hasDropdown?: boolean;
  hasSidebar?: boolean;
}

export const ResultsHeader = ({ hasDropdown = false, hasSidebar }: Props) => {
  const { t } = useTranslation();

  return (
    <header className="results-header flex flex-wrap items-center justify-between gap-2 px-3 py-4 lg:px-5 lg:pb-0">
      <div className="flex items-center gap-1">
        {hasSidebar && (
          <AnchorButton variant="ghost" href="#nav" className="lg:!hidden">
            <ChevronLeft className="h-4 w-4" />
          </AnchorButton>
        )}
        <h1 className="text-pretty text-xl font-semibold leading-tight text-foreground">
          {t('sections.studies.results.responses', { count: 2 })}
        </h1>
      </div>

      {hasDropdown ? (
        <div>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <Filter className="h-4 w-4" /> {t('sections.studies.results.filter')}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Item 1</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ) : null}
    </header>
  );
};

/* eslint-disable react/display-name */
'use client';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

import { cn } from '../lib/utils';

const DEFAULT_OPEN_DELAY = 300;
const DEFAULT_CLOSE_DELAY = 500;

const PopoverOnOpenChangeContext = React.createContext({
  handleOpen: () => {},
  handleClose: () => {},
});

type CustomPopoverProps = {
  openDelay?: number;
  closeDelay?: number;
  openOnHover?: boolean;
};
type PopoverProps = React.ComponentProps<typeof PopoverPrimitive.Root> & CustomPopoverProps;
const Popover: React.FC<PopoverProps> = ({ open, onOpenChange, openDelay, closeDelay, openOnHover, ...props }) => {
  const [defaultOpen, defaultOnOpenChange] = React.useState(false);
  const _onOpenChange = onOpenChange ?? defaultOnOpenChange;
  const openTimerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeTimerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleOpen = () => {
    if (!openOnHover) {
      return;
    }
    if (closeTimerRef.current) {
      clearTimeout(closeTimerRef.current);
    }
    openTimerRef.current = setTimeout(() => _onOpenChange(true), openDelay ?? DEFAULT_OPEN_DELAY);
  };

  const handleClose = () => {
    if (!openOnHover) {
      return;
    }
    if (openTimerRef.current) {
      clearTimeout(openTimerRef.current);
    }
    closeTimerRef.current = setTimeout(() => _onOpenChange(false), closeDelay ?? DEFAULT_CLOSE_DELAY);
  };

  return (
    <PopoverOnOpenChangeContext.Provider
      value={{
        handleClose,
        handleOpen,
      }}
    >
      <PopoverPrimitive.Root {...props} open={open ?? defaultOpen} onOpenChange={_onOpenChange} />
    </PopoverOnOpenChangeContext.Provider>
  );
};

const PopoverTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>((props, ref) => {
  const { handleOpen, handleClose } = React.useContext(PopoverOnOpenChangeContext);

  return <PopoverPrimitive.Trigger ref={ref} {...props} onMouseEnter={handleOpen} onMouseLeave={handleClose} />;
});

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => {
  const { handleOpen, handleClose } = React.useContext(PopoverOnOpenChangeContext);

  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        className={cn(
          `z-50 w-72 rounded-md border border-border bg-popover p-4 text-popover-foreground shadow-md outline-none
          data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0
          data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2
          data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2`,
          className,
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  );
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };

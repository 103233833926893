import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AlertWarningIcon, Link as UILink } from 'ui';
import { useQuery } from 'urql';

import { PaymentOverdueBannerDocument } from 'generated/graphql';
import { globalVariables } from 'lib/globalVariables';

import type { PaymentOverdueBannerQuery, PaymentOverdueBannerQueryVariables } from 'generated/graphql';
import type { ArrayElement } from 'shared-utils';

export function paymentIsOverDue(transaction: ArrayElement<PaymentOverdueBannerQuery['transactionsByTeam']>): boolean {
  if (transaction?.status !== 2 || (transaction?.invoice?.amount_due ?? 0) <= 0) {
    return false;
  }

  return isAfter(new Date(), add(new Date(transaction?.created!), { days: 90 }));
}

type Props = {
  teamId: string;
};

export const PaymentOverDueBanner = ({ teamId }: Props) => {
  const [{ data, fetching }] = useQuery<PaymentOverdueBannerQuery, PaymentOverdueBannerQueryVariables>({
    query: PaymentOverdueBannerDocument,
    variables: {
      teamId,
    },
  });

  const hasOverDuePayment = useMemo(() => {
    if (!data || fetching) {
      return false;
    }

    return data.transactionsByTeam?.some(paymentIsOverDue);
  }, [data, fetching]);

  // There shouldn't be any reason to show this banner in demo mode
  if (globalVariables.getEnvironmentVariables().isDemo) {
    return null;
  }

  if (!hasOverDuePayment) {
    return null;
  }

  document.documentElement.classList.add('has-banner');

  return (
    <div className="flex h-[46px] items-center justify-center bg-destructive px-2 text-center text-xs text-destructive-foreground sm:text-sm">
      <div>
        <AlertWarningIcon /> <strong>Your invoice is overdue.</strong> Please make a payment or your account may be
        frozen.{' '}
        <UILink color="white" as={Link} to="/settings/invoices">
          Pay now
        </UILink>
      </div>
    </div>
  );
};

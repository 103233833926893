/* eslint-disable max-lines */
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay, PhoneInput } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import ChangeFacilitator from 'components/createBooking/components/changeFacilitator';
import ChangeOrganiser from 'components/createBooking/components/changeOrganiser';
import PriceCardContainer from 'components/createBooking/components/priceCardContainer';
import ModalUpdateOrganiserDetails from 'components/createBooking/components/updateOrganiserDetails';
import { useConnectedClient } from 'context/ConnectedClientContext';
import updateMeMutation from 'data/mutations/user/updateMe';
import fetchUsersByTeamId from 'data/queries/teams/fetchUsersByTeamId';
import { bookingUtils } from 'lib/booking';
import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import { errors, validations } from 'lib/validations';

import './styles/contactsStyles.scss';

function Contacts(props: any) {
  const booking = _.get(props, 'booking');
  const [bookingState, setBookingState] = useState(booking);
  const [openModalOrganiserDetails, setOpenModalOrganiserDetails] = useState(false);
  const [loadingUI, setLoadingUI] = useState(false);
  const { details } = useConnectedClient();

  const navigate = useNavigate();

  const [facilitatorPhone, setFacilitatorPhone] = useState({
    facilitatorTempPhoneNumber: '',
    facilitatorTempPhoneCountryCode: '',
    changeFacilitatorPhoneNumber: false,
    errorTextPhoneNumber: '',
  });
  const [organiserPhone, setOrganiserPhone] = useState({
    organiserPhoneNumber: '',
    organiserPhoneCountryCode: _.get(booking, 'config.location.country', localStorage.get('countryByIp')),
    errorTextPhoneNumber: '',
  });

  const { loading, data } = useQuery(fetchUsersByTeamId, {
    variables: {
      _id: _.get(props, 'booking._team_id'),
    },
  });

  const [updateUser] = useMutation(updateMeMutation);

  useEffect(() => {
    props.updateLastStep({
      step: 'Additional Info',
      subStep: `/booking-setup/${props.booking._id}/additional-info/contacts`,
      stepId: 'additional_info_contacts',
    });
  }, []);

  useEffect(() => {
    setBookingState(_.get(props, 'booking'));
  }, [props.booking]);

  useEffect(() => {
    const organiserHasPhone =
      _.get(bookingState, 'config.contact.UserOrganiser.contact.phone.mobile') ||
      _.get(bookingState, 'config.contact.UserOrganiser._id') !== details?._id;
    if (_.get(props, 'bookingSteps.additional_info_contacts') === 'error' && !organiserHasPhone) {
      setOrganiserPhone({
        organiserPhoneNumber: '',
        organiserPhoneCountryCode: _.get(booking, 'config.location.country', localStorage.get('countryByIp')),
        errorTextPhoneNumber: 'Required',
      });
    }
  }, [props.bookingSteps]);

  useEffect(() => {
    props.renderRightContent(rightContent());
    props.renderRightAppPreview(null);
    // setBookingState(booking);
  }, [booking]);

  const rightContent = () => {
    const cardTexts1 = [
      {
        cardTitle: <span>We don&apos;t display your contact information</span>,
        cardText:
          'Your email and phone number is hidden from applicants. All messaging is done via the Askable messaging interface.',
      },
      {
        cardTitle: 'Only invited participants can contact you',
        cardText: 'Only participants who are confirmed to participate will have the option to contact the researcher.',
      },
      {
        cardTitle: 'Why do we need a phone number?',
        cardText:
          'If there any any issues on the day, either the Askable team or participants may need a way to get in touch with you.',
      },
    ];
    const cardTexts2 = [
      {
        cardText:
          'Sometimes you might be in charge of selecting participants and managing the calendar, whilst someone else on your team does the actual interviews.',
      },
      {
        cardText:
          'In this case, we want to make sure that in-app messages from the participant go to the right person on testing day.',
        additionalText: true,
      },
    ];
    const cardTexts3 = [
      {
        cardText: (
          <span>
            Yes. Since you&apos;re using Askable Sessions, you&apos;ll be able to easily invite observers and
            notetakers.
          </span>
        ),
      },
      {
        cardText: (
          <span>
            Observers don&apos;t need an Askable account and you can set a meeting password. Once you have a participant
            confirmed on your calendar, you&apos;ll be able to share the meeting link.
          </span>
        ),
        additionalText: true,
      },
    ];

    return (
      <Fragment>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          booking={bookingState}
          bookingSteps={props.bookingSteps}
          team={_.get(props, 'team')}
          context={props.context}
          condensedCard
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer sliderCard cardId="__contactsCard1" cardClass="slideInAnimationDelay80" cardTexts={cardTexts1} />
        {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
          <Fragment>
            <CardContainer
              additionalCard
              cardId="__contactsCard2"
              cardClass="slideInAnimationDelay160"
              cardTitle="Organiser vs Facilitator?"
              cardTexts={cardTexts2}
              onLinkClick={() => {
                window.open(
                  'https://help.askable.com/en/articles/4849221-what-s-the-difference-between-the-booking-organiser-and-facilitator',
                  '_blank',
                );
              }}
            />
            {bookingUtils.isAskableLive(booking) && (
              <CardContainer
                additionalCard
                cardId="__contactsCard3"
                cardClass="slideInAnimationDelay240"
                cardTitle="Can I invite observers / notetakers?"
                cardTexts={cardTexts3}
                linkText="Try a demo"
                onLinkClick={() => {
                  navigate('/askable-sessions/demo');
                }}
                onAdditionalLinkClick={() => {
                  window.open('https://help.askable.com/en/articles/3945805-askable-live-quick-faq', '_blank');
                }}
              />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };

  const onClickNext = () => {
    props.history.push({
      pathname: `/booking-setup/${booking._id}/additional-info/legal-privacy`,
      booking: null,
      bookingState: null,
    });
  };

  const renderOrganiserPhoneNumber = () => {
    if (_.get(bookingState, 'config.contact.UserOrganiser.contact.phone.mobile')) {
      return <p>{utils.formatPhoneNumber(_.get(bookingState, 'config.contact.UserOrganiser.contact.phone.mobile'))}</p>;
    }
    if (_.get(bookingState, 'config.contact.UserOrganiser._id') !== details?._id!) {
      return (
        <p className="font--red">
          {_.get(bookingState, 'config.contact.UserOrganiser.meta.identity.firstname', 'Organiser')}
          &apos;s phone number is missing
        </p>
      );
    }
    return (
      <div className="organiserPhoneNumberContainer">
        <PhoneInput
          countryCode={
            organiserPhone.organiserPhoneCountryCode ||
            _.get(bookingState, 'config.location.country', localStorage.get('countryByIp'))
          }
          phoneNumber={organiserPhone.organiserPhoneNumber}
          onChange={({ phone, country_code }) => {
            return setOrganiserPhone({
              organiserPhoneNumber: phone,
              organiserPhoneCountryCode: country_code,
              errorTextPhoneNumber: '',
            });
          }}
          className="phoneInput"
          errorTextBottom={organiserPhone.errorTextPhoneNumber}
        />
        <a className="saveLink" onClick={saveOrganiserPhoneNumber}>
          Save
        </a>
      </div>
    );
  };

  const renderFacilitatorDetails = () => {
    // It should only render facilitator details when they are not the same as the organiser,
    //  otherwise we would be just doubling up information
    if (
      _.get(bookingState, 'config.contact._organiser_user_id') ===
      _.get(bookingState, 'config.contact._facilitator_user_id')
    ) {
      return null;
    }

    return (
      <Fragment>
        <p>{_.get(bookingState, 'config.contact.UserFacilitator.email')}</p>
        <div className="facilitatorPhoneNumberContainer">{renderFacilitatorPhoneNumber()}</div>
      </Fragment>
    );
  };

  const renderFacilitatorPhoneNumber = () => {
    if (facilitatorPhone.changeFacilitatorPhoneNumber) {
      return (
        <div className="facilitatorPhoneNumberInternalContainer">
          <PhoneInput
            countryCode={
              facilitatorPhone.facilitatorTempPhoneCountryCode ||
              _.get(bookingState, 'config.location.country', localStorage.get('countryByIp'))
            }
            phoneNumber={facilitatorPhone.facilitatorTempPhoneNumber}
            onChange={({ phone, country_code }) => {
              return setFacilitatorPhone({
                ...facilitatorPhone,
                facilitatorTempPhoneNumber: phone,
                facilitatorTempPhoneCountryCode: country_code,
                errorTextPhoneNumber: '',
              });
            }}
            errorTextBottom={facilitatorPhone.errorTextPhoneNumber}
          />
          <a className="saveLink" onClick={onSaveFacilitatorTempPhoneNumber}>
            Save
          </a>
        </div>
      );
    }
    const facilitatorPhoneNumber =
      _.get(bookingState, 'config.contact.facilitator_temp_phone_number') ||
      _.get(bookingState, 'config.contact.UserFacilitator.contact.phone.mobile');

    if (facilitatorPhoneNumber) {
      return (
        <Fragment>
          <p>{utils.formatPhoneNumber(facilitatorPhoneNumber)}</p>
          <a
            className="addEditLabel text-xs"
            onClick={() => setFacilitatorPhone({ ...facilitatorPhone, changeFacilitatorPhoneNumber: true })}
          >
            Edit
          </a>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <p className="text-red mr-2">Phone number missing</p>
        <a
          className="text-xs"
          onClick={() => setFacilitatorPhone({ ...facilitatorPhone, changeFacilitatorPhoneNumber: true })}
        >
          Add
        </a>
      </Fragment>
    );
  };

  const saveOrganiserPhoneNumber = async () => {
    validations.startValidations();
    const phoneIsValid = validations.validatePhoneNumber(
      _.get(organiserPhone, 'organiserPhoneNumber'),
      _.get(organiserPhone, 'organiserPhoneCountryCode'),
    );
    if (phoneIsValid) {
      setLoadingUI(true);
      // Updates the user phone number
      await updateUser({
        variables: {
          user: {
            contact: {
              phone: {
                country_code: organiserPhone.organiserPhoneCountryCode,
                mobile: organiserPhone.organiserPhoneNumber,
              },
            },
          },
        },
      })
        .then(userData => {
          if (userData && userData.data.updateMe) onChangeContactInfo(_.get(userData, 'data.updateMe'));
          setLoadingUI(false);
        })
        .catch(() => {
          setLoadingUI(false);
        });
      toast.success('Phone number updated successfully');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ organiserPhoneNumber: string; ... Remove this comment to see the full error message
      setOrganiserPhone({
        organiserPhoneNumber: '',
        organiserPhoneCountryCode: null,
      });
    } else {
      setOrganiserPhone({ ...organiserPhone, errorTextPhoneNumber: errors.phoneInvalid });
    }
    validations.stopValidations();
  };

  const onSaveFacilitatorTempPhoneNumber = async () => {
    validations.startValidations();
    const phoneIsValid = validations.validatePhoneNumber(
      _.get(facilitatorPhone, 'facilitatorTempPhoneNumber'),
      _.get(facilitatorPhone, 'facilitatorTempPhoneCountryCode'),
    );
    if (phoneIsValid) {
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          contact: {
            ...bookingState.config.contact,
            facilitator_temp_phone_number: facilitatorPhone.facilitatorTempPhoneNumber,
          },
        },
      };
      setBookingState(bookingStateObj);

      // Updates the user phone number
      props.updateBooking(bookingStateObj, {
        config: {
          contact: {
            facilitator_temp_phone_number: facilitatorPhone.facilitatorTempPhoneNumber,
          },
        },
      });

      setFacilitatorPhone({
        facilitatorTempPhoneCountryCode: '',
        facilitatorTempPhoneNumber: '',
        changeFacilitatorPhoneNumber: false,
        errorTextPhoneNumber: '',
      });
    } else {
      setFacilitatorPhone({ ...facilitatorPhone, errorTextPhoneNumber: errors.phoneInvalid });
    }
    validations.stopValidations();
  };

  const onChangeFacilitatorOrganiser = (contact: any) => {
    if (contact) {
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          contact: {
            ...bookingState.config.contact,
            ...contact,
          },
        },
      };
      setBookingState(bookingStateObj);
      props.updateBookingState(bookingStateObj);
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }
  };

  const onChangeContactInfo = (contact: any) => {
    if (contact) {
      const bookingStateObj = {
        ...bookingState,
        config: {
          ...bookingState.config,
          contact: {
            ...bookingState.config.contact,
            UserOrganiser: {
              ...bookingState.config.contact.UserOrganiser,
              contact: _.get(contact, 'contact'),
              meta: _.get(contact, 'meta'),
              email: _.get(contact, 'email'),
            },
          },
        },
      };
      setBookingState(bookingStateObj);
      props.updateBookingState(bookingStateObj);
      props.validateBooking(bookingStateObj, document.location.pathname, true);
    }
  };

  return (
    <div className="createBookingContent">
      {(loading || loadingUI) && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h1 id="__pageTitle" className="title">
        Contacts
      </h1>
      <div className="organiserFacilitatorContainer">
        <div className="organiserFacilitatorGroup">
          <p className="organiserFacilitatorLabel">Organiser</p>
          <div className="organiserFacilitatorDetails">
            <p className="font--bold">
              {_.get(bookingState, 'config.contact.UserOrganiser.meta.identity.firstname')}{' '}
              {_.get(bookingState, 'config.contact.UserOrganiser.meta.identity.lastname')}{' '}
              {_.get(bookingState, 'config.contact._organiser_user_id') === details?._id! ? '(you)' : ''}
            </p>
            <p>{_.get(bookingState, 'config.contact.UserOrganiser.email')}</p>
            {renderOrganiserPhoneNumber()}
            {_.get(bookingState, 'config.contact.UserOrganiser.contact.phone.mobile') &&
              _.get(bookingState, 'config.contact._organiser_user_id') === details?._id! && (
                <div
                  onClick={() => {
                    setOpenModalOrganiserDetails(true);
                  }}
                  className="updateInfoLink"
                >
                  Update contact info
                </div>
              )}
          </div>
          <div className="changeLink">
            <ChangeOrganiser
              data={data}
              booking={bookingState}
              onChangeOrganiser={(contact: any) => {
                onChangeFacilitatorOrganiser(contact);
              }}
            />
          </div>
        </div>
        {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
          <div className="organiserFacilitatorGroup borderTop">
            <p className="organiserFacilitatorLabel">Facilitator</p>
            <div className="organiserFacilitatorDetails">
              <p className="font--bold">
                {_.get(bookingState, 'config.contact.UserFacilitator.meta.identity.firstname')}{' '}
                {_.get(bookingState, 'config.contact.UserFacilitator.meta.identity.lastname')}{' '}
                {_.get(bookingState, 'config.contact._facilitator_user_id') === details?._id! ? '(you)' : ''}
              </p>
              {renderFacilitatorDetails()}
            </div>
            <div className="changeLink">
              <ChangeFacilitator
                data={data}
                booking={bookingState}
                loadingUI={loadingUI}
                onChangeFacilitator={(contact: any) => {
                  onChangeFacilitatorOrganiser(contact);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="buttonNextContainer">
        <Button variant="primary" size="lg" onClick={onClickNext}>
          Next
        </Button>
      </div>
      {openModalOrganiserDetails && (
        <ModalUpdateOrganiserDetails
          onClose={() => setOpenModalOrganiserDetails(false)}
          open={openModalOrganiserDetails}
          contact={bookingState.config.contact}
          booking={bookingState}
          onChangeContact={onChangeContactInfo}
        />
      )}
    </div>
  );
}

export default deprecatedWithRouter(Contacts);

import { Archive, CircleDashed, CirclePause, CircleEllipsis } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import type { Booking } from 'generated/graphql';

const intToEnum = [
  'draft',
  'active',
  'pending_review',
  'in_review',
  'rejected',
  'completed',
  'pending_payment',
  'archived',
] as const;

const CircleFilled = ({ className }: { className?: string }) => (
  <svg fill="none" height="24" width="24" viewBox="0 0 24 24" className={className}>
    <path stroke="currentColor" strokeWidth="2" d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z" />
    <circle cx="12" cy="12" r="7" fill="currentColor" />
  </svg>
);

const CheckFilled = ({ className }: { className?: string }) => (
  <svg fill="currentColor" height="24" width="24" viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Zm5.3-14.2-6 6a1.1 1.1 0 0 1-1.6 0l-3-3a1.1 1.1 0 0 1 1.6-1.6l2.2 2.2 5.2-5.2a1.1 1.1 0 0 1 1.6 1.6Z"
    />
  </svg>
);

const statusTypes = {
  draft: { icon: CircleDashed, tone: 'text-muted-foreground' },
  in_review: { icon: CircleEllipsis, tone: 'text-warning' },
  pending_review: { icon: CircleEllipsis, tone: 'text-warning' },
  pending_payment: { icon: CirclePause, tone: 'text-warning' },
  active: { icon: CircleFilled, tone: 'text-success' },
  paused: { icon: CirclePause, tone: 'text-warning' },
  archived: { icon: Archive, tone: 'text-muted-foreground' },
  completed: { icon: CheckFilled, tone: 'text-success' },
  rejected: { icon: CirclePause, tone: 'text-error' },
} as const;

export const StudyStatusIndicator = ({ status }: { status: Booking['status'] }) => {
  const { t } = useTranslation();

  if (status === undefined || status === null) {
    return null;
  }

  const statusEnum = intToEnum[status];
  const tone = statusTypes[statusEnum]?.tone;
  const IconComponent = statusTypes[statusEnum]?.icon;

  return (
    <div className="flex items-center gap-2 whitespace-nowrap text-sm text-muted-foreground">
      {IconComponent ? <IconComponent className={`h-3.5 w-3.5 ${tone}`} /> : null}
      {t(`sections.studies.status.${statusEnum}`)}
    </div>
  );
};

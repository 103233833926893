import type { ReactNode } from 'react';

interface SectionProps {
  children: ReactNode;
  description?: string;
  icon?: ReactNode;
  id: string;
  title?: string;
}

export const SectionContainer = ({ children, description, icon, id, title }: SectionProps) => {
  return (
    <section className="flex flex-col gap-5" id={id}>
      {title ? (
        <header className="flex flex-col gap-1 px-4 lg:px-2">
          <h2 className="flex items-center gap-2 text-xl font-semibold text-foreground">
            {icon} {title}
          </h2>
          {description ? <div className="text-sm text-muted-foreground">{description}</div> : null}
        </header>
      ) : null}

      <div className="flex flex-col gap-6 rounded-2xl bg-background p-4 lg:p-6">{children}</div>
    </section>
  );
};

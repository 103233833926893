import { Button } from '@askable/ui/core/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@askable/ui/core/form';
import { Input } from '@askable/ui/core/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';

import { MinimalPage } from 'components/MinimalPage/MinimalPage';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { useUpdateMeMutation } from 'generated/graphql';

const formSchema = z.object({
  first_name: z.string().min(2, {
    message: 'First name is required',
  }),
  last_name: z.string().min(2, {
    message: 'Last name is required',
  }),
});

export const CompleteProfileContainer = () => {
  const [searchParams] = useSearchParams();
  const { details } = useConnectedClient();
  const navigate = useNavigate();
  const [updateUser, { loading }] = useUpdateMeMutation();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
    },
  });

  useEffect(() => {
    if (!details?.name?.firstname && !details?.name?.lastname) {
      return;
    }

    if (details.name?.firstname && !details.name?.lastname) {
      form.setValue('first_name', details.name.firstname);
      return;
    }

    if (!details.name?.firstname && details.name?.lastname) {
      form.setValue('last_name', details.name.lastname);
      return;
    }

    navigate('/');
  }, [details]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await updateUser({
      variables: {
        user: {
          meta: {
            identity: {
              firstname: values.first_name,
              lastname: values.last_name,
            },
          },
          // password: values.password,
        },
      },
    });

    window.location.replace(searchParams.get('redirect_path') ?? '/');
  };

  return (
    <MinimalPage bodyClasses="mt-10 md:container py-10 !max-w-lg">
      <div className="space-y-8">
        <h1 className="text-2xl font-semibold">Your details</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First name</FormLabel>
                  <FormControl>
                    <Input {...field} autoFocus />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex">
              <Button type="submit" isLoading={loading} variant="primary" className="ml-auto">
                Continue
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </MinimalPage>
  );
};

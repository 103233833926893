import { Button } from '@askable/ui/core/button';
import { Input } from '@askable/ui/core/input';
import { Label } from '@askable/ui/core/label';
import { cn } from '@askable/ui/lib/utils';
import { Explore } from '@askable/ui/unmod/icons';
import { Loader2, Search } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { useBlockContext } from 'containers/Studies/BuildStudy/BuildStudy';
import { FigmaFileDetails } from 'containers/Studies/BuildStudy/data/FigmaFileDetails.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { FigmaFileCanvas } from 'generated/graphql';

interface SelectStartScreenProps {
  activeStartScreenId: string | null;
  fileId?: string | null;
  onChange: (startScreenId: string) => void;
}

interface SelectScreenItemProps {
  height: number | null;
  width: number | null;
  imageUrl: string | null;
  index: number;
  isSelected: boolean;
  name: string;
  nodeId: string;
  isMobile: boolean;
  onSelect: (id: string) => void;
}

const SelectScreenItem = ({ nodeId, imageUrl, index, isMobile, isSelected, name, onSelect }: SelectScreenItemProps) => {
  return (
    <button
      className={cn(
        `row-span-2 grid w-32 translate-y-0 grid-rows-[subgrid] items-center gap-0 overflow-hidden rounded-md border-0.5
        border-border text-sm opacity-100 transition-all hover:border-input focus:border-input active:border-primary
        active:ring-1`,
        'starting:translate-y-2 starting:opacity-0',
        {
          'border-primary ring-1 ring-primary hover:border-primary focus:border-primary': isSelected,
          // if there's no image_url we can assume we're currently fetching for it
          // though there is an edge case where the user refreshes their browser while we're fetching.
          // in that case they'd see this loading UI until they clicked the refresh button again.
          checkered: !imageUrl,
        },
      )}
      onClick={() => onSelect(nodeId)}
      style={{ transitionDelay: `${30 * index}ms` }}
      title={name}
      type="button"
    >
      <div className={cn('h-20 w-32 overflow-hidden', { 'h-44': isMobile })}>
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="Preview"
            className={cn('min-h-full min-w-full object-cover object-top', {
              'object-center': isMobile,
            })}
          />
        ) : (
          <div className="h-full w-full animate-pulse bg-border" />
        )}
      </div>
      <div className="w-full truncate border-t border-border bg-secondary p-2">{name}</div>
    </button>
  );
};

export const SelectStartScreen = ({ activeStartScreenId, fileId, onChange }: SelectStartScreenProps) => {
  const { t } = useTranslation();
  const { setActiveSection } = useStudyContext();
  const { setScreenSelection } = useBlockContext();
  const [selectedScreenId, setSelectedScreenId] = useState<string | null>(activeStartScreenId);
  const [searchQuery, setSearchQuery] = useState('');

  const [{ data, fetching }] = useQuery({
    query: FigmaFileDetails,
    pause: !fileId,
    variables: { fileId: fileId ?? '' },
    requestPolicy: 'cache-and-network',
  });

  const screenLabel = t('sections.studies.start').toLocaleLowerCase();

  const handleSelectScreenButton = async () => {
    if (!selectedScreenId) {
      return;
    }
    onChange(selectedScreenId);
    setScreenSelection(null);
  };

  const { matchingFlowStartingPoints, matchingScreens } = useMemo(() => {
    const allScreens = data?.figmaFile?.frames ?? [];
    const flowStartingPointNodeIds = ((data?.figmaFile?.canvases as FigmaFileCanvas[]) ?? []).flatMap(
      canvas => canvas.flow_starting_point_node_ids ?? [],
    );
    const screens = searchQuery
      ? allScreens.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
      : allScreens;
    const startingPoints = screens.filter(item => flowStartingPointNodeIds.includes(item.node_id));
    return { matchingFlowStartingPoints: startingPoints, matchingScreens: screens };
  }, [data?.figmaFile?.frames, data?.figmaFile?.canvases, searchQuery]);

  const isSubmitDisabled = !selectedScreenId || !matchingScreens.some(s => s.node_id === selectedScreenId);

  return (
    <div className="figma-select-screen grid min-h-0 grid-rows-[1fr_auto] bg-secondary py-4 lg:py-5 lg:pl-4">
      <div className="flex flex-col gap-4 overflow-y-auto pb-12">
        <header
          className="sticky top-0 z-10 flex flex-wrap items-center justify-between gap-2 bg-secondary/90 px-4 py-4 backdrop-blur-sm lg:-my-2
            lg:pr-8"
        >
          <h3 className="font-semibold leading-none">{t('sections.studies.selectAScreen', { type: screenLabel })}</h3>

          <div className="flex items-center gap-0">
            <Label htmlFor="search-screens" className="z-10 -mr-7 text-muted-foreground">
              <div className="sr-only">{t('sections.studies.searchScreens')}</div>
              <Search className="h-4 w-4" />
            </Label>

            <Input
              className="w-[220px] rounded-xl border-input pl-8"
              id="search-screens"
              placeholder={`${t('sections.studies.searchScreens')}...`}
              type="search"
              value={searchQuery}
              disabled={fetching}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </div>
        </header>

        {fetching && !data ? (
          <div className="flex h-20 items-center justify-center">
            <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6 px-4 lg:pr-8">
              {matchingFlowStartingPoints.length > 0 ? (
                <div className="flex flex-col gap-4">
                  <h4 className="text-sm font-semibold text-muted-foreground">
                    {t('sections.studies.searchScreensStartingPointHeading')}
                  </h4>
                  <div className="grid grid-cols-[repeat(auto-fit,8rem)] gap-3">
                    {matchingFlowStartingPoints.map((item, index) => {
                      return (
                        <SelectScreenItem
                          index={index}
                          isSelected={selectedScreenId === item.node_id}
                          key={item.node_id}
                          onSelect={setSelectedScreenId}
                          width={item.width}
                          height={item.height}
                          imageUrl={item.image_url}
                          nodeId={item.node_id}
                          isMobile={item.is_mobile ?? false}
                          name={item.name}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {matchingScreens.length > 0 ? (
                <div className="flex flex-col gap-4">
                  <h4 className="text-sm font-semibold text-muted-foreground">
                    {t('sections.studies.searchScreensAllScreensHeading')}
                  </h4>
                  <div className="grid grid-cols-[repeat(auto-fit,8rem)] gap-3">
                    {matchingScreens.map((item, index) => (
                      <SelectScreenItem
                        index={index}
                        isSelected={selectedScreenId === item.node_id}
                        key={item.node_id}
                        onSelect={setSelectedScreenId}
                        width={item.width}
                        height={item.height}
                        imageUrl={item.image_url}
                        nodeId={item.node_id}
                        isMobile={item.is_mobile ?? false}
                        name={item.name}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>

            {!fetching && matchingScreens.length === 0 ? (
              <div className="flex h-full flex-col items-center justify-center gap-3 text-center font-semibold opacity-50">
                <Explore size={48} />
                {t('sections.studies.searchScreensNotFound')}
              </div>
            ) : null}
          </>
        )}
      </div>

      <footer className="z-10 -mt-10 flex justify-end gap-2 bg-secondary/90 px-4 pb-2 pt-4 backdrop-blur-sm lg:pr-8">
        <Button
          variant="ghost"
          onClick={() => {
            setScreenSelection(null);
            setActiveSection('editor');
          }}
        >
          {t('global.cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleSelectScreenButton();
            setActiveSection('editor');
          }}
          disabled={isSubmitDisabled}
          isLoading={fetching}
        >
          {t('sections.studies.setAsScreen', { type: screenLabel })}
        </Button>
      </footer>
    </div>
  );
};

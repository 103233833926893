import { graphql } from 'src/graphql';

export const FigmaPrototypeResultStats = graphql(`
  query FigmaPrototypeResultStats($_booking_id: ID!, $_task_id: ID!) {
    figmaPrototypeResultStats(_booking_id: $_booking_id, _task_id: $_task_id) {
      average_duration
      completed_count
      misclick_rate
      skipped_count
      total_responses
    }
  }
`);

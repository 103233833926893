import { ActiveStatus } from '@askable/ui/icons/ActiveStatus';
import { ArchiveStatus } from '@askable/ui/icons/ArchiveStatus';
import { CompletedStatus } from '@askable/ui/icons/CompleteStatus';
import { DraftStatus } from '@askable/ui/icons/DraftStatus';
import { InReviewStatus } from '@askable/ui/icons/InReviewStatus';
import { RejectStatus } from '@askable/ui/icons/RejectStatus';

import { BOOKING_STATUS } from 'lib/constants';

import { CellStatus } from '../components/CellStatus';

import type { LucideProps } from 'lucide-react';
import type { ReactNode } from 'react';

export const statusData: Record<
  (typeof BOOKING_STATUS)[keyof typeof BOOKING_STATUS],
  { Icon: (props: LucideProps) => ReactNode; title: string }
> = {
  [BOOKING_STATUS.ACTIVE]: {
    Icon: ActiveStatus,
    title: 'Active',
  },
  [BOOKING_STATUS.IN_REVIEW]: {
    Icon: InReviewStatus,
    title: 'In Review',
  },
  [BOOKING_STATUS.DRAFT]: {
    Icon: DraftStatus,
    title: 'Draft',
  },
  [BOOKING_STATUS.COMPLETED]: {
    Icon: CompletedStatus,
    title: 'Completed',
  },
  [BOOKING_STATUS.PENDING_REVIEW]: {
    Icon: InReviewStatus,
    title: 'Pending review',
  },
  [BOOKING_STATUS.REJECTED]: {
    Icon: RejectStatus,
    title: 'Rejected',
  },
  [BOOKING_STATUS.PENDING_PAYMENT]: {
    Icon: InReviewStatus,
    title: 'Pending payment',
  },
  [BOOKING_STATUS.ARCHIVED]: {
    Icon: ArchiveStatus,
    title: 'Archived',
  },
};

type Props = { status: keyof typeof BOOKING_STATUS };

export const BookingCellStatus = ({ status }: Props) => {
  const { Icon, title } = statusData[BOOKING_STATUS[status]];

  return <CellStatus Icon={<Icon size={16} />} title={title} />;
};

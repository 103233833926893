import { toast } from '@askable/ui/core/sonner';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import './boxMessageStyles.scss';

function BoxMessage(props: any) {
  const [shouldDisplay, setShouldDisplay] = useState(true);

  useEffect(() => {
    if (_.get(props, 'error.extensions.code') === 401) {
      setShouldDisplay(false);
      toast.error(_.get(props, 'error.message'));
    } else if (!shouldDisplay) {
      setShouldDisplay(true);
    }
  }, [props.error]);

  if (!shouldDisplay) return null;

  return (
    <div
      id={props.id && props.id}
      className={`boxMessageContainer ${props.type} ${props.className ? props.className : ''}`}
    >
      {props.children}
    </div>
  );
}

export default BoxMessage;

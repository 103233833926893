import { createLucideIcon } from 'lucide-react';

import { cn } from '../lib/utils';

import type { LucideProps } from 'lucide-react';

export const DraftStatusComp = createLucideIcon('DraftStatus', [
  [
    'path',
    {
      d: 'M11 2h2M18.707 5l1.414 1.414M22 11v2M19.829 18.414l-1.415 1.414M13 22h-2M2 13v-2M4 6.414 5.414 5M5.414 19.829 4 18.414',
      stroke: 'currentColor',
      strokeLinecap: 'round',
    },
  ],
]);

export const DraftStatus = ({ className, ...props }: LucideProps) => {
  return <DraftStatusComp className={cn('fill-muted-foreground', className)} {...props} />;
};

import { AskableIconLoading } from '@askable/ui/core/askable-icon';
import { KindeProvider, useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useFeatureFlags } from 'feature-flags';
import { tryit } from 'radash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClient } from 'urql';

import { InitializeUserDocument } from 'generated/graphql';
import { AUTH_CONFIG } from 'network/auth';

import type { InitializeUserMutationResult, InitializeUserMutationVariables, UserType } from 'generated/graphql';
import type { ReactNode } from 'react';

const isDangerouslyUseLocalStorage =
  import.meta.env.VITE_ENVIRONMENT?.toLowerCase() === 'preview' || import.meta.env.MODE === 'development';

const KindeWrapper = ({ children }: { children: ReactNode }) => {
  const { isLoading, isAuthenticated, getToken } = useKindeAuth();
  const [ready, setReady] = useState(false);
  const client = useClient();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    AUTH_CONFIG.getToken = getToken;
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userType = params.get('user_type');

    if (isLoading) {
      return;
    }

    if (!isAuthenticated || !userType) {
      setReady(true);
      return;
    }

    getToken().then(token => {
      client
        .mutation<InitializeUserMutationResult, InitializeUserMutationVariables>(
          InitializeUserDocument,
          {
            input: {
              type: userType! as UserType,
            },
          },
          {
            fetchOptions: {
              headers: {
                Authorization: token!,
              },
            },
          },
        )
        .toPromise()
        .then(() => {
          setReady(true);
          const url = new URL(window.location.href);
          navigate(`${url.pathname}${url.search}`, { replace: true });
        });
    });
  }, [isLoading]);

  if (isLoading || !ready) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <AskableIconLoading />
      </div>
    );
  }

  return children;
};

export function useIsKinde() {
  const { KINDE_AUTH } = useFeatureFlags(['KINDE_AUTH'], { application: 'clients' });

  if (localStorage.getItem('access_token')) {
    return false;
  }

  return KINDE_AUTH;
}

export const AuthWrapper = ({ children }: { children: ReactNode }) => {
  const isKinde = useIsKinde();
  const navigate = useNavigate();

  if (!isKinde) {
    return children;
  }

  return (
    <KindeProvider
      clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
      domain={import.meta.env.VITE_KINDE_DOMAIN}
      logoutUri={window.location.origin}
      redirectUri={window.location.origin}
      isDangerouslyUseLocalStorage={isDangerouslyUseLocalStorage}
      onRedirectCallback={async (_user, app_state) => {
        const appState = app_state as { redirect_uri: string; utm?: string };

        const [, url] = await tryit(() => {
          return new URL(appState.redirect_uri);
        })();

        const [, utmObj] = await tryit(() => {
          return JSON.parse(appState.utm || '') as { utm_source?: string; utm_campaign?: string };
        })();
        if (utmObj) {
          // We are going to save after viewer query succeeds. We need to wait as kinde setup is done for the current user in our database
          if (utmObj.utm_source) {
            sessionStorage.setItem('utm_source', utmObj.utm_source);
          }
          if (utmObj.utm_campaign) {
            sessionStorage.setItem('utm_campaign', utmObj.utm_campaign);
          }
        }

        if (!url) {
          navigate('/');
          return;
        }
        const params = new URLSearchParams(url?.search);
        params.append('redirect', url?.pathname);
        navigate(`${url?.pathname}?${params.toString()}`, { replace: true });
      }}
    >
      <KindeWrapper>{children}</KindeWrapper>
    </KindeProvider>
  );
};

import { useQuery } from '@apollo/client';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { Navigate, useParams } from 'react-router-dom';

import { LoadingOverlay, AccessDeniedMessage } from 'components/common';
import { useConnectedClient } from 'context/ConnectedClientContext';
import fetchBookingById from 'data/queries/booking/fetchBookingById';
import updateBookingSubscription from 'data/subscriptions/booking/updateBookingSubscription';
import { BOOKING_TYPE } from 'lib/constants';
import { teamUtils } from 'lib/teams';

import CreateBookingWithData from './createBookingWithData';

function CreateBooking(props: any) {
  const params = useParams();
  const bookingID = params.bookingID || _.get(props, 'bookingID');
  const currency = teamUtils.getCurrency();
  const { details } = useConnectedClient();
  const connectedUser = details?._id;

  const { loading, subscribeToMore, data } = useQuery(fetchBookingById, {
    skip: !bookingID,
    variables: {
      id: bookingID,
      currency_code: currency.code,
    },
  });

  if (bookingID) {
    if (loading) return <LoadingOverlay style={{ opacity: 0.8 }} />;

    // Redirect unmod studies to new UI
    if (data?.bookingByID?.type === BOOKING_TYPE.UNMODERATED) {
      return <Navigate to={`/studies/${data?.bookingByID?._id}`} />;
    }

    // Validate if user has permission to access the booking
    const bookingTeamUsers = _.get(data, 'bookingByID.team.users') || [];
    const bookingOwners = _.get(data, 'bookingByID._owner_id') || [];
    const isTeamUser = _.find(bookingTeamUsers, (item: any) => item._id === connectedUser);
    const isOwnerUser = _.find(bookingOwners, (item: any) => item === connectedUser);
    if (_.size(bookingTeamUsers) > 0 && !isTeamUser && !isOwnerUser) {
      return (
        <AccessDeniedMessage
          message="Oops! You're not allowed to see this study 😕"
          booking={_.get(data, 'bookingByID')}
          userId={connectedUser}
        />
      );
    }

    return (
      <CreateBookingWithData
        client={props.client}
        booking={_.get(data, 'bookingByID')}
        subscribeToUpdateBooking={() => {
          subscribeToMore({
            document: updateBookingSubscription,
            variables: {
              id: bookingID,
              currency_code: currency.code,
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;

              return { ...prev, booking: _.get(subscriptionData, 'data.bookingByID') };
            },
          });
        }}
      />
    );
  }

  return <CreateBookingWithData booking={null} />;
}

export default deprecatedWithRouter(CreateBooking);

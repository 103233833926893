import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { BadgeCheck, CircleAlert, CircleCheck, FilePlus, NotebookPen, Settings, Users } from 'lucide-react';
import { useState, useEffect, Fragment } from 'react';

import { askablePlusUtils } from 'lib/askablePlus';

import { stepperMenuUtils } from './stepperMenuUtils';

import './styles/stepperMenuStyles.scss';

function CreateAskablePlusBriefStepperMenu(props: any) {
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const steps: any = [];

  const totalResearchTypesSelected = askablePlusUtils.totalResearchTypesSelected(props.askablePlusBrief);
  const hasDiscoveryInterviewType = askablePlusUtils.hasDiscoveryInterviewType(props.askablePlusBrief);
  const hasUsabilityTestingType = askablePlusUtils.hasUsabilityTestingType(props.askablePlusBrief);
  const hasCompetitiveAnalysisType = askablePlusUtils.hasCompetitiveAnalysisType(props.askablePlusBrief);
  const hasSurveyType = askablePlusUtils.hasSurveyType(props.askablePlusBrief);
  const hasLongitudinalStudyType = askablePlusUtils.hasLongitudinalStudyType(props.askablePlusBrief);

  if (!_.get(props.askablePlusBrief, '_id')) {
    steps.push({
      index: 0,
      label: 'Project Setup',
      icon: <Settings className="h-4 w-4 text-foreground" />,
      link: '/askable-plus/create',
      subSteps: stepperMenuUtils.projectSetupSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'project_setup'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'project_setup'),
    });
  } else {
    steps.push({
      index: 10,
      label: 'Project Setup',
      icon: <Settings className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/project-setup`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/project-setup/project-title`,
      subSteps: stepperMenuUtils.projectSetupSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'project_setup'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'project_setup'),
    });
  }
  if (totalResearchTypesSelected > 1 && hasDiscoveryInterviewType) {
    steps.push({
      index: 20,
      label: 'Discovery Interview',
      icon: <NotebookPen className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/discovery-interview`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/discovery-interview/quota`,
      subSteps: stepperMenuUtils.discoveryInterviewSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'discovery_interview'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'discovery_interview'),
    });
  }
  if (totalResearchTypesSelected > 1 && hasUsabilityTestingType) {
    steps.push({
      index: 30,
      label: 'Usability Testing',
      icon: <NotebookPen className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/usability-testing`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/usability-testing/quota`,
      subSteps: stepperMenuUtils.usabilityTestingSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'usability_testing'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'usability_testing'),
    });
  }
  if (totalResearchTypesSelected > 1 && hasCompetitiveAnalysisType) {
    steps.push({
      index: 40,
      label: 'Competitive Analysis',
      icon: <NotebookPen className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/competitive-analysis`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/competitive-analysis/quota`,
      subSteps: stepperMenuUtils.competitiveAnalysisSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'competitive_analysis'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'competitive_analysis'),
    });
  }
  if (totalResearchTypesSelected > 1 && hasSurveyType) {
    steps.push({
      index: 50,
      label: 'Survey',
      icon: <NotebookPen className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/survey`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/survey/quota`,
      subSteps: stepperMenuUtils.surveySubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'survey'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'survey'),
    });
  }
  if (totalResearchTypesSelected > 1 && hasLongitudinalStudyType) {
    steps.push({
      index: 60,
      label: 'Longitudinal Study',
      icon: <NotebookPen className="h-4 w-4 text-foreground" />,
      baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/longitudinal-study`,
      link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/longitudinal-study/quota`,
      subSteps: stepperMenuUtils.longitudinalStudySubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
      completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'longitudinal_study'),
      error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'longitudinal_study'),
    });
  }
  steps.push({
    index: 70,
    label: 'Audience',
    icon: <Users className="h-4 w-4 text-foreground" />,
    baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/audience`,
    link:
      props?.askablePlusBrief?.askable_plus?.audience?.description === null
        ? `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/audience/demographic-filters`
        : `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/audience/participant-criteria`,
    subSteps: stepperMenuUtils.audienceSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
    completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'audience'),
    error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'audience'),
  });
  steps.push({
    index: 80,
    label: 'Additional Info',
    icon: <FilePlus className="h-4 w-4 text-foreground" />,
    baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/additional-info`,
    link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/additional-info/moderation-guide`,
    subSteps: stepperMenuUtils.additionalInfoSubSteps(props.askablePlusBrief, props.askablePlusBriefSteps),
    completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'additional_info'),
    error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'additional_info'),
  });
  steps.push({
    index: 90,
    label: 'Review & Submit',
    icon: <BadgeCheck className="h-4 w-4 text-foreground" />,
    baseLink: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/review-submit`,
    link: `/askable-plus/${_.get(props, 'askablePlusBrief._id', ':projectID')}/review-submit/confirm-brief`,
    completed: stepperMenuUtils.isStepCompleted(props.askablePlusBriefSteps, 'review_submit'),
    error: stepperMenuUtils.stepHasErrors(props.askablePlusBriefSteps, 'review_submit'),
  });

  useEffect(() => {
    const step = _.find(steps, (value: any) => props.location.pathname.includes(value.baseLink));
    const subStep = _.find(_.get(step, 'subSteps'), (value: any) => props.location.pathname.includes(value.link));
    setActiveStep(_.get(step, 'index') || 0);
    setActiveSubStep(_.get(subStep, 'index') || 0);
  });

  const onClickStep = (link: any) => {
    // Clicking on current step
    if (props.location.pathname === link) return null;

    // If booking doesn't exists, create it
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.createAskablePlusBrief(link);
    }

    if (_.get(props, 'askablePlusBrief._id')) {
      // Navigate to clicked page
      props.history.push({ pathname: link });
    }
  };

  const renderSubSteps = (value: any, index: any) => {
    return (
      <a
        className={`subStep ${value.index === activeSubStep || (!props.askablePlusBrief && value.index === 5) ? 'active' : ''}`}
        key={`subStep_${index}`}
        onClick={() => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
          onClickStep(value.link, value.id);
        }}
      >
        <div className="labelContainer">
          <p className="label">{value.label}</p>
        </div>
        <div className="status">
          {_.get(value, 'completed') && !_.get(value, 'error') && <CircleCheck className="h-4 w-4 text-success" />}
          {_.get(value, 'error') && <CircleAlert className="h-4 w-4 text-destructive" />}
        </div>
      </a>
    );
  };

  const renderStep = (value: any, index: any) => {
    return (
      <Fragment key={`step_${index}`}>
        <a
          className={`step ${value.index === activeStep ? 'active' : ''}`}
          key={`step_${index}`}
          onClick={() => {
            const lastStep = _.find(props.lastStep, (item: any) => item.step === value.label);
            const linkTo = _.get(lastStep, 'subStep') || value.link;
            const linkToId = _.get(lastStep, 'id') || value.id;

            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
            onClickStep(linkTo, linkToId);
          }}
        >
          <div className="icon">{value.icon}</div>
          <div className="labelContainer">
            <p className="label">{value.label}</p>
          </div>
          <div className="status">
            {_.get(value, 'completed') && !_.get(value, 'error') && <CircleCheck className="h-4 w-4 text-success" />}
            {_.get(value, 'completed') && _.get(value, 'error') && <CircleAlert className="h-4 w-4 text-destructive" />}
          </div>
        </a>
        {value.index === activeStep && _.get(value, 'subSteps') && (
          <div className="subSteps">
            {_.map(_.get(value, 'subSteps'), (item: any, key: any) => renderSubSteps(item, key))}
          </div>
        )}
      </Fragment>
    );
  };

  const onSaveExit = () => {
    props.updateAskablePlusBrief().then(() => {
      onClickStep('/');
    });
  };

  return (
    <>
      <div className="askablePlusBriefStepperMenuContainer">
        {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'value' implicitly has an 'any' type. */}
        {steps.map((value, index) => renderStep(value, index))}
      </div>
      <div className="mt-4 flex justify-center">
        <Button onClick={onSaveExit}>Save &amp; Exit</Button>
      </div>
    </>
  );
}

export default deprecatedWithRouter(CreateAskablePlusBriefStepperMenu);

export const labels = {
  download: 'Download video',
  error: {
    title: 'Playback error',
    description: 'Unable to load video. Please try refreshing the page or contacting support.',
    download: 'Download failed',
  },
  fullscreen: 'Full screen',
  mute: 'Mute',
  pause: 'Pause',
  play: 'Play',
  playbackRate: 'Playback speed',
  progress: 'Progress',
  unmute: 'Unmute',
  volume: 'Volume',
};

import { Label } from '@askable/ui/core/label';
import { RadioGroup, RadioGroupItem } from '@askable/ui/core/radio-group';
import _ from 'lodash';
import { Component } from 'react';

import { Button, Modal } from 'components/common';
import { globalVariables } from 'lib/globalVariables';

import './styles/syncCalendarModalStyles.scss';

class SyncCalendarTab extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      typeOfBooking: 1,
    };

    this.getCalendarLink = this.getCalendarLink.bind(this);
    this.getCalendarName = this.getCalendarName.bind(this);
  }

  getCalendarLink() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'typeOfBooking' does not exist on type 'R... Remove this comment to see the full error message
    switch (this.state.typeOfBooking) {
      case 1:
        return `webcal://reports.askable.com/ical/booking/${_.get(this.props, 'booking._id')}${globalVariables.isStaging() ? '?_db=staging' : ''}`;
      case 2:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
        return `webcal://reports.askable.com/ical/team/${_.get(this.props.booking, 'team._id')}${globalVariables.isStaging() ? '?_db=staging' : ''}`;
      default:
    }
  }

  getCalendarName() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'typeOfBooking' does not exist on type 'R... Remove this comment to see the full error message
    switch (this.state.typeOfBooking) {
      case 1:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
        return _.get(this.props.booking, 'config.session.type') === 1
          ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
            `[Askable] ${this.props.booking.name} | ${this.props.booking.total_participants} Participants`
          : // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
            `[Askable] ${this.props.booking.name}`;
      case 2:
        return '[Askable] All studies';
      default:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
        return _.get(this.props.booking, 'config.session.type') === 1
          ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
            `[Askable] ${this.props.booking.name} | ${this.props.booking.total_participants} Participants`
          : // @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message
            `[Askable] ${this.props.booking.name}`;
    }
  }

  render() {
    return (
      <Modal
        className="syncCalendarTabContainer"
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        open={this.props.open}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
        onClose={this.props.onClose}
      >
        <img className="illustration" src="/illustrations/syncCalendarIcon.png" alt="Calendar illustration" />
        <div className="internalContent">
          <h1 id="__pageTitle" className="title pb-2">
            Sync sessions to your calendar
          </h1>
          <RadioGroup
            // @ts-expect-error ts-migrate(2339)
            defaultValue={this.state.typeOfBooking as any}
            onValueChange={(value: any) => this.setState({ typeOfBooking: parseInt(value, 10) })}
          >
            {[
              {
                label: (
                  <span>
                    Sessions for this study <strong>only</strong>
                  </span>
                ),
                value: 1,
              },
              {
                label: (
                  <span>
                    {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'booking' does not exist on type 'Readonl... Remove this comment to see the full error message */}
                    <strong>all</strong> {_.get(this.props.booking, 'team.name')} studies
                  </span>
                ),
                value: 2,
              },
            ].map((option: any) => (
              <div className="flex items-center space-x-2" key={option.value}>
                <RadioGroupItem value={option.value} id={`radioTypeBookings_${option.value}`} />
                <Label htmlFor={`radioTypeBookings_${option.value}`}>{option.label}</Label>
              </div>
            ))}
          </RadioGroup>

          <div className="buttonsContainer">
            <div className="btnGoogle">
              <Button
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'typeOfBooking' does not exist on type 'R... Remove this comment to see the full error message
                label={`Add ${this.state.typeOfBooking === 1 ? 'this study' : 'ALL studies'} to Google Calendar`}
                variant="default"
                target="_blank"
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                href={`https://calendar.google.com/calendar/r?cid=${encodeURIComponent(this.getCalendarLink())}`}
                icon={<img src="/icons/google-calendar.png" alt="Google Calendar" />}
                className="btnGoogleCalendar"
              />
              <span className="warningMessage">
                Note: Google Calendar only refreshes every <strong>6-24 hours</strong>
              </span>
            </div>
            <Button
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'typeOfBooking' does not exist on type 'R... Remove this comment to see the full error message
              label={`Add ${this.state.typeOfBooking === 1 ? 'this study' : 'ALL studies'} to Calendar App`}
              variant="default"
              href={this.getCalendarLink()}
              icon={<img src="/icons/calendar-icon.png" alt="Apple Calendar" />}
              className="btnAppleCalendar"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SyncCalendarTab;

import { flexRender } from '@tanstack/react-table';
import { Td, Tr } from 'ui';

import type { TableRowSubmission } from '../../../hooks/useBookingParticipantsTableData';
import type { BookingParticipantActions } from '../../../state/booking-participant-state';
import type { Cell, Row } from '@tanstack/react-table';
import type { FC, MouseEventHandler } from 'react';
import type { TableCellProps } from 'ui';

type Props = {
  isSelected: boolean;
  setSidePanel: BookingParticipantActions['setPanelState'];
  submissionId: string;
  rowId: string;
  getVisibleCells: Row<TableRowSubmission>['getVisibleCells'];
};

export const ApplicantTableRow: FC<Props> = ({ getVisibleCells, rowId, isSelected, submissionId, setSidePanel }) => {
  const handleClick: MouseEventHandler<HTMLTableRowElement> = () => {
    setSidePanel(p => {
      if (p?.panel === 'details' && p.submissionId === submissionId) {
        return null;
      }
      return {
        submissionId,
        panel: 'details',
        type: 'single',
      };
    });
  };

  return (
    <Tr
      data-row-id={submissionId}
      key={rowId}
      _groupHover={{ bg: isSelected ? 'blue.50' : 'gray.50' }}
      bg={isSelected ? 'blue.50' : undefined}
      role="group"
      onClick={handleClick}
    >
      {getVisibleCells().map(cell => (
        <ApplicantTableData cell={cell} key={cell.id} isSelected={isSelected} />
      ))}
    </Tr>
  );
};

type ApplicantTableDataProps = {
  isSelected: boolean;
  cell: Cell<TableRowSubmission, unknown>;
};

const STICKY_STYLES: { left: TableCellProps; empty: TableCellProps } = {
  left: { left: 0, zIndex: 9, position: 'sticky' },
  empty: {},
};

const ApplicantTableData: FC<ApplicantTableDataProps> = ({ cell, isSelected }) => {
  const render = flexRender(cell.column.columnDef.cell, cell.getContext());
  const extra: TableCellProps = STICKY_STYLES[typeof cell.column.getIsPinned() === 'string' ? 'left' : 'empty'];

  return (
    <Td
      key={cell.id}
      {...extra}
      _groupHover={{ bg: isSelected ? 'blue.50' : 'gray.50' }}
      bg={isSelected ? 'accent-12' : 'white'}
      color="inherit"
    >
      {render}
    </Td>
  );
};

import { Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const EmptyState = ({ title }: { title?: string }) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full min-h-44 w-full flex-col items-center justify-center gap-2 text-sm text-muted-foreground">
      <Info className="h-8 w-8" />
      {title ?? t('sections.studies.results.noResponses')}
    </div>
  );
};

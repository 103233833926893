/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const projectFieldsFragment = gql`
  fragment projectFields on Project {
    _id
    _team_id
    _user_id
    name
    status
    type
    team {
      _id
      name
      users {
        _id
      }
    }
    askable_plus {
      objective
      description
      research_type {
        continuous_ai_moderated {
          quota
          _booking_id
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        continuous_researcher_moderated {
          quota
          _booking_id
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        discovery {
          _booking_id
          quota
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        usability {
          _booking_id
          quota
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        competitive_analysis {
          _booking_id
          quota
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        survey {
          _booking_id
          quota
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          Booking {
            _id
            name
            status
          }
        }
        longitudinal {
          _booking_id
          quota
          booking_type
          session {
            slot_min
            slot_max
            duration
            type
            time_limit
          }
          longitudinal_study {
            participant_workload {
              time
              measure
              frequency
            }
            period {
              time
              frequency
            }
            extra {
              time
              measure
              description
            }
          }
          Booking {
            _id
            name
            status
          }
        }
      }
      deliverables {
        research_report
        video_recordings
        transcriptions
        executive_summary
        highlight_reel
        other
        other_description
      }
      researcher_deliverables {
        research_report {
          url
        }
        transcriptions {
          url
        }
        executive_summary {
          url
        }
        highlight_reel {
          url
        }
        other {
          url
        }
      }
      due_date
      continuous_duration
      audience {
        description
        booking_config {
          participant_category
          timezone
          location {
            country
            city
            region
            state
            latitude
            longitude
            name
          }
          incentive {
            currency_code
            currency_symbol
            country_code
            value
          }
          session {
            duration
            time_limit
          }
          online_task {
            links {
              _id
              name
              url
              checked
            }
            type
            tool
            required_device
            required_features
          }
          remote {
            tool
            askable_live
          }
          question {
            _id
            title
            description
            options {
              _id
              label
              screen_in
            }
            config {
              display_participant
              multiple_selection
              none_of_the_above
              type
            }
          }
          criteria {
            meta_identity_birthday_year {
              field
              value
              operator
            }
            meta_identity_gender {
              field
              value
              operator
            }
            meta_family_status {
              field
              value
              operator
            }
            meta_education {
              field
              value
              operator
            }
            meta_work_status {
              field
              value
              operator
            }
            meta_work_employment_type {
              field
              value
              operator
            }
            meta_work_industry {
              _industry_id
              industry_name
              subcategories {
                _subcategory_id
                subcategory_name
              }
            }
            meta_identity_languages_english_speak {
              field
              value
              operator
            }
            meta_identity_locales {
              field
              value
              operator
              locale
            }
            meta_identity_languages {
              field
              value
              operator
            }
            meta_education_field {
              field
              value
              operator
            }
            meta_individual_income {
              field
              value
              operator
            }
            meta_family_income {
              field
              value
              operator
            }
            meta_tech_savviness {
              field
              value
              operator
            }
            meta_home_ownership {
              field
              value
              operator
            }
            meta_purchasing_behaviour {
              field
              value
              operator
            }
            meta_children {
              field
              value
              operator
            }
            custom {
              _question_id
              value
              operator
            }
            filters_order
            exclude_participants_from_time
            locations {
              states {
                name
                state
                formatted_address
                latitude
                longitude
                google_location_types
                google_location {
                  viewport {
                    northeast {
                      lat
                      lng
                    }
                    southwest {
                      lat
                      lng
                    }
                  }
                }
              }
              bounds {
                formatted_address
                state
                latitude
                longitude
                google_location_types
                google_location {
                  geometry {
                    latitude_ne
                    longitude_ne
                    latitude_sw
                    longitude_sw
                  }
                  viewport {
                    northeast {
                      lat
                      lng
                    }
                    southwest {
                      lat
                      lng
                    }
                  }
                }
              }
              countries {
                name
                state
                formatted_address
                latitude
                longitude
                google_location_types
                google_location {
                  viewport {
                    northeast {
                      lat
                      lng
                    }
                    southwest {
                      lat
                      lng
                    }
                  }
                }
              }
            }
          }
        }
      }
      assets {
        _id
        name
        url
        checked
      }
      additional_info {
        moderation_guide_type
        moderation_guide_url
        timezone
        nda_guide_type
        nda_guide_url
        notes
      }
      steps {
        project_setup
        project_setup_project_title
        project_setup_project_brief
        project_setup_key_objective
        project_setup_project_context
        project_setup_research_type
        project_setup_deliverables
        project_setup_frequency_duration
        project_setup_quota
        project_setup_session_duration
        project_setup_session_format
        project_setup_meeting_format
        discovery_interview
        discovery_interview_quota
        discovery_interview_session_duration
        discovery_interview_meeting_format
        discovery_interview_session_format
        usability_testing
        usability_testing_quota
        usability_testing_session_duration
        usability_testing_meeting_format
        survey
        survey_quota
        survey_duration
        longitudinal_study
        longitudinal_study_quota
        longitudinal_study_duration
        competitive_analysis
        competitive_analysis_quota
        audience
        audience_participant
        audience_participant_criteria
        audience_demographic_filters
        audience_custom_screener
        audience_incentives
        audience_participant_locations
        additional_info
        additional_info_link_to_assets
        additional_info_moderation_guide
        additional_info_your_timezone
        additional_info_legal_privacy
        additional_info_additional_notes
        review_submit
        review_submit_confirm_brief
      }
      meetings {
        briefing {
          timezone
          meeting_date
          meeting_link
          client_confirmed
          researcher_confirmed
        }
        debriefing {
          timezone
          meeting_date
          meeting_link
          client_confirmed
          researcher_confirmed
        }
      }
      researcher_hourly_rate
    }
    users {
      _id
      added_date
      status
      role
      User {
        _id
        email
        type {
          researcher
        }
        meta {
          identity {
            firstname
            lastname
            middlename
            gender
          }
          social {
            google {
              id
            }
          }
          work {
            title
            company_name
          }
        }
        contact {
          phone {
            country_code
            mobile
          }
        }
      }
    }
    owner {
      _id
      email
      displayName
      meta {
        identity {
          firstname
          lastname
        }
      }
    }
    bookings {
      _id
      status
      config {
        demo
        timezone
        online_task {
          type
        }
      }
      total_participants
      name
      user {
        displayName
      }
      rating {
        overall
      }
      type
      session {
        _id
        start
        end
        status
        askable_live_rooms {
          sid
          composition_sid
        }
        askable_live_users {
          _user_id
          observer_identifier
          online
          User {
            _id
            type {
              client
              participant
              researcher
            }
            meta {
              identity {
                firstname
                lastname
              }
            }
          }
        }
      }
      history {
        askable_live_recordings_expired
      }
      ParticipantSessions(status: [1], cancel: [0]) {
        completed
        cancel
        status
        session {
          end
        }
      }
    }
    pricing {
      booking_credits
      researcher_hours
      total_credits
      total_participants
    }
    rating {
      overall
      additional_information
    }
    admin {
      mission_critical
    }
  }
`;

import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { IndustryList } from 'containers/Studies/Recruit/data/IndustryList.query';

import type { IndustryAndOccupationType } from 'containers/Studies/Recruit/components/IndustryAndOccupation';

interface SummaryItemIndustriesProps {
  options: IndustryAndOccupationType[];
}

export const SummaryItemIndustries = ({ options }: SummaryItemIndustriesProps) => {
  const { t } = useTranslation();
  const [industryListQuery] = useQuery({ query: IndustryList });

  const getIndustryList = (options: IndustryAndOccupationType[]): string[] => {
    // Flatten the options to a list of industry - occupation names
    const optionsByIndustry = options
      .map(({ _id, name, subcategories }) => {
        if (!subcategories?.length) {
          return [{ _id, name, subcategory: null }];
        }
        return subcategories.map(subcategory => ({ _id, name, subcategory }));
      })
      .flat();
    return optionsByIndustry
      .map(item => {
        const industryItem = industryListQuery.data?.industryList?.find(industry => industry?._id === item._id);
        const occupation =
          industryItem?.subcategories?.find(option => option?._id === item.subcategory?._id)?.name ??
          t(`sections.studies.recruit.fields.occupationsPlaceholderEmpty`);
        if (!industryItem) {
          return '';
        }

        return `${industryItem?.name} - ${occupation}`;
      })
      .filter(Boolean);
  };

  return options.length > 0 && options[0]?.name !== '' ? (
    <SummaryItem
      label={t('sections.studies.recruit.fields.industryAndOccupationTitle')}
      type="industry_and_occupation"
      options={getIndustryList(options)}
      variant="stacked"
    />
  ) : null;
};

import { TriangleAlert } from 'lucide-react';
import { useEffect, useRef, useState, isValidElement } from 'react';
import ReactPlayer from 'react-player/file';

import { cn } from '../../lib/utils';
import { toast } from '../sonner';

import { ControlsBar } from './controls-bar';
import { labels as defaultLabels } from './labels';
import { PosterOverlay, VideoOverlay } from './overlay';

import type { ReactNode } from 'react';

export type Labels = typeof defaultLabels;

export interface Chapter {
  label: string;
  node_id?: string;
  timestamp: number;
}

export interface VideoPlayerProps {
  chapters?: Chapter[]; // Add timestamps to jump to
  isLoading?: boolean;
  isPip?: boolean;
  labels?: Labels;
  placeholder?: ReactNode | ReactNode[]; // Processing state overlay
  posterImage?: string; // Show image overlay until the user clicks
  preload?: 'auto' | 'none';
  seekTo?: number | null;
  url?: string;
  enableDownload?: boolean;
  downloadFileName?: string;
  onEnded?: () => void;
  onProgress?: (progress: { seconds: number; percent: number }) => void;
  onReady?: (player: ReactPlayer) => void;
}

const getPlaceholder = (placeholder: ReactNode | ReactNode[]) => {
  if (isValidElement(placeholder)) {
    return placeholder;
  }

  if (!Array.isArray(placeholder)) {
    return null;
  }

  if (placeholder[1]) {
    return placeholder[1];
  }

  return placeholder[0];
};

export const VideoPlayer = ({
  chapters,
  isLoading = false,
  isPip,
  labels = defaultLabels,
  placeholder,
  posterImage,
  preload = 'auto',
  seekTo,
  url,
  enableDownload,
  downloadFileName,
  onEnded,
  onProgress,
  onReady,
}: VideoPlayerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mouseTimeoutRef = useRef<NodeJS.Timeout>();
  const playerRef = useRef<ReactPlayer>(null);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMouseOverContainer, setIsMouseOverContainer] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  // User interaction with video player to animate a play/pause overlay
  const [isUserPlayed, setIsUserPlayed] = useState<boolean | null>(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [progress, setProgress] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [showPoster, setShowPoster] = useState(!!posterImage);
  const [volume, setVolume] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);

  const isControlsDisabled = isLoading || duration === 0 || loadingProgress === 0;
  const isControlsVisible = (isMouseOverContainer && showControls) || !isPlaying || isControlsDisabled;

  // Controls visibility of the controls bar
  const handleMouseMove = () => {
    if (showPoster) {
      return;
    }

    setShowControls(true);

    if (mouseTimeoutRef.current) {
      clearTimeout(mouseTimeoutRef.current);
    }

    if (isPlaying) {
      mouseTimeoutRef.current = setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }
  };

  const handleDownloadVideo = async () => {
    try {
      setIsDownloading(true);
      const response = await fetch(url!, { referrerPolicy: 'strict-origin-when-cross-origin' });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const extension = url?.split('.')?.at(-1);
      const fileName = downloadFileName ?? `download.${extension}`;

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(url!);
    } catch (err: unknown) {
      console.error(err);
      toast.error(labels.error.download);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (mouseTimeoutRef.current) {
        clearTimeout(mouseTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isPlaying) {
      setShowControls(true);
      if (mouseTimeoutRef.current) {
        clearTimeout(mouseTimeoutRef.current);
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    if (playerRef?.current && seekTo !== null && seekTo !== undefined) {
      playerRef.current.seekTo(seekTo + 0.001, 'seconds');
      setProgress(seekTo / duration);
    }
  }, [seekTo, playerRef, duration]);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.addEventListener('fullscreenchange', () => {
        if (typeof document !== 'undefined') {
          setIsFullscreen(!!document.fullscreenElement);
        }
      });
    }
  }, [containerRef]);

  const playVideo = () => {
    setShowPoster(false);
    setIsPlaying(!isPlaying);
    setIsUserPlayed(!isPlaying);
  };

  if (placeholder) {
    return (
      <div
        ref={containerRef}
        className="relative h-full w-full items-center justify-center overflow-hidden rounded-md bg-darkest p-4 text-lightest"
      >
        {getPlaceholder(placeholder)}
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={cn('h-full w-full bg-darkest/60', {
        'rounded-md': !isFullscreen,
      })}
    >
      <div
        className={cn('relative isolate h-full w-full overflow-hidden bg-darkest transition-[opacity,transform]', {
          'fixed bottom-2 left-2 z-20 aspect-[16/9] h-auto w-[320px] rounded-md': isPip && !isFullscreen,
          'rounded-md': !isFullscreen,
        })}
        onMouseEnter={() => setIsMouseOverContainer(true)}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setIsMouseOverContainer(false);
          setShowControls(false);

          if (mouseTimeoutRef.current) {
            clearTimeout(mouseTimeoutRef.current);
          }
        }}
      >
        {!error ? (
          <>
            {showPoster && posterImage ? (
              <PosterOverlay image={posterImage} label={labels.play} handleClick={playVideo} />
            ) : null}

            <div
              className={cn('absolute h-full w-full', {
                'pointer-events-none': showPoster,
              })}
            >
              <VideoOverlay
                isLoading={isLoading || isBuffering}
                isPlaying={isPlaying}
                isUserPlayed={isUserPlayed}
                label={labels.play}
                handleClick={playVideo}
              />

              <ReactPlayer
                ref={playerRef}
                url={url}
                controls={false}
                height="100%"
                playbackRate={playbackRate}
                playing={isPlaying}
                preload={preload}
                progressInterval={50}
                volume={volume}
                width="100%"
                onDuration={setDuration}
                onEnded={onEnded}
                onProgress={({ played, loaded, playedSeconds }) => {
                  setProgress(played);
                  setLoadingProgress(loaded);
                  onProgress?.({ seconds: playedSeconds, percent: played });
                }}
                onReady={player => onReady?.(player as ReactPlayer)}
                onBuffer={() => setIsBuffering(true)}
                onBufferEnd={() => setIsBuffering(false)}
                onError={setError}
              />

              <ControlsBar
                {...{
                  containerRef,
                  chapters,
                  downloadVideo: enableDownload ? handleDownloadVideo : undefined,
                  isDownloading,
                  duration,
                  isDisabled: isControlsDisabled,
                  isFullscreen,
                  isMobile: !!isPip,
                  isPlaying: isPlaying,
                  isVisible: isControlsVisible,
                  labels,
                  loadingProgress,
                  playbackRate,
                  playerRef,
                  playVideo,
                  progress,
                  setPlaybackRate,
                  setVolume,
                  volume,
                }}
              />
            </div>
          </>
        ) : (
          <div className="flex h-full w-full items-center justify-center gap-3">
            <TriangleAlert className="h-8 w-8 text-destructive" />
            <div className="flex flex-col">
              <div className="font-semibold">{labels.error.title}</div>
              {labels.error.description}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import type { RecruitFormFields } from 'containers/Studies/data/schemas/recruitSchema';
import type { Booking } from 'generated/graphql';

type PartialOrNull<T> = {
  [P in keyof T]?: T[P] extends Array<infer U> ? U[] : T[P] | null;
};

export function mapBookingToFormFields(study: Booking) {
  const mapped: PartialOrNull<RecruitFormFields> = {
    // Recruit
    participants: study?.recruit?.participants || 0,
    device: study?.recruit?.device,
    locations:
      study?.recruit?.locations?.map(l => ({
        type: l.type!,
        location: {
          city: l.location?.city ?? null,
          country: l.location?.country ?? null,
          state: l.location?.state ?? null,
          region: l.location?.region ?? null,
          formatted_address: l.location?.formatted_address ?? '',
          google_location: l.location?.google_location ?? null,
          latitude: l.location?.latitude ?? null,
          longitude: l.location?.longitude ?? null,
          postal_code: l.location?.postal_code ?? null,
          street1: l.location?.street1 ?? null,
        },
      })) || [],
    age_range: study?.recruit?.age_range || [18, 99],
    gender: Array.isArray(study?.recruit?.gender)
      ? study?.recruit?.gender?.length
        ? study?.recruit?.gender
        : ['any_gender']
      : [],
    participant_selection: study?.recruit?.participant_selection,
    participant_source: study?.recruit?.participant_source,
    participants_excluded_months: study?.recruit?.participants_excluded_months
      ? (study?.recruit?.participants_excluded_months.toString() as RecruitFormFields['participants_excluded_months'])
      : 'null',
    custom_terms_template_id: study?.recruit?.custom_terms?._template_id || null,
    custom_terms_recipients: study?.recruit?.custom_terms?.recipients?.join(', ') || '',
    screener_questions: (study?.recruit?.screener_questions || []).map(question => ({
      _id: question._id,
      title: question.title,
      description: question.description,
      type: question.type,
      is_multiple_selection: !!question.is_multiple_selection,
      options: (question.options || []).map(option => ({
        _id: option._id,
        label: option.label,
        is_qualified: !!option.is_qualified,
      })),
    })),

    industry_and_occupation:
      study?.recruit?.industry_and_occupation?.map(i => ({
        _id: i._id,
        name: i.name,
        subcategories: i.subcategories?.map(s => ({
          _id: s._id,
          name: s.name,
        })),
      })) || [],
    languages:
      study?.recruit?.languages?.map(i => ({
        locale: i.locale,
        fluency: i.fluency,
      })) || [],
    education_level: study?.recruit?.education_level || [],
    marital_status: study?.recruit?.marital_status || [],
    occupational_status: study?.recruit?.occupational_status || [],
    business_ownership: study?.recruit?.business_ownership || [],

    // Contact
    _primary_contact_id: study?.contact?._primary_contact_id,

    // Listing information
    listing_title: study?.listing_information?.title,
    listing_description: study?.listing_information?.description,

    // Form specific controls
    // TODO: these can _probably_ live in local state within the form fields?
    enable_advanced_filters:
      !!study?.recruit?.industry_and_occupation?.length ||
      !!study?.recruit?.languages?.length ||
      !!study?.recruit?.education_level?.length ||
      !!study?.recruit?.marital_status?.length ||
      !!study?.recruit?.occupational_status?.length ||
      !!study?.recruit?.business_ownership?.length,
    enable_custom_terms: !!study?.recruit?.custom_terms,
  };

  // HACK: this is intentionally bypassing the type check; we _do_ want to be able to have the
  // form in invalid states, we will show validation errors accordingly
  return mapped as RecruitFormFields;
}

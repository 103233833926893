/* eslint-disable max-lines */
import { Explore, Participant, Review } from '@askable/ui/unmod/icons';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import differenceInYears from 'date-fns/differenceInYears';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import { capitalize, objectify, title } from 'radash';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import {
  Box,
  Checkbox,
  ExternalLinkIcon,
  Flex,
  HeartFilledIcon,
  Input,
  Link,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
} from 'ui';
import { useDebounceCallback } from 'usehooks-ts';
import { shallow } from 'zustand/shallow';

import { Locale, ToggleShortlistSubmissionDocument } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import { BOOKING_STATUS } from 'lib/constants';
import countries from 'lib/data/phoneCountryCodes.json';
import { userUtils } from 'lib/user';
import { utils } from 'lib/utils';
import { urqlClient } from 'network/client';
import { BookingTypes, isAutomatedBooking, isModeratedBooking } from 'utils/booking-utils';

import { bookingParticipantSelectors, useBookingParticipantState } from '../../state/booking-participant-state';
import { formatEligibility, getBookingColumnConfig } from '../../utils/booking-participant-utils';
import { BookingParticipantMenu } from '../BookingParticipantMenu';
import { BookingParticipantRating } from '../BookingParticipantRating';
import { BookingParticipantSessionAction } from '../BookingParticipantSessionAction';
import { BookingParticipantStatus } from '../BookingParticipantStatus';
import { BookingParticipantUnmodPlaybackLink } from '../BookingParticipantUnmodPlaybackLink';
import { ParticipantTableActions } from '../ParticipantTableActions/ParticipantTableActions';

import { ApplicantTableHeaderRow } from './components/ApplicantTableHeaderRow';
import { ApplicantTableRow } from './components/ApplicantTableRow';
import { ApplicantTechCheck } from './components/ApplicantTechCheck';
import { BookingParticipantAllocatedSession } from './components/BookingParticipantAllocatedSession';
import { BookingParticipantVoiceSampleButton } from './components/BookingParticipantVoiceSampleButton';
import { QuestionAnswerCell, QuestionHeader } from './components/QuestionAnswerCell';
import { TableAgreement } from './components/TableAgreement';

import type { ParticipantAgreementStatus } from './components/TableAgreement';
import type { TableRowSubmission } from '../../hooks/useBookingParticipantsTableData';
import type { BookingParticipantStateAndActions } from '../../state/booking-participant-state';
import type { VisibilityState } from '@tanstack/react-table';
import type {
  BookingContainerBookingQuery,
  Data,
  FetchBookingByIdQuery,
  Maybe,
  ToggleShortlistSubmissionMutationResult,
  ToggleShortlistSubmissionMutationVariables,
} from 'generated/graphql';
import type { TFunction } from 'i18next';
import type React from 'react';
import type { FC, ReactElement } from 'react';

/**
 * We want to define these outside of react and not use the hooks for performance reasons.
 * For example, using the toggleShortlist from the useToggleShortlistSubmissionMutation will cause
 * the entire BookingParticipantsTableContainer to rerender if we pass it down from here, but if we use the hook
 * inside the ShortListComponent itself we could be potentially creating 50+ unnecessary instances of useToggleShortlistSubmissionMutation
 */
const participantActions = {
  toggleShortlist: (id: string, shortlisted: boolean) => {
    return urqlClient
      .mutation<ToggleShortlistSubmissionMutationResult, ToggleShortlistSubmissionMutationVariables>(
        ToggleShortlistSubmissionDocument,
        {
          id,
          shortlisted,
        },
      )
      .toPromise();
  },
};

const TODAY = new Date();

function includeColumnIf<T>(column: T | T[], i = false) {
  if (i) {
    return Array.isArray(column) ? column : [column];
  }

  return [];
}

type Props = {
  booking: BookingContainerBookingQuery['bookingByID'];
  isSessions: boolean;
  isLoading: boolean;
  pptActions: {
    setPanelState: BookingParticipantStateAndActions['setPanelState'];
    onMarkAsCompleteMenuItemClick: (submissionId: string) => void;
  };
  submissions?: TableRowSubmission[];
  pagination?: ReactElement;
  isCustomNDA?: boolean;
};

const columnHelper = createColumnHelper<TableRowSubmission>();

type GenerateColumnsProps = {
  isSessions: boolean;
  isCustomNDA: boolean;
  booking: FetchBookingByIdQuery['bookingByID'];
  pptActions: {
    setPanelState: BookingParticipantStateAndActions['setPanelState'];
    onMarkAsCompleteMenuItemClick: (submissionId: string) => void;
  };
  t: TFunction;
};

type ParticipantShortListActionProps = {
  id: string;
  shortlisted?: Maybe<boolean>;
};

const ParticipantShortListAction: FC<ParticipantShortListActionProps> = ({ shortlisted, id }) => {
  const [localShortlisted, setLocalShortlisted] = useState(shortlisted ?? false);
  const debouncedToggleShortlist = useDebounceCallback(participantActions.toggleShortlist, 550);

  const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.stopPropagation();

    try {
      setLocalShortlisted(currentShortlisted => {
        const newShortlisted = !currentShortlisted;
        debouncedToggleShortlist(id, newShortlisted);
        return newShortlisted;
      });
    } catch {
      /**
       * If the mutation fails we need to rollback the optimistic update
       */
      setLocalShortlisted(a => !a);
    }
  };

  useEffect(() => {
    if (shortlisted === localShortlisted) {
      return;
    }

    setLocalShortlisted(shortlisted ?? false);
  }, [shortlisted]);

  const handleLabelClick: React.MouseEventHandler<HTMLDivElement> & React.MouseEventHandler<HTMLLabelElement> = e => {
    e.stopPropagation();
  };

  return (
    <Box
      as="label"
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      onClick={handleLabelClick}
    >
      <Input
        type="checkbox"
        checked={localShortlisted}
        opacity="0"
        position="absolute"
        cursor="pointer"
        onChange={handleChange}
      />
      <HeartFilledIcon
        color={localShortlisted ? 'brand.500' : 'gray.200'}
        cursor="pointer"
        fontSize="18"
        _hover={{ color: localShortlisted ? 'brand.500' : 'gray.300' }}
        zIndex={1}
      />
    </Box>
  );
};

const generateColumns = ({ booking, pptActions, isSessions, isCustomNDA, t }: GenerateColumnsProps) => {
  const columnConfig = getBookingColumnConfig(booking);
  const isModerated = isModeratedBooking(booking?.type);
  const hasMultipleCountries = bookingUtils.allCountries(booking?.config?.criteria?.locations).length > 1;
  const isAutomated = isAutomatedBooking(booking?.config);
  const isUsingSessions = booking?.config?.remote?.askable_live || false;
  const keyed = objectify(booking?.config?.online_task?.links ?? [], a => a?._id!);

  return [
    columnHelper.accessor('_id', {
      id: '_id',
      size: 24,
      maxSize: 24,
      minSize: 24,
      cell: ({ row, getValue }) => {
        const id = getValue();

        return (
          <Flex>
            <Box
              cursor="pointer"
              onClick={e => {
                e.stopPropagation();
                row?.getToggleSelectedHandler()(e);
              }}
            >
              <Checkbox mr="4" size="lg" pointerEvents="none" isChecked={row?.getIsSelected()} />
            </Box>
            <ParticipantShortListAction shortlisted={row?.original?.shortlisted} id={id!} />
          </Flex>
        );
      },
      header: ({ table }) => (
        <Checkbox
          size="lg"
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
    }),
    columnHelper.accessor(row => row?.applicant?.firstname, {
      id: 'displayName',
      header: 'Participant name',
      size: 192,
      cell: info => {
        return (
          <Text noOfLines={1}>
            {info.getValue()} {info.row?.original?.applicant.lastname}
          </Text>
        );
      },
    }),
    columnHelper.accessor(row => row?.applicant.linkedin, {
      id: 'linked_in',
      header: 'LinkedIn',
      minSize: 28,
      maxSize: 28,
      cell: a => {
        const href = a.getValue();

        if (!href || !userUtils.isValidLinkedInURL(href)) {
          return null;
        }

        return (
          <Link href={href} colorScheme="linkedin" target="_blank" onClick={e => e.stopPropagation()}>
            <svg fill="none" viewBox="0 0 12 12" className="h-4 w-4">
              <path
                fill="currentColor"
                d="M10.223 10.226H8.447V7.441c0-.663-.013-1.518-.925-1.518-.927 0-1.069.723-1.069 1.47v2.833H4.676V4.5h1.707v.78h.022c.239-.45.819-.925 1.686-.925 1.8 0 2.133 1.185 2.133 2.728v3.143Zm-7.555-6.51a1.031 1.031 0 1 1 0-2.061 1.031 1.031 0 0 1 0 2.061Zm.891 6.51H1.777V4.5H3.56v5.726ZM11.114 0H.886A.875.875 0 0 0 0 .865v10.27c0 .479.396.865.885.865h10.226a.878.878 0 0 0 .889-.864V.864C12 .386 11.6 0 11.111 0h.002Z"
              />
            </svg>
          </Link>
        );
      },
    }),
    columnHelper.display({
      id: 'Actions',
      size: 16,
      minSize: 16,
      cell: ({ row }) => (
        <BookingParticipantMenu
          isAskableSessions={isSessions}
          isAutomated={isAutomated}
          isBookingComplete={booking?.status === BOOKING_STATUS.COMPLETED}
          isModerated={isModerated}
          sessionId={row?.original?.session?._id!}
          submissionId={row?.original?._id!}
          submissionStatus={row?.original?.status!}
          handleSetPanelState={pptActions.setPanelState}
          onMarkAsCompleteMenuItemClick={pptActions.onMarkAsCompleteMenuItemClick}
        />
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      minSize: 52,
      maxSize: 52,
      size: 52,
      cell: info => {
        const status = info.getValue();
        if (!status) {
          return null;
        }

        return (
          <BookingParticipantStatus
            paymentDue={info.row?.original?.transaction?.payment_due}
            submissionStatus={status}
          />
        );
      },
    }),
    columnHelper.accessor('rating', {
      id: 'rating',
      header: 'Rating',
      enableHiding: false,
      minSize: 32,
      maxSize: 32,
      size: 32,
      cell: ({ row }) => {
        const status = row.original?.status;

        if (!status) {
          return null;
        }

        return (
          <BookingParticipantRating
            firstName={row.original?.applicant.firstname || 'participant'}
            submissionId={row.original?._id!}
            status={status}
            rating={row.original?.rating}
          />
        );
      },
    }),
    ...includeColumnIf(
      columnHelper.accessor('status', {
        id: 'sessionActions',
        header: 'Session actions',
        size: 44,
        minSize: 44,
        maxSize: 44,
        cell: info => {
          return (
            <BookingParticipantSessionAction
              submissionStatus={info.getValue()}
              userId={info?.row?.original?.applicant?._id!}
              session={info?.row?.original?.session}
              bookingId={booking?._id!}
              teamId={booking?._team_id ?? undefined}
            />
          );
        },
      }),
      booking?.config?.remote?.askable_live ?? false,
    ),
    ...includeColumnIf(
      columnHelper.accessor('status', {
        id: 'unmodPlaybackLink',
        header: 'Session recording',
        size: 48,
        minSize: 48,
        maxSize: 48,
        cell: info => {
          return (
            <BookingParticipantUnmodPlaybackLink
              submissionStatus={info.getValue()}
              userId={info?.row?.original?.applicant?._id!}
              bookingId={booking?._id!}
              teamId={booking?._team_id ?? undefined}
            />
          );
        },
      }),
      bookingUtils.isAiModeration(booking),
    ),
    ...includeColumnIf(
      columnHelper.accessor('session', {
        id: 'allocated_session',
        header: 'Allocated session',
        maxSize: 80,
        cell: info => {
          const session = info.getValue();
          if (!session?.session?.start || !session?.session?.end) {
            return null;
          }

          const submission = info.row?.original;

          return (
            <BookingParticipantAllocatedSession
              sessionStart={session?.session?.start}
              sessionEnd={session?.session?.end}
              pptTimezone={submission?.applicant?.timezone || ''}
              displayName={submission?.applicant?.firstname || ''}
              isPptInDifferentTimezone={
                getTimezoneOffset(submission?.applicant?.timezone || '') !==
                getTimezoneOffset(utils.getCurrentTimezone())
              }
            />
          );
        },
      }),
      isModerated,
    ),
    columnHelper.accessor('eligibility', {
      id: 'eligibility',
      header: 'Eligible',
      maxSize: 28,
      minSize: 28,
      cell: info => formatEligibility(info.getValue()),
    }),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant.setup_check, {
        id: 'tech_check',
        header: 'Tech check',
        maxSize: 24,
        minSize: 24,
        cell: info => {
          const value = info.getValue();
          return <ApplicantTechCheck check={value} />;
        },
      }),
      isModerated && isUsingSessions,
    ),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.agreement?.status, {
        id: 'agreement',
        header: 'Legal & Privacy',
        maxSize: 36,
        minSize: 36,
        cell: info => {
          const value = info.getValue() as ParticipantAgreementStatus;

          if (!value) {
            return null;
          }

          return <TableAgreement status={value} />;
        },
      }),
      isCustomNDA,
    ),
    columnHelper.accessor(row => row?.created, {
      id: 'created_at',
      header: 'Applied',
      minSize: 40,
      maxSize: 40,
      cell: info => {
        return formatDistanceToNowStrict(info.getValue()!, { addSuffix: true });
      },
    }),
    columnHelper.accessor(row => row?.applicant?.gender, {
      id: 'gender',
      header: 'Gender',
      cell: info => capitalize(info.getValue() ?? ''),
    }),
    columnHelper.accessor(row => row?.applicant.age, {
      id: 'age',
      header: 'Age',
      maxSize: 24,
      minSize: 24,
      cell: info => {
        const birthday = info.getValue();

        if (!birthday) {
          return null;
        }

        return differenceInYears(TODAY, birthday);
      },
    }),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant.country, {
        id: 'country',
        header: 'Country',
        size: 64,
        maxSize: 64,
        minSize: 64,
        cell: ({ getValue }) => {
          const value = getValue();
          const country = countries.find(({ region }) => region === value)?.name ?? 'Unknown';
          return (
            <Tooltip label={value}>
              <Text noOfLines={1}>{country}</Text>
            </Tooltip>
          );
        },
      }),
      hasMultipleCountries,
    ),
    columnHelper.accessor(row => row?.applicant.city, {
      id: 'location',
      header: 'Location',
      size: 64,
      maxSize: 64,
      minSize: 64,
      cell: ({ getValue }) => {
        const value = getValue();

        return (
          <Tooltip label={value}>
            <Text noOfLines={1}>{value}</Text>
          </Tooltip>
        );
      },
    }),
    columnHelper.accessor(row => row?.applicant?.recording_url, {
      id: 'feedback_sample',
      header: 'Voice sample',
      maxSize: 28,
      minSize: 28,
      cell: info => {
        const recordingUrl = info.getValue();
        if (!recordingUrl) {
          return null;
        }

        return <BookingParticipantVoiceSampleButton recordingUrl={recordingUrl} />;
      },
    }),
    ...includeColumnIf(
      [
        columnHelper.accessor(row => row?.applicant?.industry?.name, {
          id: 'industry',
          header: 'Industry',
          size: 64,
          maxSize: 64,
          minSize: 64,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return null;
            }

            return (
              <Tooltip label={value} openDelay={200}>
                <Text noOfLines={1}>{title(value)}</Text>
              </Tooltip>
            );
          },
        }),
        columnHelper.accessor(row => row?.applicant?.sub_industry?.name, {
          id: 'role',
          header: 'Role',
          size: 64,
          maxSize: 64,
          minSize: 64,
          cell: info => {
            const value = info.getValue();

            if (!value) {
              return null;
            }

            return (
              <Tooltip label={value} openDelay={200}>
                <Text noOfLines={1}>{title(value)}</Text>
              </Tooltip>
            );
          },
        }),
        columnHelper.accessor(row => row?.applicant?.job_title, {
          id: 'job_title',
          header: 'Job title',
          size: 64,
          maxSize: 64,
          minSize: 64,
          cell: info => {
            const value = info.getValue();

            return <Text noOfLines={1}>{title(value)}</Text>;
          },
        }),
      ],
      columnConfig.industry,
    ),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant?.work_type, {
        id: 'work_type',
        header: 'Work type',
        cell: info => {
          const value = info.getValue();

          if (!value) {
            return null;
          }

          return <Text noOfLines={1}>{title(value.join(', '))}</Text>;
        },
      }),
      columnConfig.workStatus,
    ),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant?.employment_type, {
        id: 'employment_type',
        header: 'Employment type',
        cell: info => {
          return <Text noOfLines={1}>{info.getValue()}</Text>;
        },
      }),
      columnConfig.employmentType,
    ),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant?.family_status, {
        id: 'marital_status',
        header: 'Marital status',
        cell: info => {
          const value = info.getValue();

          if (!value) {
            return null;
          }

          return <Text noOfLines={1}>{title(value.join(', '))}</Text>;
        },
      }),
      columnConfig.familyStatus,
    ),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.applicant?.english_level, {
        id: 'english_level',
        header: 'English level',
        cell: info => {
          const value = info.getValue();

          if (!value) {
            return null;
          }

          return <Text noOfLines={1}>{title(value.join(', '))}</Text>;
        },
      }),
      columnConfig.englishLevel,
    ),
    ...includeColumnIf(
      columnHelper.accessor('locale', {
        id: 'locale',
        header: 'Language',
        size: 64,
        maxSize: 64,
        minSize: 64,
        cell: info => {
          const value = info.getValue();

          if (!value) {
            return null;
          }

          const locale = info.row?.original?.locale;
          const localeProficiency = info.row?.original?.applicant?.locale_proficiency;

          if (!locale || !localeProficiency) {
            return null;
          }

          const scopedLocale = match(locale)
            .when(
              locale => [Locale.EnUs, Locale.EnGb, Locale.EnAu].includes(locale),
              () => Locale.En,
            )
            .otherwise(locale => locale);

          return (
            <Text noOfLines={1}>
              {t(`global.locale.${scopedLocale}`)} ({t(`global.localeProficiency.${localeProficiency}`)})
            </Text>
          );
        },
      }),
      columnConfig.locale,
    ),
    ...(booking?.config?.question ?? []).map(question => {
      return columnHelper.accessor(() => question?._id, {
        id: question?.title!,
        header: () => <QuestionHeader title={question?.title} />,
        size: 64,
        minSize: 64,
        maxSize: 64,
        cell: info => {
          const answers = info.row?.original?.data?.filter(a => a?._question_id === question?._id);

          if (!answers?.length) {
            return null;
          }

          return (
            <QuestionAnswerCell
              answers={answers as Data[]}
              multiSelection={question?.config?.multiple_selection ?? false}
            />
          );
        },
      });
    }),
    ...includeColumnIf(
      columnHelper.accessor(row => row?.session?._online_task_link_id, {
        id: 'online_task',
        header: 'Task link',
        maxSize: 36,
        minSize: 36,
        size: 36,
        cell: info => {
          const taskId = info.getValue();

          if (!taskId) {
            return null;
          }

          const task = keyed[taskId];

          if (!task) {
            return null;
          }

          return (
            <Link
              noOfLines={1}
              href={task.url!}
              target="_blank"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {task.name} <ExternalLinkIcon mb="1" ml="1" />
            </Link>
          );
        },
      }),
      !isModerated && booking?.type !== BookingTypes.LONGITUDINAL_STUDY,
    ),
  ];
};

const PINNED_COLUMNS = { left: ['_id'] };

export const BookingParticipantsTable = memo(
  ({ submissions, pptActions, pagination, isCustomNDA, booking, isSessions, isLoading, ...props }: Props) => {
    const [rowSelection, onRowSelectionChange] = useBookingParticipantState(
      bookingParticipantSelectors.selectedRowState,
      shallow,
    );

    const { t } = useTranslation();

    const { order: columnOrder, visibility: columnVisibility } = useBookingParticipantState(
      bookingParticipantSelectors.columnConfig,
      shallow,
    );
    const isHeaderAds = localStorage.getItem('showingHeaderAds') === 'true';
    const onColumnVisibilityChange = useBookingParticipantState(bookingParticipantSelectors.updateColumnVisibility);
    const updateColumnOrder = useBookingParticipantState(bookingParticipantSelectors.updateColumnOrder);
    const updateSort = useBookingParticipantState(bookingParticipantSelectors.updateSort);
    const setSidePanel = useBookingParticipantState(bookingParticipantSelectors.setPanelState);
    const currentSegment = useBookingParticipantState(bookingParticipantSelectors.segmentId);

    const persistedColumnOrder = useBookingParticipantState(bookingParticipantSelectors.persistedColumnOrder);
    const persistedColumnVisibility = useBookingParticipantState(bookingParticipantSelectors.persistedColumnVisibility);
    const persistedSort = useBookingParticipantState(bookingParticipantSelectors.persistedSort);

    // Force update the state to what is stored so the save as segment button will be visible immediately if applicable
    // Run on mount and on segment change to catch all cases
    const restoreTableActions = () => {
      setTimeout(() => {
        if (currentSegment === 'all') {
          if (persistedColumnOrder?.length) {
            updateColumnOrder(persistedColumnOrder);
          }
          if (persistedColumnVisibility) {
            onColumnVisibilityChange(persistedColumnVisibility);
          }
          if (persistedSort?.length) {
            updateSort(persistedSort);
          }
        }
      }, 0);
    };

    useEffect(() => {
      restoreTableActions();
    }, []);

    useEffect(() => {
      restoreTableActions();
    }, [currentSegment]);

    const columns = useMemo(() => {
      return generateColumns({
        booking,
        pptActions,
        isSessions,
        isCustomNDA: isCustomNDA ?? false,
        t,
      });
    }, [booking]);

    const table = useReactTable({
      data: submissions ?? [],
      columns,
      state: {
        columnOrder,
        rowSelection,
        columnPinning: PINNED_COLUMNS,
        columnVisibility,
      },
      onColumnVisibilityChange: a => {
        /**
         * Every column order is not saved against the segment. It only happens if the order is changed or visibility is changed
         * We need to perform this update because if we update the visibility but not the order, it wont save the order
         */
        const updateValue = typeof a === 'function' ? a(columnVisibility) : a;

        const update =
          columnOrder.length >= 1
            ? updateValue
            : columns.reduce<VisibilityState>((acc, curr) => {
                acc[curr.id!] = updateValue[curr?.id!] ?? true;

                return acc;
              }, {});

        onColumnVisibilityChange(update);
      },
      getCoreRowModel: getCoreRowModel(),
      onRowSelectionChange,
    });

    const { rows } = table.getRowModel();

    const orderedColumns = useMemo(() => {
      return table
        .getAllColumns()
        .filter(column => column.getCanHide())
        .sort((a, b) => columnOrder.indexOf(a.id) - columnOrder.indexOf(b.id));
    }, [columnOrder, table]);

    const clearRowSelection = () => {
      onRowSelectionChange({});
    };

    return (
      <Box h="full" position="relative">
        <div className="flex flex-wrap items-center justify-between gap-2 px-4 py-4">
          <ParticipantTableActions
            booking={booking}
            clearRows={clearRowSelection}
            selectedRows={rowSelection}
            getSelectedRows={table.getSelectedRowModel}
            visibleColumns={columnVisibility}
            columns={orderedColumns}
          />
          {pagination && pagination}
        </div>

        {/**
         * TODO: h value is currently based on "container height - segment header height - number of pixels cut off at the bottom of page"
         * It is not ideal to have this value hardcoded. We need to find a better way to calculate this value. Potentially calculating static height of wrapper div and set to child div.
         * Linear ticket: https://linear.app/askable/issue/ASK-2265/table-style-improvement
         */}
        <TableContainer
          overflowY="auto"
          h={`calc(var(--mainContainerHeight) - 170px${isHeaderAds ? ' - var(--headerAdsHeight)' : ''})`}
        >
          <Table {...props} w="full" size="sm" minW="100vw">
            <Thead>
              {table.getHeaderGroups().map(headerGroup => {
                return <ApplicantTableHeaderRow group={headerGroup} key={headerGroup.id} />;
              })}
            </Thead>
            <Tbody boxShadow="sm">
              {isLoading ? (
                <TableLoadingState columns={columns} />
              ) : (
                rows.map(row => {
                  const isSelected = row?.getIsSelected();
                  return (
                    <ApplicantTableRow
                      isSelected={isSelected}
                      rowId={row.id}
                      getVisibleCells={row.getVisibleCells}
                      submissionId={row.original?._id!}
                      key={row?.id}
                      setSidePanel={setSidePanel}
                    />
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>
        {!rows.length && !isLoading ? <TableEmptyState status={booking?.status ?? 0} /> : null}
      </Box>
    );
  },
);

BookingParticipantsTable.displayName = 'BookingParticipantsTable';

// Amount of rows of skeletons we want to show
const TABLE_LOADING_STATE_ROWS = new Array(4).fill(null).map((_, index) => `index-${index}`);

/**
 * Used to generate random widths for the each skeleton loader
 */
function getColumnWidth(i: number): string {
  if (i === 0) {
    return '20px';
  }

  return i === 2 ? '32px' : `calc(75% - ${Math.random() * 3}em)`;
}

const TableLoadingState = memo(({ columns }: { columns: { id?: string }[] }) => {
  return (
    <>
      {TABLE_LOADING_STATE_ROWS.map(a => {
        return (
          <Tr key={a}>
            {columns.map((b, index) => {
              return (
                <Td borderBottom="0" key={b.id}>
                  <Skeleton h={index === 2 ? '32px' : '20px'} mr="4" w={getColumnWidth(index)} />
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </>
  );
});

TableLoadingState.displayName = 'TableLoadingState';

const TableEmptyState = memo(({ status }: { status: number }) => {
  const { t } = useTranslation();
  const filterCount = useBookingParticipantState(bookingParticipantSelectors.filterCount);

  return (
    <div className="absolute top-[5.5rem] flex h-full w-full flex-col items-center justify-center gap-4 text-center text-foreground">
      {filterCount ? (
        <>
          <Explore size={64} />
          <div className="max-w-64 text-pretty">{t('sections.studies.participants.noResults')}</div>
        </>
      ) : (
        <>
          {status === 3 ? <Review size={64} /> : <Participant size={64} />}
          <div className="max-w-64 text-pretty">
            {status === 3
              ? t('sections.studies.participants.inReviewNoParticipants')
              : t('sections.studies.participants.activeNoParticipants')}
          </div>
        </>
      )}
    </div>
  );
});

TableEmptyState.displayName = 'TableEmptyState';

import { Button } from '@askable/ui/core/button';
import { datadogRum } from '@datadog/browser-rum';
import { MessageCircleWarningIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { KNOWN_ERROR_TYPES_ARRAY } from 'lib/graphqlErrorHandler';

import { ErrorPage } from '../ErrorPage/ErrorPage';

import type { KNOWN_ERROR_TYPES } from 'lib/graphqlErrorHandler';

export const ErrorBoundaryGeneric = () => {
  const [params] = useSearchParams();
  const intercom = useIntercom();
  const { t } = useTranslation();
  const clientContext = useConnectedClient();

  const datadogContext = datadogRum.getInternalContext();

  const onContactSupport = () => {
    intercom.show();
  };

  const title = (() => {
    const error = params.get('error');

    if (KNOWN_ERROR_TYPES_ARRAY.some(e => e === error)) {
      return t(`sections.errorBoundary.${error as KNOWN_ERROR_TYPES}`);
    }

    return t('sections.errorBoundary.default');
  })();

  return (
    <ErrorPage className="text md:max-w-[540px]">
      <MessageCircleWarningIcon className="h-8 w-8" />
      <h2 className="text-lg font-medium text-foreground">{title}</h2>
      <p>
        {t('sections.errorBoundary.teamNotified')}{' '}
        <Button variant="link" className="p-0" onClick={onContactSupport}>
          {t('sections.errorBoundary.contactSupport')}
        </Button>
      </p>

      {/*
       * TODO: we should see what other information would be useful here, e.g. SSO connection ID (I could not find that one
       * exposed anywhere tho), etc
       */}
      <div className="flex flex-col gap-1 pt-2 text-sm text-muted-foreground">
        {clientContext?.details?.kinde_user_id && (
          <span>
            {t('sections.errorBoundary.userMeta.id', {
              kindeUserId: clientContext?.details?.kinde_user_id,
            })}
          </span>
        )}
        {datadogContext?.session_id && (
          <span>
            {t('sections.errorBoundary.userMeta.session', {
              sessionId: datadogContext?.session_id,
            })}
          </span>
        )}
      </div>
    </ErrorPage>
  );
};

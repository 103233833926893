import { toast } from '@askable/ui/core/sonner';
import { useState } from 'react';
import { graphql } from 'src/graphql';
import { Button, Flex, Heading, HStack, Image, Spacer, Stack, Text, VStack, Tag, TagLabel } from 'ui';
import { useMutation } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { ResearcherCertificationStatus } from 'generated/graphql';
import { handleError } from 'utils/error_utils';

import { ResearcherAdditionalDetailsContainer } from '../ResearcherAdditionalDetails';

import { ResearcherCertificationPending } from './ResearcherCertificationPending';

import type { FC, PropsWithChildren } from 'react';

const ResearcherBeginCertProcessMutation = graphql(`
  mutation ResearcherBeginCertProcess {
    researcherBeginCertification {
      redirect_url
    }
  }
`);

// Lets use a real person for this so its dynamic
const CertificationUserCard: FC<PropsWithChildren<any>> = () => {
  return (
    <VStack
      rounded="lg"
      p="4"
      justifyContent="flex-start"
      alignItems="flex-start"
      border="1px"
      borderColor="gray.200"
      spacing={4}
      maxW={['100%', '330']}
    >
      <Image height="290" src="/researcher-certification-displaypic.png" rounded="lg" w="full" objectFit="cover" />

      <Spacer />
      <Heading size="md">Stefano Portaluri</Heading>

      <Tag bg="blue.700" borderRadius="full" size="lg" color="white">
        <Image src="/askable-plus-logo.svg" mr={1} />
        <TagLabel>11 Askable+ projects</TagLabel>
      </Tag>

      <Text>
        “Askable+ allows me to network with some of the biggest design teams in the world and also develop a great
        second income in my spare time”
      </Text>
    </VStack>
  );
};

export const ResearcherCertificationContainer: FC = () => {
  // Get the current status of the certification

  const [isLoading, setIsLoading] = useState(false);
  const { details } = useConnectedClient();

  const [, beginCertProcess] = useMutation(ResearcherBeginCertProcessMutation);

  const handleGetCertifiedPress = async () => {
    try {
      setIsLoading(true);
      const result = await beginCertProcess({});

      // Unlikely to happen
      if (!result.data?.researcherBeginCertification?.redirect_url) {
        throw new Error();
      }

      if (result.error) {
        throw result.error;
      }

      window.location.replace(result.data.researcherBeginCertification.redirect_url);
    } catch (e) {
      setIsLoading(false);
      toast.error(`Oh no. ${handleError(e)}`);
    }
  };

  if (!details?.contact?.phone?.mobile) {
    return <ResearcherAdditionalDetailsContainer />;
  }

  if (
    // If the status matches any of these values, return the pending screen
    [
      ResearcherCertificationStatus.OnHold,
      ResearcherCertificationStatus.Certified,
      ResearcherCertificationStatus.InReview,
      ResearcherCertificationStatus.Ineligible,
    ].includes(details?.researcher?.certification?.status!)
  ) {
    return (
      <ResearcherCertificationPending
        status={details?.researcher?.certification?.status}
        firstname={details?.name?.firstname}
      />
    );
  }

  const steps = [
    'Get your Askable+ Researcher certification',
    'Pick your projects, on your schedule',
    'Get paid super fast',
  ];

  return (
    <Stack pb={10} spacing={[10, 36]} direction={['column', 'row']} alignItems="center">
      <VStack alignItems="flex-start" spacing={8}>
        <Heading>Hey, {details.name?.firstname}! Keen to make UX life the best life?</Heading>
        <VStack alignItems="flex-start" spacing={6}>
          {steps.map((step, index) => {
            return (
              <HStack justifyContent="center" alignItems="center" key={step} spacing="4">
                <Flex
                  bg="blue.700"
                  color="white"
                  rounded="full"
                  justifyContent="center"
                  alignItems="center"
                  w="8"
                  h="8"
                >
                  {index + 1}
                </Flex>
                <Text>{step}</Text>
              </HStack>
            );
          })}
        </VStack>

        <Stack justifyContent="center" alignItems="center" spacing={3} w="full">
          <Button isLoading={isLoading} w="full" onClick={handleGetCertifiedPress} colorScheme="brand">
            {details?.researcher?.certification?.status === ResearcherCertificationStatus.NotCertified
              ? 'Get certified'
              : 'Continue certification'}
          </Button>
          <Text fontSize="md" color="gray.600">
            {(() => {
              switch (details?.researcher?.certification?.status) {
                case ResearcherCertificationStatus.InProgress:
                case ResearcherCertificationStatus.InProgressDropped:
                  return 'Feel free to skip any questions you answered in previous submissions';
                default:
                  return 'You will be taken to an external site for a 30-minute unmoderated interview';
              }
            })()}
          </Text>
        </Stack>
      </VStack>

      <CertificationUserCard />
    </Stack>
  );
};

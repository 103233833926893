import { Button } from '@askable/ui/core/button';
import { FormControl, FormItem } from '@askable/ui/core/form';
import { MultiSelect } from '@askable/ui/core/multi-select';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@askable/ui/core/select';
import { Trash2 } from 'lucide-react';
import { memo } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { LANGUAGE_FLUENCY_OPTIONS } from 'containers/Studies/data/schemas/recruitSchema';

import type { Locale } from 'generated/graphql';

interface LanguagesItemProps {
  index: number;
  items: Locale[];
  isDisabled?: boolean;
  selectedLanguages: (string | undefined)[];
  onRemove: (itemIndex: number) => void;
}

const LanguagesItemComponent = ({ index, items, isDisabled, selectedLanguages, onRemove }: LanguagesItemProps) => {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({ name: `languages.${index}` });

  const availableLanguages = items
    .filter(item => !selectedLanguages.includes(item) || item === field.value?.locale)
    .map(item => ({
      label: t(`global.locale.${item}`),
      value: item,
    }));

  const fluency = LANGUAGE_FLUENCY_OPTIONS.map(option => ({
    label: t(`global.localeProficiency.${option}`),
    value: option,
  }));

  const handleChangeLanguage = (value: string) => {
    if (value !== field.value?.locale) {
      field.onChange({
        ...field.value,
        locale: value,
        fluency: [],
      });
    }
  };

  const handleChangeFluency = (values: string[]) => {
    field.onChange({
      ...field.value,
      fluency: values,
    });
  };

  return (
    <FormItem className="flex flex-col gap-2 opacity-100 transition-opacity starting:opacity-0 sm:flex-row">
      <div
        className="flex w-full flex-col gap-2 opacity-100 transition-opacity starting:opacity-0 sm:flex-row"
        data-testid={field.value.locale}
      >
        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_occupations_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.industryTitle')}
            </label>
            <Select value={field.value.locale} onValueChange={handleChangeLanguage} disabled={isDisabled}>
              <SelectTrigger>
                <SelectValue placeholder={t('sections.studies.recruit.fields.languagesPlaceholder')} />
              </SelectTrigger>
              <SelectContent className="z-20">
                {availableLanguages.map(option => (
                  <SelectItem key={option.value} value={option.value ?? ''}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </FormControl>

        <FormControl>
          <div className="flex-1">
            <label htmlFor={`input_fluency_${index}`} className="sr-only">
              {t('sections.studies.recruit.fields.languagesFluencyTitle')}
            </label>
            <MultiSelect
              disabled={isDisabled || !field.value.locale}
              id={`input_fluency_${index}`}
              options={fluency}
              values={field.value.fluency}
              onChange={handleChangeFluency}
            />
          </div>
        </FormControl>

        <Button
          disabled={isDisabled}
          size="icon"
          title={t('global.remove')}
          variant="ghost"
          onClick={() => onRemove(index)}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>

      {error ? <FormErrorMessage message={error.message} prefix="sections.studies.formValidation" /> : null}
    </FormItem>
  );
};

LanguagesItemComponent.displayName = 'LanguagesItem';

export const LanguagesItem = memo(LanguagesItemComponent);

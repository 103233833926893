import { clsx } from 'clsx';
import { Children, isValidElement } from 'react';
import { twMerge } from 'tailwind-merge';

import type config from '../tailwind.config.js';
import type { ClassValue } from 'clsx';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const MOBILE_BREAKPOINT = 640;

// Convert Tailwind color to HSL value
type Colors = typeof config.theme extends { colors: infer C } ? C : never;
type ColorKeys<T> = T extends object
  ? keyof T extends string
    ? keyof T | `${keyof T}-${ColorKeys<T[keyof T]>}`
    : never
  : never;

export type ColorValue = ColorKeys<Colors>;

export const getColorValue = (color: string): string => {
  if (color.includes('-')) {
    const [base, variant] = color.split('-');
    return `hsl(var(--${base}-${variant}))`;
  }

  return `hsl(var(--${color}))`;
};

// Check if the item should be a mobile view based on width
// Shared between Clients and Sessions
export const isMobileByWidth = (width?: number | null): boolean => {
  return width ? width <= MOBILE_BREAKPOINT : false;
};

// Format seconds to 00:00 eg. 350 -> 05:50
// If format is 'labels', return 5m 50s
export const secondsToTime = (
  value: number,
  format?: 'default' | 'labels',
  precision: 'second' | 'decisecond' = 'second',
): string => {
  const secondsValue = precision === 'decisecond' ? Math.round(value * 10) / 10 : Math.round(value);

  const minutes = Math.floor(secondsValue / 60);
  const remainingSeconds = secondsValue % 60;

  // Format the seconds string based on precision
  const secondsString =
    precision === 'decisecond' ? remainingSeconds.toFixed(1).replace(/\.0$/, '') : remainingSeconds.toString();

  // Format the final output
  if (format === 'labels') {
    return minutes > 0 ? `${minutes}m ${secondsString.padStart(2, '0')}s` : `${secondsString}s`;
  }

  return `${minutes}:${secondsString.padStart(2, '0')}`;
};

export const getValidChildren = (children: React.ReactNode): React.ReactElement[] => {
  return Children.toArray(children).filter(child => isValidElement(child)) as React.ReactElement[];
};

import { TableColumnHeader } from '@askable/ui/core/table-column-header';

import type { Column } from '@tanstack/react-table';
import type React from 'react';

interface Props<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  /**
   * If the table is empty, disable sorting.
   */
  disableSortByPageCount?: number;
}

export function ColumnHeader<TData, TValue>({
  column,
  title,
  className,
  disableSortByPageCount,
}: Props<TData, TValue>) {
  return (
    <TableColumnHeader
      title={title}
      className={className}
      sortConfig={
        column.getCanSort()
          ? {
              isSorted: column.getIsSorted(),
              disableSortByPageCount,
              onAscClick: () => column.toggleSorting(false),
              onDescClick: () => column.toggleSorting(true),
            }
          : undefined
      }
    />
  );
}

import pluralizeLib from 'pluralize';

pluralizeLib.singular('peoples');
pluralizeLib.addSingularRule(/peoples$/i, 'person');

type PadConfig = {
  seconds?: number;
  minutes?: number;
  hours?: number;
};

function padNumberStart(num: number, padLength = 2) {
  return num.toString().padStart(padLength, '0');
}

export function pluralize(word: string, count?: number, inclusive?: boolean) {
  return pluralizeLib(word, count, inclusive);
}

export function formatMsToTime(ms: number, padCfg?: PadConfig) {
  let seconds = Math.floor(ms / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  // If you don't want to roll hours over, e.g. 24 to 00
  // comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  // hours %= 24;

  return `${hours > 0 ? `${padNumberStart(hours, padCfg?.hours)}:` : ''}${padNumberStart(minutes, padCfg?.minutes)}:${padNumberStart(seconds, padCfg?.seconds)}`;
}

export function formatSToTime(s: number, padCfg?: PadConfig) {
  return formatMsToTime(Math.floor(s * 1000), padCfg);
}

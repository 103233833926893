import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Explore = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <g stroke={bgHsl}>
        <path
          fill={bgHsl}
          strokeWidth="3.4"
          d="M43.3 20.6C38.1 14.2 37.5 9.3 31 9.3c-3.2 0-5.9-3.7-13.7 1.4C7.4 17.1.2 31.9 3.4 39.4A27 27 0 0 0 27.7 56C41.6 56 51.4 41.1 53 34.8c1.6-6.4-3.1-6.2-9.7-14.2Z"
        />
        <path
          fill={fgHsl}
          strokeWidth="2.3"
          d="M45 16.2C40.7 10.8 36 8 30.7 8c-2.7 0-7-.3-13.4 4-8 5.3-7 16.2-5.6 21.5 1.3 5.3 10.6 13.3 20 12 9.3-1.3 16-8 17.3-13.3 1.3-5.4 1.3-9.4-4-16Z"
        />
        <path
          fill={bgHsl}
          strokeLinecap="round"
          strokeWidth="3.4"
          d="M61.3 58.1c0 .8-1.4 3.2-2.9 3.2-1.4 0-13-16-13-16h2.3s13.6 12 13.6 12.8Z"
        />
        <path
          fill={fgHsl}
          strokeWidth="3.4"
          d="M14 12.1a24.4 24.4 0 0 1 16.7-7.7c5.4 0 10.8 2.5 15.5 7.6a27 27 0 0 1 8 16.2c.3 5.1-1.8 10.1-7.7 14.3-6 4.3-11.8 6.4-17 6.5-5 0-10-2-14.4-6.4A23.8 23.8 0 0 1 7 28c-.4-5.1 1.4-10.6 6.9-15.8Z"
        />
      </g>
    </svg>
  );
};

import { Avatar, AvatarImage, AvatarFallback } from '@askable/ui/core/avatar';
import { TableCell, TableRow } from '@askable/ui/core/table';
import { cn } from '@askable/ui/lib/utils';
import { format, formatISO } from 'date-fns';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { BookingTaskResultPartial } from 'containers/Studies/Results/data/BookingTaskResults.query';

interface MultipleChoiceQuestionResponseTableRowProps {
  result: BookingTaskResultPartial;
}

export const MultipleChoiceQuestionResponseTableRow = forwardRef<
  HTMLTableRowElement,
  MultipleChoiceQuestionResponseTableRowProps
>(({ result }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { study } = useStudyContext();

  const name = result.user?.displayName ?? result.user?._id;
  const initials =
    result.user?.displayName
      ?.split(' ')
      .map(n => n[0])
      .join('') ?? 'U';

  const isClickable = result.status === 'completed';
  const answers = 'answers' in result.details ? result.details?.answers : null;
  const otherAnswer = 'other_answer' in result.details ? result.details?.other_answer : null;

  return (
    <TableRow
      ref={ref}
      onClick={
        isClickable ? () => navigate(`/studies/${study._id}/results/participant/${result.user?._id}`) : undefined
      }
      key={result._id}
      className={cn('h-auto', { 'cursor-pointer hover:bg-secondary': isClickable })}
      id={`result-${result._id}`}
    >
      <TableCell>
        <div className="flex min-w-40 max-w-48 flex-row items-center gap-2" title={name}>
          <Avatar size="sm">
            <AvatarImage src={result.user?.picture || undefined} alt={result.user?.displayName || undefined} />
            <AvatarFallback variant="primary">{initials}</AvatarFallback>
          </Avatar>
          <span className="truncate">{name}</span>
        </div>
      </TableCell>
      <TableCell>
        <ul>
          {answers?.map(a => <li key={a._id}>{a.value}</li>)}
          {otherAnswer ? (
            <li>
              {t('components.multipleChoiceQuestion.other')}: {otherAnswer}
            </li>
          ) : null}
        </ul>
      </TableCell>
      <TableCell>
        {result.task_ended ? (
          <time dateTime={formatISO(result.task_ended)} className="whitespace-nowrap">
            {format(result.task_ended, 'd MMM yyyy, h:mmaaa')}
          </time>
        ) : (
          <>-</>
        )}
      </TableCell>
    </TableRow>
  );
});

MultipleChoiceQuestionResponseTableRow.displayName = 'MultipleChoiceQuestionResponseTableRow';

import { AnchorButton } from '@askable/ui/core/button';
import { Skeleton } from '@askable/ui/core/skeleton';
import { X, CircleCheck, CircleX } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { BookingSubmissionByUser } from 'containers/Studies/Results/data/BookingSubmissionByUser.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type {
  BookingSubmissionByUserProps,
  ScreenerQuestionAnswerPartial,
} from 'containers/Studies/Results/data/BookingSubmissionByUser.query';
import type { Booking } from 'generated/graphql';

/**
 * Sidebar panel for displaying additional participant information
 */

// Screener question answers are a flat array so need to group them by question ID
const groupScreenerAnswersByQuestion = (answers: BookingSubmissionByUserProps['data'], study: Booking) => {
  const screenerQuestions = study.recruit?.screener_questions;

  if (!screenerQuestions || !screenerQuestions.length) {
    return [];
  }

  return screenerQuestions.map(question => {
    return {
      _id: question._id,
      title: question.title,
      answers: answers?.reduce<ScreenerQuestionAnswerPartial[]>((acc, answer) => {
        if (answer?._question_id === question._id) {
          acc.push(answer);
        }
        return acc;
      }, []),
    };
  });
};

export const ParticipantDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { study } = useStudyContext();
  const { studyId, participantId } = params;

  const [{ data, fetching: isLoading }] = useQuery({
    query: BookingSubmissionByUser,
    variables: {
      _booking_id: studyId ?? '',
      _user_id: participantId ?? '',
    },
    pause: !studyId || !participantId,
    requestPolicy: 'cache-first',
  });

  const screenerQuestionsGrouped = groupScreenerAnswersByQuestion(data?.bookingSubmissionByUser?.data ?? [], study);

  // @todo need to request this data when a user starts an unmod study
  // const studyDetails = {
  //   duration: 0, // Get from first task start time and last task end time?
  //   startTime: 0, // First task start time?
  //   device: 'Unknown',
  //   os: 'Unknown', // Can get from user.devices.os?
  //   browser: 'Unknown',
  // };

  // Close the details panel if no participant is selected
  useEffect(() => {
    if (!participantId) {
      window.location.hash = '#main';
    }
  }, [participantId]);

  return (
    <aside className="results-details overflow-hidden" id="participant-demographic-info">
      <div className="flex h-full min-w-[22rem] flex-col gap-6 overflow-auto rounded-xl bg-background p-3 lg:p-6">
        <header className="flex items-center justify-between gap-3">
          <h3 className="text-xl font-semibold">
            {t('sections.studies.results.participantDetails.additionalParticipantInfo')}
          </h3>
          <AnchorButton variant="ghost" size="icon" href="#main">
            <X className="h-4 w-4" />
          </AnchorButton>
        </header>

        {!isLoading && data?.bookingSubmissionByUser ? (
          <>
            <section className="flex flex-col gap-4" id="participant-screener-questions">
              <header>
                <h3 className="text-lg font-semibold">
                  {t('sections.studies.results.participantDetails.screenerQuestions')}
                </h3>
              </header>

              <ul className="flex flex-col gap-4 text-sm">
                {screenerQuestionsGrouped.length > 0 ? (
                  screenerQuestionsGrouped.map(question => (
                    <li className="flex flex-col gap-2" key={question?._id}>
                      <h4 className="font-semibold">{question?.title}</h4>

                      <ul className="flex flex-col gap-1">
                        {question.answers?.map(answer => (
                          <li className="flex gap-2" key={answer._answer_id}>
                            {answer?.option ? (
                              <>
                                {answer?.option?.screen_in ? (
                                  <CircleCheck className="relative top-[1px] h-4 w-4 text-success" />
                                ) : (
                                  <CircleX className="relative top-[1px] h-4 w-4 text-destructive" />
                                )}
                                {answer?.option?.label}
                              </>
                            ) : (
                              answer.value
                            )}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))
                ) : (
                  <div className="text-sm text-muted-foreground">
                    {t('sections.studies.results.noScreenerQuestions')}
                  </div>
                )}
              </ul>
            </section>

            {/* <section className="flex flex-col gap-4" id="participant-study-details">
          <header>
            <h3 className="text-lg font-semibold">{t('sections.studies.results.participantDetails.general')}</h3>
          </header>

          <ul className="flex flex-col gap-2 text-sm">
            {(Object.keys(studyDetails) as Array<keyof typeof studyDetails>).map(key => (
              <li className="grid grid-cols-2" key={key}>
                <h4 className="font-semibold">{t(`sections.studies.results.participantDetails.${key}`)}</h4>
                <div className="text-right">{studyDetails[key]}</div>
              </li>
            ))}
          </ul>
        </section> */}
          </>
        ) : (
          <>
            <Skeleton className="h-12 w-full" />
            <Skeleton className="h-12 w-3/5" />
          </>
        )}
      </div>
    </aside>
  );
};

import { secondsToTime } from '@askable/ui/lib/utils';
import { useTranslation } from 'react-i18next';

import { formatPercentage } from 'containers/Studies/utils/utils';

interface HeatmapDetailsProps {
  clickCount: number;
  duration?: number;
  misclickRate?: number;
}

export const HeatmapDetails = ({ clickCount, duration = 0, misclickRate = 0 }: HeatmapDetailsProps) => {
  const { t } = useTranslation();

  return (
    <aside className="flex flex-col gap-6 p-4 sm:w-32 lg:w-60">
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-semibold">{t('sections.studies.results.screen.metrics')}</h3>

        <ul className="flex justify-between gap-2 sm:flex-col">
          <li>
            <div className="text-sm">{t('sections.studies.results.clicks', { count: 2 })}</div>
            <div className="text-lg font-semibold">{clickCount}</div>
          </li>
          <li>
            <div className="text-sm">{t('sections.studies.results.misclickRate')}</div>
            <div className="text-lg font-semibold">{formatPercentage(misclickRate)}</div>
          </li>
          <li>
            <div className="text-sm">{t('sections.studies.results.duration')}</div>
            <div className="text-lg font-semibold">{secondsToTime(duration / 1000, 'labels', 'decisecond')}</div>
          </li>
        </ul>
      </div>
    </aside>
  );
};

import { FormControl, FormItem, FormLabel } from '@askable/ui/core/form';
import { Switch } from '@askable/ui/core/switch';
import { cn } from '@askable/ui/lib/utils';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { InfoPopover } from 'containers/Studies/components/InfoPopover';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface SwitchFieldProps {
  description?: string;
  infoPopover?: InfoPopoverProps;
  isDisabled?: boolean;
  isFocused?: boolean;
  label: string;
  name: string;
  size?: 'default' | 'lg';
}

const SwitchFieldComponent = ({ description, infoPopover, isDisabled, label, name, size }: SwitchFieldProps) => {
  const { field } = useController({ name });

  return (
    <FormItem>
      <div className="relative flex flex-col justify-between gap-2 md:flex-row md:items-center">
        <FormLabel className={cn('flex w-fit items-center gap-1', { 'text-base leading-tight': size === 'lg' })}>
          {label} {infoPopover ? <InfoPopover {...infoPopover} /> : null}
        </FormLabel>

        <FormControl>
          <Switch
            {...field}
            aria-describedby={description ? `description_${name}` : undefined}
            checked={!!field.value}
            disabled={isDisabled}
            onCheckedChange={field.onChange}
            size="small"
          />
        </FormControl>
      </div>

      {description ? (
        <div id={`${name}_description`} className="max-w-[60ch] text-pretty text-xs text-muted-foreground md:-mt-2">
          {description}
        </div>
      ) : null}
    </FormItem>
  );
};

SwitchFieldComponent.displayName = 'SwitchField';

export const SwitchField = memo(SwitchFieldComponent);

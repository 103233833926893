import { Button } from '@askable/ui/core/button';
import { cn } from '@askable/ui/lib/utils';
import { AudioLines, Pause } from 'lucide-react';
import { useRef, useState } from 'react';

import type React from 'react';

type Props = {
  recordingUrl: string;
};

export const BookingParticipantVoiceSampleButton = ({ recordingUrl }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const soundRef = useRef<HTMLAudioElement | null>(null);

  const handleMouseOver = () => {
    if (soundRef.current !== null) {
      return;
    }

    soundRef.current = new Audio(recordingUrl);
  };

  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();

    if (isPlaying) {
      soundRef.current?.pause();
      setIsPlaying(false);
      return;
    }

    setIsPlaying(true);

    soundRef?.current?.play();
    soundRef!.current!.onended = () => {
      setIsPlaying(false);
    };
  };

  return (
    <Button
      aria-label="Play voice sample"
      size="sm"
      onMouseEnter={handleMouseOver}
      onClick={handleClick}
      className={cn('aspect-square !rounded-full p-4', {
        'bg-primary text-primary-foreground hover:bg-primary/90': isPlaying,
      })}
    >
      {isPlaying ? <Pause className="h-4 w-4" /> : <AudioLines className="h-4 w-4" />}
    </Button>
  );
};

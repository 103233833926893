'use client';

import { useTheme } from 'next-themes';
import { Toaster as Sonner, toast } from 'sonner';

import type React from 'react';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      visibleToasts={5}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast:
            'toast group flex w-[360px] items-center gap-2 rounded-lg border border-border p-3 transition group-[.toaster]:bg-popover group-[.toaster]:text-popover-foreground group-[.toaster]:drop-shadow-lg group-[.toaster]:data-[type=error]:border-destructive group-[.toaster]:data-[type=error]:bg-destructive group-[.toaster]:data-[type=error]:text-destructive-foreground group-[.toaster]:[&>[data-icon]]:-ml-3 group-[.toaster]:[&>[data-icon]]:w-0 group-[.toaster]:[&>button]:data-[type=error]:border-destructive-foreground group-[.toaster]:[&>button:hover]:data-[type=error]:border-destructive-foreground group-[.toaster]:[&>button:hover]:data-[type=error]:bg-white/20 group-[.toaster]:[&>button]:data-[type=error]:text-destructive-foreground group-[.toaster]:data-[type=warning]:bg-primary group-[.toaster]:data-[type=warning]:text-primary-foreground group-[.toaster]:data-[type=warning]:border-ring',
          title: 'text-sm',
          description: 'text-xs',
          actionButton:
            'group-[.toaster]:active:scale-97 rounded group-[.toaster]:border group-[.toaster]:border-solid group-[.toaster]:bg-secondary group-[.toaster]:p-3 group-[.toaster]:text-sm group-[.toaster]:border-input group-[.toaster]:text-foreground',
          cancelButton:
            'group-[.toaster]:active:scale-97 rounded group-[.toaster]:border group-[.toaster]:border-solid group-[.toaster]:bg-transparent group-[.toaster]:p-3 group-[.toaster]:text-sm group-[.toaster]:border-input group-[.toaster]:text-inherit',
        },
      }}
      icons={{
        error: <span />,
        info: <div />,
        success: <div />,
        warning: <div />,
      }}
      {...props}
    />
  );
};

export { Toaster, toast };

import { Skeleton } from '@askable/ui/core/skeleton';
import { useMemo } from 'react';

import { Stat } from 'containers/Studies/Results/components/Stat';

import type { StatProps } from 'containers/Studies/Results/components/Stat';
import type { FigmaPrototypeResultStats as FigmaPrototypeResultStatsType } from 'generated/graphql';

interface StatsProps {
  isLoading: boolean;
  statsData: FigmaPrototypeResultStatsType | null;
}

/**
 * A list of stats for the Figma prototype task
 */
export const Stats = ({ isLoading, statsData }: StatsProps) => {
  const stats: StatProps[] = useMemo(() => {
    return statsData
      ? [
          {
            type: 'reachedGoal',
            value: statsData.total_responses ? statsData.completed_count / statsData.total_responses : 0,
            count: statsData.completed_count ?? 0,
          },
          {
            type: 'reachedGoalFailed',
            value: statsData.total_responses ? statsData.skipped_count / statsData.total_responses : 0,
            count: statsData.skipped_count,
          },
          {
            type: 'misclickRate',
            value: statsData.misclick_rate,
          },
          {
            type: 'averageDuration',
            value: statsData.average_duration,
          },
        ]
      : [];
  }, [statsData]);

  return (
    <section id="stats" className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
      {!isLoading && statsData ? (
        stats.map(stat => <Stat key={stat.type} {...stat} variant="block" />)
      ) : (
        <>
          <Skeleton className="h-[5.375rem] flex-1" />
          <Skeleton className="h-[5.375rem] flex-1" />
          <Skeleton className="h-[5.375rem] flex-1" />
          <Skeleton className="h-[5.375rem] flex-1" />
        </>
      )}
    </section>
  );
};

import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { cn } from '@askable/ui/lib/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { DataTestId } from 'shared-utils';
import { AlertWarningIcon } from 'ui';

import { useIsKinde } from 'containers/Auth/AuthWrapper';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { globalVariables } from 'lib/globalVariables';
import { localStorage } from 'lib/storage';

import { useAppContext } from '../Askable/Providers/appProvider';

import type { FC } from 'react';

type Props = {
  onSignOut: () => void;
  hideSignIntoAnotherTeam: boolean;
};

export const MenuContainer: FC<Props> = ({ onSignOut, hideSignIntoAnotherTeam }) => {
  const { details } = useConnectedClient();
  const { t } = useTranslation();
  const appContext = useAppContext();
  const connectedAsClient = useMemo(() => !!localStorage.get('connectedAsClient'), []);
  const isKinde = useIsKinde();
  const navigate = useNavigate();

  const handleSwitchTeams = async (id: string) => {
    await appContext.onSwitchToDifferentTeam(id);
    if (!isKinde) {
      // For old auth flow, we have an issue to reload the page after switching teams. Therefore we manually reload it.
      window.location.reload();
    }
  };

  const isResearcher = details?.type?.researcher;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="cursor-pointer">
        <Button
          variant="ghost"
          className="h-[46px] rounded-none border-b-0 border-l border-solid bg-transparent px-4 text-left outline-none focus-visible:border-b
            focus-visible:border-solid focus-visible:border-border focus-visible:ring-0"
        >
          {connectedAsClient ? <AlertWarningIcon marginLeft="-3" marginTop="-4" /> : null}
          <div className="leading-1 max-w-48">
            <span className="line-clamp-1 block w-full truncate text-sm font-semibold">{details?.team?.name}</span>

            <span
              data-testid={DataTestId.userName}
              className={cn('block truncate', isResearcher ? 'text-sm font-semibold' : 'text-xs font-normal')}
            >
              {details?.name?.firstname}
            </span>
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-2 mt-1 w-56" side="bottom" align="end">
        <DropdownMenuItem asChild>
          <Link to="/settings/account">{t('sections.navigation.accountSettings')}</Link>
        </DropdownMenuItem>
        {!isResearcher ? (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel className="text-xs text-muted-foreground">Team</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => appContext.onOpenInviteTeamMemberModal(null, true)}>
              {t('sections.navigation.invite')}
            </DropdownMenuItem>
            <DropdownMenuItem asChild>
              <Link to="/settings/team">{t('sections.settings.title')}</Link>
            </DropdownMenuItem>
          </>
        ) : null}
        {details?.Teams && details?.Teams.length > 0 ? (
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>{t('components.teamSwitcher.switchTeam')}</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuLabel>
                  {details?.type?.researcher
                    ? t('components.teamSwitcher.teams')
                    : t('components.teamSwitcher.yourTeams')}
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                  className="max-h-[70vh] overflow-y-auto"
                  value={isResearcher ? details?.team?.id! : details?.ConnectedTeam?._id}
                  onValueChange={handleSwitchTeams}
                >
                  {details?.Teams?.map(team => {
                    return team?._id ? (
                      <DropdownMenuRadioItem value={team._id} key={team._id}>
                        {team.name}
                      </DropdownMenuRadioItem>
                    ) : null;
                  })}
                </DropdownMenuRadioGroup>
                {!hideSignIntoAnotherTeam && !isResearcher ? (
                  <>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => navigate('/teams')}>
                      {t('components.teamSwitcher.signInToAnotherTeam')}
                    </DropdownMenuItem>
                  </>
                ) : null}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        ) : null}

        {globalVariables.getEnvironmentVariables().isDemo ? (
          <DropdownMenuItem
            onClick={async () => {
              const resetDataUrl = new URL('https://reports.askable.com/reset-demos');
              resetDataUrl.searchParams.append('env', 'dev');
              resetDataUrl.searchParams.append('email', details?.email ?? '');
              resetDataUrl.searchParams.append('team', details?.team?.id ?? '');
              resetDataUrl.searchParams.append('password', 'false');
              window.open(resetDataUrl.toString(), '_blank');
            }}
          >
            {t('sections.navigation.resetDemoData')}
          </DropdownMenuItem>
        ) : null}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={onSignOut}>{t('global.signOut')}</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { Alert } from '@askable/ui/core/alert';
import { useTranslation } from 'react-i18next';

import { TextareaField } from 'containers/Studies/components/Fields/TextareaField';

import { OpportunityListingPreview } from './OpportunityListingPreview';

export const SectionOpportunityListing = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-x-8 gap-y-4 md:grid-cols-2">
      <div className="flex flex-col gap-6">
        <TextareaField
          label={t('sections.studies.recruit.fields.titleTitle')}
          name="listing_title"
          placeholder={t('sections.studies.recruit.fields.titlePlaceholder')}
          infoPopover={{
            title: t('sections.studies.recruit.fields.titleCalloutTitle'),
            description: t('sections.studies.recruit.fields.titleCalloutDescription'),
          }}
          maxLength={240}
          rows={1}
          isDisabled={isDisabled}
        />

        <TextareaField
          label={t('sections.studies.recruit.fields.descriptionTitle')}
          name="listing_description"
          isDisabled={isDisabled}
        />

        <div>
          <Alert variant="info" className="flex w-full flex-col gap-2 border-0 bg-info-foreground text-sm">
            <div className="font-semibold">{t('sections.studies.recruit.opportunityListingCalloutTitle')}</div>
            <div>{t('sections.studies.recruit.opportunityListingCalloutDescription')}</div>
          </Alert>
        </div>
      </div>
      <div>
        <OpportunityListingPreview />
      </div>
    </div>
  );
};

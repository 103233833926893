import { createLucideIcon } from 'lucide-react';

import { cn } from '../lib/utils';

import type { LucideProps } from 'lucide-react';

const ArchiveStatusComp = createLucideIcon('ArchiveStatus', [
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '10',
      stroke: 'currentColor',
      strokeWidth: '2px',
    },
  ],
  [
    'path',
    {
      stroke: 'currentColor',
      strokeLinecap: 'round',
      strokeWidth: '2px',
      d: 'M8 12h8',
    },
  ],
]);

export const ArchiveStatus = ({ className, ...props }: LucideProps) => {
  return <ArchiveStatusComp className={cn('text-muted-foreground', className)} {...props} />;
};

import { Skeleton } from '@askable/ui/core/skeleton';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { cn } from '@askable/ui/lib/utils';
import { Emotion, emotionsScaleMap, emotionColors, OpinionScaleResult } from '@askable/ui/unmod/opinion-scale';
import { UserCircle } from 'lucide-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { DownloadChart } from 'containers/Studies/Results/components/Task/DownloadChart';
import { TaskHeader } from 'containers/Studies/Results/components/Task/TaskHeader';
import { OpinionScaleResult as OpinionScaleResultQuery } from 'containers/Studies/Results/data/OpinionScaleResult.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';
import { BookingTaskResultDetailsType } from 'generated/graphql';

interface OpinionScaleResultsProps {
  labels: string[];
  taskId: string;
  title: string;
}

export const OpinionScaleResults = ({ labels, title, taskId }: OpinionScaleResultsProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const chartRef = useRef<HTMLDivElement>(null);

  const [{ data, fetching, error }] = useQuery({
    query: OpinionScaleResultQuery,
    variables: {
      _booking_id: study._id,
      _task_id: taskId,
    },
    pause: !study._id || !taskId,
  });

  const results = data?.bookingTaskResultOpinionScale;
  const ratingCount = results?.rating_counts.length ?? 0;

  if (!fetching && error) {
    return (
      <>
        <TaskHeader responses={0} title={title} type={BookingTaskResultDetailsType.OpinionScale} />
        <div className="text-destructive">{error.message}</div>
      </>
    );
  }

  return (
    <>
      {results && !fetching ? (
        <>
          <TaskHeader
            responses={results.total_responses}
            title={title}
            type={BookingTaskResultDetailsType.OpinionScale}
          />

          <section id="stat" className="grid md:grid-cols-2">
            <Tooltip>
              <TooltipTrigger className="flex flex-col justify-center gap-2 rounded-2xl border-0.5 border-border p-4 text-left text-sm leading-none">
                <div>{t('sections.studies.results.averageRating')}</div>
                <OpinionScaleResult
                  value={results.average_rating}
                  max={ratingCount}
                  type={results.scale_type}
                  size="large"
                />
              </TooltipTrigger>
              <TooltipContent>
                {t('sections.studies.results.averageRating')}: {parseFloat(results.average_rating.toFixed(1))} /{' '}
                {ratingCount}
              </TooltipContent>
            </Tooltip>
          </section>

          {ratingCount > 0 ? (
            <div className="flex flex-col gap-6">
              <div className="flex items-center justify-end gap-2">
                <DownloadChart chartRef={chartRef} title={title} studyName={study.name ?? study._id} />
              </div>

              <div ref={chartRef} className="flex flex-col gap-2">
                <div
                  data-print-only="true"
                  aria-hidden="true"
                  className="hidden items-center justify-between gap-2 pb-4"
                >
                  <div className="text-lg font-semibold">
                    {title} ({parseFloat(results.average_rating.toFixed(1))}/{ratingCount})
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {results.total_responses}{' '}
                    {t('sections.studies.results.responses', { count: results.total_responses }).toLocaleLowerCase()}
                  </div>
                </div>

                <ol
                  role="figure"
                  className="flex h-[18rem] w-full text-center leading-none [&:has(li:hover)>li:not(:hover)]:opacity-60"
                >
                  {results.rating_counts.map((option, i) => {
                    const percentage =
                      option.count > 0 && results.total_ratings > 0
                        ? Math.round((option.count / results.total_ratings) * 100)
                        : 0;

                    return (
                      <li
                        key={i}
                        className="group flex h-full flex-1 flex-col justify-end gap-2 rounded-md py-1 transition-opacity"
                        tabIndex={0}
                        title={`${option.rating} / ${results.rating_counts.length}`}
                        aria-roledescription={t('sections.studies.results.ratingBar')}
                        aria-label={`${t('sections.studies.options', { count: 1 })} ${option.rating} by ${option.count} ${t('sections.studies.recruit.participants', { count: option.count }).toLocaleLowerCase()}`}
                      >
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center justify-center gap-1 text-sm text-muted-foreground">
                            <UserCircle className="h-3 w-3" />
                            {option.count}
                          </div>
                          <div className="font-medium">{percentage}%</div>
                        </div>

                        <div className="border-b-0.5 border-accent px-2" style={{ height: `${percentage}%` }}>
                          <div
                            className={cn('h-full w-full rounded-t-md bg-color-chart dark:bg-accent-12', {
                              [`${emotionColors[i + 1]}`]: results.scale_type === 'emotions',
                            })}
                          ></div>
                        </div>

                        <div className="text-sm">
                          {results.scale_type === 'emotions' ? (
                            <div className="flex justify-center">
                              <Emotion typeIndex={emotionsScaleMap[ratingCount as keyof typeof emotionsScaleMap][i]} />
                            </div>
                          ) : (
                            option.rating
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ol>
                <ol className="flex w-full justify-between text-sm text-muted-foreground">
                  {labels.map((label, i) => (
                    <li key={i}>{label}</li>
                  ))}
                </ol>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <Skeleton className="h-12" />
          <div className="flex gap-2">
            <Skeleton className="h-[5.375rem] flex-1" /> <Skeleton className="h-[5.375rem] flex-1" />
          </div>
          <Skeleton className="h-[18rem]" />
        </>
      )}
    </>
  );
};

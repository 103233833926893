/* eslint-disable max-lines */
import { useMutation } from '@apollo/client';
import { Button } from '@askable/ui/core/button';
import { Command, CommandItem, CommandList } from '@askable/ui/core/command';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';
import _ from 'lodash';
import { CircleCheck, CirclePlus, CircleX, List, Text } from 'lucide-react';
import { useEffect, useState } from 'react';

import PriceCardContainer from 'components/askablePlus/components/priceCardContainer';
import { BoxMessage, LoadingOverlay } from 'components/common';
import CardContainer from 'components/createBooking/components/cardContainer';
import deleteProjectQuestionMutation from 'data/mutations/askablePlus/deleteProjectQuestion';
import duplicateProjectQuestionMutation from 'data/mutations/askablePlus/duplicateProjectQuestion';
import reorderProjectQuestionsMutation from 'data/mutations/askablePlus/reorderProjectQuestions';
import { utils } from 'lib/utils';

import ScreeningQuestionModal from './screeningQuestionModal';

import './styles/customScreenerStyles.scss';

function CustomScreener(props: any) {
  const askablePlusBrief = _.get(props, 'askablePlusBrief');

  const [askablePlusBriefState, setAskablePlusBriefState] = useState(askablePlusBrief);
  const [customQuestionType, setCustomQuestionType] = useState(1);
  const [draggedFrom, setDraggedFrom] = useState(null);
  const [draggedTo, setDraggedTo] = useState(null);
  const [error, setError] = useState<any>(null);
  const [indexCustomQuestionToBeEdited, setIndexCustomQuestionToBeEdited] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [openCustomQuestionsModal, setOpenCustomQuestionsModal] = useState(false);
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [savedScreeningQuestions, setSavedScreeningQuestions] = useState(
    _.get(askablePlusBrief, 'askable_plus.audience.booking_config.question') || [],
  );

  useEffect(() => {
    if (!_.get(props, 'askablePlusBrief._id')) {
      props.history.push('/askable-plus/create');
    } else {
      props.updateLastStep({
        step: 'Audience',
        subStep: `/askable-plus/${askablePlusBrief._id}/audience/custom-screener`,
        stepId: 'audience_custom_screener',
      });
      props.renderRightContent(rightContent());
    }
  }, []);
  const [deleteProjectQuestion] = useMutation(deleteProjectQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);

      onChangeQuestions(_.get(data, 'deleteProjectQuestion.askable_plus.audience.booking_config.question'));
    },

    onError: e => {
      setIsLoading(false);

      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });

  const [reorderProjectQuestions] = useMutation(reorderProjectQuestionsMutation, {
    onCompleted: data => {
      setIsLoading(false);

      onChangeQuestions(_.get(data, 'reorderProjectQuestions.askable_plus.audience.booking_config.question'));
    },

    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });

  const [duplicateProjectQuestion] = useMutation(duplicateProjectQuestionMutation, {
    onCompleted: data => {
      setIsLoading(false);
      onChangeQuestions(_.get(data, 'duplicateProjectQuestion.askable_plus.audience.booking_config.question'));
    },
    onError: e => {
      setIsLoading(false);
      setError(_.get(e, 'graphQLErrors[0]'));
    },
  });
  const rightContent = () => {
    const cardTexts1 = [
      {
        cardText: (
          <span>
            Check out our awesome beginner&apos;s guide on how to write the most effective screening questions to get
            the perfect participants.
          </span>
        ),
      },
      {
        middleLink: true,
        middleLinkAction: () => {
          window.open(
            'https://help.askable.com/en/articles/4849468-how-to-get-started-with-screener-question-writing',
            '_blank',
          );
        },
      },
    ];
    return (
      <>
        <p className="cardContainerTitle">Pricing</p>
        <PriceCardContainer
          askablePlusBrief={askablePlusBriefState}
          team={_.get(props, 'team')}
          context={props.context}
        />
        <p className="cardContainerTitle additionalTitle">Faqs</p>
        <CardContainer
          cardId="__customScreenerCard2"
          cardClass="slideInAnimationDelay80"
          cardTitle="Can I get some help with writing my screener questions?"
          cardTexts={cardTexts1}
        />
      </>
    );
  };

  const onChangeQuestions = (questionData: any) => {
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            question: questionData,
          },
        },
      },
    };

    setSavedScreeningQuestions(questionData);
    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);
  };

  const onDragStart = (ev: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    setDraggedFrom(Number(ev.currentTarget.dataset.id));
    ev.dataTransfer.effectAllowed = 'move';

    // Firefox requires calling dataTransfer.setData for the drag to properly work
    ev.dataTransfer.setData('text/html', null);
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
    const newDraggedTo = Number(ev.currentTarget.dataset.id);
    if (draggedTo !== newDraggedTo) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      setDraggedTo(newDraggedTo);
    }
  };

  const onDragEnd = () => {
    if (draggedFrom !== draggedTo) {
      reorderProjectQuestions({
        variables: {
          project_id: askablePlusBriefState._id,
          questions: utils.arrayMove(utils.removeTypenames(savedScreeningQuestions), draggedFrom, draggedTo),
        },
      });

      setDraggedTo(null);
      setDraggedFrom(null);
    }
  };

  const renderAnswerOptions = (answer: any, index: any, answerIndex: any) => {
    return (
      <div key={`answer_${answer.label}_${index}`} className="answer">
        {answer.screen_in ? (
          <CircleCheck className="h-4 w-4" color="#219653" style={{ height: 14, width: 14 }} />
        ) : (
          <CircleX className="h-4 w-4" color="#EB5757" style={{ height: 14, width: 14 }} />
        )}
        <span id={`__question_${answerIndex}_option_${index}`} className="text">
          {answer.label}
        </span>
      </div>
    );
  };

  const renderQuestion = (value: any, index: any) => {
    return (
      <li
        key={`customQuestion_${index}`}
        className={`customQuestion ${draggedTo === index ? 'draggedTo' : ''}`}
        data-id={index}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <div className="typesContainer">
          {value.config.type === 1 ? (
            <div className="typeContainer">
              <List color="#888" className="h-4 w-4" />
              <p className="type">Multi Choice</p>
            </div>
          ) : (
            <div className="typeContainer">
              <Text color="#888" className="h-4 w-4" />
              <p className="type">Short Answer</p>
            </div>
          )}
        </div>
        <div className="questionContainer">
          <p id={`__questionTitle_${index}`} className="question">
            {value.title}
          </p>
          {value.description && (
            <p id={`__questionDescription_${index}`} className="description">
              {value.description}
            </p>
          )}
          {value.config.multiple_selection && (
            <p id={`__questionMultipleSelection_${index}`} className="multipleSelection">
              Select as many as you like
            </p>
          )}
          {value.options.length > 0 && (
            <div className="answersContainer">
              {_.map(value.options, (optVal: any, optIndex: any) => renderAnswerOptions(optVal, optIndex, index))}
            </div>
          )}
        </div>
        <div className="actionsContainer">
          <div className="mainActions">
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                setCustomQuestionType(
                  askablePlusBriefState.askable_plus.audience.booking_config.question.find(
                    (item: any, indexOrigin: any) => indexOrigin === index,
                  ).config.type,
                );
                setIndexCustomQuestionToBeEdited(index);
                setOpenCustomQuestionsModal(true);
              }}
            >
              Edit
            </a>
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                const newArrayQuestions = _.clone(savedScreeningQuestions);
                newArrayQuestions.push({
                  _id: utils.newObjectId(),
                  ..._.omit(value, '_id'),
                });

                const askablePlusBriefStateObj = {
                  ...askablePlusBriefState,
                  askable_plus: {
                    ...askablePlusBriefState.askable_plus,
                    audience: {
                      ...askablePlusBriefState.askable_plus.audience,
                      booking_config: {
                        ...askablePlusBriefState.askable_plus.audience.booking_config,
                        question: newArrayQuestions,
                      },
                    },
                  },
                };

                setSavedScreeningQuestions(newArrayQuestions);
                setAskablePlusBriefState(askablePlusBriefStateObj);
                props.updateAskablePlusBriefState(askablePlusBriefStateObj);
                duplicateProjectQuestion({
                  variables: {
                    project_id: askablePlusBriefState._id,
                    question_id: value._id,
                  },
                });
              }}
            >
              Duplicate
            </a>
          </div>
          <div className="secondaryActions">
            <a
              id={value._id ? value._id : index}
              onClick={() => {
                deleteProjectQuestion({
                  variables: {
                    project_id: askablePlusBriefState._id,
                    question_id: value._id,
                  },
                });
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </li>
    );
  };

  const renderSavedQuestions = () => {
    return (
      _.size(savedScreeningQuestions) >= 1 && (
        <div className="questionsContainer">
          <ul className="customQuestions">{_.map(savedScreeningQuestions, renderQuestion)}</ul>
        </div>
      )
    );
  };

  const onClickLinkGeneralParticipantCriteria = () => {
    const askablePlusBriefStateObj = {
      ...askablePlusBriefState,
      askable_plus: {
        ...askablePlusBriefState.askable_plus,
        audience: {
          ...askablePlusBriefState.askable_plus.audience,
          booking_config: {
            ...askablePlusBriefState.askable_plus.audience.booking_config,
            criteria: {
              custom: [],
              filters_order: null,
              meta_children: null,
              meta_education: null,
              meta_education_field: null,
              meta_family_income: null,
              meta_family_status: null,
              meta_home_ownership: null,
              meta_identity_birthday_year: null,
              meta_identity_gender: null,
              meta_identity_languages: null,
              meta_identity_languages_english_speak: null,
              meta_identity_locales: null,
              meta_individual_income: null,
              meta_purchasing_behaviour: null,
              meta_tech_savviness: null,
              meta_work_employment_type: null,
              meta_work_industry: null,
              meta_work_status: null,
              locations: {
                ...askablePlusBriefState.askable_plus.audience.booking_config.criteria.locations,
              },
            },
            question: [],
          },
        },
      },
    };

    setAskablePlusBriefState(askablePlusBriefStateObj);
    props.updateAskablePlusBriefState(askablePlusBriefStateObj);

    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/participant-criteria`;
    props.history.push({ pathname: redirectTo });
  };

  const onClickNext = () => {
    const redirectTo = `/askable-plus/${askablePlusBrief._id}/audience/incentives`;
    props.history.push({ pathname: redirectTo });
  };

  return (
    <>
      {isLoading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="createAskablePlusBriefContent">
        <h1 id="__pageTitle" className="title">
          Create a custom screener
        </h1>

        <p className="label">
          Adding your own customer screener is a great way to get even more specific about what kind of participants you
          want.
        </p>

        {error && (
          <BoxMessage type="error" error={error}>
            <p className="error">{error.message}</p>
          </BoxMessage>
        )}

        {renderSavedQuestions()}

        <div>
          <Popover open={openFilterOptions} onOpenChange={setOpenFilterOptions}>
            <PopoverTrigger asChild>
              {_.size(savedScreeningQuestions) > 0 ? (
                <Button
                  variant="link"
                  onClick={() => {
                    setIndexCustomQuestionToBeEdited(-1);
                  }}
                >
                  + Add another custom question
                </Button>
              ) : (
                <button
                  type="button"
                  className="flex items-center justify-center gap-4 rounded-sm border p-4 text-left shadow hover:shadow-md
                    focus-visible:ring-offset-2"
                >
                  <CirclePlus className="h-5 w-5" />
                  <div>
                    <div className="text-sm font-bold">Add a custom screening question</div>
                    <div className="text-xs text-muted-foreground">Multiple choice or short answer</div>
                  </div>
                </button>
              )}
            </PopoverTrigger>
            <PopoverContent className="w-[18rem] p-0" align="start">
              <Command label="Customer screener filter">
                <CommandList>
                  <CommandItem
                    className="flex gap-2"
                    onSelect={() => {
                      setOpenFilterOptions(false);
                      setCustomQuestionType(1);
                      setOpenCustomQuestionsModal(true);
                    }}
                  >
                    <List className="h-4 w-4" /> Multiple choice
                  </CommandItem>
                  <CommandItem
                    className="flex gap-2"
                    onSelect={() => {
                      setOpenFilterOptions(false);
                      setCustomQuestionType(2);
                      setOpenCustomQuestionsModal(true);
                    }}
                  >
                    <Text className="h-4 w-4" /> Short answer
                  </CommandItem>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>

        <div className="linkTextContainer mtop20" onClick={onClickLinkGeneralParticipantCriteria}>
          <span className="linkTextLabel">I'll provide general participant criteria</span>
        </div>
        <div className="buttonNextContainer">
          <Button variant="primary" size="lg" onClick={onClickNext}>
            Next
          </Button>
        </div>
      </div>

      {openCustomQuestionsModal && (
        <ScreeningQuestionModal
          askablePlusBrief={askablePlusBriefState}
          onClose={() => setOpenCustomQuestionsModal(false)}
          onSave={(projectQuestions: any) => {
            setOpenCustomQuestionsModal(false);
            setIndexCustomQuestionToBeEdited(-1);
            if (projectQuestions) onChangeQuestions(projectQuestions.askable_plus.audience.booking_config.question);
          }}
          open={openCustomQuestionsModal || false}
          itemToBeEdited={
            _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.question') &&
            _.find(
              _.get(askablePlusBriefState, 'askable_plus.audience.booking_config.question'),
              (item: any, indexOrigin: any) => indexOrigin === indexCustomQuestionToBeEdited,
            )
          }
          projectID={_.get(askablePlusBriefState, '_id')}
          questionType={customQuestionType}
        />
      )}
    </>
  );
}

export default deprecatedWithRouter(CustomScreener);

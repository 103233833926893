import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Review = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={fgHsl}
        d="M24.1 3.8c-7.9-.5-12.7 1.9-14.1 3l-.4 47.3 3 1.9 36.1.5-.6-7.1-.8-33.8.8-12.2c-4.7.3-16 .9-24 .4Z"
      />
      <path
        fill={fgHsl}
        d="M37.3 19.2c5.6-5.2 12-5 17 0 4.8 5.1 6 11.4 0 15.6-6.2 4.2-11.8 4.3-16.4 0-4.7-4.3-6.2-10.4-.6-15.6Z"
      />
      <path
        fill={bgHsl}
        d="M34.1 13.6c.5-.1.8-.7.6-1.2a1 1 0 0 0-1.3-.6c-.7.3-1.7.4-2.6.4l-14 .7a1 1 0 0 0 0 2l14.1-.7c1 0 2.2-.1 3.2-.6Zm-5.7 5.1a1 1 0 0 0-1.2-.8c-1.7.4-4.4.4-6.7.5h-3.2a1 1 0 0 0 0 2h3.2c2.5-.1 5.2-.2 7.2-.6.5 0 .8-.6.7-1.1Zm-11.7 6.6 1.8.1a53.3 53.3 0 0 0 3.5 0 1 1 0 0 0 0-2h-5a1 1 0 1 0-.2 2ZM28 32.5a1 1 0 0 0-1.1-.8c-1.8.4-4.4.4-6.8.5H17a1 1 0 0 0 0 2h3.3c2.4-.1 5.1-.2 7-.6.6 0 1-.6.8-1.1Zm-1.1 4.7c-1.8.4-4.4.4-6.8.4l-3.2.1a1 1 0 0 0 0 2h.1l3.2-.1c2.4 0 5.1-.1 7-.5.6-.1 1-.6.8-1.2a1 1 0 0 0-1.1-.7Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M47.6 2c.4 0 .7 0 1 .3.3.2.5.5.5 1v12c2.1.5 4 1.7 5.8 3.5 2.4 2.6 4 5.5 4.2 8.3 0 1.7-.4 3.4-1.4 5-.5.9-1.1 2-2 3a312.5 312.5 0 0 1 6.5 5.8l.1.5c0 .3 0 .6-.2.8a3.6 3.6 0 0 1-1 1.2c-.3.2-.7.4-1 .4l-.6-.1-.2-.2-.4-.3a67 67 0 0 1-5-6 15.1 15.1 0 0 1-4.1 2.9l.5 16.2c0 .7-.5 1.2-1.1 1.3l-1.8.2-.2.1v.1l.2 3.3-1.1.2v-.3.3c-3.1.4-6 .5-8.9.5-4.2 0-8.3-.3-12.5-.7h-.4c-5-.4-10-.8-15.5-.7H7.9l-.1-1a344 344 0 0 1-2-49.4v-1l1-.2A14.7 14.7 0 0 0 8 8.7l.7-.1v-.3c0-1.6.8-2.5 1.9-3.1 1-.6 2.3-1 3.5-1.3l.7-.1V4v-.2a56.7 56.7 0 0 1 17-1c5.3.2 10.7.4 15.8-.8Zm-.2 39H45c-6 0-10.7-4-12.6-8.4a7 7 0 0 1-.3-3.6 14 14 0 0 1 2.4-5.9c.6-1.5 1.6-3 3.1-4.4a13.1 13.1 0 0 1 9.1-4V4.8c-5 1-10 .7-14.9.5-5.8-.3-11.2-.5-16.4 1l-.7.1c-1.1.3-2 .5-2.7.9-.6.3-.9.6-.9 1.1-.5 15.3.1 30.8 1.9 46 5.1 0 10 .3 14.6.7 6.7.5 13 1 20.2.3L47.4 41Zm-8.6-21c2.6-2.4 5.3-3.5 7.7-3.4 2.4 0 4.9 1 7.1 3.4a12 12 0 0 1 3.7 7.2c.2 2.1-.8 4.3-3.5 6.2-2.9 2-5.5 2.9-7.8 2.9s-4.5-.9-6.6-2.8c-2.2-2-3.5-4.3-3.7-6.5-.2-2.2.6-4.6 3.1-7Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

import { FormLabel } from '@askable/ui/core/form';

import { InfoPopover } from 'containers/Studies/components/InfoPopover';

import type { InfoPopoverProps } from 'containers/Studies/components/InfoPopover';

interface FieldLabelProps {
  name: string;
  label: string;
  description?: string;
  infoPopover?: InfoPopoverProps;
}

export const FieldLabel = ({ name, label, description, infoPopover }: FieldLabelProps) => {
  return (
    <div className="flex flex-col gap-1">
      <FormLabel hasErrorStyle={false} className="items-center gap-0" htmlFor={`input_${name}`}>
        {label} {infoPopover ? <InfoPopover {...infoPopover} /> : null}
      </FormLabel>

      {description ? (
        <div id={`description_${name}`} className="max-w-[60ch] text-pretty pb-1 text-xs text-muted-foreground">
          {description}
        </div>
      ) : null}
    </div>
  );
};

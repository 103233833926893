import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Segment = ({ bgColor = 'foreground', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={bgHsl}
        d="M39.2 9.5v.7a1 1 0 0 1-1.7 0 12.4 12.4 0 0 1-.2-6.6 1 1 0 0 1 1.3-.5c.4.2.6.8.4 1.2v.4a11 11 0 0 0 .2 4.8Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M5 52a1 1 0 0 1 .3-1l18.4-18.7 8.3-8.6a1399.1 1399.1 0 0 1 17.2-17l.4-.2h.3c.4 0 .7 0 1 .4 3 3.6 6.9 10 8.3 17.5 1.4 7.5.3 16.1-6.3 24.1A31.5 31.5 0 0 1 26.3 61a36.7 36.7 0 0 1-20.9-8.3A1 1 0 0 1 5 52Zm44.8-42s.2 0 .2.2a27.4 27.4 0 0 1 2.1 13c0 .2 0 .3-.3.4l-19 4c-.6 0-1-.6-.5-1l17-16.5.5-.1ZM38.2 48a27 27 0 0 1-12.4 4.5.5.5 0 0 1-.3-.5l2.7-18c0-.4.6-.6.9-.3L41.3 44c.3.2.3.6 0 .8-.9 1-1.8 2-3.1 3Zm6.8-7a37.4 37.4 0 0 0 6.6-14.5v-.4a.5.5 0 0 0-.5 0l-19.8 4.3c-.2 0-.3.2-.4.5 0 .2 0 .5.2.6l.4.4a254 254 0 0 0 11.4 10.6h.5c.6-.4 1.1-1 1.6-1.5Zm-20.2-6.7c.4-.3 1 0 1 .5L23.1 52c0 .3-.3.5-.5.5-5.8-.2-8.4-.4-13.4-1.2a.5.5 0 0 1-.3-.9l15.8-16Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        d="M29 10c1.3 2 1.8 2.7 3.4 4.7.4.4 1 .5 1.5.1.5-.3.5-1 .2-1.5a68 68 0 0 1-3.6-4.8A1 1 0 0 0 29 10Zm1.3 9.5a1 1 0 0 0-.8-1.3 41.4 41.4 0 0 0-3.3-.7H26a9 9 0 0 0-2.8-.3 1 1 0 0 0-.9 1.3c.1.5.7 1 1.3.8a6 6 0 0 1 2 .3h.2l.6.1h.2l2.3.5a1 1 0 0 0 1.3-.7Z"
      />
    </svg>
  );
};

import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Target = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path fill={bgHsl} d="M44 51.6c-12.2 12-34.1 6-38.9-9.3C1 28.8 8.6 13.2 26 8.5l18 43.1Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M25.8 7.8c.4-.1.7 0 .9.4l18 43.2c.1.2 0 .6-.2.8a24 24 0 0 1-22.9 5.7c-7.8-2-14.7-7.4-17.2-15.4-4.3-14 3.7-30 21.4-34.7Zm-.2 1.6C9 14.2 1.8 29.2 5.9 42.1a22.2 22.2 0 0 0 16 14.4c7.3 1.8 15.4.5 21.2-5L25.6 9.4Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M36.4 55.1C25.2 58.3 12.8 53 9 37.7.7 4.5 48.5-3.8 55.6 27.7c2.9 13.1-9.1 24.6-19.2 27.4Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M26.3 7.5a25.3 25.3 0 0 1 30.2 20c1.6 7-.8 13.4-4.9 18.4-4 5-9.7 8.7-15 10.2-5.8 1.6-12 1-17.2-2A23.9 23.9 0 0 1 8 38a24.9 24.9 0 0 1 3-20.8c3.5-5.2 9.2-8.5 15.3-9.7ZM12.6 18.3a22.9 22.9 0 0 0-2.7 19.2 22.8 22.8 0 0 0 10.5 15A20.5 20.5 0 0 0 36 54.2a28.7 28.7 0 0 0 14-9.5A19.7 19.7 0 0 0 54.6 28 23.3 23.3 0 0 0 26.7 9.5a22.1 22.1 0 0 0-14.1 8.8Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="M40.7 18.1c7 3.4 10.8 11.6 5.9 20.8C36 59.1 8.5 42.1 19 23.2c4.3-7.7 15.4-8.2 21.7-5Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M24.4 46.4A17 17 0 0 1 18.2 23l2 1-2-1c2.4-4.3 6.6-6.5 10.8-7.2a21 21 0 0 1 12 1.6c3.6 1.8 6.5 4.8 7.9 8.8 1.3 4 1 8.5-1.5 13.3a17 17 0 0 1-10.8 9c-4.3 1-8.6.2-12.2-2Zm11-2.4c3-.7 5.8-2.7 8-6.8 1.9-3.7 2-7 1.1-9.7-.9-2.6-2.9-4.8-5.6-6.1a15.8 15.8 0 0 0-9.1-1.2 10 10 0 0 0-7.6 4.9 12.4 12.4 0 0 0 4.5 17.4 12 12 0 0 0 8.8 1.5Z"
        clipRule="evenodd"
      />
      <path fill={bgHsl} d="M38 33.5c-.5 2.5-2.8 4.4-6.1 3.6-7.3-1.7-4.1-11.8 2.7-10 2.8.7 3.9 4.2 3.4 6.4Z" />
      <path fill={fgHsl} d="M56.7 9.6 55.3 8 32 31.3l1.5 1.6L56.7 9.6Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M55.3 6c.5 0 1 .2 1.4.6l1.5 1.6c.7.8.7 2 0 2.8L34.8 34.3a2 2 0 0 1-2.9 0l-1.4-1.6a2 2 0 0 1 0-2.8L54 6.6a2 2 0 0 1 1.4-.6Zm0 2L32 31.3l1.5 1.6L56.7 9.6 55.3 8Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m54.4 2.3.5 4.6-3.3 3.2-.6-4.6 3.4-3.2Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M54.7 1.4c.4 0 .6.4.7.8l.5 4.6c0 .3 0 .6-.3.8l-3.3 3.2a1 1 0 0 1-1.7-.6l-.5-4.6c0-.3 0-.6.3-.8l3.3-3.2a1 1 0 0 1 1-.2Zm-2.6 4.5.3 2 1.5-1.4-.3-2.1-1.5 1.5Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m62.6 10.6-4.5-.7-3.3 3.2 4.5.7 3.3-3.2Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M63.6 10.3a1 1 0 0 0-.8-.7L58.2 9a1 1 0 0 0-.8.3l-3.3 3.2a1 1 0 0 0 .5 1.7l4.6.7c.3 0 .6 0 .8-.2l3.3-3.2c.3-.3.4-.7.3-1ZM59 12.8l-2-.4 1.4-1.4 2.1.3-1.5 1.5Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m51 5.5.6 4.6-3.3 3.1-.6-4.5L51 5.5Z" />
      <path fill={fgHsl} d="m47.9 8.4.5 4.6-3.3 3.2-.6-4.6L48 8.4Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M51.4 4.5c.3.2.6.5.6.9l.6 4.5c0 .4 0 .7-.3.9L49 14a1 1 0 0 1-1.7-.6l-.6-4.6.3-.9 3.4-3.1a1 1 0 0 1 1-.3ZM48.8 9l.2 2.1 1.6-1.4-.3-2.1L48.8 9Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M48.2 7.4c.4.2.6.5.7.9l.5 4.5c0 .4 0 .7-.3.9l-3.3 3.2a1 1 0 0 1-1.7-.6l-.6-4.6c0-.3.1-.6.3-.8l3.4-3.2a1 1 0 0 1 1-.3ZM45.6 12l.2 2 1.6-1.4-.3-2.1-1.5 1.5Z"
        clipRule="evenodd"
      />
      <path fill={fgHsl} d="m59.3 13.8-4.5-.7-3.3 3.2 4.5.7 3.3-3.2Z" />
      <path fill={fgHsl} d="m56.1 17-4.5-.7-3.4 3.2 4.6.8L56 17Z" />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M60.3 13.5a1 1 0 0 0-.8-.7l-4.6-.7a1 1 0 0 0-.8.3l-3.3 3.2a1 1 0 0 0 .5 1.7l4.6.7c.3 0 .6 0 .8-.2l3.3-3.2c.3-.3.4-.7.3-1ZM55.7 16l-2.1-.4 1.5-1.4 2 .3-1.4 1.5Z"
        clipRule="evenodd"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M57 16.8a1 1 0 0 0-.7-.7l-4.6-.8a1 1 0 0 0-.8.3l-3.4 3.2a1 1 0 0 0 .6 1.7l4.5.7c.3.1.7 0 .9-.2l3.3-3.2c.3-.3.4-.7.3-1Zm-4.5 2.4-2.1-.3 1.5-1.5 2 .3-1.4 1.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { HeatmapContent } from 'containers/Studies/Results/components/Heatmap/HeatmapContent';
import { RecordingContent } from 'containers/Studies/Results/components/Recording/RecordingContent';
import { Stat } from 'containers/Studies/Results/components/Stat';
import { FigmaPrototypeResultDialogHeader } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogHeader';
import { FigmaPrototypeResultDialogLoading } from 'containers/Studies/Results/components/Task/FigmaPrototype/Result/FigmaPrototypeResultDialogLoading';
import { FigmaPrototypeResultScreens } from 'containers/Studies/Results/data/FigmaPrototypeResultScreens.query';

import type { BookingTaskResultFigmaPrototype } from 'generated/graphql';

/**
 * Render recording and screens for a task
 */
export const FigmaPrototypeResultDialogContentScreen = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') ?? 'recording');

  const { studyId, taskId } = params;
  const nodeId = searchParams.get('node_id');

  const [{ data, fetching, error }] = useQuery({
    query: FigmaPrototypeResultScreens,
    variables: {
      _booking_id: studyId ?? '',
      _task_id: taskId ?? '',
    },
    pause: !taskId || !studyId,
  });

  const resultData = data?.figmaPrototypeResultScreens;

  // For heatmap and video chapters
  const screens = resultData?.map(s => s.screen) ?? [];
  const activeScreenIndex = resultData?.findIndex(s => s.id === nodeId) ?? 0;
  const activeScreen = resultData?.[Math.min(activeScreenIndex, screens.length - 1)] ?? resultData?.[0];

  // For recording
  const relatedResults = activeScreen?.results as BookingTaskResultFigmaPrototype[];

  // For header: title, navigation and stats
  const nextResultId = resultData?.[activeScreenIndex + 1]?.screen.id ?? null;
  const previousResultId = resultData?.[activeScreenIndex - 1]?.screen.id ?? null;

  if (fetching && !resultData) {
    return <FigmaPrototypeResultDialogLoading />;
  }

  if (!fetching && error) {
    return <div className="p-4 text-destructive">{error.message}</div>;
  }

  if (!fetching && activeScreen) {
    return (
      <>
        <FigmaPrototypeResultDialogHeader
          activeTab={activeTab}
          isLoading={fetching}
          nextResultId={nextResultId}
          previousResultId={previousResultId}
          title={activeScreen.name}
          type="screen"
          onTabChange={setActiveTab}
        >
          <>
            <Stat type="responses" value={activeScreen?.stats?.total_responses ?? 0} />
            <Stat type="misclickRate" value={activeScreen?.stats?.misclick_rate ?? 0} variant="inline" />
            <Stat type="averageDuration" value={activeScreen?.stats?.average_duration ?? 0} variant="inline" />
          </>
        </FigmaPrototypeResultDialogHeader>

        {activeTab === 'heatmap' ? (
          <HeatmapContent screens={screens} activeScreen={activeScreen.screen} />
        ) : (
          <RecordingContent
            results={relatedResults}
            screens={screens}
            title={activeScreen.name ?? t('sections.studies.results.screen.count', { count: activeScreenIndex + 1 })}
          />
        )}
      </>
    );
  }
};

import { createLucideIcon } from 'lucide-react';

export const CircleXSolid = createLucideIcon('CircleXSolid', [
  [
    'path',
    {
      d: 'M12 1a11 11 0 1 0 0 22 11 11 0 0 0 0-22ZM9 8c.3 0 .5.1.7.3l2.3 2.3 2.3-2.3a1 1 0 1 1 1.4 1.4L13.4 12l2.3 2.3a1 1 0 0 1-1.4 1.4L12 13.4l-2.3 2.3a1 1 0 0 1-1.4-1.4l2.3-2.3-2.3-2.3A1 1 0 0 1 9 8Z',
      fill: 'currentColor',
      stroke: 'none',
    },
  ],
]);

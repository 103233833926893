import { toast } from '@askable/ui/core/sonner';
import { formatInTimeZone } from 'date-fns-tz';
import { template } from 'radash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatSessionTime } from 'shared-utils';
import { Textarea, Button, FormControl, FormLabel, FormErrorMessage } from 'ui';
import { useMutation } from 'urql';

import { useConnectedClient } from 'context/ConnectedClientContext';
import { SubmissionReinviteDocument, SubmissionStatus } from 'generated/graphql';
import { handleRequest } from 'network/handleRequest';
import { getParticipantOpportunityLink } from 'utils/url-utils';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';

import type {
  IncentiveBookingConfig,
  Maybe,
  SessionsType,
  SubmissionReinviteMutationResult,
  SubmissionReinviteMutationVariables,
} from 'generated/graphql';

type Props = {
  session?: Maybe<SessionsType>;
  pptDisplayname: string;
  onClose: () => void;
  pptTimezone: string;
  bookingId: string;
  isModeratedBooking: boolean;
  submissionStatus: SubmissionStatus;
  submissionId: string;
  incentive?: IncentiveBookingConfig | null;
  description: string | null;
};

type FormValues = {
  note: string;
};

const generateMessageTemplate = ({
  isModeratedBooking,
  submissionStatus,
  shortDescription,
  incentiveText,
  opportunityLink,
  pptName,
  senderText,
  sessionTime,
}: {
  isModeratedBooking: boolean;
  submissionStatus: SubmissionStatus;
  pptName: string;
  sessionTime?: string;
  shortDescription?: string;
  incentiveText: string;
  opportunityLink: string;
  senderText: string;
}) => {
  const custom = (() => {
    if (isModeratedBooking) {
      if (submissionStatus === SubmissionStatus.Invited) {
        return 'please confirm your spot for the session on {{sessionTime}} paying {{incentiveText}}. Please confirm by going to:{{opportunityLink}}. Spots are limited.';
      }
      return 'a spot is now available for the session on {{sessionTime}} paying {{incentiveText}}. Please confirm by going to:{{opportunityLink}}. Spots are limited.';
    }
    return 'a spot is available for {{shortDescription}}. Please confirm by going to: {{opportunityLink}}.';
  })();

  return template(`Hey {{pptName}}, ${custom}\n\n{{senderText}}`, {
    pptName,
    sessionTime,
    incentiveText,
    opportunityLink,
    shortDescription,
    senderText,
  });
};

export const BookingParticipantResendInvite = ({
  session,
  onClose,
  pptTimezone,
  pptDisplayname,
  bookingId,
  isModeratedBooking,
  submissionStatus,
  submissionId,
  incentive,
  description,
}: Props) => {
  const { t } = useTranslation();
  const pptName = pptDisplayname || 'there';

  const { handleSubmit, register, reset, setValue, formState } = useForm<FormValues>();

  const { details } = useConnectedClient();

  useEffect(() => {
    const senderText = details?.name?.firstname || 'the team';
    const incentiveText = `${incentive?.currency_symbol}${incentive?.value}`;
    const opportunityLink = getParticipantOpportunityLink(bookingId);
    const sessionTime = (() => {
      if (!isModeratedBooking || !session?.session?.start) {
        return '';
      }

      const startDayText = formatInTimeZone(session.session.start, pptTimezone, 'EEEE dd MMM');
      const startTimeText = formatInTimeZone(session.session.start, pptTimezone, 'h:mm aaa');
      return `${startDayText} at ${startTimeText}`;
    })();

    setValue(
      'note',
      generateMessageTemplate({
        incentiveText,
        isModeratedBooking,
        opportunityLink,
        pptName,
        senderText,
        submissionStatus,
        sessionTime,
        // this is the title of the study - seems to be in a different field for unmod studies
        shortDescription: description || '',
      }),
    );
  }, [reset, setValue, pptName, session, pptTimezone, details, description]);

  const [{ fetching }, resendInvite] = useMutation<
    SubmissionReinviteMutationResult,
    SubmissionReinviteMutationVariables
  >(SubmissionReinviteDocument);

  const onConfirmResendInvite = async (values: FormValues) => {
    if (!session?._id) {
      return;
    }

    const [, error] = await handleRequest(async () => {
      return resendInvite({
        input: {
          _booking_id: bookingId,
          _submission_id: submissionId,
          activeSessionId: session?._id!,
          customMessage: values.note,
        },
      });
    });

    if (error) {
      toast.error('Failed to resend invite');
      return;
    }

    toast.success(t('sections.bookingParticipantTable.inviteSent'));
    onClose();
  };

  const dateText =
    session?.session?.start && session.session.end
      ? `on ${formatSessionTime({ start: session.session?.start, end: session.session?.end })}.`
      : '';

  return (
    <BookingSidePanelStepperContainer
      onSubmit={handleSubmit(onConfirmResendInvite)}
      title="Resend invite"
      as="form"
      support={isModeratedBooking ? `To ${pptName} for the session ${dateText}` : `To ${pptName}`}
      onCancelClick={onClose}
      footer={
        <Button
          data-testid="resend-invite"
          colorScheme="blue"
          size="lg"
          type="submit"
          w="full"
          mt="auto"
          isLoading={fetching}
        >
          Resend invite
        </Button>
      }
    >
      <FormControl isInvalid={!!formState.errors.note}>
        <FormLabel htmlFor="note" mb="2">
          Message
        </FormLabel>
        <Textarea
          data-testid="resend-invite-textarea"
          id="note"
          rows={9}
          {...register('note', { required: 'Message is required' })}
          name="note"
          w="full"
        />
        <FormErrorMessage>{formState.errors.note?.message}</FormErrorMessage>
      </FormControl>
    </BookingSidePanelStepperContainer>
  );
};

import { DialogTitle } from '@askable/ui/core/dialog';
import { Skeleton } from '@askable/ui/core/skeleton';

export const FigmaPrototypeResultDialogLoading = () => {
  return (
    <>
      <DialogTitle className="p-4">
        <Skeleton className="h-12 w-3/5" />
      </DialogTitle>
      <div className="px-4">
        <Skeleton className="h-60 w-full" />
      </div>
    </>
  );
};

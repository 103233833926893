import type { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  headerSlot?: ReactNode;
}

export const Card = ({ children, headerSlot }: CardProps) => {
  return (
    <div className="flex flex-col gap-3 rounded-2xl border-0.5 border-border p-4 md:p-6">
      {headerSlot ? <header className="flex flex-wrap items-center justify-between gap-2">{headerSlot}</header> : null}

      {children}
    </div>
  );
};

import { VideoPlayer } from '@askable/ui/core/video-player';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { EmptyState } from 'containers/Studies/Results/components/EmptyState';
import { LoadingState } from 'containers/Studies/Results/components/LoadingState';
import { PlaybackMediaUrl } from 'containers/Studies/Results/data/PlaybackMediaUrl.query';
import { useStudyContext } from 'containers/Studies/StudiesContainer';

import type { Chapter } from '@askable/ui/core/video-player';

type RecordingPlayerProps = {
  chapters?: Chapter[];
  code: string;
  id: string;
  seekTo?: number;
  title?: string;
};

export const RecordingPlayer = ({ chapters, code, id, seekTo, title }: RecordingPlayerProps) => {
  const { t } = useTranslation();
  const { study } = useStudyContext();
  const [playbackMediaUrlQuery] = useQuery({ query: PlaybackMediaUrl, variables: { _room_id: id, code } });

  const url = playbackMediaUrlQuery.data?.playbackMediaUrl;
  const downloadFileName = `askable_${study.name?.replace(/ /g, '-').toLocaleLowerCase()}-${title?.replace(/ /g, '-').toLocaleLowerCase()}.mp4`;

  if (playbackMediaUrlQuery.fetching) {
    return <LoadingState title={t('sections.studies.results.video.loading')} />;
  }

  if (!url) {
    return <EmptyState title={t('sections.studies.results.video.notFound')} />;
  }

  return (
    <VideoPlayer url={url} chapters={chapters} seekTo={seekTo} enableDownload downloadFileName={downloadFileName} />
  );
};

import { AnchorButton, Button } from '@askable/ui/core/button';
import { Popover, PopoverContent, PopoverTrigger } from '@askable/ui/core/popover';
import { ExternalLink, Info } from 'lucide-react';
import { useTranslation } from 'react-i18next';

/**
 * Extends the Popover component with custom styling and behaviour.
 * Open on hover or tap, so it can support touch devices too
 */

export interface InfoPopoverProps {
  description?: string;
  isOpen?: boolean;
  moreLink?: string;
  moreLinkLabel?: string;
  title?: string;
}

export const InfoPopover = ({
  isOpen: externalIsOpen,
  description,
  moreLink,
  moreLinkLabel,
  title,
}: InfoPopoverProps) => {
  const { t } = useTranslation();

  return (
    <Popover openOnHover modal={false} open={externalIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon_sm"
          className="-mb-1 -mt-1 cursor-default rounded-full text-input hover:bg-transparent focus:bg-transparent focus-visible:ring-0
            aria-[expanded=true]:bg-transparent"
          onClick={e => e.stopPropagation()}
        >
          <Info className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-4 p-4" align="center">
        <div className="flex flex-col gap-1">
          {title ? <div className="font-semibold leading-snug">{title}</div> : null}
          {description ? <div className="whitespace-pre-wrap text-pretty text-sm">{description}</div> : null}
        </div>

        {moreLink ? (
          <AnchorButton href={moreLink} variant="info" target="_blank">
            {moreLinkLabel ?? t('global.learnMore')} <ExternalLink className="h-4 w-4" />
          </AnchorButton>
        ) : null}
      </PopoverContent>
    </Popover>
  );
};

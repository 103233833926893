import { getColorValue } from '../../lib/utils';

import type { IconProps } from '.';

export const Radio = ({ bgColor = 'foreground', fgColor = 'background', size = 48 }: IconProps) => {
  const bgHsl = getColorValue(bgColor);
  const fgHsl = getColorValue(fgColor);

  return (
    <svg fill="none" viewBox="0 0 64 64" width={size} height={size} aria-hidden="true">
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M11 10.3a27.3 27.3 0 0 0-1 39.1c2.5 2.7 5.4 5 8.7 6.5 14.1 6.6 29.2 4.4 37.9-5.8a25.7 25.7 0 0 0 4-24.7C58.2 18 49.2 11.2 41.9 7.6a33 33 0 0 0-11.3-3.4c-7-.8-14.2.7-19.6 6Z"
        clipRule="evenodd"
      />
      <path
        fill={fgHsl}
        d="M42 51.3a24.7 24.7 0 0 1-20.5 3.3A23.9 23.9 0 0 1 7.9 43 25.1 25.1 0 0 1 18 8.2c6-3.3 12.6-3 18.7-.6A31 31 0 0 1 51 19c3.2 5 4.5 11 3.2 16.7-1.3 5.7-5 11.3-12.1 15.6Z"
      />
      <path
        fill={bgHsl}
        fillRule="evenodd"
        d="M14.2 30c.4-8.2 7.6-14.5 16-14.2h.2c8.5.4 15.1 7.4 14.7 15.6a15.1 15.1 0 0 1-16 14.1H29A15.1 15.1 0 0 1 14.2 30Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

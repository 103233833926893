import {
  Ban,
  Briefcase,
  Building2,
  CalendarDays,
  Contact,
  FileCheck,
  Gem,
  Globe,
  GraduationCap,
  MapPin,
  Smartphone,
  Store,
  UserCheck,
  UserPlus,
  Users,
} from 'lucide-react';

import type { LucideIcon } from 'lucide-react';

export type SummaryItemType =
  | 'age_range'
  | 'business_ownership'
  | 'device'
  | 'education_level'
  | 'gender'
  | 'industry_and_occupation'
  | 'languages'
  | 'locations'
  | 'marital_status'
  | 'occupational_status'
  | 'participant_selection'
  | 'participant_source'
  | 'participants_excluded_months'
  | 'participants'
  | 'primary_contact'
  | 'custom_terms_title';

interface SummaryItemProps {
  label: string;
  options: (string | number)[] | null | undefined;
  type: SummaryItemType;
  variant?: 'inline' | 'stacked' | 'dash-between';
}

const summaryItemIconByType: Record<SummaryItemType, LucideIcon> = {
  age_range: CalendarDays,
  business_ownership: Store,
  device: Smartphone,
  education_level: GraduationCap,
  gender: Users,
  industry_and_occupation: Building2,
  languages: Globe,
  locations: MapPin,
  marital_status: Gem,
  occupational_status: Briefcase,
  participant_selection: UserCheck,
  participant_source: UserPlus,
  participants_excluded_months: Ban,
  participants: Users,
  primary_contact: Contact,
  custom_terms_title: FileCheck,
};

export const SummaryItem = ({ label, type, options, variant = 'inline' }: SummaryItemProps) => {
  const Icon = summaryItemIconByType[type];

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <li className="flex h-[calc-size(auto)] gap-2 transition-[height] starting:h-0">
      <Icon className="h-4 w-4 text-muted-foreground" />
      <div className="flex flex-col truncate">
        <div className="text-xs text-muted-foreground">{label}</div>

        {variant === 'dash-between' ? `${options[0]} - ${options[1]}` : null}

        {variant === 'inline' ? (
          <div className="w-full whitespace-normal leading-normal">{options.join(', ')}</div>
        ) : null}

        {variant === 'stacked' ? (
          <ul className="flex flex-col">
            {options.map(option => (
              <li
                key={option}
                className="h-[calc-size(auto)] truncate transition-[height] starting:h-0"
                title={String(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </li>
  );
};

import { flexRender } from '@tanstack/react-table';
import { Th, Tr } from 'ui';

import type { TableRowSubmission } from '../../../hooks/useBookingParticipantsTableData';
import type { Header, HeaderGroup } from '@tanstack/react-table';
import type { FC } from 'react';

type Props = {
  group: HeaderGroup<TableRowSubmission>;
};

export const ApplicantTableHeaderRow: FC<Props> = ({ group }) => {
  return (
    <Tr key={group.id} role="group">
      {group.headers.map(header => {
        return <ApplicantTableHeader header={header} key={header.id} />;
      })}
    </Tr>
  );
};

type ApplicantTableHeaderProps = {
  header: Header<TableRowSubmission, unknown>;
};

const ApplicantTableHeader: FC<ApplicantTableHeaderProps> = ({ header }) => {
  const render = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());
  const extra = typeof header.column.getIsPinned() === 'string' ? { left: 0, zIndex: 10 } : {};

  return (
    <Th
      top={0}
      bg="white"
      zIndex="8"
      position="sticky"
      {...extra}
      key={header.id}
      fontSize="sm"
      colSpan={header.colSpan}
      width={header.getSize()}
      color="inherit"
    >
      {render}
    </Th>
  );
};

/**
 * TODO: this should be abstracted out into a Form component with helpers within the
 * UI component library
 */

import { useEffect, useRef } from 'react';
import { type UseFormReturn } from 'react-hook-form';

type MaybeField = { [key: string]: boolean | MaybeField };

type GetDirtyValuesArgs = {
  values: any;
  dirtyFields: MaybeField;
};

function getDirtyValues({ values, dirtyFields }: GetDirtyValuesArgs) {
  return Object.keys(dirtyFields).reduce<Record<string, any>>((all, key) => {
    if (typeof dirtyFields[key] === 'boolean' || Array.isArray(dirtyFields[key])) {
      all[key] = values[key];
    } else {
      all[key] = getDirtyValues({ values: values[key], dirtyFields: dirtyFields[key] });
    }
    return all;
  }, {});
}

type Args<T = any> = {
  form: UseFormReturn<any>;
  handleSubmit: ({ values, dirtyValues }: { values: T; dirtyValues: Partial<T> }) => any;
  preventTrigger?: boolean;
  onlyDirtyValues?: boolean;
};

export function useFormAutoSave<T = any>({ form, handleSubmit, preventTrigger }: Args<T>) {
  const debounceTimer = useRef<NodeJS.Timer>();
  useEffect(() => {
    // We _do_ want immediate validation when `preventTrigger` is false
    if (!preventTrigger) {
      form.trigger();
    }

    const subscription = form.watch(() => {
      if (!preventTrigger) {
        form.trigger();
      }

      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(async () => {
        const values = form.getValues();
        const dirtyValues = getDirtyValues({ values, dirtyFields: form.formState.dirtyFields }) as Partial<T>; // TODO

        if (Object.keys(values).length) {
          await handleSubmit({ values, dirtyValues });
          form.reset(values, {
            keepErrors: true,
            keepIsSubmitted: true,
            keepSubmitCount: true,
            keepValues: true,
          });
        }
      }, 400);
    });
    return () => {
      subscription.unsubscribe();
      clearTimeout(debounceTimer.current);
    };
  }, [form, preventTrigger]);
}

import { Link } from '@askable/ui/core/link';
import { Logo, IconLogo } from '@askable/ui/core/logo';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useMemo } from 'react';

import { useConnectedClient } from 'context/ConnectedClientContext';

export const MinimalHeader = () => {
  const kinde = useKindeAuth();
  const viewer = useConnectedClient();

  const email = useMemo(() => {
    if (kinde.user?.email) {
      return kinde.user?.email;
    }
    if (viewer?.details?.email) {
      return viewer?.details?.email;
    }
    return null;
  }, [kinde.user?.email, viewer?.details?.email]);

  return (
    <div className="left-0 right-0 flex w-full justify-between">
      <IconLogo className="h-9 sm:hidden" />
      <Logo className="hidden h-7 sm:block" />
      <div className="flex-col items-end space-y-1 overflow-hidden">
        {viewer?.authState === 'unauthenticated' ? (
          <>
            <div className="flex space-x-1 text-sm">
              <p className="text-muted-foreground">Signed out</p>
            </div>
            <Link href="/logout" className="text-sm">
              Log in
            </Link>
          </>
        ) : (
          <>
            <div className="flex w-full space-x-1 whitespace-nowrap text-sm">
              {email ? (
                <p className="max-w-full overflow-hidden text-ellipsis text-muted-foreground">
                  Signed in as <span className="font-medium text-foreground">{email}</span>
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
            </div>
            <Link href="/logout" className="text-sm">
              Sign out
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

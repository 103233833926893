import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';

import type { AnalysesInsightsTheme } from 'generated/graphql';

/** Label Group
 * If one label, show the label text with the coloured dot
 * If many labels, show a coloured dot for each label wrap in a tooltip with the label text
 */

type LabelGroupProps = {
  themes: AnalysesInsightsTheme[];
  title: string;
};

const Label = ({ accent }: { accent: string }) => (
  <div
    aria-label={accent}
    className="aspect-square h-2 w-2 rounded-full border border-solid border-background bg-primary [&:not(:first-child)]:-ml-1"
    style={{ backgroundColor: accent }}
  />
);

export const LabelGroup = ({ themes, title }: LabelGroupProps) => {
  return themes.length === 1 ? (
    <div className="flex items-center gap-1 whitespace-nowrap">
      <Label accent={themes[0].accent} /> {themes[0].name}
    </div>
  ) : (
    <Tooltip>
      <TooltipTrigger className="flex items-center gap-1 whitespace-nowrap">
        <div className="flex">
          {themes.map(label => (
            <Label accent={label.accent} key={label.name} />
          ))}
        </div>
        {title}
      </TooltipTrigger>
      <TooltipContent>{themes.map(b => b.name).join(', ')}</TooltipContent>
    </Tooltip>
  );
};

import { Card, CardContent, CardDescription, CardHeader } from '@askable/ui/core/card';
import { cn } from '@askable/ui/lib/utils';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
};
export const CreateTeamCard = ({ className }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/teams/create');
  };

  return (
    <Card
      className={cn('group flex cursor-pointer flex-col justify-between font-medium hover:shadow-md', className)}
      onClick={handleClick}
    >
      <CardHeader>
        <div className="flex h-12 w-12 items-center justify-center rounded-sm bg-secondary text-secondary-foreground group-hover:bg-secondary/80">
          <Plus />
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription>Create a new team</CardDescription>
      </CardContent>
    </Card>
  );
};

import { Avatar, AvatarFallback } from '@askable/ui/core/avatar';
import { cn } from '@askable/ui/lib/utils';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export interface ParticipantItemProps {
  completed?: number;
  id: string;
  isActive?: boolean;
  name: string;
}

export const ParticipantItem = ({ completed, id, isActive = false, name }: ParticipantItemProps) => {
  const initials =
    name
      .split(' ')
      .map(n => n[0])
      .join('') ?? 'U';

  return (
    <Link
      to={{ pathname: `participant/${id}`, hash: 'main' }}
      title={name}
      className={cn(
        'flex w-full items-center gap-2 rounded-xl border-0.5 border-border bg-background p-2 text-sm text-secondary-foreground',
        'hover:border-input focus:border-input active:border-primary active:ring-1 active:ring-primary',
        {
          'border-primary bg-background ring-1 ring-primary hover:border-primary focus:border-primary': isActive,
        },
      )}
    >
      <Avatar>
        <AvatarFallback variant="primary">{initials}</AvatarFallback>
      </Avatar>

      <div className="flex flex-col truncate text-left font-medium">
        <div className="truncate">{name}</div>
        {completed ? (
          <div className="text-xs text-muted-foreground">
            {format(completed, 'd MMM')} &middot; {format(completed, 'h:mmaaa')}
          </div>
        ) : null}
      </div>
    </Link>
  );
};

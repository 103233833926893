/* eslint-disable max-lines */
import { toast } from '@askable/ui/core/sonner';
import { memo } from 'radash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Radio,
  RadioCard,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useRadioGroup,
  VStack,
} from 'ui';
import { useMutation } from 'urql';

import { useBooking } from 'containers/Booking/BookingContainer';
import { ReportIssueDocument, ReportReason, ReportRequest, SubmissionStatus } from 'generated/graphql';

import { useBookingParticipantInviteWaitlistedModalCaller } from '../BookingParticipantInviteWaitlistedModal';

import { BookingSidePanelStepperContainer } from './BookingSidePanelStepperContainer';

import type { OpenInviteWaitlistedModalFunc } from '../BookingParticipantInviteWaitlistedModal';
import type { Maybe, ReportIssueMutation, ReportIssueMutationVariables } from 'generated/graphql';
import type { FC } from 'react';

type RequestOptions = ReportRequest | 'none';

const REPORT_TYPES: { value: ReportReason; title: string }[] = [
  { value: ReportReason.ParticipantNoShow, title: "The participant didn't turn up" },
  { value: ReportReason.TechnicalIssues, title: 'Technical issue' },
  { value: ReportReason.InaccurateScreenerAnswers, title: 'Inaccurate screener answers' },
  { value: ReportReason.CommunicationIssues, title: 'Communication issues' },
  { value: ReportReason.Others, title: 'Other reason' },
];

type StateSection = 'report-request' | 'report-reason' | 'complete';

type StepperConfig = {
  [key in StateSection]: { previous: Maybe<StateSection>; next: Maybe<StateSection> };
};

// Only supports moderated session
const getReportConfig = memo(
  (
    status: SubmissionStatus,
  ): {
    config: StepperConfig;
    options: { value: ReportReason; title: string }[];
  } => {
    switch (status) {
      case SubmissionStatus.Confirmed:
      case SubmissionStatus.Completed:
        return {
          options: REPORT_TYPES,
          config: {
            'report-reason': {
              previous: null,
              next: 'report-request',
            },
            'report-request': {
              previous: 'report-reason',
              next: 'complete',
            },
            complete: {
              previous: 'report-request',
              next: null,
            },
          },
        };
      default: {
        return {
          options: REPORT_TYPES.filter(
            a => a.value === ReportReason.Others || a.value === ReportReason.CommunicationIssues,
          ),
          config: {
            'report-reason': {
              next: 'complete',
              previous: null,
            },
            'report-request': {
              next: null,
              previous: null,
            },
            complete: {
              previous: null,
              next: null,
            },
          },
        };
      }
    }
  },
);

type FormState = {
  reason: ReportReason;
  request: RequestOptions;
  otherReason: string;
};

export type BookingParticipantReportProps = {
  userId: string;
  bookingId: string;
  submissionId: string;
  onClose: () => void;
  displayName: string;
  submissionStatus: SubmissionStatus;
  openInviteWaitlistedModal: OpenInviteWaitlistedModalFunc;
  sessionId?: string | null;
  sessionStartTime?: number | null;
  isModeratedBooking: boolean;
};

export const BookingParticipantsReport: FC<BookingParticipantReportProps> = ({
  userId,
  onClose,
  displayName,
  submissionStatus,
  submissionId,
  bookingId,
  openInviteWaitlistedModal,
  sessionId,
  sessionStartTime,
  isModeratedBooking,
}) => {
  const [section, setSection] = useState<StateSection>('report-reason');
  const booking = useBooking();

  const [, reportIssue] = useMutation<ReportIssueMutation, ReportIssueMutationVariables>(ReportIssueDocument);

  const { canInviteWaitlisted, waitlistedSubmissionsWithSameSession } =
    useBookingParticipantInviteWaitlistedModalCaller({
      reviewSubmission: !!booking?.config?.options?.review_submission,
      bookingId,
      sessionId,
      submissionStatus,
      isModeratedBooking,
    });

  useEffect(() => {
    // The panel stays open when selecting a different participant so we need to reset the state here
    setSection('report-reason');
    reset();
  }, [userId]);

  const config = useMemo(() => {
    return getReportConfig(submissionStatus);
  }, [submissionStatus]);

  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm<FormState>({
    defaultValues: {
      reason: config.options[0].value,
      request:
        submissionStatus === SubmissionStatus.Completed || submissionStatus === SubmissionStatus.Confirmed
          ? ReportRequest.Replace
          : 'none',
    },
  });

  const stepper = useMemo(() => {
    return config.config[section];
  }, [section]);

  const requestWatch = watch('request');

  const handleBackClick = () => {
    if (stepper.previous === null) {
      return;
    }
    setSection(stepper.previous);
  };

  const onSubmit = async (fv: FormState) => {
    try {
      const { error } = await reportIssue({
        bookingId,
        bookingSubmissionId: submissionId,
        reportReason: fv.reason,
        reportRequest: fv.request === 'none' ? undefined : fv.request,
        reportDetails: fv.otherReason,
      });
      if (error) {
        throw error;
      }

      if (canInviteWaitlisted) {
        // When trigger invite waitlisted modal, we don't want to keep the report panel open
        openInviteWaitlistedModal({
          sessionId,
          sessionStartTime,
          submissionId,
          waitlistedSubmissionsWithSameSession,
        });
        onClose();
        return;
      }

      if (stepper?.next) {
        setSection(stepper?.next);
      }
    } catch {
      toast.error('Failed to report issue. Please try again later');
    }
  };

  const handleNextClick = async () => {
    if (stepper.next === null) {
      return;
    }

    if (section === 'report-reason') {
      const result = await trigger();
      if (!result) {
        return;
      }
    }

    if (stepper.next === 'complete') {
      handleSubmit(onSubmit)();
      return;
    }

    setSection(stepper.next);
  };

  const footer = useMemo(() => {
    const isConfirm = stepper.next === 'complete';

    const text = (() => {
      if (isConfirm) {
        return 'Confirm';
      }

      return 'Next';
    })();

    return (
      <Button size="lg" w="full" colorScheme="blue" onClick={handleNextClick}>
        {text}
      </Button>
    );
  }, [section, requestWatch]);

  const isCompleted = section === 'complete';

  return (
    <BookingSidePanelStepperContainer
      title={!isCompleted ? 'Report issue' : ''}
      support={!isCompleted ? `With ${displayName}` : ''}
      onCancelClick={onClose}
      onBackClick={stepper.previous && !isCompleted ? handleBackClick : null}
      footer={!isCompleted && footer}
      cancelButton={isCompleted ? <CloseButton onClick={onClose} /> : null}
    >
      <Flex flexDirection="column" w="full">
        <Controller
          control={control}
          name="reason"
          render={({ field }) => {
            if (section !== 'report-reason') {
              return <Fragment />;
            }

            return (
              <RadioGroup onChange={field.onChange} value={field.value}>
                <Flex direction="column">
                  <Text mb="2">What was the issue?</Text>
                  <Stack spacing={2}>
                    {config.options.map(type => {
                      return (
                        <Radio value={type.value} key={type.value}>
                          {type.title}
                        </Radio>
                      );
                    })}
                  </Stack>
                  <FormControl isInvalid={!!errors.otherReason}>
                    <Flex direction="column" mt="6">
                      <Text mb="2">Additional information</Text>
                      <Textarea minH="40" autoFocus {...register('otherReason', { required: true })} />
                      <FormErrorMessage>
                        {errors.otherReason &&
                          errors.otherReason.type === 'required' &&
                          'Additional information is required'}
                      </FormErrorMessage>
                    </Flex>
                  </FormControl>
                </Flex>
              </RadioGroup>
            );
          }}
        />

        <Controller
          name="request"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            if (section !== 'report-request') {
              return <React.Fragment />;
            }

            return <RequestOptionsField onChange={field.onChange} value={field.value} />;
          }}
        />

        {section === 'complete' && <ReportCompleted request={requestWatch} />}
      </Flex>
    </BookingSidePanelStepperContainer>
  );
};

const REQUEST_OPTIONS: { title: string; body: string; value: RequestOptions }[] = [
  {
    title: 'Replace participant',
    body: "We'll add a spot to your study so you can invite a different participant.",
    value: ReportRequest.Replace,
  },
  {
    title: 'Credit refund',
    body: "We'll refund credits for this participant back into your team's account.",
    value: ReportRequest.Refund,
  },
];

type RequestOptionsProps = {
  value: string;
  onChange: () => void;
};

const RequestOptionsField: FC<RequestOptionsProps> = ({ value, onChange }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange,
  });

  const group = getRootProps();

  return (
    <VStack {...group} spacing={4} alignItems="flex-start">
      <Text>Choose one of the options below:</Text>
      {REQUEST_OPTIONS.map(request => {
        const radio = getRadioProps({ value: request.value });
        return (
          <RadioCard title={request.title} key={request.value} {...radio}>
            {request.body}
          </RadioCard>
        );
      })}
    </VStack>
  );
};

type ReportCompletedProps = {
  request: RequestOptions;
};

const REPORT_COMPLETED: {
  [key in ReportCompletedProps['request']]: {
    title: string;
    body: string;
    image: string;
  };
} = {
  refund: {
    title: 'Credit refunded',
    body: "We've added the credits back in to your account",
    image: '/report-confirmation-credit.svg',
  },
  replace: {
    title: "We've opened up a new session",
    body: 'You can now invite another participant as a replacement',
    image: '/report-confirmation-generic.svg',
  },
  none: {
    title: 'We appreciate your feedback',
    body: 'It helps keep our participant panel accurate and honest',
    image: '/report-confirmation-generic.svg',
  },
};

const ReportCompleted: FC<ReportCompletedProps> = ({ request }) => {
  const { body, title, image } = REPORT_COMPLETED[request ?? 'generic'];

  return (
    <Flex flexDir="column" alignItems="center" whiteSpace="normal" textAlign="center">
      <Image mb="6" src={image} alt={image} w="40" />
      <Heading my="4" size="md">
        {title}
      </Heading>
      <Text>{body}</Text>
    </Flex>
  );
};

import { Button } from '@askable/ui/core/button';
import { toast } from '@askable/ui/core/sonner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@askable/ui/core/tooltip';
import { Download } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadChart } from 'containers/Studies/utils/downloadChart';

interface DownloadChartProps {
  chartRef: React.RefObject<HTMLDivElement>;
  title: string;
  studyName: string;
}

export const DownloadChart = ({ chartRef, title, studyName }: DownloadChartProps) => {
  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);

  const handleDownload = async () => {
    if (chartRef.current) {
      setIsExporting(true);
      const filename = `chart_${title.replace(/ /g, '-').toLowerCase()}_${studyName?.replace(/ /g, '-').toLowerCase()}`;

      try {
        await downloadChart(chartRef.current, filename);
      } catch (e) {
        toast.error(t('sections.errorBoundary.downloadFailed'));
        console.error(e);
      } finally {
        setIsExporting(false);
      }
    }
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          aria-label={t('sections.studies.results.downloadChart')}
          isLoading={isExporting}
          onClick={handleDownload}
          size="icon"
          variant="outline"
        >
          <Download className="h-4 w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('sections.studies.results.downloadChart')} (.png)</TooltipContent>
    </Tooltip>
  );
};

import { format } from 'date-fns';
import * as htmlToImage from 'html-to-image';

const PADDING = 12;
const SCALE = 2;

/**
 * Downloads a chart as a PNG image and returns a promise that resolves when complete
 * @param element The HTML element to capture
 * @param filename Base filename for the downloaded image
 * @returns Promise that resolves when the download is complete or rejects on error
 */
export const downloadChart = (element: HTMLElement, filename: string = 'chart'): Promise<void> => {
  if (!element) {
    return Promise.reject(new Error('No element provided'));
  }

  const printOnlyElements = element.querySelectorAll('[data-print-only="true"]');
  printOnlyElements.forEach(el => {
    (el as HTMLElement).style.display = 'flex';
  });

  return htmlToImage
    .toPng(element, {
      width: element.offsetWidth + PADDING * 2,
      height: element.offsetHeight + PADDING * 2,
      canvasHeight: (element.offsetHeight + PADDING * 2) * SCALE,
      canvasWidth: (element.offsetWidth + PADDING * 2) * SCALE,
      backgroundColor: '#ffffff',
      style: { padding: `${PADDING}px` },
    })
    .then(dataUrl => {
      printOnlyElements.forEach(el => {
        (el as HTMLElement).style.display = 'none';
      });

      const link = document.createElement('a');
      link.download = `${filename}_${format(new Date(), 'yyyy-MM-dd_HH-mm')}.png`;
      link.href = dataUrl;
      link.click();
    })
    .catch(error => {
      printOnlyElements.forEach(el => {
        (el as HTMLElement).style.display = 'none';
      });
      return Promise.reject(error);
    });
};

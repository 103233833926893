import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@askable/ui/core/dropdown-menu';
import produce from 'immer';
import { ChevronDown } from 'lucide-react';
import { capitalize } from 'radash';
import { Fragment, useMemo } from 'react';

import { SubmissionStatus } from 'generated/graphql';

import type {
  BookingParticipantSinglePanelType,
  BookingParticipantStateAndActions,
} from '../state/booking-participant-state';

export type Props = {
  isAskableSessions: boolean;
  isAutomated: boolean;
  isBookingComplete?: boolean;
  isModerated: boolean;
  sessionId?: string | null;
  submissionId: string;
  submissionStatus: SubmissionStatus;
  handleSetPanelState: BookingParticipantStateAndActions['setPanelState'];
  onMarkAsCompleteMenuItemClick: (submissionId: string) => void;
};

export type AvailableModStatuses = Exclude<
  SubmissionStatus,
  | SubmissionStatus.InProgress
  | SubmissionStatus.PendingCheck
  | SubmissionStatus.HelpRequested
  | SubmissionStatus.TimeExpired
  | SubmissionStatus.PendingTechCheck
>;

export type AvailableUnmodStatuses = Exclude<
  SubmissionStatus,
  | SubmissionStatus.PendingTechCheck
  | SubmissionStatus.Confirmed
  | SubmissionStatus.InviteDeclined
  | SubmissionStatus.ParticipantCancelled
>;

type MenuItems = BookingParticipantSinglePanelType | 'mark-as-complete';

const availableMenuItemsOne: MenuItems[] = ['invite', 'message'];
const availableMenuItemsTwo: MenuItems[] = ['report-issue'];

// Actions that require a participant session. Hide them if no session
const sessionRequired: MenuItems[] = ['cancel', 'report-issue', 'resend-invite', 'session-joining-links'];

/**
 * Completed booking menu items
 */
const completed = [{ id: 'complete', items: ['message'] as MenuItems[] }];
const completedMenuItems: Record<AvailableModStatuses | AvailableUnmodStatuses, { id: string; items: MenuItems[] }[]> =
  {
    available: completed,
    invited: completed,
    completed,
    confirmed: completed,
    waitlisted: completed,
    invite_declined: completed,
    participant_cancelled: completed,
    reported: completed,
    in_progress: completed,
    help_requested: completed,
    pending_check: completed,
    time_expired: completed,
    [SubmissionStatus.Excluded]: [],
  };

/**
 * Unmoderated menu items
 */
const automatedUnmoderatedMenuItems: Record<AvailableUnmodStatuses, { id: string; items: MenuItems[] }[]> = {
  available: [
    { id: 'available-one', items: ['message'] },
    { id: 'available-two', items: ['report-issue'] },
  ],
  invited: [
    { id: 'invited-one', items: ['message'] },
    { id: 'invited-two', items: ['cancel', 'report-issue'] },
  ],
  waitlisted: [
    { id: 'waitlisted-one', items: ['resend-invite', 'message'] },
    { id: 'waitlisted-two', items: ['cancel', 'report-issue'] },
  ],
  help_requested: [
    {
      id: 'help-requested-one',
      items: ['mark-as-complete', 'message'],
    },
    {
      id: 'help-requested-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  in_progress: [
    {
      id: 'in-progress-one',
      items: ['mark-as-complete', 'message'],
    },
    {
      id: 'in-progress-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  pending_check: [
    {
      id: 'pending-check-one',
      items: ['mark-as-complete', 'cancel', 'message'],
    },
    {
      id: 'pending-check-two',
      items: ['report-issue'],
    },
  ],
  completed: [
    { id: 'completed-one', items: ['message'] },
    { id: 'completed-two', items: ['report-issue'] },
  ],
  reported: [{ id: 'reported-one', items: ['message'] }],
  time_expired: [
    { id: 'time-expired-one', items: ['mark-as-complete', 'message'] },
    { id: 'time-expired-two', items: ['report-issue'] },
  ],
  [SubmissionStatus.Excluded]: [],
};

const handpickedUnmoderatedMenu = produce(automatedUnmoderatedMenuItems, draft => {
  draft.available[0].items.unshift('invite');

  draft.waitlisted[0].items = ['resend-invite', 'message'];

  draft.invited[0].items = ['resend-invite', 'mark-as-complete', 'message'];
  draft.invited[1].items = ['cancel', 'report-issue'];

  draft.time_expired[0].items = ['resend-invite', 'mark-as-complete', 'message'];
});

/**
 * Moderated menu items
 */
const moderatedMenuItems: Record<AvailableModStatuses, { id: string; items: MenuItems[] }[]> = {
  available: [
    { id: 'available-one', items: availableMenuItemsOne },
    { id: 'available-two', items: availableMenuItemsTwo },
  ],
  invited: [
    { id: 'invited-one', items: ['resend-invite', 'reschedule', 'message'] },
    { id: 'invited-two', items: ['cancel', 'report-issue'] },
  ],
  completed: [{ id: 'completed-2', items: ['report-issue'] }],
  confirmed: [
    {
      id: 'confirmed-one',
      items: ['session-joining-links', 'message', 'reschedule', 'mark-as-complete'],
    },
    {
      id: 'confirmed-two',
      items: ['cancel', 'report-issue'],
    },
  ],
  waitlisted: [
    { id: 'waitlisted-one', items: ['reschedule', 'resend-invite', 'message'] },
    { id: 'waitlisted-two', items: ['cancel', 'report-issue'] },
  ],
  // InviteDeclined and ParticipantCancelled should behave like Available
  invite_declined: [
    { id: 'invite-declined-one', items: availableMenuItemsOne },
    { id: 'invite-declined-two', items: availableMenuItemsTwo },
  ],
  participant_cancelled: [
    { id: 'participant-cancelled-one', items: availableMenuItemsOne },
    { id: 'participant-cancelled-two', items: availableMenuItemsTwo },
  ],
  reported: [{ id: 'reported', items: ['invite', 'message'] }],
  [SubmissionStatus.Excluded]: [],
};

export const BookingParticipantMenu = ({
  isAskableSessions,
  isAutomated,
  isBookingComplete,
  isModerated,
  sessionId,
  submissionId,
  submissionStatus,
  handleSetPanelState,
  onMarkAsCompleteMenuItemClick,
}: Props) => {
  const actions = useMemo(() => {
    if (isBookingComplete) {
      return completedMenuItems;
    }

    if (!isModerated) {
      if (isAutomated) {
        return automatedUnmoderatedMenuItems;
      }

      return handpickedUnmoderatedMenu;
    }

    return moderatedMenuItems;
  }, [isBookingComplete, isModerated, isAutomated]);

  const filteredActions = useMemo(() => {
    const currentStatus = submissionStatus as AvailableModStatuses & AvailableUnmodStatuses;
    const menuItems = actions[currentStatus];

    if (!menuItems) return null;

    if (!sessionId) {
      // Filter out items that require a session
      return menuItems
        .map(container => ({
          ...container,
          items: container.items.filter(item => !sessionRequired.includes(item)),
        }))
        .filter(container => container.items.length > 0); // Remove empty containers
    }

    return menuItems;
  }, [actions, submissionStatus, sessionId]);

  const handleClick = (action: MenuItems) => {
    if (action === 'mark-as-complete') {
      onMarkAsCompleteMenuItemClick(submissionId);
      return;
    }

    handleSetPanelState({
      type: 'single',
      panel: action,
      submissionId,
    });
  };

  if (!filteredActions) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="icon" aria-label="Actions" data-testid="menu-button">
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {filteredActions?.map((container, index) => {
          return (
            <Fragment key={container.id}>
              {container.items.map(action => {
                if (!isAskableSessions && action === 'session-joining-links') {
                  return null;
                }

                const itemTitle = capitalize(action.split('-').join(' '));

                return (
                  <DropdownMenuItem
                    data-testid={itemTitle}
                    key={`${submissionId}-${action}`}
                    onClick={event => {
                      event.stopPropagation();
                      handleClick(action);
                    }}
                  >
                    {itemTitle}
                  </DropdownMenuItem>
                );
              })}
              {filteredActions?.[index + 1]?.id && <DropdownMenuSeparator />}
            </Fragment>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { Button } from '@askable/ui/core/button';
import { deprecatedWithRouter } from 'HOC/deprecatedWithRouter';

import './styles/projectCompletedScreenStyles.scss';

function ProjectCompletedScreen(props: any) {
  const onClickDone = () => {
    props.history.push({ pathname: '/' });
  };

  const renderContentPage = () => {
    return (
      <div className="askablePlusProjectCompletedScreenContent">
        <img className="confirmationImage" src="/askablePlus/illustrations/personOk.png" alt="personOk" />
        <span className="confirmationTitle font--title1">Nice work!</span>
        <span className="confirmationText">
          Your study is complete. You should receive your payment within the next 21 days
        </span>
        <div className="buttonContainer">
          <Button variant="primary" size="lg" onClick={onClickDone}>
            Back to Dashboard
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="askablePlusProjectCompletedScreenContainer">
      <div className="askablePlusProjectCompletedScreenContentContainer">{renderContentPage()}</div>
    </div>
  );
}

export default deprecatedWithRouter(ProjectCompletedScreen);

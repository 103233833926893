import { Skeleton } from '@askable/ui/core/skeleton';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'urql';

import { SummaryItem } from 'containers/Studies/Recruit/components/Summary/SummaryItem';
import { UsersByTeamId } from 'containers/Studies/Recruit/data/UsersByTeamId.query';
import { useConnectedClient } from 'context/ConnectedClientContext';

import type { User } from 'containers/Studies/Recruit/data/UsersByTeamId.query';

export const SummaryItemPrimaryContact = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  const { details } = useConnectedClient();

  const teamId = details?.ConnectedTeam?._id;

  // @todo fetch the user info by their ID instead
  const [{ data, fetching, error }] = useQuery({
    query: UsersByTeamId,
    pause: !teamId,
    variables: { id: teamId },
  });

  const formatUserName = (user: User): string => {
    const firstName = user.User?.meta?.identity?.firstname ?? '';
    const lastName = user.User?.meta?.identity?.lastname ?? '';
    return `${firstName} ${lastName}`.trim() || t('global.unknownUser');
  };

  const getUserById = (users: (User | null)[]) => {
    if (!users) {
      return null;
    }

    const user = users?.find(user => user?._id === userId);
    return user ? [formatUserName(user)] : [`${t('global.unknownUser')} (ID: ${userId})`];
  };

  return (
    <>
      {fetching && !data?.teamById ? <Skeleton className="h-6 w-32" /> : null}

      {!fetching && data?.teamById?.users ? (
        <SummaryItem
          label={t('sections.studies.recruit.fields.primaryContactTitle')}
          type="primary_contact"
          options={error ? [`ID: ${userId}`] : data.teamById.users ? getUserById(data.teamById.users) : null}
        />
      ) : null}
    </>
  );
};

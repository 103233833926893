import { TeamSwitcher } from '@askable/ui/components/team-switcher';
import { Avatar, AvatarFallback } from '@askable/ui/core/avatar';
import { Button } from '@askable/ui/core/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@askable/ui/core/dropdown-menu';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSwitchTeam } from 'src/hooks/useSwitchTeam';

import { useConnectedClient } from 'context/ConnectedClientContext';

export const DiscoverHeader = () => {
  const { details: clientDetails, actions } = useConnectedClient();
  const { switchTeam } = useSwitchTeam({ updateClientDetails: actions.updateClientDetails });
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();

  const onSignOut = () => {
    navigate('/logout', { replace: true });
  };

  return (
    <header
      className="sticky top-0 z-10 flex items-center justify-between border-b border-solid border-border bg-background/90 p-2
        backdrop-blur-sm sm:p-3"
    >
      <h2 className="pl-1">
        <svg height="24" fill="none" viewBox="0 0 512 116" className="h-5 sm:h-6">
          <title>Askable</title>
          <g fill="#DC3C4C">
            <path
              fillRule="evenodd"
              d="M48.78 0c37.8.06 48.68 10.86 48.61 48.27-.02 15.07-1.81 25.82-6.41 33.27-10.02 20.95-32.28 30.95-42.4 33.34l.04-18.5C10.8 96.3-.06 85.5 0 48.1.06 10.7 10.97-.06 48.78 0Zm-.2 40a9.7 9.7 0 0 1-9.75-9.66 9.7 9.7 0 0 1 9.76-9.64 9.7 9.7 0 0 1 9.75 9.65 9.7 9.7 0 0 1-9.76 9.64Zm0 6.62c8.08 0 21.13 1.67 21.12 9.66 0 7.99-8.72 17.68-21.13 17.67-12.42 0-21.13-9.7-21.13-17.7 0-7.98 13.06-9.63 21.14-9.63Z"
              clipRule="evenodd"
            />
            <path d="M121.02 60.35c0-18.8 13.6-28.49 26.07-28.49a21.25 21.25 0 0 1 15.92 6.2l1.54-4.88h15.43v54.31H165.3l-2.3-5.32a20.67 20.67 0 0 1-16.66 6.6c-13.47 0-25.32-11.05-25.32-28.42Zm42.62 0c0-8.97-5.7-14.26-12.93-14.26-7.23 0-12.93 5.53-12.93 14.26s5.6 14.23 12.93 14.23 12.93-5.47 12.93-14.23ZM188.75 74.43l12.5-5.65a11.12 11.12 0 0 0 10.19 7.3c4.17 0 6.04-2.07 6.04-4.34s-1.87-4.16-6.57-5.98l-6.82-2.42a16.27 16.27 0 0 1-8.86-5.97 16.05 16.05 0 0 1-3.2-10.14c0-8.97 8.11-15.43 18.84-15.43 9.32 0 15.08 3.6 18.3 10.1l-11.7 6.5a6.36 6.36 0 0 0-6.02-4.73c-2.41 0-4.04 1.32-4.04 3.29s1.08 2.99 3.62 3.89l7.44 2.72c8.56 3.16 14.14 8.27 14.14 17.93 0 9.65-8.44 17.28-21.8 17.28-11.87 0-19.2-5.74-22.06-14.35ZM240.26 9.77h16.67v41.31l15.44-17.93h20.16l-23.12 24.87 24.57 29.47h-21.1l-16-20.32v20.32h-16.62V9.77Z" />
            <path d="M291.99 60.35c0-18.8 13.6-28.49 26.1-28.49a21.26 21.26 0 0 1 15.89 6.2l1.53-4.88h15.44v54.31h-14.69l-2.28-5.32a20.65 20.65 0 0 1-16.67 6.6c-13.48 0-25.32-11.05-25.32-28.42Zm42.65 0c0-8.97-5.7-14.26-12.93-14.26-7.24 0-12.93 5.53-12.93 14.26s5.57 14.23 12.93 14.23c7.35 0 12.93-5.47 12.93-14.23ZM378.25 81.28l-1.86 5.97h-13.93V9.54h16.67v29.11A22.1 22.1 0 0 1 396 31.6c12.72 0 25.65 8.96 25.65 28.48 0 18.27-13.05 28.46-26.52 28.46a20.18 20.18 0 0 1-16.88-7.27Zm26.62-20.93c0-8.6-5.57-14.14-13.02-14.14-7.45 0-13.05 5.41-13.05 14.14s5.7 14.11 12.93 14.11a13.27 13.27 0 0 0 9.69-4.2 13.01 13.01 0 0 0 3.45-9.9ZM428.23 9.77h16.67V87.5h-16.67V9.77ZM483.9 88.78c-16.66 0-28.18-11.4-28.18-28.46 0-17.07 11.42-28.49 28.06-28.49 19.3 0 28.07 13.28 28.07 26.64 0 2-.22 4.01-.67 5.98h-38.8a11.4 11.4 0 0 0 4.06 7.57 11.57 11.57 0 0 0 8.22 2.65 12.29 12.29 0 0 0 11.42-6.3l13.78 6.72c-4.8 8.7-13.48 13.69-25.96 13.69Zm-11.18-35.3h22.07a10.38 10.38 0 0 0-3.72-6.62 10.52 10.52 0 0 0-7.25-2.35 10.7 10.7 0 0 0-7.3 2.36 10.53 10.53 0 0 0-3.8 6.6Z" />
          </g>
        </svg>
      </h2>

      <div className="flex gap-2">
        <Button
          className="hidden aspect-square rounded-full"
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        >
          {theme === 'dark' ? <Moon className="h-4 w-4" /> : <Sun className="h-4 w-4" />}
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="rounded-full p-0">
              <Avatar>
                <AvatarFallback>
                  {clientDetails?.name?.firstname ? clientDetails.name.firstname.slice(0, 1) : 'U'}
                </AvatarFallback>
              </Avatar>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mr-2 mt-1 w-56" side="bottom" align="end">
            <DropdownMenuLabel>{clientDetails?.ConnectedTeam?.name}</DropdownMenuLabel>
            <TeamSwitcher
              handleSwitchTeams={switchTeam}
              activeOrgId={clientDetails?.ConnectedTeam?._id}
              teams={clientDetails?.Teams as any}
              hideSignIntoAnotherTeam
            />
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={onSignOut}>{t('global.signOut')}</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
};

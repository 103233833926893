import { toast } from '@askable/ui/core/sonner';
import { useEffect, useState } from 'react';
import { useSubscription } from 'urql';

import { ParticipantSubmissionsDocument } from 'generated/graphql';

import type {
  ParticipantSubmissionsSubscription,
  ParticipantSubmissionsSubscriptionVariables,
} from 'generated/graphql';

type BookingParticipantsNewInformationPopupProps = {
  viewerId: string;
  bookingId: string;
  onRefresh: () => void;
};

export const BookingParticipantsNewInformationPopup = ({
  bookingId,
  viewerId,
  onRefresh,
}: BookingParticipantsNewInformationPopupProps) => {
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [{ data }] = useSubscription<
    ParticipantSubmissionsSubscription,
    number,
    ParticipantSubmissionsSubscriptionVariables
  >(
    {
      query: ParticipantSubmissionsDocument,
      variables: {
        bookingId,
      },
    },
    (acc = 0, curr) => {
      if (curr.submissionsByBooking?.meta?.triggeredBy === viewerId) {
        return acc;
      }

      return acc + 1;
    },
  );

  useEffect(() => {
    // When new data is received, if a toast is already visible, don't show another one
    if (data && !isToastVisible) {
      setIsToastVisible(true);
      toast.dismiss();

      toast.warning('Study updates available', {
        duration: Infinity,
        position: 'bottom-left',
        action: {
          label: 'Refresh',
          onClick: () => {
            onRefresh();
            setIsToastVisible(false);
          },
        },
        cancel: {
          label: 'Dismiss',
          onClick: () => setIsToastVisible(false),
        },
        onAutoClose: () => setIsToastVisible(false),
        onDismiss: () => setIsToastVisible(false),
      });
    }
  }, [data]);

  return null;
};

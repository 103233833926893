/* eslint-disable max-lines */
import { toast } from '@askable/ui/core/sonner';
import _ from 'lodash';
import { ArrowDownToLine, ArrowRight, Calendar } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useIntercom } from 'react-use-intercom';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Stat,
  StatHelpText,
  StatNumber,
  Text,
  VStack,
  Wrap,
} from 'ui';

import SyncCalendarModal from 'components/manageBooking/common/syncCalendarModal';
import { bookingUtils } from 'lib/booking';
import { cherryPickingUtils } from 'lib/cherryPicking';
import { BOOKING_STATUS } from 'lib/constants';
import { csv } from 'lib/csv';
import { utils } from 'lib/utils';
import { useIsNewPssBooking } from 'utils/booking-utils';

import type { FC } from 'react';
import type { BoxProps } from 'ui';

import './styles/cherryPickingRecruitmentStyles.scss';

type Props = {
  booking: any;
  bookingSubmissions?: any;
  industries?: any;
  hiddenColumns?: any;
  filters?: any;
  sortedBy?: any;
  hiddenParticipants?: any;
  shortlistedParticipants?: any;
  showHiddenParticipants?: any;
  showShortlistedParticipants?: any;
  contentContainerProps?: BoxProps;
  onClose?: () => void;
};

const CherryPickingRecruitment: FC<Props> = props => {
  const intercom = useIntercom();
  const {
    booking,
    bookingSubmissions,
    industries,
    hiddenColumns,
    filters,
    sortedBy,
    hiddenParticipants,
    shortlistedParticipants,
    showHiddenParticipants,
    showShortlistedParticipants,
  } = props;

  const newPssBooking = useIsNewPssBooking(booking.created);

  console.log('booking', booking);

  const allTableColumns = cherryPickingUtils.inviteApplicantsColumns(booking, industries);
  const tableColumns = _.filter(allTableColumns, (item: any) => !hiddenColumns.includes(item.id));
  const tableData = cherryPickingUtils.getApplicantsData(booking, bookingSubmissions, industries);

  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [orderedAndFiltered, setOrderedAndFiltered] = useState([]);
  const [openSyncCalendarModal, setOpenSyncCalendarModal] = useState(false);

  useEffect(() => {
    // Filters the results array based on the selected filters
    const orderedFiltered = cherryPickingUtils.defaultFiltersAndSorting(
      booking,
      tableData,
      filters,
      sortedBy,
      hiddenParticipants,
      shortlistedParticipants,
      showShortlistedParticipants,
      showHiddenParticipants,
    );
    setOrderedAndFiltered(orderedFiltered);
  }, [
    booking,
    bookingSubmissions,
    sortedBy,
    filters,
    hiddenParticipants,
    shortlistedParticipants,
    showShortlistedParticipants,
    showHiddenParticipants,
  ]);

  const onCopyShareLink = (bookingLink: any) => {
    utils.copy(bookingLink, () => {
      setIsLinkCopied(true);

      setTimeout(() => {
        setIsLinkCopied(false);
      }, 2000);
      toast.info('Link copied to your clipboard');
    });
  };

  const renderByoRecruitment = () => {
    if (!bookingUtils.isBYO(booking)) return null;

    const bookingLink = _.get(booking, 'config.recruitment.ClientShareLink.short_url', '');

    return (
      <VStack borderWidth="1px" p="6" rounded="md" w="full" spacing={4} alignItems="flex-start">
        <Heading fontSize="lg">Recruit participants by sharing a link</Heading>
        <FormControl>
          <Input id="shareLink" value={bookingLink} readOnly disabled />
          <Flex alignItems="center" justifyContent="space-between" mt="2">
            <Text fontSize="sm">{_.get(booking, 'config.recruitment.ClientShareLink.hits', '0')} link views</Text>
            <Button variant="link" size="sm" onClick={() => onCopyShareLink(bookingLink)}>
              {isLinkCopied ? 'Copied!' : 'Copy link'}
            </Button>
          </Flex>
        </FormControl>
        <Text>
          Applicants will not <strong>see or receive</strong> notifications for other opportunities unless they opt in.
        </Text>
        <VStack w="full" alignItems="flex-start">
          <Text fontWeight="semibold">Need help finding participants?</Text>
          <Text>
            Recruit from Askable’s panel instead.
            <br />
            We can help you find and recruit the remaining participants from Askable’s panel.{' '}
            <Button variant="link" onClick={() => intercom.showNewMessages()}>
              Chat
            </Button>{' '}
            to our friendly team today.
          </Text>
        </VStack>
      </VStack>
    );
  };

  const renderRecruitmentStats = () => {
    const totalApplicants = _.get(booking, 'ParticipantSessionsCount.total', 0);
    const eligibleApplicants = _.get(booking, 'ParticipantSessionsCount.total_eligible', 0);

    const enoughParticipants = Math.round(booking.total_participants * 1.25 + 30);
    const recruitmentActive = bookingUtils.isCompleted(booking) ? false : enoughParticipants >= eligibleApplicants;

    return (
      <Box borderWidth="1px" p="6" rounded="md" w="full">
        <HStack justifyContent="space-between" alignItems="center" mb="4">
          <Heading fontSize="lg">Recruitment stats</Heading>
          <Text fontSize="sm" className={`contentStatus ${recruitmentActive ? 'active' : ''}`}>
            {recruitmentActive ? 'Actively recruiting' : 'Recruitment completed'}
          </Text>
        </HStack>

        <Wrap>
          <Stat>
            <StatNumber>{totalApplicants}</StatNumber>
            <StatHelpText>{utils.pluralize('Applicant', totalApplicants)}</StatHelpText>
          </Stat>
          <Stat>
            <StatNumber>{_.get(booking, 'total_participants')}</StatNumber>
            <StatHelpText>Your quota</StatHelpText>
          </Stat>
          <Stat>
            <StatNumber>{eligibleApplicants ?? 0}</StatNumber>
            <StatHelpText>Eligible {utils.pluralize('applicant', eligibleApplicants)}</StatHelpText>
          </Stat>
        </Wrap>
        {!bookingUtils.isCompleted(booking) && (
          <Alert status="warning" mt="4">
            {recruitmentActive && (
              <AlertDescription className="recruitmentInto">
                Recruitment will automatically stop at {enoughParticipants} eligible applicants
              </AlertDescription>
            )}
            {!recruitmentActive && (
              <AlertDescription>
                Recruitment completed at {enoughParticipants} eligible applicants. Need more?{' '}
                <Button
                  variant="link"
                  onClick={() => {
                    intercom.showNewMessages(
                      `Hi! I want to buy a recruitment boost for my study.\n\nStudy: ${_.get(booking, '_id')})\n\nComments: `,
                    );
                  }}
                >
                  Chat to us
                </Button>
              </AlertDescription>
            )}
          </Alert>
        )}
      </Box>
    );
  };

  const renderProjectProgress = () => {
    const refundedParticipants = _.get(booking, 'ParticipantSessionsCount.total_refunded') || 0;
    const completedConfirmed =
      (_.get(booking, 'ParticipantSessionsCount.completed', 0) || 0) +
      (_.get(booking, 'ParticipantSessionsCount.scheduled', 0) || 0);
    const completed = _.get(booking, 'ParticipantSessionsCount.completed', 0) || 0;
    const confirmed = _.get(booking, 'ParticipantSessionsCount.scheduled', 0) || 0;
    const lastSession = _.max(
      _.map(_.get(booking, 'session'), (session: any) => {
        return session.end;
      }),
    );
    const bookingFinishDate = [BOOKING_STATUS.COMPLETED, BOOKING_STATUS.ARCHIVED].includes(booking.status)
      ? booking?.history?.completed_date || lastSession
      : lastSession;
    const daysLeft = moment(bookingFinishDate).diff(moment(), 'days');
    let daysLeftLabel = `${daysLeft} ${utils.pluralize('day', daysLeft)} left`;
    if (daysLeft <= 0) {
      daysLeftLabel = `Closed on ${moment(bookingFinishDate).format('Do MMM YYYY')}`;
    }

    return (
      <Box borderWidth="1px" p="6" rounded="md" w="full">
        <HStack w="full" justifyContent="space-between" mb="4">
          <Heading fontSize="lg">Study progress</Heading>
          <Text fontSize="sm">{daysLeftLabel}</Text>
        </HStack>
        <Wrap>
          <Stat>
            <StatNumber>{completed}</StatNumber>
            <StatHelpText>Completed</StatHelpText>
          </Stat>
          {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
            <Stat>
              <StatNumber>{confirmed}</StatNumber>
              <StatHelpText>Confirmed</StatHelpText>
            </Stat>
          )}
          <Stat>
            <StatNumber>{_.get(booking, 'total_participants') - completedConfirmed - refundedParticipants}</StatNumber>
            <StatHelpText>To go</StatHelpText>
          </Stat>
          {(bookingUtils.isOnlineTask(booking) || bookingUtils.isLongitudinal(booking)) && (
            <Stat>
              <StatNumber>{_.get(booking, 'ParticipantSessionsCount.in_progress', 0) || 0}</StatNumber>
              <StatHelpText>In progress</StatHelpText>
            </Stat>
          )}
          <Stat>
            <StatNumber>
              {(_.get(booking, 'ParticipantSessionsCount.invited', 0) || 0) +
                (_.get(booking, 'ParticipantSessionsCount.waiting_list', 0) || 0)}
            </StatNumber>
            <StatHelpText>Invited</StatHelpText>
          </Stat>
          {refundedParticipants > 0 && (
            <Stat>
              <StatNumber>{refundedParticipants}</StatNumber>
              <StatHelpText>Refunded</StatHelpText>
            </Stat>
          )}
        </Wrap>
      </Box>
    );
  };

  return (
    <Box className="recruitmentPageContainer">
      {props.onClose && (
        <div className="closeContainer" onClick={props.onClose}>
          <div className="closeButton">
            <p className="closeText">Close</p>
            <ArrowRight className="arrow h-5 w-5" />
          </div>
        </div>
      )}
      <Box className="recruitmentContent" {...props.contentContainerProps}>
        <VStack w="full" spacing={6} alignItems="flex-start">
          {renderByoRecruitment()}
          {renderRecruitmentStats()}
          {renderProjectProgress()}
          <HStack alignItems="flex-start">
            {(bookingUtils.isRemote(booking) || bookingUtils.isInPerson(booking)) && (
              <Button
                size="sm"
                variant="ghost"
                leftIcon={<Calendar className="h-4 w-4" />}
                onClick={() => {
                  setOpenSyncCalendarModal(!openSyncCalendarModal);
                }}
              >
                Sync to calendar
              </Button>
            )}

            <Button
              as={newPssBooking ? undefined : (CSVLink as any)}
              variant="ghost"
              size="sm"
              onClick={() => {}}
              leftIcon={<ArrowDownToLine className="h-4 w-4" />}
              data={csv.getTemplate('Applicants', {
                header: tableColumns,
                content: orderedAndFiltered,
                booking,
              })}
              filename={`askable_applicants_${moment().format('DDMMMYYYY-hhmmA')}.csv`}
              target=""
            >
              Export participants
            </Button>
          </HStack>
        </VStack>
      </Box>
      {openSyncCalendarModal && (
        <SyncCalendarModal
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ open: true; onClose: () => void; booking: ... Remove this comment to see the full error message
          open={openSyncCalendarModal}
          onClose={() => setOpenSyncCalendarModal(false)}
          booking={booking}
        />
      )}
    </Box>
  );
};

export default CherryPickingRecruitment;

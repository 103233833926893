import { createLucideIcon } from 'lucide-react';

export const TimerSolid = createLucideIcon('TimerSolid', [
  [
    'path',
    {
      d: 'M10 1a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2h-4Zm2 4a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm3 5a1 1 0 0 1 .7 1.7l-3 3a1 1 0 0 1-1.4-1.4l3-3c.2-.2.4-.3.7-.3Z',
      fill: 'currentColor',
      stroke: 'none',
    },
  ],
]);

import { graphql } from 'src/graphql';

export const FigmaFileDetails = graphql(`
  query FigmaFileDetails($fileId: String!) {
    figmaFile(fileId: $fileId) {
      _id
      created
      updated
      file_id
      _importer_user_id
      name
      figma_last_modified
      link_is_public
      frames {
        node_id
        name
        width
        height
        image_url
        is_mobile
      }
      canvases {
        node_id
        name
        flow_starting_point_node_ids
      }
    }
  }
`);

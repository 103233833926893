import { AskableIconLoading } from '@askable/ui/core/askable-icon';
import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useOutletContext, useParams, useResolvedPath } from 'react-router-dom';
import { Alert, AlertDescription, Flex } from 'ui';
import { useQuery } from 'urql';

import { FeedbackForm } from 'components/FeedbackForm/FeedbackForm';
import { useConnectedClient } from 'context/ConnectedClientContext';
import { BookingContainerBookingDocument } from 'generated/graphql';
import { bookingUtils } from 'lib/booking';
import { BOOKING_TYPE } from 'lib/constants';

import { bookingRoutesNames } from './containers/BookingParticipantsContainer/components/BookingParticipantsNavigation';
import { useBookingParticipantState } from './containers/BookingParticipantsContainer/state/booking-participant-state';
import { userCanViewBooking } from './utils';

import type { BookingContainerBookingQuery, BookingContainerBookingQueryVariables } from 'generated/graphql';
import type { FC } from 'react';

const InReviewBanner = ({ isInReview }: { isInReview: boolean }) => {
  const { pathname: resolvedPathname } = useResolvedPath(bookingRoutesNames.participants);
  const { pathname: currentPathname } = useLocation();
  const isOnParticipantsRoute = resolvedPathname === currentPathname;
  /**
   * Not show in review banner when
   * 1. Booking is not in review
   * 2. New participant table is not available and user is on participant table route
   */
  if (!isInReview || isOnParticipantsRoute) {
    return null;
  }

  return (
    <Alert
      status="warning"
      alignItems="center"
      minH="14"
      justifyContent="center"
      borderRadius="none"
      textAlign="center"
    >
      <AlertDescription>Study currently in review. This usually takes between 2-3 business hours.</AlertDescription>
    </Alert>
  );
};

export const BookingContainer: FC = () => {
  const params = useParams();
  const isDisplayingHeaderAds = localStorage.getItem('showingHeaderAds') === 'true';
  const viewer = useConnectedClient();

  const resetParticipantState = useBookingParticipantState(a => a.reset);
  const [{ data, fetching, error }] = useQuery<BookingContainerBookingQuery, BookingContainerBookingQueryVariables>({
    query: BookingContainerBookingDocument,
    variables: {
      id: params.bookingId!,
    },
  });

  const bookingContext = useMemo(() => {
    return data?.bookingByID;
  }, [data]);

  useEffect(() => {
    return () => {
      resetParticipantState();
    };
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    throw error;
  }, [error]);

  if (fetching || !bookingContext) {
    return null;
  }

  if (!userCanViewBooking(bookingContext, viewer.details)) {
    throw new Error('Booking not found');
  }

  // Redirect unmod studies to new UI
  if (bookingContext.type === BOOKING_TYPE.UNMODERATED) {
    return <Navigate to={`/studies/${bookingContext._id}`} />;
  }

  return (
    <>
      <Flex
        w="full"
        alignItems="flex-start"
        direction="column"
        h={`calc(var(--mainContainerHeight)${isDisplayingHeaderAds ? ' - var(--headerAdsHeight)' : ''})`}
      >
        <InReviewBanner isInReview={bookingUtils.isInReview(data?.bookingByID)} />
        <Flex direction="column" h="full" w="full">
          <Suspense
            fallback={
              <div className="flex h-screen w-screen items-center justify-center">
                <AskableIconLoading />
              </div>
            }
          >
            <Outlet context={bookingContext} />
          </Suspense>
        </Flex>
      </Flex>

      {data?.bookingByID?.project?.type !== 1 ? (
        <FeedbackForm
          id={params.bookingId}
          missionCritical={bookingContext.admin?.mission_critical}
          name={bookingContext.name ?? ''}
          rating={bookingContext.rating?.overall}
          status={bookingContext.status ?? 1}
          type="study"
        />
      ) : null}
    </>
  );
};

export function useBooking() {
  return useOutletContext<BookingContainerBookingQuery['bookingByID']>();
}

export type BookingContainerConfig = {
  sessionType: 'individual' | 'group';
};

export function useBookingContainerConfig() {
  const booking = useBooking();

  return useMemo<BookingContainerConfig>(() => {
    return {
      sessionType: booking?.config?.session?.type === 1 ? 'individual' : 'group',
    };
  }, [booking?.config?.session]);
}

import { AnchorButton } from '@askable/ui/core/button';
import { TentTreeIcon } from 'lucide-react';

import { ErrorPage } from '../ErrorPage/ErrorPage';

export const NotFound = () => {
  return (
    <ErrorPage className="text text-foreground md:max-w-2xl">
      <TentTreeIcon className="h-8 w-8" />
      <h2 className="text-lg font-medium text-foreground">This page couldn’t be found</h2>
      <p>Let's get you back on track</p>
      <div className="pt-2">
        <AnchorButton variant="primary" size="lg" href="/">
          Return home
        </AnchorButton>
      </div>
    </ErrorPage>
  );
};
